import { ComplianceServiceHelper } from '../shared/services/compliance-service-helper';
import { SharepointMapper } from 'src/shared/utils/sharepoint-mapper'; 
import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subscription, forkJoin, from, interval, of, timer } from 'rxjs';

import { PeoplePickerSelectedRow } from 'src/shared/components/peoplepicker-dynamic-table/people-picker-selected-row.interface'; 
import { AppSettings } from 'src/shared/app-settings';
import { MainService } from 'src/shared/services/main.service';
import { CustomFormControl } from 'src/shared/custom/custom-form-control';
import { DynamicTableAction } from 'src/shared/models/dynamic-table-action';
import { DateFormatterService } from 'src/shared/utils/utils-date';
import { SharepointValidator } from 'src/shared/utils/sharepoint-mapper';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { PeoplePickerUser } from 'src/shared/models/people-picker-user';
import { PeoplepickerService } from 'src/shared/services/peoplepicker.service';
import { CurrentUser } from 'src/shared/models/current-user-data';
import { SecurityService } from 'src/shared/services/security.service';
import { FormStatusValue, StatusValue } from 'src/shared/models/form-status-value';
import { ComplianceService } from '../shared/services/compliance-service';
import { AppContext } from '../shared/models/app-context';
import { RolesValidatorService } from '../shared/utils/compliance-roles-services';
import { ClosingChecklistSecurityMatrix } from '../shared/utils/closing-checklist-security-matrix';
import { ComplianceSharepointMapper } from '../shared/utils/compliance-sharepoint';
import { SharepointObject } from '../shared/models/sharepoint-object';
import { SharepointObjectMyDeal } from '../shared/models/sharepoint-object-mydeal';
import { DealTeamObject } from '../shared/models/dealteam-object';
import { FormListFields } from '../shared/models/form-list-fields';
import { FreeTextTableItem } from 'src/shared/models/free-text-table-item';

import { ModalComponent } from 'src/shared/components/modal/modal.component';
import { EngagementOverviewComponent } from '../engagement-overview/engagement-overview.component';
import { CrossBorderComponent } from '../cross-border/cross-border.component';
import { CapitalRaisedComponent } from '../capital-raised/capital-raised.component';
import { FreeTextSelectedRow } from 'src/shared/components/free-text-table/free-text-selected-row.interface'; 
import { FreeTextObject } from '../shared/models/free-text-object';
import { MarketingAndCommunicationsComponent } from '../marketing-and-communications/marketing-and-communications.component';
import { BooksAndRecordsComponent } from '../books-and-records/books-and-records.component';
import { SupervisorySignoffComponent } from '../supervisory-signoff/supervisory-signoff.component';
import { FeesComponent } from '../fees/fees.component';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { FormActionGroupValue } from '../shared/models/form-action-group-value';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { ReactivateFormComponent } from 'src/shared/components/reactivate-form/reactivate-form.component';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { SharepointObjectCompliance } from '../shared/models/sharepoint-object-compliance';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';
import { AlertMessageComponent } from 'src/shared/components/alert-message/alert-message.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ValuationMetricsComponent } from '../valuation-metrics/valuation-metrics.component';
import { BuyerDealMarketingComponent } from '../buyer-deal-marketing/buyer-deal-marketing.component';
import { ProjectOverviewComponent } from '../project-overview/project-overview.component';
import { SharepointDealMarketingSection, SharePointObjectValuationMetrics } from '../shared/models/sharepoint-closingchecklist-object';
import { ClientOverviewComponent } from '../client-overview/client-overview.component';
import { DealOverviewComponent } from '../deal-overview/deal-overview.component';
import { SharepointClosingCheckListObject } from '../shared/models/sharepoint-closingchecklist-object';
import { QualityOfEarningsComponent } from '../quality-of-earnings/quality-of-earnings.component';
import { DatesTimelineComponent } from '../dates-timeline/dates-timeline.component';
import { SharepointValuationObject } from '../shared/models/sharepoint-valudation-object';
import { IndemnificationComponent } from '../indemnification/indemnification.component';
import { SharepointDealMarketingObject } from '../shared/models/sharepoint-deal-marketing-object';
import { DCFFeeStructureComponent } from '../dcf-fee-structure/dcf-fee-structure.component';
import { DealStructureComponent } from '../deal-structure/deal-structure.component';
import { AdditionalCommentaryComponent } from '../additional-commentary/additional-commentary.component';
import { Series24ApprovalComponent } from '../series-24-approval/series-24-approval.component';
import { Series24FinalApproval } from 'src/shared/models/series24FinalApproval';
import { DealTeamSignoffsComponent } from '../deal-team-signoffs/deal-team-signoffs.component';
import { FinanceComponent } from '../finance/finance.components';
import { DocumentTypeItem } from 'src/shared/models/document-type-item';
import { AttachmentItem } from 'src/shared/models/attachment-item';
import { FilesService } from 'src/shared/services/files.service';
import { DatePipe } from '@angular/common';
import { DealTeamSignoffs } from 'src/shared/models/deal-team-signoffs';
import { ClosingChecklistNotifyPopupComponent } from 'src/shared/components/alerts/closing-checklist-notify-popup/closing-checklist-notify-popup.component';
import { DealwbsService } from 'src/app/services/dealwbs.service';
import { ClosingCheckListService } from 'src/app/services/closingchecklist.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { DealWbsSetupService } from 'src/app/deal-wbs-setup/shared/services/deal-wbs-setup.service';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { MainServiceHelper } from 'src/shared/services/main-service-helper';
import { DateService } from 'src/app/services/date.service';
import { EngagementHealthObjectServiceService } from 'src/app/services/engagement-health-object-service.service';
import { AlertService } from 'src/app/_alert';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';


const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/groups/' + environment.aDGroupId + '/members?$select=displayName,jobTitle,userPrincipalName&$top=200';

const modalTypes = {
  submit: 'submit',

  initiatedform: 'initiated-form',
  mandatoryFields: 'mandatory-fields',
  retrieve: 'retrieve',
  discontinue: 'discontinue',
  discontinueReason: 'discontinue-reason',
  discontinueConfirmed: 'discontinue-confirmed',
  returnForRework: 'return-for-rework',
  returnForReworkReason: 'return-for-rework-reason',
  returnForReworConfirmed: 'return-for-rework-confirmed',
  approval: 'approval',
  access: 'access',
  noDCFMember: 'no-dcf-member',
  userLeftDcf: 'user-left-dcf-member',
  reactivateForm: 'reactivate-form',
  reactivateConfirmed: 'reactivate-confirmed',
  filesUploading: 'files-uploading',
  error: 'error',
  save: 'save',
  noValidForm: 'no-valid-form',
  formUpdated: 'form-updated',
  signOffApproved: 'sign-Off-Approved',
  allDealTeamMemberApproval: 'all-DealTeamMember-Approval',
  complianceSignOff: 'compliance-sign-Off',
  retryworkflow: 'retry-workflow'
};

const formGroups = {
  engagementOverviewFormGroup: 'engagementOverviewFormGroup',
  crossBorderFormGroup: 'crossBorderFormGroup',
  capitalRaisedFormGroup: 'capitalRaisedFormGroup',
  marketingAndComunicationsFormGroup: 'marketingAndComunicationsFormGroup',
  booksAndRecordsFormGroup: 'booksAndRecordsFormGroup',
  supervisorySignoffFormGroup: 'supervisorySignoffFormGroup',
  feesFormGroup: 'feesFormGroup',
  myDealFormGroup: 'myDealFormGroup',
  projectOverViewFormGroup: 'projectOverViewFormGroup',
  clientOverViewFormGroup: 'clientOverViewFormGroup',
  dealOverViewFormGroup: 'dealOverViewFormGroup',
  dcfFeeStrcutureFormGroup: 'dcfFeeStrcutureFormGroup',
  buyerDealMarketingFormGroup: 'buyerDealMarketingFormGroup',
  dealStructureFormGroup: 'dealStructureFormGroup',
  qualityOfEarningsFormGroup: 'qualityOfEarningsFormGroup',
  additionalCommentaryFormGroup: 'additionalCommentaryFormGroup',
  timelineContentFormGroup: 'timelineContentFormGroup',
  financeFormGroup: 'financeFormGroup',
  valuationMetricsFormGroup: 'valuationMetricsFormGroup',
  indemnificationFormGroup: 'indemnificationFormGroup'

};
const responseCodes = {
  ok: 'ok',
  conflicts: 'conflicts',
  tryAgain: 'tryAgain',
  fail: 'fail',
  formUpdated: 'formUpdated',
  incomingUpdates: 'incomingUpdates',
  noDCFMember: 'no-dcf-member',
  noValidForm: 'no-valid-form',
  returnForReworkReasonEmpty: 'return-for-rework-reason-empty',
  filesUploading: 'files-uploading'
};
const alertOptions = {
  autoClose: true,
  keepAfterRouteChange: false
};
const alertTypes = {
  success: 'success',
  warning: 'warning',
  primary: 'primary',
  danger: 'danger'
};
const documentType: Array<DocumentTypeItem> = new Array<DocumentTypeItem>();

@Component({
  selector: 'app-closing-checklist-form',
  templateUrl: './closing-checklist-form.component.html',
  styleUrls: ['./closing-checklist-form.component.scss']
})
export class ClosingChecklistFormComponent implements OnInit {

  appFormGroup: any;
  currentUser: CurrentUser;
  complianceFormId: number;
  formId: number;
  myDealFormId: number;
  isFinanceForm: boolean = false;
  valuationListId: number;
  dealMarketingId: number;
  saveInProgress: boolean = false;
  saveButtonVisible: boolean;
  showSeries24FinalApprovalSection: boolean = false;
  showDealTeamSignoffsSection: boolean = false;
  formStatus: string;
  modalType: string;
  currentUserName:any;
  initialMyDealList:any;
  initialDealWbsList: any;
  breakPointMultiValueStatus: boolean;
  subscription: Subscription;
  formSubmitted: boolean;
  initialFormValidators: Array<any>;
  discontinueReason = '';
  returnForReworkReason = '';
  discontinuedFormActionGroup: string;
  maxNumOfCharacters: number;
  discontinuedFormStatus: string;
  engagementId:string;
  appContext: AppContext;
  sharePointMapper: ComplianceSharepointMapper;
  pS24Values: Array<PeoplepickerDynamicTableItem> = [];
  ps24Row: PeoplePickerSelectedRow;
  initialpS24Values: Array<PeoplepickerDynamicTableItem> = [];
  previousSeries24List = AppSettings.compliancePreviousSeries24;
  ppmdValues: Array<PeoplepickerDynamicTableItem> = [];
  ppmdRow: PeoplePickerSelectedRow;
  initialPpmdValue: Array<PeoplepickerDynamicTableItem> = [];
  previousPPMDList = AppSettings.compliancePreviousPPMD;
  dealTeamList = AppSettings.dealTeamTableList;
  dealTeamValues: Array<PeoplepickerDynamicTableItem> = [];

  initialAttachments: Array<DocumentTypeItem> = [];
  formStatusRetrieve: any;


  formActionGroup = AppSettings.initialFormActionGroup;
  initialMyDealSetupObject: SharepointObjectMyDeal;
  initialSharepointObject: any;

  dttlValues: Array<FreeTextTableItem> = [];
  dttlList = AppSettings.complianceDTTLList;
  initialdttlValues: Array<FreeTextTableItem> = [];

  counterpartiesList = AppSettings.complianceCounterpartiesList;
  counterpartiesValues: Array<FreeTextTableItem> = [];
  initialcounterpartiesValues: Array<FreeTextTableItem> = [];

  S24DeletionActive = false;
  PPMDDeletionActive = false;
  showInitiate: boolean=false; //TODO: Change this to false when the component is ready
  initialClosingCheckList:any;

  @ViewChild('Modal', { static: true })
  modal: ModalComponent;

  @ViewChild(ClosingChecklistNotifyPopupComponent, { static: true }) notifypopupComponent: ClosingChecklistNotifyPopupComponent;

  @ViewChild(EngagementOverviewComponent, { static: true })
  engagementOverviewComponent: EngagementOverviewComponent;

  @ViewChild(CrossBorderComponent, { static: true })
  crossBorderComponent: CrossBorderComponent;

  @ViewChild(CapitalRaisedComponent, { static: true })
  capitalRaisedComponent: CapitalRaisedComponent;

  @ViewChild(MarketingAndCommunicationsComponent, { static: true })
  marketingAndCommunicationsComponent: MarketingAndCommunicationsComponent;

  @ViewChild(BooksAndRecordsComponent, { static: true })
  booksAndRecordsComponent: BooksAndRecordsComponent;

  @ViewChild(SupervisorySignoffComponent, { static: true })
  supervisorySignoffComponent: SupervisorySignoffComponent;

  @ViewChild(FeesComponent, { static: true })
  feesComponent: FeesComponent;

  @ViewChild(ValuationMetricsComponent, { static: true })
  valuationMetricsComponent: ValuationMetricsComponent;

  @ViewChild(DealStructureComponent, { static: true })
  dealStructureComponent: DealStructureComponent;

  @ViewChild(IndemnificationComponent, { static: true })
  indemnificationComponent: IndemnificationComponent;

  @ViewChild(BuyerDealMarketingComponent, { static: true })
  buyerDealMarketingComponent: BuyerDealMarketingComponent;

  @ViewChild(AdditionalCommentaryComponent, { static: true })
  additionalCommentaryComponent: AdditionalCommentaryComponent;

  @ViewChild(Series24ApprovalComponent, { static: true })
  series24ApprovalComponent: Series24ApprovalComponent;

  @ViewChild(DealTeamSignoffsComponent, { static: true })
  dealTeamSignOffsComponent: DealTeamSignoffsComponent;

  @ViewChild(SidebarComponent, { static: true })
  sidebarComponent: SidebarComponent;

  @ViewChild(AlertMessageComponent, { static: false })
  alert: AlertMessageComponent;

  @ViewChild(ReactivateFormComponent, { static: false })
  reactivateFormComponent: ReactivateFormComponent;

  @ViewChild(ProjectOverviewComponent, { static: true })
  projectOverviewComponent: ProjectOverviewComponent;

  @ViewChild(ClientOverviewComponent, { static: true })
  clientOverViewComponent: ClientOverviewComponent;

  @ViewChild(DealOverviewComponent, { static: true })
  dealOverviewComponent: DealOverviewComponent;

  @ViewChild(DCFFeeStructureComponent, { static: true })
  dCFFeeStructureComponent: DCFFeeStructureComponent;

  @ViewChild(DatesTimelineComponent, { static: true })
  datesTimelineComponent: DatesTimelineComponent;

  @ViewChild(QualityOfEarningsComponent, { static: true })
  qualityOfEarningsComponent: QualityOfEarningsComponent;

  @ViewChild(FinanceComponent, { static: true })
  financeComponent: FinanceComponent;

  executingWorkflowChangeAction: any;
  alertMessageType: any;

  initialMyDealObject: any;

  initialValuationMetrics: any;

  initialBuyerDealMarketing: any;

  initialCCFormObject: any;
  complianceResponse:any;
  series24FinalApproval: Series24FinalApproval;
  series24Signature: boolean;
  Series24: string;
  newWorkflow: boolean;
  ShowSubmitForReviewSection: boolean;
  isAutoSaveEnabled:boolean=false;
  conflictInputsOnSave: boolean;
  overwriteInputs: boolean;
  differences: any = {};
  adGroupMember: any;
  initialDealTeamTable: Array<PeoplepickerDynamicTableItem> = [];
  documentTypes: Array<DocumentTypeItem> = [];
  hasSeries24ApprovalAccess: boolean;
  hasDealTeamSignoffApprovalAccess: boolean;
  firstColumnItemList:any;
  secondColumnItemList:any;
  thirdColumnItemList:any;
  DiscontinuedOn: any;
  DiscontinuedBy: any;
  s24GroupUsers: any;
  rangeKey: any;
  MyDealRangeKey: any;
  isadgroup:boolean=false;
  private responseevaluations:any;
  private dealmarketigncount:any;



  constructor(private mainService: MainService,
    private closingCheckListService:ClosingCheckListService,
    private httpClient: HttpClient,
    private peoplepickerService: PeoplepickerService,
    private route: ActivatedRoute,
    private ComplianceService: ComplianceService,
    private dateFormatterService: DateFormatterService,
    private securityService: SecurityService,
    private spinnerService: SpinnerService,
    private fileService: FilesService,
    private rolesValidator: RolesValidatorService,
    private tooltipHelperService: TooltipHelperService,
    private ComplianceServiceHelper: ComplianceServiceHelper,
    private changeDetector: ChangeDetectorRef,
    private datePipe: DatePipe,
    private dealWbsService: DealwbsService,
    private dealWbsSetupService: DealWbsSetupService,
    private dropdownService: DropdownService,
    private mainServiceHelper: MainServiceHelper,
    private dateService: DateService,
    private commonHelperService: CommonHelperService,
    private alertService:AlertService,
    private EHOService:EngagementHealthObjectServiceService,
      public globalHelperService: GlobalHelperService,
      private cdr: ChangeDetectorRef
  ) { }

  get formGroups() {
    return formGroups;
  }

  ngOnInit() {
    this.UIActions();
    document.body.style.overflowX = 'hidden';
    this.spinnerService.showAutoSaveSpinner();
    localStorage.setItem("CurrentAccessPage","ClosingChecklistForm");
    this.globalHelperService.currentAccessPage="ClosingChecklistForm";
    this.documentTypes[0] = AppSettings.documentType;
    this._setFormId();
      localStorage.setItem('formId', this.formId.toString());
      this.globalHelperService.formID = this.formId.toString();
    this._createForm();
    
    // this.commonHelperService.getUserGroupMembership().subscribe((res:any)=>{
    //   this.isadgroup=res?.isADGroupUser;
    // })
    // this.getAdGroup();

    this.commonHelperService.getUserGroupMembership().subscribe((res:any) => {
      this.isadgroup = res?.isADGroupUser;
      this.myDealFormId =  res?.myDealID;
      if(res!=null && res!=undefined)this.getAdGroup();      
    });   
    this.currentUserName = localStorage.getItem("userProfileName")?.toString();
    this.cdr.detectChanges();
  }

  // getAdGroup() {
  //   if(this.formId){
  //       forkJoin([
  //         //this.mainService.getCurrentUserData_Observable(),
  //         this.mainService.getAppConfigurationValue(AppSettings.ContentManagementFormTypes.ClosingChecklist)
  //         ]).subscribe(([applicationContext
  //         ]) => {
  //           this.commonHelperService.getUserData().subscribe(user=>{
  //             if(user.length !=0){
  //          this.currentUser = user;

  //          this.appContext = applicationContext;
  //          this.maxNumOfCharacters= applicationContext.maxLengthComments;
  //         //  this.dealWbsService.GetDealADGroupDetails().subscribe({
  //         //   next: (profile: any) => {
  //         //     this.adGroupMember = profile;
  //         //     //  if (this._isDCFMemberInAdGroup(this.currentUser.email,this.adGroupMember.value)) {
  //               if (this.isadgroup) {
  //              //  localStorage.setItem('adGroupMember',JSON.stringify(this.adGroupMember.value));
  //               //this._setFormId();
               
  //               //this.LoadDropdownLists();
                
  //               this.initializeSecurity();
  //             } else {
  //               this.spinnerService.hideAutoSaveSpinner();
  //               this._openModal(modalTypes.noDCFMember);
  //               this.spinnerService.hideAutoSaveSpinner();
  //             }
  //         // },
  //         // error: (err: any) => {
  //         //   if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
  //         //   this.spinnerService.hideAutoSaveSpinner();
  //         //   console.log("Error" + err);
  //         // }
  //         // })
  //       }
  //       })
  //       })
  //     }
  //     else{
  //       this._openModal(modalTypes.noValidForm);
  //       this.spinnerService.hideAutoSaveSpinner();
  //     }
  // }

  getAdGroup() {
    if (this.formId) {
      this.commonHelperService.getUserData().subscribe(user => {
        if (user.length != 0) {
          this.currentUser = user;
          this.mainService.currentUser=this.currentUser;
          if (this.isadgroup) {
            this.adGroupMember=JSON.parse((localStorage as any).getItem("adGroupMember"));
               this.initializeSecurity();
              } else {
                this.spinnerService.hideAutoSaveSpinner();
                this._openModal(modalTypes.noDCFMember);
                this.spinnerService.hideAutoSaveSpinner();
              }
          // forkJoin([this.mainService.getAppConfigurationValue(AppSettings.ContentManagementFormTypes.ClosingChecklist)]).subscribe(([applicationContext]) => {
          //   this.appContext = applicationContext;
          //   this.maxNumOfCharacters = applicationContext.maxLengthComments;

          //   if (this.isadgroup) {
          //    this.initializeSecurity();
          //   } else {
          //     this.spinnerService.hideAutoSaveSpinner();
          //     this._openModal(modalTypes.noDCFMember);
          //     this.spinnerService.hideAutoSaveSpinner();
          //   }
          // })
        }
      });
    }
    else {
      this._openModal(modalTypes.noValidForm);
      this.spinnerService.hideAutoSaveSpinner();
    }
  }
  UIActions(){
    document.getElementById("dashboardLink")?.scrollIntoView({behavior:'smooth'})
    document.body.style.overflow ="hidden";
   }
 

  initializeSecurity_bckNew() {
      this.closingCheckListService.GetClosingCheckListFormDetails(this.formId).subscribe((responseFormItem:any)=>{
        if(responseFormItem.length==0){
          this.spinnerService.hideAutoSaveSpinner();
          this._openModal(modalTypes.noValidForm);
          return;
        }
        this.initialClosingCheckList=responseFormItem = responseFormItem[0];
        if(responseFormItem!=undefined){
          this.myDealFormId = responseFormItem.MyDealID
          this.mainService.formId = this.formId;
          this.mainService.myDealFormId  = this.myDealFormId;
          this.rolesValidator.validateFormRoles_ClosingCheckList(this.currentUser.groups,this.currentUser.email,this.formId,this.myDealFormId).subscribe((roleList)=>{
            if (responseFormItem.FormStatus!=null && responseFormItem.FormStatus!="") {
              this.securityService.getPermissions("",responseFormItem.FormStatus, responseFormItem.FormActionGroup, roleList, ClosingChecklistSecurityMatrix);
              this.securityService.securityMatrixEvent.subscribe(response => {
                console.log("Security matrix generated.");
                //this.initializeForm(responseFormItem);
              });
            } else {
              // this.dealWbsSetupService.updateFormOnRetryWorkflow(responseFormItem.FormStatus, this.formId,this.initialDealWbsList.Created)
              // .subscribe((response) => {
              //   if (response) {
              //     this._openModal(modalTypes.retryWorkflow);
              //   }
              //   this.spinnerService.hideAutoSaveSpinner();
              // });
            }
          })
        }
    });
  }
  initializeSecurity() {
    forkJoin([
      //this.mainService.retrieveForm_CCF(AppSettings.closingChecklistFormList, this.formId),
      this.ComplianceService.getListItems_CCL(AppSettings.closingCheckListValuations, this.formId),
      this.ComplianceService.getListItems_CCL(AppSettings.closingCheckListDealMarketingCount, this.formId),
      this.mainService.getAppConfigurationValue(AppSettings.ContentManagementFormTypes.ClosingChecklist),
      this.ComplianceService.retrieveTableDetails(AppSettings.complianceFormList, this.formId,environment.ClosingChecklistForm),
      this.ComplianceService.retrieveTableDetails(AppSettings.myDealFormList, this.myDealFormId,environment.Newdealform), 
      this.rolesValidator.validateFormRoles_ClosingCheckList(this.currentUser.groups, this.currentUser.email, this.formId, this.myDealFormId),
    ]).subscribe(([valuationList, dealMarketingList,applicationContext,responseComplianceForm,resMyDeal,roleList]) => {
      this.appContext = applicationContext;
      this.maxNumOfCharacters = applicationContext.maxLengthComments; 
      if(this.mainService.closingchecklistdata.length==0){
        this.spinnerService.hideAutoSaveSpinner();
        this._openModal(modalTypes.noValidForm);
        return;
      }        
      // let responseFormItem = responseFormList[0];
        let responseFormItem = this.mainService.closingchecklistdata[0];
        this.responseevaluations=valuationList;
        this.dealmarketigncount=dealMarketingList;
        this.initialClosingCheckList=responseFormItem;
        this.isFinanceForm = (responseFormItem.IsFinanceForm == "TRUE")? true:false;
        this.myDealFormId = responseFormItem[AppSettings.myDealSiteColumn];
        this.mainService.myDealFormId = this.myDealFormId;
        this.mainService.formId = this.formId;                       
        if (responseComplianceForm) {
          //this.myDealFormId = responseComplianceForm.MyDealId;
          this.complianceResponse=responseComplianceForm;
          this.complianceFormId = responseComplianceForm.ID;
          this.mainService.complianceID=this.complianceFormId;          
        }
        if(resMyDeal){
          this.MyDealRangeKey=resMyDeal[0].Created;
        }

        const status={
          formStatus: (this.formId==null||this.formId==undefined)?AppSettings.initialFormStatus:responseFormItem.FormStatus,
          formActionGroup: (this.formId==null||this.formId==undefined)?AppSettings.initialFormActionGroup:responseFormItem.FormActionGroup
        }
        this.formStatusRetrieve = status;
        this.securityService.getPermissions(this.currentUser.email,status.formStatus, status.formActionGroup, roleList, ClosingChecklistSecurityMatrix);
       // this._initialSetValidators(responseFormItem.FormStatus, responseFormItem.FormActionGroup, responseFormItem.IsFinanceForm);
        //this._populateTooltips();
      if (!this._areSubListCreated(valuationList, dealMarketingList)) {
        const createValuationObjectItem = {
          FormID: this.formId.toString()
        };
        const createDealObjectItem = {
          FormID: this.formId.toString()
        };
        const complianceObjectItem = {
          FormID: this.formId.toString(),
          MyDealID: this.mainService.myDealFormId.toString()
        };
        //Added this here to load the form for the new CCL Form Creation
        this.securityService.securityMatrixEvent.subscribe(response => {
          forkJoin([
            this.mainService.createListItem_CCF(AppSettings.closingCheckListValuations, createValuationObjectItem),
            this.mainService.createListItem_CCF(AppSettings.closingCheckListDealMarketingCount, createDealObjectItem),
            this.mainService.createListItem_CCF(AppSettings.complianceFormList, complianceObjectItem),
  
          ])
            .subscribe(([valuationListCreated, dealMarketingListCreated, complianceFormList]) => {
            this.valuationListId = valuationListCreated;
            this.dealMarketingId = dealMarketingListCreated;
            this.complianceFormId = complianceFormList.ID;
            this.initializeForm(responseFormItem,resMyDeal);
            //this._getItem(responseFormItem,resMyDeal);
          });
        });
      }
      else {
        this.valuationListId = valuationList.FormID;
        this.dealMarketingId = dealMarketingList.FormID;
        this._getItem(responseFormItem,resMyDeal);
      }
    });
    //this.LoadDropdownLists();
  }

  initializeForm(responseFormItem:any,resMyDeal:any) {
    if (this.hasPermission('Access')) {
      this._initialSetValidators(responseFormItem.FormStatus, responseFormItem.FormActionGroup, responseFormItem.IsFinanceForm);
      this._createSharepointMapper();
     if (responseFormItem.FormStatus == "Draft") {
        this.rangeKey=responseFormItem.Created;
        this.showInitiate = false;
        this.projectOverviewComponent.showInitiateButton();
      }
      else {
        this.showInitiate = true;
        this._setSharepointMapper();
        this._populateDocumentTypes(this.documentTypes);
         this._populateTooltips();
      }
      this.LoadDropdownLists();
      this._handlerRetrieve(responseFormItem,resMyDeal);
        } else {
           this._openModal(modalTypes.access);
           this.spinnerService.hideAutoSaveSpinner();
         }
  }

  //  isValidStatus(FormStatus:string) {
  //   const validFormStatus = ['Draft', 'Pending', 'Discontinued', 'Completed'];
  //     var isValidFormStatus = validFormStatus.find(function(status) {
  //       return FormStatus === status;
  //     });

  //     var isValid = isValidFormStatus ? true : false;
  //     return isValid;
  //   }

onInitiate() {
  this.spinnerService.showAutoSaveSpinner();
  from(this._validateDCFUsersBeforeUserAction()).pipe(
    switchMap(areValid => {
      if (areValid) {
        this._setValidators(this.initialFormValidators);
        this.formSubmitted = true;
        const jsonItems = {
          Status:'Initiated',
          FormStatus: 'Initiated'
        };
        const jsonItemMyDeal = {
          EngagementName: this.appFormGroup.get(formGroups.myDealFormGroup).get('engagementName').value
        };
        if (this.appFormGroup.get(formGroups.myDealFormGroup).get('engagementName').value != "" && this.appFormGroup.get(formGroups.projectOverViewFormGroup).get('dealteamTable').value.length >= 1) {
          return from(this.mainService.updateForm_CCF(AppSettings.closingChecklistFormList, this.formId, jsonItems,this.rangeKey,true)).pipe(
            switchMap(() => from(this.mainService.updateForm_CCF(AppSettings.myDealFormList, this.myDealFormId, jsonItemMyDeal,this.MyDealRangeKey))),
            tap(() => {
              this._openModal(modalTypes.initiatedform);
              this.spinnerService.hideAutoSaveSpinner();
            })
          );
        } else {
          this.spinnerService.hideAutoSaveSpinner();
          this._openModal(modalTypes.mandatoryFields);
          return of();
        }
      } else {
        this.spinnerService.hideAutoSaveSpinner();
          this._openModal(modalTypes.userLeftDcf);
          return of();
      }
    })
  ).subscribe();
}
  initiateFormValueEventHandler(value:any) {
    this.onInitiate();
  }

  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
  }

  private _getFolderUrl() {
    //if(this.isFinanceForm)
    return this.financeComponent.getFolderUrl();
  }

  redirectOkModal(modalType: string) {
    if(modalType==modalTypes.noValidForm)
      {
       //this.buttonsVisible=false;
      }
      else
      {
         window.location.href =AppSettings.Engagementdashboard;
      }
    //window.location.href=AppSettings.ClosingCheckListForm;
  }

  scrollToTop() {    
    let data= document.getElementById('form-body') as HTMLInputElement;
    data.scrollIntoView();
  }

  retrieveForm(responseFormItem:any,resMyDeal:any) {
    this.UIActions();
    if(responseFormItem.FormStatus == "Draft")
    {
        this.formStatus = responseFormItem.FormStatus;
        this.formActionGroup = responseFormItem.FormActionGroup;
        this.discontinuedFormActionGroup = responseFormItem.DiscontinuedFormActionGroup;
        this.discontinuedFormStatus = responseFormItem.DiscontinuedFormStatus;
        this.DiscontinuedBy = responseFormItem.DiscontinuedBy;
        this.DiscontinuedOn = responseFormItem.DiscontinuedOn;
        let responseMyDeal = resMyDeal[0];
        this.dealTeamValues = this.mainService.myDealTeamTableList;
        let dealTeamValues1=this.mainService.myDealTeamTableList;
        console.log("draft"+this.dealTeamValues);
        this._populateProjectOverviewSection(dealTeamValues1);
        this._populateMyDealSection(responseMyDeal);
        this._handlerFormStatus();
        this._handlerSubmitForReviewSection();
        this.spinnerService.hideAutoSaveSpinner();
        this._openModal("retrieve");
       
    }
    else{
    const folderUrl = this._getFolderUrl();
    forkJoin([
      //this.ComplianceService.retrieveTableDetails(AppSettings.complianceFormList, this.formId,environment.ClosingChecklistForm),
      //this.ComplianceService.retrieveTableDetails(AppSettings.myDealFormList, this.myDealFormId,environment.Newdealform),
      this.fileService.getFiles(this.formId,AppSettings.FormTypes.ClosingCheckListForm),
      this.ComplianceService.retrieveTableDetails(this.dealTeamList, this.myDealFormId,environment.Newdealform),
      this.ComplianceService.retrieveTableDetails(this.previousSeries24List, this.complianceFormId,environment.ClosingChecklistForm),
      this.ComplianceService.retrieveTableDetails(this.previousPPMDList, this.complianceFormId,environment.ClosingChecklistForm),
      //this.ComplianceService.getDCFGroupMembers(),
      this.ComplianceService.getDTTLList(this.dttlList, this.complianceFormId),
      this.ComplianceService.retrieveTableDetails(this.counterpartiesList, this.complianceFormId,environment.ClosingChecklistForm),
     // this.ComplianceService.GetGroupData(AppSettings.series24Group),
     // this.ComplianceService.getListItems_CCL(AppSettings.closingCheckListValuations, this.formId),
     // this.ComplianceService.getListItems_CCL(AppSettings.closingCheckListDealMarketingCount, this.formId),
      //this.ComplianceService.retrieveTableDetails(AppSettings.closingChecklistAdditionalComments, this.formId,environment.ClosingChecklistForm),
     // this.ComplianceService.retrieveTableDetails(AppSettings.closingCheckListFeeStructureBreakpoints, this.formId,environment.ClosingChecklistForm),
     // this.ComplianceService.GetGroupData(AppSettings.closingChecklistVisitorsGroup),
      this.EHOService.GetEHOObjects(null,this.myDealFormId)
      //updateFormDealTeamMembers ///TODO:create this method for updating Mydeal DealTeamUsers in ClosingCheckListForm
      // tslint:disable-next-line: max-line-length
      // Note: tooltips for compliance are currently handled as strings within each component, uncomment next 2 lines if they need to be retrieved from BE
      // ,
      // this._populateTooltips()
    ]).subscribe(([ responseAttachments,dealteamlist,
        responsePreviousS24Table, responsePPMDTable, responseDTTL,
        responseCounterparties, 
      ehoData]) => {

        let responseMyDeal = resMyDeal[0];
        this.engagementId=ehoData[0].ID;
        let responseValuations=this.responseevaluations;
        let responseDealMarketings=this.dealmarketigncount;
        let responseDocumentComments=this.mainService.closingchecklistadditionalcomments;
        this.formStatus = responseFormItem.FormStatus;
        this.formActionGroup = responseFormItem.FormActionGroup;
        this.discontinuedFormActionGroup = responseFormItem.DiscontinuedFormActionGroup;
        this.discontinuedFormStatus = responseFormItem.DiscontinuedFormStatus;
        this.pS24Values = responsePreviousS24Table;
        this.s24GroupUsers=this.mainService.myDealS24List;      //series24users;
        this.ppmdValues = responsePPMDTable;
        this.dttlValues = responseDTTL;
        this.DiscontinuedBy = responseFormItem.DiscontinuedBy;
        this.DiscontinuedOn = responseFormItem.DiscontinuedOn;
        const counterpartiesList = responseCounterparties.map((item:any )=> {
          const counterparties = this.mainServiceHelper.createCounterpartiesItem(item.ID, item);
          return this.mainServiceHelper.createCounterpartiesFreeTextTableItem(item.ID, counterparties, item);
        });

        this.counterpartiesValues=counterpartiesList;
        let dealTeamValues1 =dealteamlist;// this.mainService.myDealTeamTableList;
        this.dealTeamValues = dealteamlist;
        this.DiscontinuedOn = responseFormItem.DiscontinuedOn;
        this.DiscontinuedBy = responseFormItem.DiscontinuedBy;        
        this._populateMyDealSection(responseMyDeal);
        //Uncomment below when we do peoplepicker binding
        console.log(" non draft"+this.dealTeamValues);
        this._populateProjectOverviewSection(this.dealTeamValues);
        this._populateClientOverviewSection(responseFormItem);
        this._populateDealOverviewSection(responseFormItem);
        this._populateTimeLineSection(responseFormItem);        
        this._populateQofEarningSection(responseFormItem);
        this._populateEngagementOverviewSection(this.complianceResponse, this.pS24Values, this.ppmdValues, this.counterpartiesValues);     
        this._populateDcfFeeStructureSection(responseFormItem);                
        this._populateFinanceSection(responseFormItem);
        this._populateDocumentSection(responseAttachments);
        this._populateCrossBorderSection(this.complianceResponse, this.dttlValues);
        this._populateCapitalRaisedSection(this.complianceResponse);
        this._populateMarketingAndCommunicationsSection(this.complianceResponse);
        this._populateBooksAndRecordsSection(this.complianceResponse);
        this._populateSupervisorySignoffSection(this.complianceResponse);
        this._populateFeesSection(this.complianceResponse);
        this._populateBuyerDealerMarketingSection(responseFormItem, responseDealMarketings);
        this._populateValuationMetricsSection(responseValuations, responseFormItem);
        this._populateDealStructure(responseFormItem); // this should be populated affter valuation Metric section dont change the order.
        this._populateIndemnificationSection(responseFormItem);
        this._populteCommentSection(responseDocumentComments);
      //Added below to load CurrentPPMDLead
        this._createPeoplePickerUserArrays(this.complianceResponse);
        
        this.Series24 = responseMyDeal.Series24DisplayNames ? responseMyDeal.Series24DisplayNames : "";
        this.mainService.getDealTeamUserReviewsCCL(AppSettings.closingChecklistDealTeamSignOff, this.formId).subscribe((dealTeamReviewData:any) => {

          if (Object.keys(this.complianceResponse).length > 0) {
            this.newWorkflow = true;
            this.series24FinalApproval = {
              series24Signature: responseFormItem.Series24Signature,
              series24ApprovalDate: responseFormItem.Series24ApprovalDate,
              series24Selected: this.Series24
            };
            this._handlerDealTeamSignoffSection(dealTeamValues1, dealTeamReviewData, this.Series24);
            //validateCompliance(complianceData);
            this._handlerSeries24ApprovalSection(this.series24FinalApproval, responseFormItem.Series24ReviewedTransaction, responseFormItem.Series24ReviewedPersonnelHours)
            //applySecurity(securityContext, gblFormStatus, gblFormGroup, formData.Series24UserName, newWorkflow, isClosingChecklistVisitorsGroup);
          } else {
            this.newWorkflow = false;
            this.Series24 = responseMyDeal.Series24DisplayNames ? responseMyDeal.Series24DisplayNames : "";
            this.series24FinalApproval = {
              series24Signature: responseFormItem.Series24Signature,
              series24ApprovalDate: responseFormItem.Series24ApprovalDate,
              series24Selected: this.Series24
            };
            this._handlerDealTeamSignoffSection( dealTeamValues1, dealTeamReviewData, this.Series24);
            this._handlerSeries24ApprovalSection(this.series24FinalApproval, responseFormItem.Series24ReviewedTransaction, responseFormItem.Series24ReviewedPersonnelHours)
            //applySecurity(securityContext, gblFormStatus, gblFormGroup, formData.Series24UserName, newWorkflow, isClosingChecklistVisitorsGroup);
            //hideNewWorkflow();
          }
        //})

        this._storeSharepointObjects(responsePreviousS24Table, responsePPMDTable, responseDTTL, responseCounterparties, this.dealTeamValues, responseAttachments, responseFormItem, responseValuations);
        //this._createPeoplePickerUserArrays(responseFormItem);

        // this._handlerFormStatus();
        // this._handlerSubmitForReviewSection();
        // this._handlerReactivateFormSection(responseFormItem.DiscontinuedOn, responseFormItem.DiscontinuedBy);
        // this.spinnerService.hideAutoSaveSpinner();

        let adGroup=localStorage.getItem("adGroupMember")?.toString();
        Promise.all([          
          this._areUsersBelongToDCFGroup(responseMyDeal.Series24, this.pS24Values, adGroup!=null?JSON.parse(adGroup):this.adGroupMember.value, this.s24GroupUsers)
        ]).then(([]) => {
          this._handlerFormStatus();
          this._handlerSubmitForReviewSection();
          //this._handlerSupervisorySignoffSection();
          this._handlerReactivateFormSection(responseFormItem.DiscontinuedOn, responseFormItem.DiscontinuedBy);
          this._handlerAutoSave();
          this.spinnerService.hideAutoSaveSpinner();
          this._openModal("retrieve");
        })
         });
      });
    }
  }


  _loadActualDealTeamList(dealTeamList:any,type:any){
    let dealTeamValues=new Array<PeoplepickerDynamicTableItem>();
    let indexValue=0;
    dealTeamList.forEach((x:any)=>{
    let dataObject=new PeoplepickerDynamicTableItem();
    dataObject.optionsSelectedFirstColumn=this.loadDropDownValues(x,"First");
    dataObject.optionsSelectedSecondColumn=this.loadDropDownValues(x,"Second");
    dataObject.optionsSelectedThirdColumn=this.loadDropDownValues(x,"Third");
    dataObject.itemId=x.ID;
    if(type!=2)
    dataObject.usersSelected.push(this.loadDealTeamMember(x,indexValue));
    else
    dataObject.usersSelected.push(this.loadDealTeamMemberByType(x,indexValue));
    dealTeamValues.push(dataObject);
    indexValue++;
    })
    return dealTeamValues;
}
  loadDealTeamMember(data: any,index:any) {
    let obj = {} as PeoplePickerUser;
    obj.DisplayText = data.Name;
    obj.Key = data.ID;
    obj.displayName = data.Name;
    obj.mail=data.Email;//this.loadEmailIdFromObject(responseFormItem,index);
    obj.LeftDCF = this._isInAdGroup(obj.mail, JSON.parse((localStorage as any).getItem("adGroupMember"))!=null?JSON.parse((localStorage as any).getItem("adGroupMember")):this.adGroupMember.value)===undefined?true:false
    return obj;
  }
  loadDealTeamMemberByType(data: any,index:any) {
    let obj = {} as PeoplePickerUser;
    obj.DisplayText = data.DisplayName;
    obj.Key = data.ID;
    obj.displayName = data.DisplayName;
    obj.mail=data.Name;//this.loadEmailIdFromObject(responseFormItem,index);      
    obj.LeftDCF = this._isInAdGroup(obj.mail, JSON.parse((localStorage as any).getItem("adGroupMember"))!=null?JSON.parse((localStorage as any).getItem("adGroupMember")):this.adGroupMember.value)===undefined?true:false
    return obj;
  }


  loadDropDownValues(data:any,columnIndex:any){
    const dropdownItem = new DropdownItem();
    if (columnIndex == "First") {
      dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
      dropdownItem.value = data.Role;
    }
    else if (columnIndex == "Second") {
      dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
      dropdownItem.value = data.PursuitRole;
    }
    else {
      dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
      dropdownItem.value = data.SwiftRole;
    }
    return dropdownItem;
  }
  
  fillingDropdownKeyValues(row:any,columnIndex:any){
    let key: any="";
    if (columnIndex == "First") {
      this.firstColumnItemList.forEach((x: any) => {
        if (x.value == row.Role) {
          key = x.key;
        }
      })
    }
    else if (columnIndex == "Second") {
      this.secondColumnItemList.forEach((x: any) => {
        if (x.value == row.PursuitRole) {
          key = x.key;
        }
      })
    }
    else {
      this.thirdColumnItemList.forEach((x: any) => {
        if (x.value == row.SwiftRole) {
          key = x.key;
        }
      })
    }
    return key;
  }
  dealTeamRowHandler(dealTeamRow: PeoplePickerSelectedRow, list:any) {
    if (this.complianceFormId) {
      this._checkDealTeamTypeEvent(dealTeamRow, list);
    } else {
      this._checkDealTeamTypeEvent(dealTeamRow, list);
      this.saveForm().subscribe(response => {
        if (response) {
          this._checkDealTeamTypeEvent(dealTeamRow, list);
        }
      });
    }
  }

  dttlRowHandler(freeTextRow: FreeTextSelectedRow, list:any) {
    if (this.complianceFormId) {
      this._checkFreeTextTypeEvent(freeTextRow, list);
    } else {
      this._checkFreeTextTypeEvent(freeTextRow, list);
      this.saveForm().subscribe(response => {
        if (response) {
          this._checkFreeTextTypeEvent(freeTextRow, list);
        }
      });
    }
  }

  onPeoplePickerToDelete(peoplepicker: PeoplepickerDynamicTableItem[], list:any) {
    const actionType = DynamicTableAction.Delete;
    peoplepicker.forEach(item => {
      const peoplepickerItem: PeoplePickerSelectedRow = {
        item,
        actionType
      };
      this._deleteDealTeamRow(peoplepickerItem, list);
    });
  }

  onDttlToDelete(dttl: FreeTextTableItem[], list: string) {
    const actionType = DynamicTableAction.Delete;
    dttl.forEach(item => {
      const dttlItem: FreeTextSelectedRow = {
        item,
        actionType
      };
      this._deleteFreeTextRow(dttlItem, list);
    });
  }

  dealTypeEvent(event:any) {
    this.capitalRaisedComponent.evaluateDealTypeDependency(event);
  }

  private _populateDocumentSection(responseAttachments:any) {
    this.financeComponent.populateDocumentSection(responseAttachments);
  }

  onSave() {
    this.spinnerService.showAutoSaveSpinner();
    this.mainService.getFormStatus_Closing_Checklist(AppSettings.closingChecklistForm, this.formId).subscribe((status:any) => {
      const formStatusChanged = this._formStatusChanged(status.formStatus, status.formActionGroup);
      if (!formStatusChanged) {
        this.saveForm().subscribe((code) => {
          if (code === responseCodes.tryAgain) {
            this._subscribeSave();
          } else if (code !== responseCodes.fail) {
            this._openModal(modalTypes.save);
          }
          this.spinnerService.hideAutoSaveSpinner();
        });
      } else {
        this._stopAutosave();
        this._openModal(modalTypes.formUpdated);
        this.spinnerService.hideAutoSaveSpinner();
      }
    })
  }
 
  onDealTeamApproval(dealTeamSignoff:any) {
    this.spinnerService.showAutoSaveSpinner();
    this._stopAutosave();
    //#region PE Issue Changes
    // from(this._validateDCFUsersBeforeUserAction()).pipe(
    //   switchMap(areValid => {
    //     if (areValid) {
    //       this._setValidators(this.initialFormValidators);
    //       this.formSubmitted = true;
    //       return from(this.saveForm()).pipe(
    //         switchMap(() => {
    //           return from(this.checkDealTeamUsersWhoLeftDCF(
    //             this.myDealFormId,
    //             this.formId,
    //             this.formStatus,
    //             this.formActionGroup
    //           )).pipe(
    //             switchMap((checkDealTeamUsersWhoLeftDCFResponse) => {
    //                   var status = AppSettings.signOffReviewStatus;
    //                   var userId = dealTeamSignoff.userId;
    //                   var userTitle = dealTeamSignoff.userName;
    //                   var comment = dealTeamSignoff.comments;
    //                   const objReviewSignOff = {
    //                     FormID: this.formId.toString(),
    //                     Comment: comment,
    //                     Status: status,
    //                     DealTeamUserDisplayName: userTitle,
    //                     DealTeamUser: userId,
    //                     CreatedBy:this.currentUser.title                        
    //                   };
    //                   return this.mainService.createListItem_CCF(
    //                     AppSettings.closingChecklistDealTeamSignOff,
    //                     objReviewSignOff
    //                   ).pipe(
    //                     switchMap((response) => {
    //                       return from(this.calculateReview(this.formId, this.myDealFormId)).pipe(
    //                         tap((reviewData:any) => {
    //                           if (reviewData) {
    //                             this.formStatus = AppSettings.signOffReviewStatus;
    //                             const jsonItems = {
    //                               FormStatus: this.formStatus,
    //                               ExecuteNewWorkflow: this.newWorkflow == true ? "TRUE" : "FALSE"
    //                             };
    //                             this.mainService.updateForm_CCF(AppSettings.closingChecklistFormList, this.formId, jsonItems,this.initialClosingCheckList.Created,true).subscribe((updateResponse)=>{
    //                               this.spinnerService.hideAutoSaveSpinner();
    //                               this._openModal(modalTypes.allDealTeamMemberApproval);
    //                             });
    //                             return of();
    //                             // return from(
    //                             //   this.mainService.updateForm_CCF(AppSettings.closingChecklistFormList, this.formId, jsonItems,this.initialClosingCheckList.Created,true)
    //                             // ).pipe(
    //                             //   tap((updateResponse) => {
    //                             //     this.spinnerService.hideAutoSaveSpinner();
    //                             //     this._openModal(modalTypes.allDealTeamMemberApproval);
    //                             //   })
    //                             // );
    //                           } else {
    //                             this.spinnerService.hideAutoSaveSpinner();
    //                             this._openModal(modalTypes.signOffApproved);
    //                             return of();
    //                           }
    //                         })
    //                       );
    //                     })
    //                   );
    //             })
    //           );
    //         })
    //       );
    //     } else {
    //       this.spinnerService.hideAutoSaveSpinner();
    //       this._openModal(modalTypes.userLeftDcf);
    //       return of();
    //     }
    //   })
    // ).subscribe();
    //#endregion
    from(this._validateDCFUsersBeforeUserAction()).pipe(
      switchMap(areValid => {
        if (areValid) {
          this._setValidators(this.initialFormValidators);
          this.formSubmitted = true;
          return from(this.saveForm()).pipe(
            switchMap(() => {
              var status = AppSettings.signOffReviewStatus;
              var userId = dealTeamSignoff.userId;
              var userTitle = dealTeamSignoff.userName;
              var comment = dealTeamSignoff.comments;
              const objReviewSignOff = {
                FormID: this.formId.toString(),
                Comment: comment,
                Status: status,
                DealTeamUserDisplayName: userTitle,
                DealTeamUser: userId,
                CreatedBy: this.currentUser.title
              };
              return from(this.DealTeamApprovalPhaseCalls(
                objReviewSignOff
              )).pipe(
                switchMap((approvals) => {
                  if (approvals.reviewData) {
                    this.formStatus = AppSettings.signOffReviewStatus;
                    const jsonItems = {
                      FormStatus: this.formStatus,
                      ExecuteNewWorkflow: this.newWorkflow == true ? "TRUE" : "FALSE"
                    };
                    this.mainService.updateForm_CCF(AppSettings.closingChecklistFormList, this.formId, jsonItems, this.initialClosingCheckList.Created, true).subscribe((updateResponse) => {
                      this.spinnerService.hideAutoSaveSpinner();
                      this._openModal(modalTypes.allDealTeamMemberApproval);
                    });
                    return of();
                    // return from(
                    //   this.mainService.updateForm_CCF(AppSettings.closingChecklistFormList, this.formId, jsonItems,this.initialClosingCheckList.Created,true)
                    // ).pipe(
                    //   tap((updateResponse) => {
                    //     this.spinnerService.hideAutoSaveSpinner();
                    //     this._openModal(modalTypes.allDealTeamMemberApproval);
                    //   })
                    // );
                  } else {
                    this.spinnerService.hideAutoSaveSpinner();
                    this._openModal(modalTypes.signOffApproved);
                    return of();
                  }

                })
              );
            })
          );
        } else {
          this.spinnerService.hideAutoSaveSpinner();
          this._openModal(modalTypes.userLeftDcf);
          return of();
        }
      })
    ).subscribe();
  }

 calculateReview(formId:any, myDealFormId:any): Observable<any> {
  var totalReviews = 0;
  var currentTotalReviews = 0;
  var reviewed = false;
  return forkJoin([
    this.ComplianceService.getDealTeamUser(this.dealTeamList, myDealFormId),
    this.mainService.getDealTeamUserReviewsCCL(AppSettings.closingChecklistDealTeamSignOff, formId)
  ]).pipe(
    map(([dealTeamData, dealTeamReviewData]) => {
      var users = dealTeamData.filter((i:any) => i.Name !== this.Series24);
      var reviews = dealTeamReviewData;
      totalReviews = users.length;
      for (var key in users) {
        //var userId = users[key].Name.Id;
        var userId = users[key].Email;
        var reviewForUser = this.getReviewForUser(userId, reviews);
        if (reviewForUser != null) {
          var signOffStatus = reviewForUser.Status;
          if (signOffStatus == AppSettings.signOffReviewStatus || signOffStatus == AppSettings.signOffNAStatus) {
            currentTotalReviews = currentTotalReviews + 1;
          }
        }
      }
      //return reviewed = true;
      return reviewed = totalReviews === currentTotalReviews;
    })
  );
}
  getReviewForUser(userId:any, dataReviews:any) {
    for (var review in dataReviews) {
      if (dataReviews[review].DealTeamUser == userId) {
        return dataReviews[review];
      }    
    }
    return null;
  }
  private DealTeamApprovalPhaseCalls(objReviewSignOff: any): Observable<any> {
    return forkJoin([
        this.checkDealTeamUsersWhoLeftDCF(
            this.myDealFormId,
            this.formId,
            this.formStatus,
            this.formActionGroup
        ),
        this.mainService.createListItem_CCF(
            AppSettings.closingChecklistDealTeamSignOff,
            objReviewSignOff
        )
    ]).pipe(
        switchMap(([checkDealTeamUsersWhoLeftDCFResponse, responseDealTeamSignoff]) => {
            return this.calculateReview(this.formId, this.myDealFormId).pipe(
                switchMap(reviewData => {
                    return of({
                        checkDealTeamUsersWhoLeftDCFResponse,
                        responseDealTeamSignoff,
                        reviewData
                    });
                })
            );
        })
    );
}
checkDealTeamUsersWhoLeftDCF(myDealFormId:any, formId:any, formStatus:any, formActionGroup:any): Observable<any> {
  if (this.isPendingForDealTeamReview(formStatus, formActionGroup)) {
    return forkJoin([
      this.mainService.getDealTeamUserReviewsCCL(AppSettings.closingChecklistDealTeamSignOff, formId),
      //this.ComplianceService.getDealTeamUser(this.dealTeamList, myDealFormId)
    ]).pipe(
      switchMap(([dealTeamReviewData]) => {
        var reviews = dealTeamReviewData;
        var dcfUsers = this.adGroupMember.value;
        //var dealTeamUsers = dealTeamData;
        return from(this.deleteNAReviewForOnboardedUsers(reviews, dcfUsers)).pipe(
          map(
            deleteResponse => deleteResponse
          )
        );
      })
    );
  } else {
    return of(true);
  }
}
  isPendingForDealTeamReview(formStatus:any, formActionGroup:any) {
    return (formStatus == "Pending" && formActionGroup == "DealTeamReview");
  }
  deleteNAReviewForOnboardedUsers(reviews:any, dcfUsers:any): Observable<any> {
    var reviewsOnBoardedUsers = this.getReviewsOnboardedUserOfDealTeamTable(reviews, dcfUsers);
    return this.mainService.removeDealTeamReviews(reviewsOnBoardedUsers);
  }
  getReviewsOnboardedUserOfDealTeamTable(reviews:any, dcfUsers:any) {
    var reviewsOnBoardedUsers = [];
    for (var review in reviews) {
      var currentReview = reviews[review];
      var userId = currentReview.DealTeamUser;
      if (currentReview.Status == AppSettings.signOffNAStatus && this.isMemberOfSpecificGroup(dcfUsers, userId)) {
        reviewsOnBoardedUsers.push(currentReview);
      }
    }
    return reviewsOnBoardedUsers;
  }
  addNAReviewForOffboardedUsers(dcfUsers:any, dealTeamUsers:any, reviews:any, formId:any): Observable<any> {
    var offBoardedUsers = this.getOffboardedUserOfDealTeamTable(dcfUsers, dealTeamUsers, reviews);
    return this.addReviewNA(formId, offBoardedUsers)
  }
  addReviewNA(formId:any, users:any): Observable<any> {
    var countUsers = users.length;
    if (countUsers > 0) {
       this.mainService.getDealTeamStatusItem(AppSettings.signOffNAStatus).subscribe((statusIdData:any) => {
        var statusId = statusIdData[0].Id;
        for (var user in users) {
          var currentUser = users[user];
          var userId = currentUser.Name.Id;
          var userName = currentUser.Name.Title;
          const objectReviewSignOff = {
            FormId: formId,
            Comment: "",
            StatusId: statusId,
            DealTeamUserName: userName,
            DealTeamUserId: userId
          };
          this.mainService.createListItem_CCF(AppSettings.closingChecklistDealTeamSignOff, objectReviewSignOff).subscribe((response) => {
            countUsers--;
            if (countUsers == 0) {
              return true;
            }
            return false //Added to remove error
          })
        }
      })
      
    }
    return of(true); //Added to remove error
  }
  getOffboardedUserOfDealTeamTable(dcfUsers:any, dealTeamUsers:any, reviews:any) {
    var offBoardedUsers = [];
    for (var user in dealTeamUsers) { //If a user left DCF and did not made a review, we create a new one with a N/A status
      var currentUser = dealTeamUsers[user];
      var userId = currentUser.Name.Id;
      if (!this.isMemberOfSpecificGroup(dcfUsers, userId) && !this.madeSignOff(userId, reviews)) {
        offBoardedUsers.push(currentUser);
      }
    }
    return offBoardedUsers;
  }
  isMemberOfSpecificGroup(groupUsers:any, userId:any): boolean {
    return groupUsers.includes((x:any) => x.Id.toLowerCase() === userId.toLowerCase())
  }
  madeSignOff(userId:any, reviews:any) {
    return reviews.includes((x:any) => x.DealTeamUserId == userId);
  }
onSubmit() {
  this.spinnerService.showAutoSaveSpinner();
  from(this._validateDCFUsersBeforeUserAction()).pipe(
    switchMap(areValid => {
      if (areValid) {
        this._setValidators(this.initialFormValidators);
        this.formSubmitted = true;
        if (this.breakPointMultiValueStatus)
          this.dCFFeeStructureComponent.highlightMultiRequiredField(this.breakPointMultiValueStatus);

        if (this.appFormGroup.valid && !this.breakPointMultiValueStatus) {
        //if (true) {
          return forkJoin([
            this.saveForm()
          ]).pipe(
            switchMap(([saveResponse]) => {
              const submissionDate = new Date();
               const objectToSend = this.ComplianceServiceHelper.createSubmitObject(submissionDate, this.newWorkflow);
              return forkJoin(
                this.mainService.updateForm_CCF(AppSettings.closingChecklistFormList, this.formId, objectToSend,this.initialClosingCheckList.Created,true)).pipe(
                tap(() => {
                  this.dealTeamValues.forEach(TeamValues => {
                    if (TeamValues.usersSelected[0].mail.toLowerCase() == this.currentUser.email.toLowerCase() && (!(this.currentUser.title == this.Series24))) {
                      var dealTeamSignoffs = this._dealTeamSignoffseObject();
                      this.onDealTeamApproval(dealTeamSignoffs);
                    }
                  })
                  this._openModal(modalTypes.submit);
                  this.spinnerService.hideAutoSaveSpinner();
                })
              );
            })
          );
        } else {
          this._openModal(modalTypes.mandatoryFields);
        }
      } else {
        this._openModal(modalTypes.userLeftDcf);
      }
      this.spinnerService.hideAutoSaveSpinner();
      return of(); //Added to remove error
    })
  ).subscribe();
}
  private _dealTeamSignoffseObject() {
    const dealTeamSignoffs = new DealTeamSignoffs();
    dealTeamSignoffs.userId = this.currentUser.email.toString();
    dealTeamSignoffs.userName = this.currentUser.title;
    dealTeamSignoffs.comments = "";
    return dealTeamSignoffs;
  }
  //Below function to Call Save from SideBar
  SaveCCLFormDetails(event: any) {
    if (event) {
      this.onSave();
    }
  }
  saveForm(): Observable<string> {
    if (!this.saveInProgress) {
      const sharepointObject = this._setSharepointObject();
      const sharepointObjectMyDeal = this._setSharepointObjectMyDeal();
      const sharepointCCFormObject = this._setSharepointClosingCheckObject();
      if (!this.complianceFormId) {
        this.saveInProgress = true;
        return from(this.mainService.createForm_CCF(AppSettings.complianceFormList, sharepointObject)).pipe(
          tap((response: any) => {
            this.complianceFormId = response.ID;
            this.saveInProgress = false;
          }),
          switchMap(() => of(this._getResponseCodeOk())),
          catchError((err: any) => {
            this.saveInProgress = false;
            return of(responseCodes.fail);
          })
        );
      } else {
        return from(this._retrieveLastChangesBeforeSave()).pipe(
          switchMap(changes => {
            const canExecuteResponse = this._canExecuteSave(changes);
            const responseCode: any = canExecuteResponse[0];
            const canExecute = canExecuteResponse[1];
            if (canExecute) {
              this.saveInProgress = true;
              this.conflictInputsOnSave = false;
              this.overwriteInputs = false;
              this.differences = this._findDifferencesBeforeSaveData();
              return from(this._updateAppFormGroup(changes)).pipe(
                switchMap(() => from(this._updateFormItem(this.differences,changes)).pipe(
                  switchMap(responseFormItem => {
                    if (responseFormItem) {
                      return from(this.mainService.retrieveForm_CCF(AppSettings.closingChecklistFormList, this.formId)).pipe(
                        switchMap(ccformdata => {
                          if (ccformdata[0].BreakpointQuestion == "YesMultiple") {
                            this.dCFFeeStructureComponent.getClosingCheckListFeeStructureBreakpoints(this.formId);
                          }
                          this.dCFFeeStructureComponent.toggleButtonActive(ccformdata[0].BreakpointQuestion, true);
                          this.dCFFeeStructureComponent.evaluateBreakPointSection(ccformdata[0].FeeType);
                          this.dCFFeeStructureComponent.radioBaseFeeEnableMethod(ccformdata[0].BaseFeeRadio, true);
                          this.dCFFeeStructureComponent.radioIncentiveFeeEnableMethod(ccformdata[0].IncentiveFeeRadio);
                          this._storeSharepointObjects(changes.responsePreviousS24Table, changes.responsePPMDTable, changes.responseDTTL,
                            changes.responseCounterparties, changes.responsedealTeam, changes.responseAttachments, changes.responseCCForm, changes.responseValuations);
                            
                        // this._storeSharepointObjectsAfterSave(changes.responsePreviousS24Table, changes.responsePPMDTable, changes.responseDTTL,
                        //   changes.responseCounterparties, changes.responsedealTeam, changes.responseAttachments, changes.responseCCForm, changes.responseValuations).subscribe((response:any)=>{

                        //   });
    
                        return of(this._getResponseCodeOk());
                        }),
                        catchError(error => {
                          this.saveInProgress = false;
                          return of(responseCodes.fail);
                        })
                      );               
                    } else {
                      this._stopAutosave();
                      return of(responseCodes.fail);
                    }
                  }),
                  tap(() => {
                    this.saveInProgress = false;
                  })
                ))
              );
            } else {
              return of(responseCode);
            }
          }),
          catchError(error => {
            this.saveInProgress = false;
            return of(responseCodes.fail);
          })
        );
      }
    }
    return of(responseCodes.ok);
  }
  private _getItem(responseFormItem:any,resMyDeal:any) {
    //forkJoin([
     // this.ComplianceService.retrieveTableDetails(AppSettings.complianceFormList, this.formId,environment.ClosingChecklistForm),
      // this.ComplianceService.retrieveTableDetails(AppSettings.myDealFormList, this.myDealFormId,environment.Newdealform), 
     //  this.rolesValidator.validateFormRoles_ClosingCheckList(this.currentUser.groups, this.currentUser.email, this.formId, this.myDealFormId),
    //  ])
     // .subscribe(([roleList]) => {
        // if (responseComplianceForm) {
        //   //this.myDealFormId = responseComplianceForm.MyDealId;
        //   this.complianceResponse=responseComplianceForm;
        //   this.complianceFormId = responseComplianceForm.ID;
        //   this.mainService.complianceID=this.complianceFormId;
        // }
        // if(resMyDeal){
        //   this.MyDealRangeKey=resMyDeal[0].Created;
        // }
        // forkJoin([
        //   this.rolesValidator.validateFormRoles_ClosingCheckList(this.currentUser.groups, this.currentUser.email, this.formId, this.myDealFormId),
        // ]).subscribe(([roleList]) => {
        // const status={
        //   formStatus: (this.formId==null||this.formId==undefined)?AppSettings.initialFormStatus:responseFormItem.FormStatus,
        //   formActionGroup: (this.formId==null||this.formId==undefined)?AppSettings.initialFormActionGroup:responseFormItem.FormActionGroup
        // }
       // this.formStatusRetrieve = status;
       // this.securityService.getPermissions(this.currentUser.email,status.formStatus, status.formActionGroup, roleList, ClosingChecklistSecurityMatrix);
        this.securityService.securityMatrixEvent.subscribe(response => {
          this.initializeForm(responseFormItem,resMyDeal);
        });
         // });
    //  });
  }
  private _retrieveLastChangesAfterSave(): Observable<any> {
    if (this.complianceFormId && this.myDealFormId && this.formId) {
      const folderUrl = this._getFolderUrl();
      return forkJoin([
        this.ComplianceService.retrieveTableDetails(AppSettings.complianceFormList, this.formId, environment.ClosingChecklistForm),
        this.ComplianceService.retrieveMyDeal_CCL(AppSettings.myDealFormList, this.myDealFormId, environment.Newdealform),
        this.mainService.retrieveForm_CCF(AppSettings.closingChecklistFormList, this.formId),
        this.ComplianceService.getListItems_CCL(AppSettings.closingCheckListValuations, this.formId),
        this.ComplianceService.getListItems_CCL(AppSettings.closingCheckListDealMarketingCount, this.formId)      
      ])
      .pipe(
        switchMap(([responseCompliance, responseMyDeal, responseCCForm,  responseValuations, responseDealMarketings]) => {         
         
         return of({
          responseCompliance, responseMyDeal, responseCCForm,  responseValuations, responseDealMarketings
          });
        })
      );
    }
    else{
      return of(null);
    }
  
}
  private _retrieveLastChangesBeforeSave(): Observable<any> {
      if (this.complianceFormId && this.myDealFormId && this.formId) {
        const folderUrl = this._getFolderUrl();
        return forkJoin([
          this.ComplianceService.retrieveTableDetails(AppSettings.complianceFormList, this.formId, environment.ClosingChecklistForm),
          this.ComplianceService.retrieveMyDeal_CCL(AppSettings.myDealFormList, this.myDealFormId, environment.Newdealform),
          this.mainService.retrieveForm_CCF(AppSettings.closingChecklistFormList, this.formId),
          this.fileService.getFiles(this.formId, AppSettings.FormTypes.ClosingCheckListForm),
          this.ComplianceService.retrieveTableDetails(this.dealTeamList, this.myDealFormId, environment.Newdealform),
          this.ComplianceService.retrieveTableDetails(this.previousSeries24List, this.complianceFormId, environment.ClosingChecklistForm),
          this.ComplianceService.retrieveTableDetails(this.previousPPMDList, this.complianceFormId, environment.ClosingChecklistForm),
          //this.ComplianceService.getDCFGroupMembers(),
          this.ComplianceService.getDTTLList(this.dttlList, this.complianceFormId),
          this.ComplianceService.retrieveTableDetails(this.counterpartiesList, this.complianceFormId, environment.ClosingChecklistForm),
         // this.ComplianceService.GetGroupData(AppSettings.series24Group),
          this.ComplianceService.getListItems_CCL(AppSettings.closingCheckListValuations, this.formId),
          this.ComplianceService.getListItems_CCL(AppSettings.closingCheckListDealMarketingCount, this.formId),
          this.ComplianceService.retrieveTableDetails(AppSettings.closingChecklistAdditionalComments, this.formId, environment.ClosingChecklistForm),
          //this.ComplianceService.GetGroupData(AppSettings.closingChecklistVisitorsGroup) Not Using the Response
        ])

        .pipe(
          switchMap(([responseCompliance, responseMyDeal, responseCCForm, responseAttachments, responsedealTeam, responsePreviousS24Table, responsePPMDTable, responseDTTL, responseCounterparties, responseValuations, responseDealMarketings, responseDocumentComments]) => {         
            this.additionalCommentaryComponent.additionalCommentarySection()
            let series24users=this.mainService.myDealS24List;
           return of({
              responseCompliance, responseMyDeal, responseCCForm, responseAttachments, responsedealTeam, responsePreviousS24Table, responsePPMDTable, responseDTTL, responseCounterparties, series24users, responseValuations, responseDealMarketings, responseDocumentComments

            });
          })
        );
      }
      else{
        return of(null);
      }
    
  }

  private _subscribeSave() {
    setTimeout(() => this.saveForm(), AppSettings.forceItemReleaseInterval);
  }

  private _canExecuteSave(changes:any) {
    let responseCode;
    let executeSave = true;
    if (this.saveInProgress) {
      responseCode = responseCodes.tryAgain;
      executeSave = false;
    } else if (this._formStatusChanged(changes.responseCCForm[0].FormStatus, changes.responseCCForm[0].FormActionGroup)) {
      responseCode = responseCodes.formUpdated;
      executeSave = false;
    }
    return [responseCode, executeSave];
  }

  private _formStatusChanged(formStatus:any, formActionGroup:any) {
    return (formStatus !== this.formStatus ||
      formActionGroup !== this.formActionGroup);
  }

  selectedFileHandler(fileSelected: boolean) {
    if (this.formId) {
      this._saveFileToLibrary();
    } else {
      this.saveForm().subscribe(response => {
        this._saveFileToLibrary();
      });
    }
  }

  private _saveFileToLibrary() {
    this.financeComponent.saveFileToLibrary(this.formId);
  }


  private _updateAppFormGroup(changes:any): Observable<boolean> {
      //update sharepoint objects and apply styles on formcontrols like green for update  and yellow for conflict values
      this._updateValuesBeforeSave(changes);

      const currentCompliancelSetupObject = this._setSharepointObject();


      const currentSharepointObjectMyDeal = this._setSharepointObjectMyDeal();

      // const changesDealTeamTable = changes.responseDealTeam;
      const changesClosingCheckList = changes.responseCCForm[0];
      const changesValuationsMetrics = changes.responseValuations;
      const changespS24values = changes.responsePreviousS24Table;
      const changesPPMDTable = changes.responsePPMDTable;
      const changesDTTl = changes.responseDTTL;
      const changeesCounterparties = changes.responseCounterparties;
      const changesDealTeam = changes.responsedealTeam;
      const changesDealMarketing = changes.responseDealMarketings;
      const changesAttachments = changes.responseAttachments;
      //populate updated values into form fields
      //need to enable below for autosave
      if(this.appContext.autoSaveEnabled){
      this._populateSections(currentCompliancelSetupObject, currentSharepointObjectMyDeal, changespS24values, changesPPMDTable, changeesCounterparties, changesDTTl, changesClosingCheckList, changesDealTeam, changesValuationsMetrics, changesAttachments, changesDealMarketing);
      }

      //update the people picker data
      return from(this._createPeoplePickerUserArraysForSave(changes.responseCompliance)).pipe(
        switchMap(() => {
         return of(true);
    })
  );
  }
  
   private _updateValuesBeforeSave(changes:any) {
     // clean or remove previous applied styles on the fields.
     this.sharePointMapper.cleanInputsEditedExternally(this.appFormGroup);
     //create new sharepoint object and assign last updated response new deal data to newly created object.
     let changesComplianceSetupObject = this._createObjectLastChangesCompliance(changes.responseCompliance);
     //create new sharepoint object and assign last updated response My Deal data to newly created object.
     const changesMyDealObject = this._createObjectLastChangesMyDeal(changes.responseMyDeal[0]);
     //create new sharepoint object and assign last updated Valuation Metrics data to newly created object.
     let changesValuationMetricsObject = this._createObjectLastChangesValuationMetrics(changes.responseValuations, changes.responseCCForm[0]);
     //create new sharepoint object and assign last updated deal marketing data to newly created object 
     let changesDealMarketingObject = this._createObjectLastChangesDealMarketing(changes.responseDealMarketings, changes.responseCCForm[0]);
     //get current new deal sharepoint data object
     const currentComplianceSetupObject = this._setSharepointObject();
     //get current My Deal sharepoint data object
     let changesCCFormObject = this.ComplianceServiceHelper.CreateCCFormDifferenceObject(changes.responseCCForm[0]);

     const currentSharepointObjectMyDeal = this._setSharepointObjectMyDeal();

     const currentAttachments = this.financeComponent.getAttachmentsRowsComplete();
     const changesAttachments = this._createObjectLastChangesAttachments(changes.responseAttachments);
     //get current ValuationMetrics data
     const currentSharepointObjectValuationMetrics = this._setSharepointObjectValuationMetrics();

     const currentSharepointObjectDealMarketing = this._setSharepointObjectDealMarketingSection();

     const currentCCFormObject = this._setSharepointClosingCheckObject();

     const currentpS24List = this.engagementOverviewComponent.getDealTeamRowsComplete_CCL();
     const currentppmdList = this.engagementOverviewComponent.getDealTeamRowsCompleteppmd_CCL();
     const currentCounterpartiesList = this.engagementOverviewComponent.getCounterPartiesRowsComplete();
     const currentDttlValues = this.crossBorderComponent.getDttlValues_CCL();
     const currentDealTeamTable = this.projectOverviewComponent.getDealTeamRowsComplete_CCL();

     //Added below logic to handle Highlighting of fields for normal save
     changesComplianceSetupObject=  this.HandleDynamicObjects(changesComplianceSetupObject,currentComplianceSetupObject,"Compliance");
     changesCCFormObject=  this.HandleDynamicObjects(changesCCFormObject,currentCCFormObject,"CCL");
     changesValuationMetricsObject=this.HandleDynamicObjects(changesValuationMetricsObject,currentSharepointObjectValuationMetrics,"valuationMetricsDifferences");
     changesDealMarketingObject=this.HandleDynamicObjects(changesDealMarketingObject,currentSharepointObjectDealMarketing,"dealMarketingDifferences");
     
     this._changeValuesIfApply(changesComplianceSetupObject, this.initialSharepointObject,
       currentComplianceSetupObject, 'complianceDifferences', changes);
     this._changeValuesIfApply(changesMyDealObject, this.initialMyDealObject, currentSharepointObjectMyDeal, 'myDealDifferences', changes);

     this._changeValuesIfApply(changesCCFormObject, this.initialCCFormObject, currentCCFormObject, 'ccFormDifferences', changes);

//Below commenting logic needs to enable at the time of autosave

     this._changeValuesIfApply(changesDealMarketingObject, this.initialBuyerDealMarketing, currentSharepointObjectDealMarketing, 'dealMarketingDifferences', changes);

     this._changeValuesIfApply(changesValuationMetricsObject, this.initialValuationMetrics, currentSharepointObjectValuationMetrics, 'valuationMetricsDifferences', changes);

  let lastPS24Table=this._loadActualDealTeamList(changes.responsePreviousS24Table,2);
     this._changeTableValuesIfApply(lastPS24Table, this.initialpS24Values, currentpS24List, AppSettings.compliancePreviousSeries24);
     let lastPPMDTable=this._loadActualDealTeamList(changes.responsePPMDTable,2);
     this._changeTableValuesIfApply(lastPPMDTable, this.initialPpmdValue,
       currentppmdList, AppSettings.compliancePreviousPPMD);

     this._changeTableValuesIfApply(changes.responseDTTL, this.initialdttlValues,
       currentDttlValues, AppSettings.complianceDTTLList);
     let lastCounterParties=this.HandleCounterParties(changes.responseCounterparties);
      this._changeTableValuesIfApply(lastCounterParties, this.initialcounterpartiesValues,
        currentCounterpartiesList, AppSettings.complianceCounterpartiesList);
        let ChangesDealTeamList= this._loadActualDealTeamList(changes.responsedealTeam,1);
      this._changeTableValuesIfApply(ChangesDealTeamList, this.initialDealTeamTable,
          currentDealTeamTable, AppSettings.dealTeamTableList);
      this._changeTableValuesIfApply(changesAttachments.length > 0 ? changesAttachments[0].documentTypeAttachments : changesAttachments, this.initialAttachments,
           currentAttachments.length > 0 ? currentAttachments[0].documentTypeAttachments : currentAttachments, AppSettings.closingCheckListAttachmentsList);
   }

   HandleDynamicObjects(changesObject:any,currentObject:any,Section:any):any{
     if (Section == "Compliance") {
      if(currentObject.CurrentPPMDLead==null||currentObject.CurrentPPMDLead==""){
       if (changesObject.CurrentPPMDLead?.results?.length == 0) {
         changesObject.CurrentPPMDLead = null;
       }
      }
       if(currentObject.CurrentPPMDLead!=null&&currentObject.CurrentPPMDLead!=""){
        if (currentObject.CurrentPPMDLead?.results==undefined) {     
          changesObject.CurrentPPMDLead = currentObject.CurrentPPMDLead;
        }
        else{
          if (currentObject.CurrentPPMDLead?.results?.length> 0) {   
            changesObject.CurrentPPMDLead = currentObject.CurrentPPMDLead;
         }
        }
       }
     }
     if (Section == "valuationMetricsDifferences") {
      if (changesObject.MultipleRangeMidpoint == null || changesObject.MultipleRangeMidpoint == undefined||changesObject.MultipleRangeMidpoint == "") {
        changesObject.MultipleRangeMidpoint = "0";
      }
      if ((changesObject.TotalConsideration == null || changesObject.TotalConsideration == undefined||changesObject.TotalConsideration == "")&&(this.initialValuationMetrics.TotalConsideration=="0")) {
        changesObject.TotalConsideration = "0";
      }
    }
    if (Section == "dealMarketingDifferences") {
      if (changesObject.PremiumOverInitialOffer == null || changesObject.PremiumOverInitialOffer == undefined) {
        changesObject.PremiumOverInitialOffer = "NaN";
      }
    }
     if (Section == "CCL") {
       if (changesObject.MultipleRangeMidpoint == null || changesObject.MultipleRangeMidpoint == undefined) {
         changesObject.MultipleRangeMidpoint = "0";
       }
       if (changesObject.TotalConsideration == null || changesObject.TotalConsideration == undefined) {
         changesObject.TotalConsideration = "0";
       }
       if (changesObject.PremiumOverInitialOffer == null || changesObject.PremiumOverInitialOffer == undefined) {
         changesObject.PremiumOverInitialOffer = "NaN";
       }
       if(changesObject.BaseFeeRadio==null||changesObject.BaseFeeRadio==undefined){
        changesObject.BaseFeeRadio="Percentage";
       }
       if(changesObject.LoiTotal==null||changesObject.LoiTotal==undefined){
        changesObject.LoiTotal="0";
       }
       if((changesObject.ValNumericalClosedConsideration==null||changesObject.ValNumericalClosedConsideration==undefined)&&currentObject.ValNumericalClosedConsideration!=""&&currentObject.ValNumericalClosedConsideration!=null){
        changesObject.ValNumericalClosedConsideration="0";
       }
       if((changesObject.ValNumericalTotal==null||changesObject.ValNumericalTotal==undefined)&&currentObject.ValNumericalTotal!=""&&currentObject.ValNumericalTotal!=null){
        changesObject.ValNumericalTotal="0";
       }
        if(currentObject.BaseFeeNumerical==null||currentObject.IncentiveFeeNumerical==""){
         changesObject.BaseFeeNumerical=null;
        }
       if(currentObject.IncentiveFeeNumerical==null||currentObject.IncentiveFeeNumerical==""){
        changesObject.IncentiveFeeNumerical=null;
       }
     }
      
   return changesObject;
   }
  HandleCounterParties(responseCounterparties: any) {
    let counterpartiesList: any;
    if (responseCounterparties.length > 0) {
      counterpartiesList = responseCounterparties.map((item: any) => {
        const counterparties = this.mainServiceHelper.createCounterpartiesItem(item.ID, item);
        return this.mainServiceHelper.createCounterpartiesFreeTextTableItem(item.ID, counterparties, item);
      });
    }
    else {
      counterpartiesList = responseCounterparties;
    }
    return counterpartiesList;
  }
  uploadedAttachmentsHandler(uploadedAttachments:any) {
    this.appFormGroup.get(formGroups.financeFormGroup)
      .get(this.sharePointMapper.getFormControlColumnName(FormListFields.financeFileUpload)).setValue(uploadedAttachments);
  }

  private _createObjectLastChangesAttachments(responseAttachments:any): Array<DocumentTypeItem> {
    const changesAttachmentObject = this.ComplianceServiceHelper.createAttachmentsObject(responseAttachments);
    return changesAttachmentObject;
  }
  
private _getPeoplepickerUserData(peoplepickerUserIds:any): any {
    const peoplePickerUserList:any = new Array<PeoplePickerUser>();
    let requests = peoplepickerUserIds.length;
    if (requests) {
      for (const userId of peoplepickerUserIds) {
        let response=this.peoplepickerService.BindParticipationUserDetails(userId);
          peoplePickerUserList.push(response);
          requests--;
          if (requests === 0) {
            return of(peoplePickerUserList);
          }
        }
    } else {
     return of(peoplePickerUserList);
    }
}
  private _createPeoplePickerUserArraysForSave(responseCompliance:any): Observable<any> {
    const clientOverViewFormGroup = this.appFormGroup.get(formGroups.engagementOverviewFormGroup);
    if(clientOverViewFormGroup.get("currentPPMDLead")?.value==null||clientOverViewFormGroup.get("currentPPMDLead")?.value==""){
      clientOverViewFormGroup.get("currentPPMDLead")?.setValue({results:[]});
     }
     else{
      let cPPMDLead=clientOverViewFormGroup.get("currentPPMDLead")?.value;
      if(cPPMDLead?.results==undefined){
        if(responseCompliance.CurrentPPMDLead==cPPMDLead){
          clientOverViewFormGroup.get("currentPPMDLead")?.setValue({results:this._getLCSPPeoplepickerUserDataForDealWbs(responseCompliance.CurrentPPMDLead,responseCompliance.CurrentPPMDLeadDisplayNames)}); 
        } 
      }
     }
    return forkJoin([
      this._getPeoplepickerUserData(clientOverViewFormGroup
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.currentPPMDLead)).value.results)
    ]).pipe(
      tap(([currentPPMDResponse]) => {
        this.engagementOverviewComponent.setCurrentPPMDLead(currentPPMDResponse);
      })
    );
  }

 
  private _createObjectLastChangesCompliance(responseComplianceChanges:any): SharepointObject {//changed

    const changesComplianceSetupObject:any = this.ComplianceServiceHelper.CreateComplianceSetupObject(responseComplianceChanges);
    if(responseComplianceChanges.CurrentPPMDLead!=null&&responseComplianceChanges.CurrentPPMDLead!=undefined&&responseComplianceChanges.CurrentPPMDLead!=""){
      changesComplianceSetupObject.CurrentPPMDLead=this._getLCSPPeoplepickerUserDataForDealWbs(responseComplianceChanges.CurrentPPMDLead,responseComplianceChanges.CurrentPPMDLeadDisplayNames)
    }

    this._setObjectFieldDate(changesComplianceSetupObject, changesComplianceSetupObject.DateICReviewApproval, FormListFields.dateICReviewApproval);
    this._setObjectFieldDate(changesComplianceSetupObject, changesComplianceSetupObject.DateSuccessFeeReceived, FormListFields.dateSuccessFeeReceived);
    this._setObjectFieldDate(changesComplianceSetupObject, changesComplianceSetupObject.DatePaidFinderFee, FormListFields.datePaidFinderFee);
    this._setObjectFieldPeoplePicker(changesComplianceSetupObject, changesComplianceSetupObject.CurrentPPMDLead, FormListFields.currentPPMDLead);
    return changesComplianceSetupObject;
  }


  private _createObjectLastChangesMyDeal(responseMyDealChanges:any): SharepointObjectMyDeal {
    const MyDealObject = this.ComplianceServiceHelper.CreateMyDealObject(responseMyDealChanges);
    // const responseMyDealChangesObject = responseMyDealChanges;
    return MyDealObject;

  }
  private _createObjectLastChangesValuationMetrics(responseValuations:any, responseFormItem:any): SharePointObjectValuationMetrics {
    const ValuationMetricsObject = this.ComplianceServiceHelper.CreateValuationMetricsObject(responseValuations, responseFormItem);
    return ValuationMetricsObject;
  }

  private _createObjectLastChangesDealMarketing(responseDealMarketing:any, responseFormItem:any): SharepointDealMarketingSection {

    const dealMarketingObject = this.ComplianceServiceHelper.CreateDealMarketingSectionObject(responseDealMarketing, responseFormItem);
    return dealMarketingObject;
  }


  private _setObjectFieldDate(object:any, fieldValue:any, fieldName:any) {
    if (!!fieldValue) {
      object[fieldName] = this.dateFormatterService.formatDateToSend(fieldValue);
    }
  }

  private _setObjectFieldPeoplePicker(object:any, fieldValue:any, fieldName:any) {
    if (!!fieldValue) {
      object[fieldName] = { results: fieldValue };
    } else {
      object[fieldName] = { results: [] };
    }
  }
  loadDefaultValuesForComparison(inital:any,changes:any,current:any):any{
    let res:any=changes;
    if(changes==""){
      if(inital=="0"&&current=="0"){
       res="0";
      }
    }
    return res;
  }
  private _changeValuesIfApply(changesObject:any, initialObject:any, currentObject:any, formObject:any, changes:any) {
    const changesKeys = Object.keys(changesObject);
    if (changesKeys.length) {
      changesKeys.forEach(input => {
        changesObject[input]=changesObject[input]==null?"":changesObject[input];
        changesObject[input]=this.loadDefaultValuesForComparison(initialObject[input],changesObject[input],currentObject[input]);
        const initialObjectValue = this._getObjectValue(initialObject[input]);
        let changesObjectValue = this._getObjectValue(changesObject[input]);
        const currentObjectValue = this._getObjectValue(currentObject[input]);
        if (initialObjectValue === currentObjectValue && initialObjectValue !== changesObjectValue) {
          this._overwriteInputWithIncomingValue(input, changesObject[input]);
        } else if (changesObjectValue !== initialObjectValue && changesObjectValue !== currentObjectValue
          && currentObjectValue !== initialObjectValue) {
          this._setInputWithConflict(input, changesObject[input], formObject);
        }
        else if (initialObjectValue !== currentObjectValue && initialObjectValue === changesObjectValue && changesObjectValue !== currentObjectValue
          && currentObjectValue) {
          this.mergeInputWithChanges(input, currentObject[input], formObject, changes);
        }
     
      });
    }
  }
  private _getObjectValue(value:any) {
    if (value === null || value === undefined) {
      value = '';
    }
    return JSON.stringify(value);
  }

  private _setInputWithConflict(input:any, changesObjectValue?:any, formObject?:any) {
    this.conflictInputsOnSave = true;
    const formControl = this._getFormControl(input);
    if (changesObjectValue && this.differences[formObject][input]) {
      formControl.setValue(changesObjectValue);
      this.differences[formObject][input] = changesObjectValue;
    }
    formControl.setConflictOnMerge(true);
  }
  private mergeInputWithChanges(input:any, currentObjectValue:any, formObject:any, changes:any) {
    if (formObject === "ccFormDifferences") {
      changes.responseCCForm[0][input] = currentObjectValue;
    }
    if (formObject === "valuationMetricsDifferences") {
      changes.responseValuations[input] = currentObjectValue;
    }
    if (formObject === "myDealDifferences") {
      changes.responseMyDeal[0][input] = currentObjectValue;
    }
    if (formObject === "dealMarketingDifferences") {
      changes.responseDealMarketings[input] = currentObjectValue;
    }

  }
  private _overwriteInputWithIncomingValue(input:any, changesObjectValue:any) {
    const formControl = this._getFormControl(input);
    formControl.setValue(changesObjectValue);
    this._setInputEditedExternally(input);
  }
  private _setInputEditedExternally(input:any) {
    this.overwriteInputs = true;
    const formControl = this._getFormControl(input);
    formControl.setEditedExternally(true);
  }

  private _getFormControl(input:any): CustomFormControl {
    const formGroup = this.sharePointMapper.getFormGroupByName(input, formGroups, this.appFormGroup);
    const formControl = formGroup.get(this.sharePointMapper.getFormControlColumnName(input)) as CustomFormControl;
    return formControl;
  }

  private _changeTableValuesIfApply(changesTable: Array<any>,
    initialTableData: Array<any>,
    currentTableData: Array<any>,
    table:any) {
    let updateTable = false;

    const changesLength = changesTable.length;
    const initialLength = initialTableData.length;
    const currentLength = currentTableData.length;

    if (changesLength > 0 && initialLength === changesLength && currentLength === changesLength) {
      updateTable = this._compareValuesTable(changesTable, initialTableData, currentTableData, table);
    } else {
      updateTable = this._compareLengthTableArrays(initialLength, currentLength, changesLength, table);
    }
    if (table === AppSettings.compliancePreviousSeries24) {
      this.engagementOverviewComponent.setUpdatedPS24(updateTable);
    }
    if (table === AppSettings.compliancePreviousPPMD) {
      this.engagementOverviewComponent.setUpdatePPMD(updateTable);
    }

    if (table == AppSettings.complianceCounterpartiesList) {
      this.engagementOverviewComponent.setUpdatedCounterParties(updateTable);
    }

    if (table == AppSettings.complianceDTTLList) {
      this.crossBorderComponent.setUpdatedDTTL(updateTable);
    }

    if (table == AppSettings.dealTeamTableList) {
      this.projectOverviewComponent.setUpdateDealTeam(updateTable);
    }

  }

  private _compareValuesTable(changesTableData: Array<any>,
    initialTableData: Array<any>,
    currentTableData: Array<any>,
    table: string): boolean {
    let updateTable = false;
    let field;
    //Already Commented in Sharepoint 
    //   let sortingParam = "documentTypeId";
    //   const changesLength = changesTableData.length;
    //   const initialLength = initialTableData.length;
    //   const currentLength = currentTableData.length;
    //   if (changesLength > 0 && initialLength === changesLength && currentLength === changesLength) {
    //     //sorting by documentTypeId
    //     if(table === AppSettings.attachmentsList ){
    //       changesTableData.sort(this._compareValues(sortingParam));
    //       initialTableData.sort(this._compareValues(sortingParam));
    //       currentTableData.sort(this._compareValues(sortingParam));
    //     }

    //   updateTable = this._compareValuesTable(changesTableData, initialTableData, currentTableData, table);
    // } else {
    //   updateTable = this._compareLengthTableArrays(initialLength, currentLength, changesLength, table);
    // }
    if(table === AppSettings.complianceDTTLList){
      for (let i = 0; i < changesTableData.length; i++) {
        const initialValue = initialTableData[i];
        const changesValue = changesTableData[i];
        const currentValue = currentTableData[i];
        let field=FormListFields.dttlInvolved;
       
        if ((Object.entries(initialValue.freeTextOptionOne.name).toString() === Object.entries(currentValue.freeTextOptionOne.name).toString() &&
        Object.entries(initialValue.freeTextOptionOne.name).toString() !== Object.entries(changesValue.freeTextOptionOne.name).toString())) {
          this._setInputEditedExternally(field);
          updateTable = true;
          break;
        } 
        else {
          
           if ((Object.entries(changesValue.freeTextOptionOne.name).toString() !== Object.entries(initialValue.freeTextOptionOne.name).toString() &&
              Object.entries(changesValue.freeTextOptionOne.name).toString() !== Object.entries(currentValue.freeTextOptionOne.name).toString() &&
              Object.entries(currentValue.freeTextOptionOne.name).toString() !== Object.entries(initialValue.freeTextOptionOne.name).toString())) {
              this._setInputWithConflict(field);
              updateTable = true;
              break;
          }
        }
      }
    }
    if(table === AppSettings.dealTeamTableList||table===AppSettings.compliancePreviousSeries24||table===AppSettings.compliancePreviousPPMD){
      for (let i = 0; i < changesTableData.length; i++) {
        const initialValue = initialTableData[i];
        const changesValue = changesTableData[i];
        const currentValue = currentTableData[i];
        let field=table==AppSettings.dealTeamTableList?FormListFields.dealTeamTable:table==AppSettings.compliancePreviousSeries24?FormListFields.previousSeries24:FormListFields.previousPPMDLead;
        if (Object.entries(initialValue).toString() === Object.entries(currentValue).toString() &&
        Object.entries(initialValue).toString() !== Object.entries(changesValue).toString()) {
          this._setInputEditedExternally(field);
          updateTable = true;
          break;
        } 
       else if ((Object.entries(initialValue.optionsSelectedFirstColumn).toString() === Object.entries(currentValue.optionsSelectedFirstColumn).toString() &&
        Object.entries(initialValue.optionsSelectedFirstColumn).toString() !== Object.entries(changesValue.optionsSelectedFirstColumn).toString())||
        (Object.entries(initialValue.usersSelected[0]).toString() === Object.entries(currentValue.usersSelected[0]).toString() &&
        Object.entries(initialValue.usersSelected[0]).toString() !== Object.entries(changesValue.usersSelected[0]).toString())) {
          this._setInputEditedExternally(field);
          updateTable = true;
          break;
        } 
        else {
          if (Object.entries(changesValue).toString() !== Object.entries(initialValue).toString() &&
              Object.entries(changesValue).toString() !== Object.entries(currentValue).toString() &&
              Object.entries(currentValue).toString() !== Object.entries(initialValue).toString()) {
              this._setInputWithConflict(field);
              updateTable = true;
              break;
          }
          else if ((Object.entries(changesValue.optionsSelectedFirstColumn).toString() !== Object.entries(initialValue.optionsSelectedFirstColumn).toString() &&
              Object.entries(changesValue.optionsSelectedFirstColumn).toString() !== Object.entries(currentValue.optionsSelectedFirstColumn).toString() &&
              Object.entries(currentValue.optionsSelectedFirstColumn).toString() !== Object.entries(initialValue.optionsSelectedFirstColumn).toString())||(
                Object.entries(changesValue.usersSelected[0]).toString() !== Object.entries(initialValue.usersSelected[0]).toString() &&
              Object.entries(changesValue.usersSelected[0]).toString() !== Object.entries(currentValue.usersSelected[0]).toString() &&
              Object.entries(currentValue.usersSelected[0]).toString() !== Object.entries(initialValue.usersSelected[0]).toString())) {
              this._setInputWithConflict(field);
              updateTable = true;
              break;
          }
        }
      }
    }
    if (table === AppSettings.compliancePreviousSeries24) {
      field = FormListFields.previousSeries24;
    }
    if (table === AppSettings.compliancePreviousPPMD) {
      field = FormListFields.previousPPMDLead;
    }
    if (table === AppSettings.complianceCounterpartiesList) {
      field = FormListFields.counterparties;
    }
    if (table === AppSettings.closingCheckListAttachmentsList) {
      field = FormListFields.financeFileUpload;
    }

    if (table === AppSettings.complianceCounterpartiesList) {

      let changesTable: FreeTextTableItem[] = changesTableData.sort(this.compare);
      let initialTable: FreeTextTableItem[] = initialTableData.sort(this.compare);
      let currentTable: FreeTextTableItem[] = currentTableData.sort(this.compare);

      for (let i = 0; i < changesTable.length; i++) {
        const initialValue = initialTable[i];
        const changesValue = changesTable[i];
        const currentValue = currentTable[i];

        updateTable = this._compareCounterPartiesRow(initialValue, currentValue, changesValue, field);
      }
    }
    return updateTable;
  }

  private _compareValues(key:any, order = 'asc') {
    return (a:any, b:any) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }
  private _compareCounterPartiesRow(initial:any, current:any, external:any, field:any): any {
    let initialCounterRow: any = initial;
    let currentCounterRow: any = current;
    let externalCounterRow: any = external;
    let properties = Object.keys(externalCounterRow);
    let updateTable = false;

    for (let index = 0; index < properties.length; index++) {
      if (initialCounterRow[properties[index]].hasOwnProperty('value')) {
        // dropdown
        if (initialCounterRow[properties[index]].value === currentCounterRow[properties[index]].value &&
          initialCounterRow[properties[index]].value !== externalCounterRow[properties[index]].value) {
          this._setInputEditedExternally(field);
          updateTable = true;
          break;
        } else {
          if (externalCounterRow[properties[index]].value !== initialCounterRow[properties[index]].value
            && externalCounterRow[properties[index]].value !== currentCounterRow[properties[index]].value
            && currentCounterRow[properties[index]].value !== initialCounterRow[properties[index]].value
          ) {
            this._setInputWithConflict(field);
            updateTable = true;
            break;
          }
        }
      } else {
        // name
        if (initialCounterRow[properties[index]].name === currentCounterRow[properties[index]].name &&
          initialCounterRow[properties[index]].name !== externalCounterRow[properties[index]].name) {
          this._setInputEditedExternally(field);
          updateTable = true;
          break;
        } else {
          if (externalCounterRow[properties[index]].name !== initialCounterRow[properties[index]].name
            && externalCounterRow[properties[index]].name !== currentCounterRow[properties[index]].name
            && currentCounterRow[properties[index]].name !== initialCounterRow[properties[index]].name
          ) {
            this._setInputWithConflict(field);
            updateTable = true;
            break;
          }
        }
      }
    }
    return updateTable;
  }

  private _compareLengthTableArrays(initialLength: number, currentLength: number, changesLength: number, table: string): boolean {
    let updateTable = false;
    let field;
    if (table === AppSettings.dealTeamTableList) {
      field = FormListFields.dealTeamTable;
    }
    if (table === AppSettings.closingCheckListAttachmentsList) {
      field = FormListFields.financeFileUpload;
    }
    if (table === AppSettings.compliancePreviousSeries24) {
      field = FormListFields.previousSeries24;
    }
    if (table === AppSettings.compliancePreviousPPMD) {
      field = FormListFields.previousPPMDLead;
    }
    if (table === AppSettings.complianceCounterpartiesList) {
      field = FormListFields.counterparties;
    }
    if (table === AppSettings.complianceDTTLList) {
      field = FormListFields.dttlInvolved
    }

    if (initialLength === currentLength && initialLength !== changesLength) {
      this._setInputEditedExternally(field);
      updateTable = true;
    }
    else {
      if (initialLength !== currentLength && initialLength !== changesLength && currentLength !== changesLength) {
        if(table === AppSettings.complianceCounterpartiesList|| table === AppSettings.closingCheckListAttachmentsList){
        this._setInputEditedExternally(field);
        updateTable = true;
      }
      else{
        this._setInputWithConflict(field);
        updateTable = true;
      }
    }
  }
    return updateTable;

  }

  //method for sorting array of counter parties objects 
  private compare(a:any, b:any) {
    if (a.itemId > b.itemId) return 1;
    if (b.itemId > a.itemId) return -1;

    return 0;
  }

  private _populateSections(responseFormItem:any, responseMyDeal:any, responsePreviousS24Table:any, responsePPMDTable:any, responseCounterparties:any, responseDTTL:any, responseClosingCheckList:any, responseDealTeam:any, responseValuations:any, responseAttachments:any, responseDealMarketing:any) {

    this.pS24Values = responsePreviousS24Table;
    this.ppmdValues = responsePPMDTable;
    this.dttlValues = responseDTTL;
    // responseFormItem=this.loadCurrentPPMDLeadValues(responseFormItem);//Added to add CurrentPPMDDisplayNames
    const counterpartiesList = responseCounterparties.map((item:any )=> {
      const counterparties = this.mainServiceHelper.createCounterpartiesItem(item.ID, item);
      return this.mainServiceHelper.createCounterpartiesFreeTextTableItem(item.ID, counterparties, item);
    });
    this.counterpartiesValues = counterpartiesList;
    this._populateMyDealSection(responseMyDeal);
    this._populateProjectOverviewSection(responseDealTeam);
    this._populateClientOverviewSection(responseClosingCheckList);
    this._populateDealOverviewSection(responseClosingCheckList);
    this._populateTimeLineSection(responseClosingCheckList);
    this._populateQofEarningSection(responseClosingCheckList);
    //if(this.isFinanceForm){
    this._populateFinanceSection(responseClosingCheckList);
    this._populateDocumentSection(responseAttachments);

    this._populateDcfFeeStructureSection(responseClosingCheckList);

    //}
  
     this._populateEngagementOverviewSection(responseFormItem, this.pS24Values, this.ppmdValues, this.counterpartiesValues);
    this._populateCrossBorderSection(responseFormItem, this.dttlValues);
    this._populateCapitalRaisedSection(responseFormItem);
    this._populateMarketingAndCommunicationsSection(responseFormItem);
    this._populateBooksAndRecordsSection(responseFormItem);
    this._populateSupervisorySignoffSection(responseFormItem);
    this._populateFeesSection(responseFormItem);
    this._populateBuyerDealerMarketingSection(responseClosingCheckList, responseDealMarketing);
    this._populateValuationMetricsSection(responseValuations, responseClosingCheckList);
    this._populateIndemnificationSection(responseClosingCheckList);
    this._populateDealStructure(responseClosingCheckList);

  }



  private _storeSharepointObjects(responsePreviousS24Table:any, responsePPMDTable:any, responseDTTL:any, responseCounterparties:any, responsedealTeam:any, responseAttachments:any, responseFormItem:any, responseValuations:any) {
    this.initialSharepointObject = this._setSharepointObject();
    this.initialMyDealObject = this._setSharepointObjectMyDeal();
    this.initialCCFormObject = this._setSharepointClosingCheckObject();
    this.initialValuationMetrics = this._setSharepointObjectValuationMetrics();
    this.initialBuyerDealMarketing = this._setSharepointObjectDealMarketingSection();

    if (this.differences.ComplianceDifferences) {
      const differencesKeys = Object.keys(this.differences.ComplianceDifferences);
      differencesKeys.forEach(input => {
        this.initialSharepointObject[input] = this.differences.ComplianceDifferences[input];
      });
      // tslint:disable-next-line: no-string-literal
      delete this.initialSharepointObject['__metadata'];
    }

    // if(this.differences.ccFormDifferences){
    //   const differencesKeys = Object.keys(this.differences.ccFormDifferences);
    //   differencesKeys.forEach(input =>{
    //     this.initialCCFormObject[input] = this.differences.ccFormDifferences[input];
    //   });

    //   delete this.initialCCFormObject['__metadata'];
    // }
    this.initialpS24Values = this._loadActualDealTeamList(responsePreviousS24Table,2);
    this.initialPpmdValue = this._loadActualDealTeamList(responsePPMDTable,2);
    this.initialdttlValues = responseDTTL;//logic to be added
    //Added to prepare initialCounterPartieslist
    const counterpartiesList = responseCounterparties.map((item:any )=> {
      const counterparties = this.mainServiceHelper.createCounterpartiesItem(item.ID, item);
      return this.mainServiceHelper.createCounterpartiesFreeTextTableItem(item.ID, counterparties, item);
    });
    this.initialcounterpartiesValues = counterpartiesList;
    let actualDealTeamList= this._loadActualDealTeamList(responsedealTeam,1);
    this.initialDealTeamTable = actualDealTeamList;

    // this.initialAttachments = this.financeComponent.getAttachmentsRowsComplete();
    this.initialAttachments = responseAttachments;
  }
  private _storeSharepointObjectsAfterSave(responsePreviousS24Table:any, responsePPMDTable:any, responseDTTL:any, responseCounterparties:any, responsedealTeam:any, responseAttachments:any, responseFormItem:any, responseValuations:any):Observable<any> { 
    return from(this._retrieveLastChangesAfterSave()).pipe(
      map(changes => {
        this.initialSharepointObject = this._setSharepointObject();
        this.initialMyDealObject = this._setSharepointObjectMyDeal();
        this.initialCCFormObject = this._setSharepointClosingCheckObject();
        this.initialValuationMetrics = this._setSharepointObjectValuationMetrics();
        this.initialBuyerDealMarketing = this._setSharepointObjectDealMarketingSection();
    
        if (changes.responseDealMarketings) {
          const differencesKeys = Object.keys(changes.responseDealMarketings);
          differencesKeys.forEach(input => {
            this.initialBuyerDealMarketing[input] = changes.responseDealMarketings[input];
          });
          // tslint:disable-next-line: no-string-literal
          delete this.initialBuyerDealMarketing['__metadata'];
        }
        if (changes.responseValuations) {
          const differencesKeys = Object.keys(changes.responseValuations);
          differencesKeys.forEach(input => {
            this.initialValuationMetrics[input] = changes.responseValuations[input];
          });
          // tslint:disable-next-line: no-string-literal
          delete this.initialValuationMetrics['__metadata'];
        }
        if (changes.responseCCForm[0]) {
          const differencesKeys = Object.keys(changes.responseCCForm[0]);
          differencesKeys.forEach(input => {
            this.initialCCFormObject[input] = changes.responseCCForm[0][input];
          });
          // tslint:disable-next-line: no-string-literal
          delete this.initialCCFormObject['__metadata'];
        }
        if (changes.responseMyDeal[0]) {
          const differencesKeys = Object.keys(changes.responseMyDeal[0]);
          differencesKeys.forEach(input => {
            this.initialMyDealObject[input] = changes.responseMyDeal[0][input];
          });
          // tslint:disable-next-line: no-string-literal
          delete this.initialMyDealObject['__metadata'];
        }
        if (changes.responseCompliance) {
          const differencesKeys = Object.keys(changes.responseCompliance);
          differencesKeys.forEach(input => {
            this.initialSharepointObject[input] = changes.responseCompliance[input];
          });
          // tslint:disable-next-line: no-string-literal
          delete this.initialSharepointObject['__metadata'];
        }

        this.initialpS24Values = this._loadActualDealTeamList(responsePreviousS24Table,2);
        this.initialPpmdValue = this._loadActualDealTeamList(responsePPMDTable,2);
        this.initialdttlValues = responseDTTL;//logic to be added
        //Added to prepare initialCounterPartieslist
        const counterpartiesList = responseCounterparties.map((item:any )=> {
          const counterparties = this.mainServiceHelper.createCounterpartiesItem(item.ID, item);
          return this.mainServiceHelper.createCounterpartiesFreeTextTableItem(item.ID, counterparties, item);
        });
        this.initialcounterpartiesValues = counterpartiesList;
        let actualDealTeamList= this._loadActualDealTeamList(responsedealTeam,1);
        this.initialDealTeamTable = actualDealTeamList;
    
        // this.initialAttachments = this.financeComponent.getAttachmentsRowsComplete();
        this.initialAttachments = responseAttachments;
      }),
      catchError(error => {
        return of(responseCodes.fail);
      })
    );
   
  }
  onDiscontinue() {
    this._setValidators([]);
    this.formSubmitted = true;
    if (this.appFormGroup.valid || this.appFormGroup.status === 'DISABLED') {
      this._openModal(modalTypes.discontinue);
    } else {
      this._openModal(modalTypes.mandatoryFields);
    }
  }
  onApprove() {
    this.spinnerService.showAutoSaveSpinner();

    this._validateDCFUsersBeforeUserAction().subscribe(areValid => {
      if (areValid) {
        this._setValidators(this.initialFormValidators);
        this.formSubmitted = true;
        if (this.appFormGroup.valid) {
          this._stopAutosave();
          this.saveForm().subscribe(() => {
            this.mainService.retrieveForm_CCF(AppSettings.closingChecklistFormList,
              this.formId).subscribe((complianceData:any) => {
                var complianceInfo = complianceData[0];
                if (this.newWorkflow) {
                  if ((complianceInfo.FormActionGroup === "Series24") ||
                    complianceInfo.FormStatus === "Discontinued"
                  ) {
                    var currentDate = new Date();
                    var dateToSent = currentDate;
                    var approver = this.currentUser.title;
                    this.formStatus = FormStatusValue.approved;
                    const jsonItems = {
                      Status:StatusValue.completed,
                      FormStatus: this.formStatus,
                      Series24ApprovalDate: dateToSent,
                      Series24Signature: approver,
                      ExecuteNewWorkflow: "TRUE"
                    };
                    this.mainService.updateForm_CCF(AppSettings.closingChecklistFormList, this.formId, jsonItems,this.initialClosingCheckList.Created,true)
                      .subscribe(() => {
                        this.spinnerService.hideAutoSaveSpinner();
                        this._openModal(modalTypes.approval);
                      });
                  } else {
                    this.spinnerService.hideAutoSaveSpinner();
                    this._openModal(modalTypes.complianceSignOff);
                  }
                } else {
                  var currentDate = new Date();
                  var dateToSent = currentDate;
                  var approver = this.currentUser.title;
                  this.formStatus = FormStatusValue.approved;
                  const jsonItems = {
                    Status:StatusValue.completed,
                    FormStatus: this.formStatus,
                    Series24ApprovalDate: dateToSent,
                    Series24Signature: approver,
                    ExecuteNewWorkflow: "FALSE"
                  };
                  this.mainService.updateForm_CCF(AppSettings.closingChecklistFormList, this.formId, jsonItems,this.initialClosingCheckList.Created,true)
                    .subscribe(() => {
                      this.spinnerService.hideAutoSaveSpinner();
                      this._openModal(modalTypes.approval);
                    });
                }
              });
          });
        }
        else {
          this.spinnerService.hideAutoSaveSpinner();
          this._openModal(modalTypes.mandatoryFields);
        }
      }
    });

  }
  onReturnForWork() {
    this._setValidators([]);
    this.formSubmitted = true;
    this._openModal(modalTypes.returnForRework);
  }

  confirmReturnForReworkReason() {
    if (this.returnForReworkReason.trim()) {
      this.spinnerService.showAutoSaveSpinner();
      this._stopAutosave();
      this.formStatus = FormStatusValue.rejected;
      const jsonItems = {
        FormStatus: this.formStatus,
        ReturnForReworkReason: this.returnForReworkReason,
        ExecuteNewWorkflow: this.newWorkflow == true ? "TRUE":"FALSE"
      };
      forkJoin([this.mainService.deleteReviewsAfterReturnForRework(this.formId),
      this.mainService.updateListItem_CCF(AppSettings.closingChecklistFormList, this.formId, jsonItems,this.initialClosingCheckList.Created,true)])
        .subscribe((response) => {
          this._openModal(modalTypes.returnForReworConfirmed);
          this.spinnerService.hideAutoSaveSpinner();
          return true;
        },
        catchError((err:any) => {
         this.spinnerService.hideAutoSaveSpinner();
         throw err;
        }));
      
    }
    else {
      this.closeReturnForRework();
      this._openModal(modalTypes.returnForReworkReason);
    }
  }
  confirmDiscontinueReason() {
    if (this.discontinueReason.trim()) {
      this.spinnerService.showAutoSaveSpinner();
      this.ComplianceService.updateFormOnDiscontinue(this.formStatus,this.formActionGroup, this.formId, this.discontinueReason,this.initialClosingCheckList.Created,true).subscribe((response) => {
        if (response) {
          this._openModal(modalTypes.discontinueConfirmed);
        }
        this.spinnerService.hideAutoSaveSpinner();
      });
    } else {
      this.closeDiscontinue();
      this._openModal(modalTypes.discontinueReason);
    }
  }

  closeDiscontinue() {    
    this.discontinueReason = '';
  }

  cancelDiscontinue() {
    this.closeDiscontinue();
    this.formSubmitted = false;
    this._setValidators(this.initialFormValidators);
  }
  closeReturnForRework() {    
    this.returnForReworkReason = '';
  }
  cancelReturnForRework() {
    this.closeReturnForRework();
    this.formSubmitted = false;
    this._setValidators(this.initialFormValidators);
  }
  // checkVisibilitySupervisorySignoffSetion() {
  //   return this._isSignoffVisible() && !this._isFormDiscontinuedByDealTeam(this.discontinuedFormActionGroup);
  // }

  showSeries24ApprovalSection() {
    return this.hasPermission('Approve');
  }

  reactivateFormHandler() {
    this._openModal(modalTypes.reactivateForm);
  }

  confirmReactivateForm() {
    this.spinnerService.showAutoSaveSpinner();
    // this.mainService.reactivateForm().then(response => {
    this.mainService.reactivateForm_Closing_Checklist(this.formId,this.initialClosingCheckList.Created).subscribe((response:any) => {
      this._openModal(modalTypes.reactivateConfirmed);
      this.spinnerService.hideAutoSaveSpinner();
    });
  }
  //old UpdateForm item with only API call
  // private _updateFormItem(sharepointObject, sharepointObjectMyDeal): Promise<boolean> {// OLD FORm UPDATE directly by API call
  //   if (this.formId) {
  //     return new Promise<boolean>((resolve, reject) => {
  //       Promise.all([
  //         this.mainService.updateListItem_CCF(AppSettings.complianceFormList, this.formId, sharepointObject),
  //         this.mainService.updateListItem_CCF(AppSettings.myDealFormList, this.myDealFormId, sharepointObjectMyDeal)])
  //         .then(([responseFormItem]) => {
  //           resolve(true);
  //       }).catch(error => {
  //         reject(false);
  //       });
  //     });
  //   } else {
  //     return new Promise<boolean>((resolve, reject) => {
  //       this.mainService.updateListItem_CCF(AppSettings.complianceFormList, this.formId, sharepointObject)
  //       .then(([responseFormItem]) => {
  //         resolve(true);
  //       }).catch(error => {
  //         reject(false);
  //       });
  //     });
  //   }
  // }
  loadSeries24DisplayName(objectMyDeal:any){
    if(objectMyDeal.Series24!=undefined){
      this.s24GroupUsers.forEach((x:any)=>{
        if(x.Email==objectMyDeal.Series24){
          objectMyDeal.Series24DisplayNames=x.LastName+', '+x.FirstName;
        }
      })
      }
      return objectMyDeal;
  }
  loadCurrentPPMDLeadValues(objectCompliance:any){
    if (objectCompliance.CurrentPPMDLead != undefined) {
      if (objectCompliance.CurrentPPMDLead?.results != undefined) {
        if (objectCompliance.CurrentPPMDLead?.results.length == 0) {
          objectCompliance.CurrentPPMDLeadDisplayNames = "";
          objectCompliance.CurrentPPMDLead ="";
        }
        else {
          objectCompliance.CurrentPPMDLead?.results?.forEach((x: any) => {
            objectCompliance.CurrentPPMDLeadDisplayNames = x.displayName;
            objectCompliance.CurrentPPMDLead = x.mail;
          })
        }
      }
    }
    return objectCompliance;
  }
  //updatedFrom Item with differences and objects
  private _updateFormItem(differences:any,changes:any): Observable<boolean> {
  let objectCompliance = differences.complianceDifferences;
  //Adding below logic to add the PPMD
  objectCompliance=this.loadCurrentPPMDLeadValues(objectCompliance);
  let objectMyDeal = differences.myDealDifferences;
//Adding below logic to add the series 24
  objectMyDeal= this.loadSeries24DisplayName(objectMyDeal);
  if(objectMyDeal.Series24Id!=undefined||isNaN(objectMyDeal.Series24Id)){delete objectMyDeal.Series24Id;}
  const objectValuationMetrics = differences.valuationMetricsDifferences;
  const objectDealMarketing = differences.dealMarketingDifferences;
  const objectCCForm = differences.ccFormDifferences;
  if(objectCCForm.hasOwnProperty('FinanceFileUpload'))
  {
      delete objectCCForm['FinanceFileUpload'];
  }
  const valuationMetricsLength = Object.keys(objectValuationMetrics).length;
  const dealMarketingLength = Object.keys(objectDealMarketing).length;
  const complianceLength = Object.keys(objectCompliance).length;
  const objectMyDealLength = Object.keys(objectMyDeal).length;
  const objectCCFormLength = Object.keys(objectCCForm).length;

  return forkJoin([
    complianceLength ? this.mainService.updateListItem_CCF(AppSettings.complianceFormList, this.formId, objectCompliance,changes.responseCompliance.Created) : of(true),
    objectMyDealLength ? this.mainService.updateListItem_CCF(AppSettings.myDealFormList, this.myDealFormId, objectMyDeal,changes.responseMyDeal[0].Created) : of(true),
    objectCCFormLength ? this.mainService.updateListItem_CCF(AppSettings.closingChecklistFormList, this.formId, objectCCForm,changes.responseCCForm[0].Created) : of(true),
    valuationMetricsLength ? this.mainService.updateListItem_CCF(AppSettings.closingCheckListValuations, this.valuationListId, objectValuationMetrics,changes.responseValuations.Created) : of(true),
    dealMarketingLength ? this.mainService.updateListItem_CCF(AppSettings.closingCheckListDealMarketingCount, this.dealMarketingId, objectDealMarketing,changes.responseDealMarketings.Created) : of(true)
  ]).pipe(
    map(([responseFormItem, responseMyDealFormItem, responseCCForm, responseValuations, responseDealMarketing]) => {
      // if (objectMyDeal.Series24Id) {
          //   this._updateMyDealDataInForm(objectMyDeal.Series24Id);//ask vinodh
          // }
      if (responseFormItem && responseMyDealFormItem && responseCCForm && responseValuations && responseDealMarketing) {
        return true;
      } else {
        return false;
      }
    })
  );
}
 
  //update for series 24 in Compliance from coming from my deal form
  private _updateMyDealDataInForm(responseSeries24:any) {
    const myDealDataObject = { Series24Id: null };
    let dealTeamMembers = [];
    if (responseSeries24) {
      myDealDataObject.Series24Id = responseSeries24;
    }
    this.saveInProgress = true;
    this.mainService.updateListItem_CCF(AppSettings.complianceFormList, this.complianceFormId, myDealDataObject,"")
      .subscribe(() => {
        this.saveInProgress = false;
        
      })
      // .catch(error => {
      //   this.saveInProgress = false;
      //   this._stopAutosave();
      // });
  }

  ConfirmDetails(event:any) //Handle the popup click
  {
    if(event.isOkayClicked) // Okay is clicked on the popup
    {
        //based on the type propgate the function call
        switch(event.modalType)
        {
          case modalTypes.allDealTeamMemberApproval:
          case modalTypes.signOffApproved:
          case modalTypes.submit: // redirect to engagement dashboard          
          case modalTypes.discontinueConfirmed: // redirect to engagement dashboard
          case modalTypes.returnForReworConfirmed: // redirect to engagement dashboard          
          case modalTypes.approval: // redirect to engagement dashboard
          case modalTypes.reactivateConfirmed: // redirect to engagement dashboard   
          // case modalTypes.retryWorkflow: // redirect to engagement dashboard   
          case modalTypes.formUpdated:  // redirect to engagement dashboard   
          // case modalTypes.activeWBSNotification: // redirect to engagement dashboard   
          case modalTypes.noDCFMember: // stay on same page
          case modalTypes.noValidForm:
          case modalTypes.access: this.redirectOkModal(event.modalType);break; // stay on the same page with freeze          
          case modalTypes.userLeftDcf:
          case modalTypes.mandatoryFields:this.scrollToTop();break;
          case modalTypes.retrieve:this.scrollToSection();break;
          case modalTypes.discontinue:this.discontinueReason=event.discontinueReason;this.confirmDiscontinueReason();break;
          case modalTypes.returnForRework:this.returnForReworkReason=event.returnForReworkReason;this.confirmReturnForReworkReason();break;
          case modalTypes.returnForReworkReason:this.onReturnForWork();break;
          case modalTypes.reactivateForm:this.confirmReactivateForm();break;
          case modalTypes.discontinueReason:this.onDiscontinue();break;
          default:break;
        }
    }
    else //cancel clicked
    {
      switch(event.modalType)
      {
        case modalTypes.discontinue:this.cancelDiscontinue();break;
        case modalTypes.returnForRework:this.cancelReturnForRework();break;
        default:break;
      }
    }
  }


  scrollToSection() {
    const url = window.location.href;
    const section = url.split('closingCheckListForm')[1].split('?')[0];
    if (section && section !== 'main') {
      this.sidebarComponent.onclick('#' + section);
    }
  }
  private _validateDCFUsersBeforeUserAction(): Observable<any> {
    // return forkJoin([
    //   //this.ComplianceService.getDCFGroupMembers(),
    //   this.ComplianceService.GetGroupData(AppSettings.series24Group)
    // ]).pipe(
    //   map(([series24users]) => {
    //     const series24Selected =
    //       this.appFormGroup.get(formGroups.myDealFormGroup)
    //         .get(this.sharePointMapper.getFormControlColumnName(FormListFields.series24)).value;
    //     // return this._areUsersBelongToDCFGroup(series24Selected, null, JSON.parse(localStorage.getItem("adGroupMember")) != null ? JSON.parse(localStorage.getItem("adGroupMember")) : this.adGroupMember.value, series24users);
    //     return this._areUsersBelongToDCFGroup(series24Selected, [], localStorage.getItem("adGroupMember") != null ? localStorage.getItem("adGroupMember") : this.adGroupMember.value, series24users);
    //   })
    // );
   
        const series24Selected =
          this.appFormGroup.get(formGroups.myDealFormGroup)
            .get(this.sharePointMapper.getFormControlColumnName(FormListFields.series24)).value;
        // return this._areUsersBelongToDCFGroup(series24Selected, null, JSON.parse(localStorage.getItem("adGroupMember")) != null ? JSON.parse(localStorage.getItem("adGroupMember")) : this.adGroupMember.value, series24users);
        return from(this._areUsersBelongToDCFGroup(series24Selected, [], localStorage.getItem("adGroupMember") != null ? localStorage.getItem("adGroupMember") : this.adGroupMember.value, this.mainService.myDealS24List));
     
  }

 

  private _isSeries24Selected(series24Email: any, series24users:any): Observable<any> {
    if (series24Email) {
     let data:any= this._isSeries24ValidUser_CCF(series24Email, series24users);
      return of(data);
    } else {
        return of(true);
    }
}



  private _setSharepointObject(): SharepointObject {
    let sharepointObject:any = new SharepointObject();

    sharepointObject = {
      ...sharepointObject,
      // Project Overview
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.engagementOverviewFormGroup) as FormGroup),
      ComplianceDealType: (this.appFormGroup.get(formGroups.engagementOverviewFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.complianceDealType)).value),


      // CrossBorder
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.crossBorderFormGroup) as FormGroup),
      // Capital Raised
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.capitalRaisedFormGroup) as FormGroup),
      // Marketing and Comunications
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.marketingAndComunicationsFormGroup) as FormGroup),
      // Books and Records
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.booksAndRecordsFormGroup) as FormGroup),
      // Buyer Dealer
      //...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.buyerDealMarketingFormGroup) as FormGroup),
      // Valuation Metrics
      //...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.valuationMetricsFormGroup) as FormGroup),
      DateICReviewApproval: this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.booksAndRecordsFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.dateICReviewApproval)).value),

      // Supervisory Signoff
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.supervisorySignoffFormGroup) as FormGroup),

      // Fees
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.feesFormGroup) as FormGroup),

      DateSuccessFeeReceived: this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.feesFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.dateSuccessFeeReceived)).value),

      DatePaidFinderFee: this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.feesFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.datePaidFinderFee)).value)

    };
    delete sharepointObject.DTTLInvolved;
    delete sharepointObject.Counterparties;
    delete sharepointObject.PreviousSeries24;
    delete sharepointObject.PreviousPPMDLead;

    return sharepointObject;
  }

  // private _setSharepointObjectCCForm(): SharepointClosingCheckListObject{
  //   let sharepointObject = new SharepointClosingCheckListObject();
  //   sharepointObject = {
  //     ...sharepointObject,
  //     //client  Overview section
  //     ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.clientOverViewFormGroup) as FormGroup),
  //    //Deal Overview Section
  //     ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.dealOverViewFormGroup)as FormGroup),

  //    };
  //     return sharepointObject;
  //   }

  private _setSharepointObjectMyDeal(): SharepointObjectMyDeal {
    let sharepointObject = new SharepointObjectMyDeal();
    const myDealFormGroup = this.appFormGroup.get(formGroups.myDealFormGroup);

    sharepointObject = {
      ...sharepointObject,

      // My Deal
      ...this.sharePointMapper.fillSharepointObject(myDealFormGroup as FormGroup),
    };

    return sharepointObject;
  }
  private _setSharepointObjectValuationMetrics(): SharePointObjectValuationMetrics {
    let sharepointObject = new SharePointObjectValuationMetrics();
    const valuationMetricsFormGroup = this.appFormGroup.get(formGroups.valuationMetricsFormGroup);

    sharepointObject = {
      ...sharepointObject,

      // Valuation Metrics
      ...this.sharePointMapper.fillSharepointObject(valuationMetricsFormGroup as FormGroup),
    };

    return sharepointObject;
  }

  private _setSharepointObjectDealMarketingSection(): SharepointDealMarketingSection {
    let sharepointObject = new SharepointDealMarketingSection();
    const buyerDealMarketngGroup = this.appFormGroup.get(formGroups.buyerDealMarketingFormGroup);
    sharepointObject = {
      ...sharepointObject,

      // Buyer Deal Marketing
      ...this.sharePointMapper.fillSharepointObject(buyerDealMarketngGroup as FormGroup),
    };

    return sharepointObject;
  }
  private _setSharepointObjectCompliance(): SharepointObjectCompliance {
    let SharepointObject = new SharepointObjectCompliance();
    SharepointObject = {
      ...SharepointObject,
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.engagementOverviewFormGroup) as FormGroup),
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.crossBorderFormGroup) as FormGroup),
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.capitalRaisedFormGroup) as FormGroup),
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.marketingAndComunicationsFormGroup) as FormGroup),
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.booksAndRecordsFormGroup) as FormGroup),
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.supervisorySignoffFormGroup) as FormGroup),
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.feesFormGroup) as FormGroup),
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.myDealFormGroup) as FormGroup),

    }
    return SharepointObject;
  }

  private _createSharepointMapper() {
    this.sharePointMapper = new ComplianceSharepointMapper();

    // My Deal
    this._fillMyDeal();
    //Project Overview section
    this._fillProjectOverview();
    //Client Overview Section
    this._fillClientOverView();
    //Deal Overview Section
    this._fillDealOverView();
    // Dates and Timeline Section
    this._filltimelineContent();
    //Quality Of Earning section
    this._fillQualityOfEarnings();
    //Finance Section
    this._fillFinance();
    // Proyect overview
    this._fillEngagementOverview();
    // Cross Border
    this._fillCrossBorder();
    // Capital Raised
    this._fillCapitalRaised();
    // Marketing and Communications
    this._fillMarketingAndCommunications();
    // Books and Records
    this._fillBooksAndRecords();
    // Supervisory Signoff
    this._fillSupervisorySignoff();
    // Fees
    this._fillFees();
    //Valuation Metrics
    this._fillValuationMetrics();
    //Buyer Dealer
    this._fillBuyerDealMarketing();
    //Deal Structure
    this._fillDealStructure();
    //Indemnification
    this._fillIndemnification();
    // Additional Commentary
    this._fillAdditionalCommentary();

    // //DCf-fee structure
    this._fillDcfFeeStrcuture();
  }

  private _fillMyDeal() {
    this.sharePointMapper.addMapping('Series24', 'series24');
    this.sharePointMapper.addMapping('EngagementName', 'engagementName');
    // CC form mydeal fields
    // this.sharePointMapper.addMapping('EngagementName', 'engagementName');
    this.sharePointMapper.addMapping('BuyerName', 'buyerName');
    // this.sharePointMapper.addMapping('Retainer', 'retainer');
    // this.sharePointMapper.addMapping('FeeType', 'feeType');
    this.sharePointMapper.addMapping('IsCompetitivePitch', 'isCompetitivePitch');
    this.sharePointMapper.addMapping('ClientName', 'clientName');
    this.sharePointMapper.addMapping('Industry', 'industry');
    this.sharePointMapper.addMapping('Subsector', 'subsector');
  }

  private _fillEngagementOverview() {
    this.sharePointMapper.addMapping('WBSCode', 'wbsCode');
    this.sharePointMapper.addMapping('ComplianceDealType', 'complianceDealType');
    this.sharePointMapper.addMapping('IsClientPublicEntity', 'isClientPublicEntity');
    this.sharePointMapper.addMapping('Symbol', 'symbol');
    this.sharePointMapper.addMapping('RemoveFromBlackList', 'removeFromBlackList');
    this.sharePointMapper.addMapping('Counterparties', 'counterparties');
    this.sharePointMapper.addMapping('BranchOffice', 'branchOffice');
    this.sharePointMapper.addMapping('WerePreviousSeries24', 'werePreviousSeries24');
    this.sharePointMapper.addMapping('PPMDDCFUser', 'pPMDDCFUser');
    this.sharePointMapper.addMapping('PPMDNonDCFUser', 'pPMDNonDCFUser');
    this.sharePointMapper.addMapping('WerePreviousPPMD', 'werePreviousPPMD');
    this.sharePointMapper.addMapping('CurrentPPMDLead', 'currentPPMDLead');
    this.sharePointMapper.addMapping('PreviousSeries24', 'previousSeries24');
    this.sharePointMapper.addMapping('PreviousPPMDLead', 'previousPPMDLead');
    this.sharePointMapper.addMapping('Series24', 'series24');
  }

  private _fillCrossBorder() {
    this.sharePointMapper.addMapping('IsDTTLInvolved', 'isDTTLInvolved');
    this.sharePointMapper.addMapping('DTTLInvolved', 'dttlInvolved');
    this.sharePointMapper.addMapping('IsXBEngagementAn', 'isXBEngagementAn');
  }

  private _fillCapitalRaised() {
    this.sharePointMapper.addMapping('CapitalRaised', 'capitalRaised');
  }

  private _fillMarketingAndCommunications() {
    this.sharePointMapper.addMapping('PitchBook', 'pitchBook');
    this.sharePointMapper.addMapping('PitchBookSeries24', 'pitchBookSeries24');
    this.sharePointMapper.addMapping('Teaser', 'teaser');
    this.sharePointMapper.addMapping('TeaserSeries24', 'teaserSeries24');
    this.sharePointMapper.addMapping('CIMMemorandumPPM', 'cimMemorandumPPM');
    this.sharePointMapper.addMapping('CIMMemorandumPPMSeries24', 'cimMemorandumPPMSeries24');
    this.sharePointMapper.addMapping('Tombstone', 'tombstone');
    this.sharePointMapper.addMapping('TombstoneSeries24', 'tombstoneSeries24');
    this.sharePointMapper.addMapping('CaseStudies', 'caseStudies');
    this.sharePointMapper.addMapping('CaseStudiesSeries24', 'caseStudiesSeries24');
    this.sharePointMapper.addMapping('PressRelease', 'pressRelease');
    this.sharePointMapper.addMapping('PressReleaseSeries24', 'pressReleaseSeries24');
    this.sharePointMapper.addMapping('LeagueTablesMarketing', 'leagueTablesMarketing');
  }

  private _fillBooksAndRecords() {
    this.sharePointMapper.addMapping('LinkFinalEngagement', 'linkFinalEngagement');
    this.sharePointMapper.addMapping('CopyFinalBriefingPaper', 'copyFinalBriefingPaper');
    this.sharePointMapper.addMapping('ExecutedEngagementLetter', 'executedEngagementLetter');
    this.sharePointMapper.addMapping('AmendmentsEngagementLetter', 'amendmentsEngagementLetter');
    this.sharePointMapper.addMapping('FullyExecutedIFA', 'fullyExecutedIFA');
    this.sharePointMapper.addMapping('PitchProposal', 'pitchProposal');
    this.sharePointMapper.addMapping('TeaserBooks', 'teaserBooks');
    this.sharePointMapper.addMapping('CIMOfferingMemorandum', 'cimOfferingMemorandum');
    this.sharePointMapper.addMapping('ManagementPresentation', 'managementPresentation');
    this.sharePointMapper.addMapping('ClientLetterMarketingPress', 'clientLetterMarketingPress');
    this.sharePointMapper.addMapping('TombstoneBooks', 'tombstoneBooks');
    this.sharePointMapper.addMapping('PressReleaseBooks', 'pressReleaseBooks');
    this.sharePointMapper.addMapping('CaseStudiesBooks', 'caseStudiesBooks');
    this.sharePointMapper.addMapping('LeagueTables', 'leagueTables');
    this.sharePointMapper.addMapping('ListProspectiveInvestorsBuyers', 'listProspectiveInvestorsBuyers');
    this.sharePointMapper.addMapping('ListActualInvestorsBuyers', 'listActualInvestorsBuyers');
    this.sharePointMapper.addMapping('ExpressionOfInterestLettersInt', 'expressionOfInterestLettersInt');
    this.sharePointMapper.addMapping('ContainsAllTransactionDocuments', 'containsAllTransactionDocuments');
    this.sharePointMapper.addMapping('DidProjectGoIC', 'didProjectGoIC');
    this.sharePointMapper.addMapping('IfNoExplain', 'ifNoExplain');
    this.sharePointMapper.addMapping('DateICReviewApproval', 'dateICReviewApproval');
    this.sharePointMapper.addMapping('ZipFileSent', 'zipFileSent');
  }

  private _fillSupervisorySignoff() {
    this.sharePointMapper.addMapping('Series24ReviewedTransaction', 'series24ReviewedTransaction');
    this.sharePointMapper.addMapping('Series24ReviewedPersonnelHours', 'series24ReviewedPersonnelHours');
    this.sharePointMapper.addMapping('Series24TransactionComments', 'series24TransactionComments');
    this.sharePointMapper.addMapping('Series24WBSCodeComments', 'series24WBSCodeComments');
  }

  private _fillFees() {
    this.sharePointMapper.addMapping('TotalAmountsPaid', 'totalAmountsPaid');
    this.sharePointMapper.addMapping('RetainerReceived', 'retainerReceived');
    this.sharePointMapper.addMapping('PeriodicOrFlat', 'periodicOrFlat');
    this.sharePointMapper.addMapping('AmountRetainer', 'amountRetainer');
    this.sharePointMapper.addMapping('PeriodRetainer', 'periodRetainer');
    this.sharePointMapper.addMapping('RetainerCredited', 'retainerCredited');
    this.sharePointMapper.addMapping('EngagementFeeBased', 'engagementFeeBased');
    this.sharePointMapper.addMapping('SuccessFeePaidFull', 'successFeePaidFull');
    this.sharePointMapper.addMapping('AmountSuccessPaid', 'amountSuccessPaid');
    this.sharePointMapper.addMapping('PotentialAmountOutstanding', 'potentialAmountOutstanding');
    this.sharePointMapper.addMapping('DateSuccessFeeReceived', 'dateSuccessFeeReceived');
    this.sharePointMapper.addMapping('ExpensesBilled', 'expensesBilled');
    this.sharePointMapper.addMapping('FinderReferralFeeOwed', 'finderReferralFeeOwed');
    this.sharePointMapper.addMapping('AmountFinderOwed', 'amountFinderOwed');
    this.sharePointMapper.addMapping('RecipientFinderFee', 'recipientFinderFee');
    this.sharePointMapper.addMapping('DatePaidFinderFee', 'datePaidFinderFee');
  }

  private _setDateInput(formGroup:any, inputName:any, input:any) {
    if (input) {
      this.appFormGroup.get(formGroup)
        .get(this.sharePointMapper.getFormControlColumnName(inputName)).setValue(
          this.dateFormatterService.formatDateOnRetrieve(input));
    }
  }

  private _setSharepointMapper() {
    this.engagementOverviewComponent.setSharepointMapper(this.sharePointMapper);
    this.crossBorderComponent.setSharepointMapper(this.sharePointMapper);
    this.capitalRaisedComponent.setSharepointMapper(this.sharePointMapper);
    this.marketingAndCommunicationsComponent.setSharepointMapper(this.sharePointMapper);
    this.booksAndRecordsComponent.setSharepointMapper(this.sharePointMapper);
    // this.supervisorySignoffComponent.setSharepointMapper(this.sharePointMapper);
    this.feesComponent.setSharepointMapper(this.sharePointMapper);
    this.valuationMetricsComponent.setSharepointMapper(this.sharePointMapper);
    this.clientOverViewComponent.setSharepointMapper(this.sharePointMapper);
    this.dealOverviewComponent.setSharepointMapper(this.sharePointMapper);
    this.datesTimelineComponent.setSharepointMapper(this.sharePointMapper);
    this.dealStructureComponent.setSharepointMapper(this.sharePointMapper);
    this.qualityOfEarningsComponent.setSharepointMapper(this.sharePointMapper);
    //if(this.isFinanceForm)
    this.financeComponent.setSharepointMapper(this.sharePointMapper);
    this.indemnificationComponent.setSharepointMapper(this.sharePointMapper);
    this.buyerDealMarketingComponent.setSharepointMapper(this.sharePointMapper);

    //this.additionalCommentaryComponent.setSharepointMapper(this.sharePointMapper);
    this.dCFFeeStructureComponent.setSharepointMapper(this.sharePointMapper);


  }

  // tslint:disable-next-line:max-line-length
  private _populateEngagementOverviewSection(responseFormItem:any, pS24Values: Array<PeoplepickerDynamicTableItem>, ppmdValues: Array<PeoplepickerDynamicTableItem>,
    counterpartiesValues: Array<FreeTextTableItem>) {    
      let ps24Data = this._loadActualDealTeamList(pS24Values,2);
      let ppmdData = this._loadActualDealTeamList(ppmdValues,2);
    
      this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.engagementOverviewFormGroup) as FormGroup, responseFormItem);

    this.appFormGroup.get(formGroups.engagementOverviewFormGroup)
      .get(this.sharePointMapper.getFormControlColumnName(FormListFields.previousSeries24)).setValue(ps24Data);

    this.appFormGroup.get(formGroups.engagementOverviewFormGroup)
      .get(this.sharePointMapper.getFormControlColumnName(FormListFields.previousPPMDLead)).setValue(ppmdData);
      if(!this.appContext.autoSaveEnabled){
      this._createPeoplePickerUserArrays(responseFormItem);
      }
    // this._setPeoplePickers(formGroups.engagementOverviewFormGroup, this.sharePointMapper.getFormControlColumnName(
    //   'CurrentPPMDLeadId'), responseFormItem.CurrentPPMDLead);

    this.appFormGroup.get(formGroups.engagementOverviewFormGroup)
      .get(this.sharePointMapper.getFormControlColumnName(FormListFields.counterparties)).setValue(counterpartiesValues);

    //enable or disble fields on YES or NO selection basis
    this.engagementOverviewComponent.evaluateIsPreviousSeries24(responseFormItem.WerePreviousSeries24);
    this.engagementOverviewComponent.evaluateIsonPPMDDCFUser(responseFormItem.PPMDDCFUser);
    this.engagementOverviewComponent.evaluateIsPreviousPPMD(responseFormItem.WerePreviousPPMD);
    this.engagementOverviewComponent.evaluateIsThisClientPublicEntity(responseFormItem.IsClientPublicEntity);

    this._setDealTeamValues(ps24Data);
    this._setPPMDValues(ppmdData);
    this._setCounterpartiesValues(counterpartiesValues);
  }

  LoadDropdownLists(){
    this.firstColumnItemList = this.mainService.dealTeamRoleList;
    this.secondColumnItemList=this.mainService.dealTeamPursuitRoleList;
    this.thirdColumnItemList=this.mainService.dealTeamSwiftRoleList;
    // this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamRole,'Role').then(
    //   (response:any) => {
    //     // Success block
    //    this.firstColumnItemList=response;
    //   }).catch((error:any) =>{console.error('Error fetching data:', error);})
  
    //   this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamPursuitRole,'Role').then(
    //   (response:any) => {
    //     // Success block
    //     this.secondColumnItemList=response;
    //    }).catch((error:any) =>{console.error('Error fetching data:', error);})
  
    //   this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamSwiftRole,'Role').then(
    //     (response:any) => {
    //       // Success block
    //       this.thirdColumnItemList=response;
    //         }).catch((error:any) =>{console.error('Error fetching data:', error);})
  }

  private _populateProjectOverviewSection(responseDealTeam: Array<PeoplepickerDynamicTableItem>) {
    this.dealTeamValues = responseDealTeam;
    let actualDealTeamList= this._loadActualDealTeamList(responseDealTeam,1);
    this.dealTeamValues= actualDealTeamList;
    this.appFormGroup.get(formGroups.projectOverViewFormGroup)
    .get(this.sharePointMapper.getFormControlColumnName(FormListFields.dealTeamTable)).setValue(this.dealTeamValues);
    this._setDealTeamTableValues(this.dealTeamValues);
    //this.spinnerService.hideAutoSaveSpinner();
  }

  private _populateMyDealSection(responseMyDeal:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.myDealFormGroup) as FormGroup, responseMyDeal);
    this.dealOverviewComponent?.evaluateIsCompetitivePitch(responseMyDeal.IsCompetitivePitch);
    //Binding for series24 dropdown
    if(responseMyDeal.Series24 != undefined && responseMyDeal.Series24 != "")
      {
        this.appFormGroup.get(formGroups.myDealFormGroup).controls.series24.setValue(responseMyDeal.Series24);
      }
      else
      {
        this.appFormGroup.get(formGroups.myDealFormGroup).controls.series24.setValue(responseMyDeal.Series24Id);
      }
  }

  private _populateClientOverviewSection(response:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.clientOverViewFormGroup) as FormGroup, response);

    this.clientOverViewComponent.evaluateClientType(response.ClientType);
    this.clientOverViewComponent.evaluateReferralSource(response.ReferralSource);
    this.clientOverViewComponent.evaluateClientLocation(response.ClientLocation);
  }

  private _populateDealOverviewSection(response:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.dealOverViewFormGroup) as FormGroup, response);
    this.financeComponent.evaluateDealType(response.DealType);
    this.buyerDealMarketingComponent.evaluateDealType(response.DealType);
  }

  private _populateTimeLineSection(response:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.timelineContentFormGroup) as FormGroup, response);

    this._setDateInput(formGroups.timelineContentFormGroup, FormListFields.pitchedDate, response.PitchedDate);
    this._setDateInput(formGroups.timelineContentFormGroup, FormListFields.signedEngagementLetter, response.SignedEngagementLetter);
    this._setDateInput(formGroups.timelineContentFormGroup, FormListFields.enteredMarket, response.EnteredMarket);
    this._setDateInput(formGroups.timelineContentFormGroup, FormListFields.receivedIoiTermSheets, response.ReceivedIoiTermSheets);
    this._setDateInput(formGroups.timelineContentFormGroup, FormListFields.receivedLOIs, response.ReceivedLOIs);
    this._setDateInput(formGroups.timelineContentFormGroup, FormListFields.signedLOI, response.SignedLOI);
    this._setDateInput(formGroups.timelineContentFormGroup, FormListFields.transactionClose, response.TransactionClose);
    this._setDateInput(formGroups.timelineContentFormGroup, FormListFields.signDate, response.SignDate);
    //enable or disble fields on YES or NO selection basis
    this.datesTimelineComponent.evaluateSimultaneousSignClose(response.SimultaneousSignClose);
  }
  private _populateQofEarningSection(response:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.qualityOfEarningsFormGroup) as FormGroup, response);

    this.qualityOfEarningsComponent.evaluatesellSideQofEPerformed(response.SellsideQofEPerformed);

  }

  private _populateFinanceSection(response:any) {        
    //convert the enDash to hyphen for FinanceProviderNameType
    const enDash = String.fromCharCode(8211); // Unicode U+2013
    const hyphen = '-';
    if(response.FinanceProviderNameType!=null && response.FinanceProviderNameType!=undefined)
        response.FinanceProviderNameType = response.FinanceProviderNameType.replace(new RegExp(enDash, 'g'), hyphen);          
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.financeFormGroup) as FormGroup, response);
    this.financeComponent.evaluateFinanceDeloitteEntity(response.FinanceDeloitteEntity);
    this.financeComponent.evaluateFinanceFutureExpenses(response.FinanceFutureExpenses);
    this.financeComponent.evaluateFinanceFuturePayments(response.FinanceFuturePayments);
    this.financeComponent.evaluateFinanceCrossBorder(response.FinanceCrossBorderEngagement);
    this.dealStructureComponent.evaluateproviderNameType(response.FinanceProviderNameType);
  }

  private _populateCrossBorderSection(responseFormItem:any, dttlValues: Array<FreeTextTableItem>) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.crossBorderFormGroup) as FormGroup, responseFormItem);

    this.appFormGroup.get(formGroups.crossBorderFormGroup)
      .get(this.sharePointMapper.getFormControlColumnName(FormListFields.dttlInvolved)).setValue(dttlValues);

    //enable or disble fields on YES or NO selection basis
    this.crossBorderComponent.evaluateIsDTTLInvolved(responseFormItem.IsDTTLInvolved);
    this._setDTTLValues(dttlValues);
  }

  private _populateCapitalRaisedSection(responseFormItem:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.capitalRaisedFormGroup) as FormGroup, responseFormItem);
  }

  private _populateMarketingAndCommunicationsSection(responseFormItem:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.marketingAndComunicationsFormGroup) as FormGroup,
      responseFormItem);

    //enable or disble fields on YES or NO selection basis
    this.marketingAndCommunicationsComponent.evaluatePitchBook(responseFormItem.PitchBook);
    this.marketingAndCommunicationsComponent.evaluateTeaser(responseFormItem.Teaser);
    this.marketingAndCommunicationsComponent.evaluateCimMemorandumPPM(responseFormItem.CIMMemorandumPPM);
    this.marketingAndCommunicationsComponent.evaluateTombstone(responseFormItem.Tombstone);
    this.marketingAndCommunicationsComponent.evaluateCaseStudies(responseFormItem.CaseStudies)
    this.marketingAndCommunicationsComponent.evaluatePressRelease(responseFormItem.PressRelease);
  }

  private _populateBooksAndRecordsSection(responseFormItem:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.booksAndRecordsFormGroup) as FormGroup, responseFormItem);
    this._setDateInput(formGroups.booksAndRecordsFormGroup, FormListFields.dateICReviewApproval, responseFormItem.DateICReviewApproval);
    //enable or disble fields on YES or NO selection basis
    this.booksAndRecordsComponent.evaluateDidProjectGoICSelection(responseFormItem.DidProjectGoIC);
    this.booksAndRecordsComponent.evaluateComplianceFormUserDetails(responseFormItem.SubmissionUser, responseFormItem.SubmissionDate, responseFormItem.SubmissionSeries24User, responseFormItem.SubmissionSeries24Date);
  }

  private _populateSupervisorySignoffSection(responseFormItem:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.supervisorySignoffFormGroup) as FormGroup, responseFormItem);


  }

  private _populateFeesSection(responseFormItem:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.feesFormGroup) as FormGroup, responseFormItem);
    this._setDateInput(formGroups.feesFormGroup, FormListFields.dateSuccessFeeReceived, responseFormItem.DateSuccessFeeReceived);
    this._setDateInput(formGroups.feesFormGroup, FormListFields.datePaidFinderFee, responseFormItem.DatePaidFinderFee);
    //enable or disble fields on YES or NO selection basis
    this.feesComponent.evaluateFinderReferralFeeOwed(responseFormItem.FinderReferralFeeOwed);
    this.feesComponent.evaluateRetainerReceived(responseFormItem.RetainerReceived);
    this.feesComponent.evaluateSuccessFeePaidFull(responseFormItem.SuccessFeePaidFull);
  }
  private _populateBuyerDealerMarketingSection(responseFormItem:any, responseDealMarketings:any) {
    let dealMarketingSectionObject = this.ComplianceServiceHelper.CreateDealMarketingSectionObject(responseDealMarketings, responseFormItem);

    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.buyerDealMarketingFormGroup) as FormGroup, dealMarketingSectionObject);

    //enable or disable fields on YES/No selection
    this.buyerDealMarketingComponent.evaluateOnInternationalBuyer(responseFormItem.InternationalBuyer);
    this.buyerDealMarketingComponent.evaluateOnPreemtiveBidder(responseFormItem.PreemptiveBidder);
    this.dealStructureComponent.evaluateBuyerType(responseFormItem.BuyerType);

  }
  private _populateIndemnificationSection(responseFormItem:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.indemnificationFormGroup) as FormGroup, responseFormItem);
    this.indemnificationComponent.evaluateIfOtherWhat(responseFormItem.ReconciliationEscrow);
    this.indemnificationComponent.evaluateSpecialIndemnificationTopic(responseFormItem.SpecialIndemnification);
  }
  private _populateDealStructure(responseFormItem:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.dealStructureFormGroup) as FormGroup, responseFormItem);
    this.dealStructureComponent.evaluateManagementFee(responseFormItem.ManagementFee);
    this.dealStructureComponent.evaluateTriggertType();    
  }
  private _populteCommentSection(responseDocumentComments:any) {
    this.appFormGroup.get(formGroups.additionalCommentaryFormGroup)
      .get(this.sharePointMapper.getFormControlColumnName(FormListFields.additionalCommenatry)).setValue(responseDocumentComments);
      
  }
  private _populateValuationMetricsSection(responseValuations:any, responseFormItem:any) {
    let sharepointObject = this.ComplianceServiceHelper.CreateValuationMetricsObject(responseValuations, responseFormItem);

    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.valuationMetricsFormGroup) as FormGroup, sharepointObject);
    this.valuationMetricsComponent.evaluateIfBelow(sharepointObject.FinalEvPitchRange)
    this.valuationMetricsComponent.evaluateIfBelowWhy(sharepointObject.IfBelowWhy)
  }
  ///dcf fee structure
  private _populateDcfFeeStructureSection(responseFormItem:any) {
    //let sharepointObject = this.ComplianceServiceHelper.CreateValuationMetricsObject(responseValuations[0],responseFormItem);

    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.dcfFeeStrcutureFormGroup) as FormGroup, responseFormItem);
    this.dCFFeeStructureComponent.toggleButtonActive(responseFormItem.BreakpointQuestion, true);
    this.dCFFeeStructureComponent.radioBaseFeeEnableMethod(responseFormItem.BaseFeeRadio, true);
    this.dCFFeeStructureComponent.radioIncentiveFeeEnableMethod(responseFormItem.IncentiveFeeRadio);
    this.dCFFeeStructureComponent.evaluateRetainerReceived(responseFormItem.MinimumSucessFee);
    this.dCFFeeStructureComponent.evaluateBreakPointSection(responseFormItem.FeeType);
    if (responseFormItem.BreakpointQuestion == "YesMultiple") {
      this.dCFFeeStructureComponent.getClosingCheckListFeeStructureBreakpoints(this.formId);
    }
    //this.valuationMetricsComponent.evaluateIfBelow(sharepointObject.FinalEvPitchRange)
    //this.valuationMetricsComponent.evaluateIfBelowWhy(sharepointObject.IfBelowWhy)
  }
  private _populateTooltips() {
    //this.tooltipHelperService.getTooltips_CCF(AppSettings.ClosingTooltipList);
    this.tooltipHelperService.getTooltipsClosingCheckList();
  }

  dealTypeEventHandler(value:any) {
    this.financeComponent.evaluateDealType(value);
    this.buyerDealMarketingComponent.evaluateDealType(value);
    this.capitalRaisedComponent.evaluateDealTypeDependency(value);
  }

  providerNameTypeEventHandler(value:any) {
    this.dealStructureComponent.evaluateproviderNameType(value);
  }
  buyerTypeEventHandler(value:any) {
    this.dealStructureComponent.evaluateBuyerType(value);
  }
  breakPointValueEventHandler(value:any) {
    this.breakPointMultiValueStatus = value;
  }

  private _setPeoplePickers(appFormGroup: string, inputName: string, userIdList:any) {
    const input = this.appFormGroup.get(appFormGroup).get(inputName);
    if (!userIdList) {
      input.setValue({ results: [] });
    } else {
      input.setValue({ results: userIdList });
    }
  }

  private _createPeoplePickerUserArrays(responseFormItem:any):any {    
    const ppmdEmailIds = responseFormItem.CurrentPPMDLead;  
    const ppmdNames =   responseFormItem?.CurrentPPMDLeadDisplayNames;
      this.engagementOverviewComponent.setCurrentPPMDLead(this._getLCSPPeoplepickerUserDataForDealWbs(ppmdEmailIds,ppmdNames));
  }


  private _getLCSPPeoplepickerUserDataForDealWbs(responseemail:any,responseName:any): any{
    const peoplePickerUserList = new Array<PeoplePickerUser>();
    if(responseemail!=null&&responseemail!=undefined&&responseemail!=""){
    if(responseemail.includes(";#")){
      let lcspemail=responseemail.split(";#");
      let lcspname=responseName.split(";#");
    if (lcspemail.length==lcspname.length) {
      for (let i=0;i<=lcspemail.length-1;i++) {
        let user=new PeoplePickerUser();
        user.displayName=lcspname[i];
        user.mail=lcspemail[i];
        user.DisplayText=lcspname[i];
        peoplePickerUserList.push(user);
      }
    } 
   }
   else{
    let user=new PeoplePickerUser();
    user.displayName=responseName;
    user.mail=responseemail;
    user.DisplayText=responseName;
    peoplePickerUserList.push(user);
  }
  
 }
 return peoplePickerUserList;
}
 
  private _createForm() {
    this.appFormGroup = new FormGroup({
      myDealFormGroup: new FormGroup({
        series24: new CustomFormControl('Current Series 24', null),
        engagementName: new CustomFormControl('engagement', null),
        buyerName: new CustomFormControl('buyerName', ''),
        retainer: new CustomFormControl('retainer', null),
        feeType: new CustomFormControl('feeType', null),
        isCompetitivePitch: new CustomFormControl('isCompetitivePitch', null),
        clientName: new CustomFormControl('clientName', null),
        industry: new CustomFormControl('industry', null),
        subsector: new CustomFormControl('subsector', null),
      }),
      projectOverViewFormGroup: new FormGroup({
        dealteamTable: new CustomFormControl('Deal Team', [])
      }),
      clientOverViewFormGroup: new FormGroup({
        clientType: new CustomFormControl('clientType', null),
        sponsorName: new CustomFormControl('sponsorName', ''),
        subsidiaryBeingSold: new CustomFormControl('subsidiaryBeingSold', ''),
        clientRelationship: new CustomFormControl('ClientRelationship', null),
        referralSource: new CustomFormControl('referralSource', null),
        referralSourceSubType: new CustomFormControl('referralSourceSubType', ''),
        //clientLocationId: new CustomFormControl('clientLocationId', null),
        clientLocation: new CustomFormControl('clientLocation', null),
        //clientCountryId: new CustomFormControl('clientCountryId', null),
        clientCountry: new CustomFormControl('clientCountry', null),
        //industryId: new CustomFormControl('industryId', null),
      }),
      dealOverViewFormGroup: new FormGroup({
        dealType: new CustomFormControl('Deal Type', ''),
        // crossBorderEngagement: new CustomFormControl('CrossBorderEngagement', { value: 'YES', disabled: false }), //commented as default value is yes TODO: check during onload form
        // firstProcessRun: new CustomFormControl('FirstProcessRun', { value: 'YES', disabled: false }), //commented as default value is yes TODO: check during onload form
        // firstInstitutionalCapital: new CustomFormControl('FirstInstitutionalCapital', { value: 'YES', disabled: false }), //commented as default value is yes TODO: check during onload form
        crossBorderEngagement: new CustomFormControl('CrossBorderEngagement', { value: '', disabled: false }),
        firstProcessRun: new CustomFormControl('FirstProcessRun', { value: '', disabled: false }),
        firstInstitutionalCapital: new CustomFormControl('FirstInstitutionalCapital', { value: '', disabled: false }),
        // isCompetitivePitch: new CustomFormControl('Is Competitive Pitch', { value: 'YES', disabled: false }),
        numberOfParticipants: new CustomFormControl('Number Of Participants', ''),
      }),
      dcfFeeStrcutureFormGroup: new FormGroup({
        feeType: new CustomFormControl('FeeType', { value: '', disabled: false }),
        // feeType: new CustomFormControl('FeeType', ''),
        retainer: new CustomFormControl('Retainer', ''),
        breakpointQuestion: new CustomFormControl('Breakpoint Question', { value: 'YES', disabled: false }),
        breakpointValue: new CustomFormControl('Breakpoint Value', ''),
        baseFeeRadio: new CustomFormControl('BaseFee Radio selection either % or $000s', ''),
        baseFeePercentage: new CustomFormControl('BaseFeePercentage', ''),
        baseFeeNumerical: new CustomFormControl('BaseFeeNumerical', ''),
        incentiveFeeRadio: new CustomFormControl('IncentiveFee Radio selection either % or $000s', ''),
        incentiveFeePercentage: new CustomFormControl('IncentiveFeePercentage', ''),
        incentiveFeeNumerical: new CustomFormControl('IncentiveFeeNumerical', ''),
        minimumSucessFee: new CustomFormControl('MinimumSucessFee', ''),
        minimumFeeApply: new CustomFormControl('MinimumFee Apply', { value: '', disabled: true }),
        describeFeeStructure: new CustomFormControl('DescribeFeeStructure textArea', '')
      }),
      buyerDealMarketingFormGroup: new FormGroup({
        buyerParentCompany: new CustomFormControl('BuyerParentCompany', ''),
        buyerType: new CustomFormControl('BuyerType', ''),
        internationalBuyer: new CustomFormControl('InternationalBuyer', { value: '', disabled: false }),
        buyerCountry: new CustomFormControl('BuyerCountry', { value: 'NO', disabled: false }),
        identifiedTierPitch: new CustomFormControl('IdentifiedTierPitch', { value: '', disabled: false }),
        buyerRelationship: new CustomFormControl('BuyerRelationship', ''),
        deloitteLCSP: new CustomFormControl('DeloitteLCSP', { value: '', disabled: false }),
        preemptiveBidder: new CustomFormControl('PreemptiveBidder', { value: '', disabled: false }),
        preemptiveFinalPurchaser: new CustomFormControl('PreemptiveFinalPurchaser', { value: '', disabled: false }),
        preemptiveOffer: new CustomFormControl('PreemptiveOffer', ''),
        premiumOverInitialOffer: new CustomFormControl('PremiumOverInitialOffer', { value: '', disabled: true }),
        marketingProcessType: new CustomFormControl('MarketingProcessType', ''),
        describeDealMarketing: new CustomFormControl('DescribeDealMarketing', ''),

        approachedUSStrategic: new CustomFormControl('ApproachedUSStrategic', { value: '', disabled: false }),
        approachedIntlStrategic: new CustomFormControl('ApproachedIntlStrategic', { value: '', disabled: false }),
        approachedPEGNoPortCo: new CustomFormControl('ApproachedPEGNoPortCo', { value: '', disabled: false }),
        approachedPEG: new CustomFormControl('ApproachedPEG', { value: '', disabled: false }),
        approachedTotal: new CustomFormControl('ApproachedTotal', { value: '', disabled: true }),
        sentCIMUSStrategic: new CustomFormControl('SentCIMUSStrategic', { value: '', disabled: false }),
        sentCIMIntlStrategic: new CustomFormControl('SentCIMIntlStrategic', { value: '', disabled: false }),
        sentCIMPEGNoPortCo: new CustomFormControl('SentCIMPEGNoPortCo', { value: '', disabled: false }),
        sentCIMPEG: new CustomFormControl('SentCIMPEG', { value: '', disabled: false }),
        sentCIMTotal: new CustomFormControl('SentCIMTotal', { value: '', disabled: true }),
        submittedIOIUSStrategic: new CustomFormControl('SubmittedIOIUSStrategic', { value: '', disabled: false }),
        submittedIOIIntlStrategic: new CustomFormControl('SubmittedIOIIntlStrategic', { value: '', disabled: false }),
        submittedIOIPEGNoPortCo: new CustomFormControl('SubmittedIOIPEGNoPortCo', { value: '', disabled: false }),
        submittedIOIPEG: new CustomFormControl('SubmittedIOIPEG', { value: '', disabled: false }),
        submittedIOITotal: new CustomFormControl('SubmittedIOITotal', { value: '', disabled: true }),
        attendedMPUSStrategic: new CustomFormControl('AttendedMPUSStrategic', { value: '', disabled: false }),
        attendedMPIntlStrategic: new CustomFormControl('AttendedMPIntlStrategic', { value: '', disabled: false }),
        attendedMPPEGNoPortCo: new CustomFormControl('AttendedMPPEGNoPortCo', { value: '', disabled: false }),
        attendedMPPEG: new CustomFormControl('AttendedMPPEG', { value: '', disabled: false }),
        attendedMPTotal: new CustomFormControl('AttendedMPTotal', { value: '', disabled: true }),
        submittedLOIUSStrategic: new CustomFormControl('SubmittedLOIUSStrategic', { value: '', disabled: false }),
        submittedLOIIntlStrategic: new CustomFormControl('SubmittedLOIIntlStrategic', { value: '', disabled: false }),
        submittedLOIPEGNoPortCo: new CustomFormControl('SubmittedLOIPEGNoPortCo', { value: '', disabled: false }),
        submittedLOIPEG: new CustomFormControl('SubmittedLOIPEG', { value: '', disabled: false }),
        submittedLOITotal: new CustomFormControl('SubmittedLOITotal', { value: '', disabled: true })

      }),
      dealStructureFormGroup: new FormGroup({
        legalStructure: new CustomFormControl('LegalStructure', ''),
        contingentTotalEVPct: new CustomFormControl('ContingentTotal EV Percentage', { value: '', disabled: true }),
        timeUntilContingentPayment: new CustomFormControl('TimeUntilContingentPayment', ''),
        triggerType: new CustomFormControl('TriggerType', ''),
        stockConsideration: new CustomFormControl('StockConsideration', { value: '', disabled: true }),
        stockConsiderationTotalEVPct: new CustomFormControl('StockConsiderationTotalEVPct', { value: '', disabled: true }),
        rolloverEquity: new CustomFormControl('RolloverEquity', ''),
        rolloverPreTaxPct: new CustomFormControl('RolloverPreTaxPct', ''),
        rolloverAfterTaxPct: new CustomFormControl('RolloverAfterTaxPct', ''),
        managementOptionPool: new CustomFormControl('ManagementOptionPool', ''),
        sponsorTransactionClosingFee: new CustomFormControl('SponsorTransactionClosingFee', ''),
        managementFee: new CustomFormControl('ManagementFee', ''),
        managementFeePct: new CustomFormControl('ManagementFeePct', { value: '', disabled: true }),
        managementFeeQty: new CustomFormControl('ManagementFeeQty', { value: '', disabled: true }),
        totalLeverage: new CustomFormControl('TotalLeverage', ''),
        equityContributionTotalPct: new CustomFormControl('EquityContributionTotalPct', { value: '', disabled: true }),
        equityContributionClosingPct: new CustomFormControl('EquityContributionClosingPct', { value: '', disabled: true })
      }),
      qualityOfEarningsFormGroup: new FormGroup({
        sellsideQofEPerformed: new CustomFormControl('SellsideQofEPerformed', { value: '', disabled: false }),
        qofEStage: new CustomFormControl('QofEStage', ''),
        whatFirmPerformed: new CustomFormControl('WhatFirmPerformed', ''),
        mainContact: new CustomFormControl('MainContact', ''),
        qofEDeloitteBranded: new CustomFormControl('QofEDeloitteBranded', { value: '', disabled: false })
      }),
      additionalCommentaryFormGroup: new FormGroup({
        additionalComments: new CustomFormControl('additional Comments', [])
      }),
      timelineContentFormGroup: new FormGroup({
        pitchedDate: new CustomFormControl('pitchedDate', ''),
        signedEngagementLetter: new CustomFormControl('signedEngagementLetter', ''),
        enteredMarket: new CustomFormControl('enteredMarket', ''),
        receivedIoiTermSheets: new CustomFormControl('receivedIoiTermSheets', ''),
        receivedLOIs: new CustomFormControl('receivedLOIs', ''),
        signedLOI: new CustomFormControl('signedLOI', ''),
        transactionClose: new CustomFormControl('transactionClose', ''),
        simultaneousSignClose: new CustomFormControl('simultaneousSignClose', ''),
        signDate: new CustomFormControl('signDate', ''),
        commentAffectingTimeline: new CustomFormControl('commentAffectingTimeline', ''),
      }),
      financeFormGroup: new FormGroup({
        financeFileUpload: new CustomFormControl('FinanceFileUpload', ''),
        financeEnterpriseValue: new CustomFormControl('FinanceEnterpriseValue', ''),
        financeDeloitteEntity: new CustomFormControl('FinanceDeloitteEntity', ''),
        financeDeloitteEntityDescription: new CustomFormControl('FinanceDeloitteEntityDescription', ''),
        financeFutureExpenses: new CustomFormControl('FinanceFutureExpenses', ''),
        financeFutureExpensesAmount: new CustomFormControl('FinanceFutureExpensesAmount', ''),
        financeFuturePayments: new CustomFormControl('financeFuturePayments', ''),
        financeFuturePaymentsType: new CustomFormControl('financeFuturePaymentsType', ''),
        financeAssetSaleDeal: new CustomFormControl('financeAssetSaleDeal', ''),
        financeCrossBorderEngagement: new CustomFormControl('financeCrossBorderEngagement', ''),
        //FinanceCrossBorderCountry:data.FinanceCrossBorderCountry,
        financeCrossBorderCountry: new CustomFormControl('financeCrossBorderCountry', ''),
        financeProviderName: new CustomFormControl('financeProviderName', ''),
        financeProviderNameType: new CustomFormControl('financeProviderNameType', ''),
        financeDealLeagueTable: new CustomFormControl('financeDealLeagueTable', ''),
        financeDealLeagueTableDesc: new CustomFormControl('financeDealLeagueTableDesc', ''),
        financeDealGlobalDeloitteSite: new CustomFormControl('financeDealGlobalDeloitteSite', ''),
        financeDealGlobalDelSiteDesc: new CustomFormControl('financeDealGlobalDelSiteDesc', ''),
        financeDealAnnouncement: new CustomFormControl('financeDealAnnouncement', ''),
        financeDealAnnouncementDesc: new CustomFormControl('financeDealAnnouncementDesc', ''),
        financeDealCaseStudy: new CustomFormControl('financeDealCaseStudy', ''),
        financeDealCaseStudyDesc: new CustomFormControl('financeDealCaseStudyDesc', ''),
      }),
      valuationMetricsFormGroup: new FormGroup({
        closingConsideration: new CustomFormControl('ClosingConsideration', { value: '', disabled: false }),
        contingentConsideration: new CustomFormControl('ContingentConsideration', { value: '', disabled: false }),
        totalConsideration: new CustomFormControl('TotalConsideration', { value: '', disabled: true }),
        ebitda: new CustomFormControl('Ebitda', { value: '', disabled: false }),
        ebitdaPeriod: new CustomFormControl('EbitdaPeriod', ''),
        ebitdaMultipleTotal: new CustomFormControl('EbitdaMultipleTotal', ''),
        ebitdaMultipleClosing: new CustomFormControl('EbitdaMultipleClosing', ''),
        finalEvPitchRange: new CustomFormControl('FinalEvPitchRange', { value: '', disabled: false }),
        ifBelowWhy: new CustomFormControl('IfBelowWhy', { value: '', disabled: true }),
        ifBelowWhyOtherReason: new CustomFormControl('IfBelowWhyOtherReason', { value: '', disabled: true }),
        pitchEBITDAMultipleRangeFirst: new CustomFormControl('PitchEBITDAMultipleRangeFirst', ''),
        pitchEBITDAMultipleRangeSecond: new CustomFormControl('PitchEBITDAMultipleRangeSecond', ''),
        multipleRangeMidpoint: new CustomFormControl('MultipleRangeMidpoint', ''),
        impliedValuationPremium: new CustomFormControl('ImpliedValuationPremium', ''),
        hitBreakpointEL: new CustomFormControl('HitBreakpointEL', ''),
        elBreakpoint: new CustomFormControl('ElBreakpoint', ''),
        valuationPremiumOverBp: new CustomFormControl('ValuationPremiumOverBp', ''),  
        ioiAboveBreakpoint: new CustomFormControl('IoiAboveBreakpoint', ''),
        loiAboveBreakpoint: new CustomFormControl('LoiAboveBreakpoint', ''),
        describeDealStructure: new CustomFormControl('DescribeDealStructure', ''),
        iOIValueClosingLow: new CustomFormControl('IOIValueClosingLow', { value: '', disabled: false }),
        iOIValueClosingHigh: new CustomFormControl('IOIValueClosingHigh', { value: '', disabled: false }),
        iOIValueContingent: new CustomFormControl('IOIValueContingent', { value: '', disabled: false }),
        iOIValueTotalHigh: new CustomFormControl('IOIValueTotalHigh', { value: '', disabled: true }),
        iOIValueClosingAvg: new CustomFormControl('IOIValueClosingAvg', { value: '', disabled: true }),
        iOIValueTotalAvg: new CustomFormControl('IOIValueTotalAvg', { value: '', disabled: true }),
        lOIValueClosingLow: new CustomFormControl('LOIValueClosingLow', { value: '', disabled: false }),
        lOIValueClosingHigh: new CustomFormControl('LOIValueClosingHigh', { value: '', disabled: false }),
        lOIValueContingent: new CustomFormControl('LOIValueContingent', { value: '', disabled: false }),
        lOIValueTotalHigh: new CustomFormControl('LOIValueTotalHigh', { value: '', disabled: true }),
        lOIValueClosingAvg: new CustomFormControl('LOIValueClosingAvg', { value: '', disabled: true }),
        lOIValueTotalAvg: new CustomFormControl('LOIValueTotalAvg', { value: '', disabled: true }),
        numericalPriceIncClosingLow: new CustomFormControl('NumericalPriceIncClosingLow', { value: '', disabled: true }),
        numericalPriceIncClosingHigh: new CustomFormControl('NumericalPriceIncClosingHigh', { value: '', disabled: true }),
        numericalPriceIncContingent: new CustomFormControl('NumericalPriceIncContingent', { value: '', disabled: true }),
        numericalPriceIncTotalHigh: new CustomFormControl('NumericalPriceIncTotalHigh', { value: '', disabled: true }),
        numericalPriceIncClosingAvg: new CustomFormControl('NumericalPriceIncClosingAvg', { value: '', disabled: true }),
        numericalPriceIncTotalAvg: new CustomFormControl('NumericalPriceIncTotalAvg', { value: '', disabled: true }),
        percentagePriceIncClosingLow: new CustomFormControl('PercentagePriceIncClosingLow', { value: '', disabled: true }),
        percentagePriceIncClosingHigh: new CustomFormControl('PercentagePriceIncClosingHigh', { value: '', disabled: true }),
        percentagePriceIncContingent: new CustomFormControl('PercentagePriceIncContingent', { value: '', disabled: true }),
        percentagePriceIncTotalHigh: new CustomFormControl('PercentagePriceIncTotalHigh', { value: '', disabled: true }),
        percentagePriceIncClosingAvg: new CustomFormControl('PercentagePriceIncClosingAvg', { value: '', disabled: true }),
        percentagePriceIncTotalAvg: new CustomFormControl('PercentagePriceIncTotalAvg', { value: '', disabled: true }),
        loiClosedConsideration: new CustomFormControl('LoiClosedConsideration', { value: '', disabled: false }),
        loiClosedContingent: new CustomFormControl('LoiClosedContingent', { value: '', disabled: false }),
        loiTotal: new CustomFormControl('LoiTotal', { value: '', disabled: true }),
        valNumericalClosedConsideration: new CustomFormControl('ValNumericalClosedConsideration', { value: '', disabled: true }),
        valNumericalTotal: new CustomFormControl('ValNumericalTotal', { value: '', disabled: true }),
        valPercentageClosedConsideration: new CustomFormControl('ValPercentageClosedConsideration', { value: '', disabled: true }),
        valPercentageTotal: new CustomFormControl('ValPercentageTotal', { value: '', disabled: true }),
      }),
      indemnificationFormGroup: new FormGroup({
        capNumerical: new CustomFormControl('CapNumerical', ''),
        capPercentageEV: new CustomFormControl('CapPercentageEV', { value: '', disabled: true }),
        capPercentageClosing: new CustomFormControl('CapPercentageClosing', { value: '', disabled: true }),
        survivalMonths: new CustomFormControl('SurvivalMonths', ''),
        escrowNumerical: new CustomFormControl('EscrowNumerical', ''),
        escrowTotalEV: new CustomFormControl('EscrowTotalEV', { value: '', disabled: true }),
        escrowClosing: new CustomFormControl('EscrowClosing', { value: '', disabled: true }),
        releaseMonths: new CustomFormControl('ReleaseMonths', ''),
        reconciliationEscrow: new CustomFormControl('ReconciliationEscrow', ''),
        ifOtherWhat: new CustomFormControl('IfOtherWhat', ''),
        deductibleNumerical: new CustomFormControl('DeductibleNumerical', ''),
        deductibleEV: new CustomFormControl('DeductibleEV', { value: '', disabled: true }),
        deductibleClosing: new CustomFormControl('DeductibleClosing', { value: '', disabled: true }),
        basketType: new CustomFormControl('BasketType', ''),
        specialIndemnification: new CustomFormControl('SpecialIndemnification', ''),
        specialIndemnificationTopic: new CustomFormControl('SpecialIndemnificationTopic', '')
      }),
      engagementOverviewFormGroup: new FormGroup({
        wbsCode: new CustomFormControl('WBS Code', ''),
        complianceDealType: new CustomFormControl('Deal Type', ''),
        isClientPublicEntity: new CustomFormControl('Is client a Publicly traded entity', ''),
        symbol: new CustomFormControl('Please enter the client ticker symbol', ''),
        removeFromBlackList: new CustomFormControl('Has a request been submitted to remove the company from the DCF blacklist', ''),
        counterparties: new CustomFormControl('Counterparties', []),
        Series24: new CustomFormControl('Who is the Series 24 on the engagement', ''),
        branchOffice: new CustomFormControl('Branch office of the Series 24', ''),
        pPMDNonDCFUser: new CustomFormControl('If No, Please enter here', ''),
        werePreviousSeries24: new CustomFormControl('Were there other Series 24s previously assigned to the project?', ''),
        previousSeries24: new CustomFormControl('If Yes, please list', []),
        // pPMDDCFUser: new CustomFormControl('Is the engagement PPMD still employed with DCF?', { value: 'YES', disabled: false }), //commented as default value is yes TODO: check during onload form
        pPMDDCFUser: new CustomFormControl('Is the engagement PPMD still employed with DCF?', { value: '', disabled: false }),
        currentPPMDLead: new CustomFormControl('Who is the day-to-day engagement PPMD lead?', { results: [] }),
        werePreviousPPMD: new CustomFormControl('Were there other day-to-day PPMDs previously assigned to the project?'),
        previousPPMDLead: new CustomFormControl('If Yes, please list', []),
      }),
      crossBorderFormGroup: new FormGroup({
        // tslint:disable-next-line:max-line-length
        isDTTLInvolved: new CustomFormControl('Did the engagement involve a Deloitte Touche Tohmatsu Limited (DTLL) Member Firm (Cross Border Transaction)?', ''),
        dttlInvolved: new CustomFormControl('Which DTTL member firms were involved in the engagement?', []),
        isXBEngagementAn: new CustomFormControl('Is the Cross Border engagement an:', '')
      }),
      capitalRaisedFormGroup: new FormGroup({
        // tslint:disable-next-line:max-line-length
        capitalRaised: new CustomFormControl('Total dollar value of capital raised, committed, refinanced or restructured in connection with this transaction', { value: '', disabled: true }),
      }),
      marketingAndComunicationsFormGroup: new FormGroup({
        pitchBook: new CustomFormControl('Was a Pitch Book used?', ''),
        pitchBookSeries24: new CustomFormControl('Is evidence of the Series 24 approval saved to final project folder?', ''),
        teaser: new CustomFormControl('Was a Teaser used?', ''),
        teaserSeries24: new CustomFormControl('Is evidence of the Series 24 approval saved to final project folder?', ''),
        cimMemorandumPPM: new CustomFormControl('Was a CIM / Memorandum / PPM used?', ''),
        cimMemorandumPPMSeries24: new CustomFormControl('Is evidence of the Series 24 approval saved to final project folder?', ''),
        tombstone: new CustomFormControl('Was a Tombstone Issued?', ''),
        tombstoneSeries24: new CustomFormControl('Is evidence of the Series 24 approval saved to final project folder?', ''),
        caseStudies: new CustomFormControl('Were Case Studies used in the engagement?', ''),
        caseStudiesSeries24: new CustomFormControl('Is evidence of the Series 24 approval saved to final project folder?', ''),
        pressRelease: new CustomFormControl('Was a Press Release issued for the engagement?', ''),
        pressReleaseSeries24: new CustomFormControl('Is evidence of the Series 24 approval saved to final project folder?', ''),
        leagueTablesMarketing: new CustomFormControl('League Tables – Has Marketing been submitted to Deal Exchange?', '')
      }),
      booksAndRecordsFormGroup: new FormGroup({
        linkFinalEngagement: new CustomFormControl('Link to final engagement project folder', ''),
        copyFinalBriefingPaper: new CustomFormControl('Copy of the final Briefing Paper', ''),
        executedEngagementLetter: new CustomFormControl('Fully executed Engagement Letter', ''),
        amendmentsEngagementLetter: new CustomFormControl('All amendments to Engagement Letter', ''),
        fullyExecutedIFA: new CustomFormControl('Fully executed IFA', ''),
        pitchProposal: new CustomFormControl('Pitch / Proposal', ''),
        teaserBooks: new CustomFormControl('Teaser', ''),
        cimOfferingMemorandum: new CustomFormControl('CIM / Offering Memorandum', ''),
        managementPresentation: new CustomFormControl('Management Presentation', ''),
        clientLetterMarketingPress: new CustomFormControl('Client Letter for Marketing Materials/Press Release', ''),
        tombstoneBooks: new CustomFormControl('Tombstone', ''),
        pressReleaseBooks: new CustomFormControl('Press Release', ''),
        caseStudiesBooks: new CustomFormControl('Case Studies', ''),
        leagueTables: new CustomFormControl('League Tables', ''),
        listProspectiveInvestorsBuyers: new CustomFormControl('Complete list of prospective investors/buyers contacted', ''),
        listActualInvestorsBuyers: new CustomFormControl('Complete list of actual investors/buyers in this transaction', ''),
        // tslint:disable-next-line: max-line-length
        expressionOfInterestLettersInt: new CustomFormControl('Expression of Interest/Signed Letters of Intent (winning buyer/investor)', ''),
        // tslint:disable-next-line: max-line-length
        containsAllTransactionDocuments: new CustomFormControl('Does the final project folder contain all final, fully executed transaction documents?', ''),
        didProjectGoIC: new CustomFormControl('Did the project go through the Investment Committee (“IC”)?', ''),
        ifNoExplain: new CustomFormControl('If no, please explain:', ''),
        dateICReviewApproval: new CustomFormControl('Date of IC Review and Approval', ''),
        // tslint:disable-next-line: max-line-length
        zipFileSent: new CustomFormControl('Has a zip file of all final engagement records been sent to US DCF Closing Engagement Review Group?', '')
      }),
      supervisorySignoffFormGroup: new FormGroup({
        // tslint:disable-next-line: max-line-length
        series24ReviewedTransaction: new CustomFormControl('Project Series 24 certifies that they have reviewed this completed transaction'),
        // tslint:disable-next-line: max-line-length
        series24ReviewedPersonnelHours: new CustomFormControl('Project Series 24 has reviewed all personnel and hours charged to this Project’s WBS Code'),
        series24TransactionComments: new CustomFormControl('Series24TransactionComments', { value: '', disabled: true }),
        series24WBSCodeComments: new CustomFormControl('Series24WBSCodeComments', { value: '', disabled: true }),
      }),
      feesFormGroup: new FormGroup({
        totalAmountsPaid: new CustomFormControl('Total Amounts paid by Client', ''),
        retainerReceived: new CustomFormControl('Retainer Received?', ''),
        periodicOrFlat: new CustomFormControl('Periodic Retainer or Flat Fee?', ''),
        amountRetainer: new CustomFormControl('Amount', ''),
        periodRetainer: new CustomFormControl('Period', ''),
        retainerCredited: new CustomFormControl('Retainer credited against the success fee?', ''),
        // tslint:disable-next-line: max-line-length
        engagementFeeBased: new CustomFormControl('Was the engagement fee T&M, milestone (with final milestone not tied to a closing) or success based?', ''),
        successFeePaidFull: new CustomFormControl('Was the Success Fee paid in full at closing?', ''),
        amountSuccessPaid: new CustomFormControl('If the total Success Fee was not paid at closing, what amount was paid at closing?', ''),
        // tslint:disable-next-line: max-line-length
        potentialAmountOutstanding: new CustomFormControl('If the total Success Fee was not paid at closing, what is the potential success fee amount outstanding post closing?', ''),
        dateSuccessFeeReceived: new CustomFormControl('Date success fee received', ''),
        expensesBilled: new CustomFormControl('Expenses billed to the client?', ''),
        finderReferralFeeOwed: new CustomFormControl('Finder / Referral Fee owed?', ''),
        amountFinderOwed: new CustomFormControl('Amount', ''),
        recipientFinderFee: new CustomFormControl('Recipient', ''),
        datePaidFinderFee: new CustomFormControl('Date Paid', ''),
      })
    });
  }



  // private _isSupervisorySignoffEditable() {
  //   return this.hasPermission('EditSignoffSection');
  // }

  private _isSignoffVisible() {
    return this.hasPermission('ShowSignoffSection');
  }

  private _checkFreeTextTypeEvent(freeTextRow: FreeTextSelectedRow, List:any) {
    if (freeTextRow.actionType === DynamicTableAction.Add) {
      this._storeFreeTextRow(freeTextRow, List);
    } else {
      this._deleteFreeTextRow(freeTextRow, List);
    }
  }

  private _deleteFreeTextRow(dttlmRow: FreeTextSelectedRow, List:any) {
    if (dttlmRow.item.itemId) {
         this.mainService.deleteListItem_CCLCompliance(List,this.complianceFormId, dttlmRow.item.itemId).subscribe(response => {
          //nothing
        });
    }
  }

  private _storeFreeTextRow(freeTextRow: FreeTextSelectedRow, List:any) {
    var freeTextRowItemId = (freeTextRow.item.itemId as any)?.ID || freeTextRow.item.itemId
    if (List === this.dttlList) {
      const objectToSend:any = this._createDTTLObject(freeTextRow.item);
      if (!freeTextRow.item.itemId) {
        this.mainService.createListItem_CCF(List, objectToSend)
          .subscribe(itemId => {
            this.crossBorderComponent.setItemFreeText(itemId, freeTextRow.item.index);
            this._updateFreeText(List);
          })
          // .catch(error => {
          // });
      } else {
        if(objectToSend.CreatedBy!=undefined||objectToSend.FormID!=undefined){delete objectToSend.CreatedBy;delete objectToSend.FormID;}
        this.mainService.updateListItem_CCFCompliance(List, freeTextRowItemId, objectToSend,"")
          .subscribe(response => {
            this._updateFreeText(List);
          });
         
      }
    } else if (List === this.counterpartiesList) {
      const objectToSend:any = this._createCounterpartiesObject(freeTextRow.item);
      if (!freeTextRow.item.itemId) {
        this.mainService.createListItem_CCF(List, objectToSend)
          .subscribe(itemId => {
            this.engagementOverviewComponent.setItemFreeText(itemId, freeTextRow.item.index);
            this._updateFreeText(List);
          })
          // .catch(error => {
          // });
      } else {
        if(objectToSend.CreatedBy!=undefined||objectToSend.FormID!=undefined){delete objectToSend.CreatedBy;delete objectToSend.FormID;}
        this.mainService.updateListItem_CCFCompliance(List, freeTextRowItemId, objectToSend,"")
          .subscribe(response => {
            this._updateFreeText(List);
          });
          
      }
    }
  }

  private _updateFreeText(List:any) {
    if (List === this.dttlList) {
      //Commented below due to we dont have specific column for this in Table.
      // this.mainService.getDTTL(List, this.complianceFormId)
      //   .then((dttl:any) => {
      //     let dttlObject = { DTTLId: { results: [] } };
      //     if (dttl.length > 0) {
      //       dttlObject = { DTTLId: { results: dttl } };
      //     }
      //     this.saveInProgress = true;
      //     this.mainService.updateListItem_CCFCompliance(AppSettings.complianceFormList, this.complianceFormId, dttlObject,"")
      //       .subscribe(() => {
      //         this.saveInProgress = false;
      //       })
            
      //   })
      //   .catch((error:any) => {
      //   });
    } else if (List === this.counterpartiesList) {
      // This code has been commented becasue CounterpartiesId is not exists in compliance sharepoint list...
      // this.mainService.getCounterparties(List, this.formId)
      // .then( counterparties => {
      //   let counterpartiesObject =  {CounterpartiesId : {results: []}};
      //   if (counterparties.length > 0) {
      //     counterpartiesObject = {CounterpartiesId : {results : counterparties}};
      //   }
      //   this.saveInProgress = true;
      //   this.mainService.updateListItem_CCF(AppSettings.complianceFormList, this.formId, counterpartiesObject)
      //   .then( () => {
      //     this.saveInProgress = false;
      //   })
      //   .catch(error => {
      //   });
      // })
      // .catch(error => {
      // });
    }
  }

  private _checkDealTeamTypeEvent(dealTeamRow: PeoplePickerSelectedRow, List:any) {
    if (dealTeamRow.actionType === DynamicTableAction.Add) {
      this._storeDealTeamRow(dealTeamRow, List);
    } else {
      this._deleteDealTeamRow(dealTeamRow, List);
    }
  }

  private _deleteDealTeamRow(dealTeamRow: PeoplePickerSelectedRow, List:any) {
    if (List === AppSettings.dealTeamTableList) {
      const dealTeamAuditObject: object = {
        DealTeamMember: dealTeamRow.item.usersSelected[0].displayName,
        FormID: this.myDealFormId,
        CreatedBy: this.currentUserName,
        Action: dealTeamRow.actionType,
        FormName: AppSettings.closingChecklistForm,
        EngagementName:this.appFormGroup.get(formGroups.myDealFormGroup).get(this.sharePointMapper.getFormControlColumnName(FormListFields.engagementName)).value
      };
      this._updateActiveDeletion(List, true);
      forkJoin([this.mainService.deleteListItem_DealWbs(List,this.myDealFormId.toString(), dealTeamRow.item.itemId.toString()),
        this.mainService.createListItem_DDT_CCL(AppSettings.dealTeamAuditLog, dealTeamAuditObject)])
          .subscribe(() => {
            this._updateActiveDeletion(List, false);
            this._updateDealTeamMembers(dealTeamRow);
          })
    }
    else {
      this._updateActiveDeletion(List, true);
      this.mainService.deleteListItem_DDT_CCL(List,this.complianceFormId.toString(), dealTeamRow.item.itemId.toString()).subscribe(response => {
        this._updateActiveDeletion(List, false);
      });
      catchError((err: any) => {
        console.log('Error Validating data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    }

  }

  private _updateActiveDeletion(listName: string, newVal: boolean) {
    switch (listName) {
      case AppSettings.compliancePreviousSeries24:
        this.S24DeletionActive = newVal;
        break;
      case AppSettings.compliancePreviousPPMD:
        this.PPMDDeletionActive = newVal;
        break;
      default:
        break;
    }
  }
  valuationMetricsStore(valuationMetrics: FormGroup) {
    this.indemnificationComponent.setFormGroup(valuationMetrics);
    this.dealStructureComponent.setFormGroup(valuationMetrics);
    this.buyerDealMarketingComponent.setFormGroup(valuationMetrics);
    this.dCFFeeStructureComponent.setFormGroup(valuationMetrics);

  }
  private _storeDealTeamRow(dealTeamRow: PeoplePickerSelectedRow, List:any) {
    var objectToSend:any;
    if (List == this.dealTeamList) {
      objectToSend = this._createDealTeamTableObject(dealTeamRow.item);
    }
    else{
       objectToSend = this._createDealTeamObject(dealTeamRow.item);
    }
   
    const addDealTeamAuditObject: object = {
      DealTeamMember: dealTeamRow.item.usersSelected[0].displayName,
      FormID: objectToSend.FormID,
      CreatedBy: this.currentUserName,
      Action: dealTeamRow.actionType,
      FormName: AppSettings.closingChecklistForm,
      EngagementName:this.appFormGroup.get(formGroups.myDealFormGroup).get(this.sharePointMapper.getFormControlColumnName(FormListFields.engagementName)).value
    };
    if (!dealTeamRow.item.itemId) {
      this.mainService.createListItem_DDT_CCL(List, objectToSend)
        .subscribe(itemId => {
          if (List === this.previousSeries24List) {
            this.engagementOverviewComponent.setItemPeoplePicker(itemId, dealTeamRow.item.index);
          } else if (List === this.previousPPMDList) {
            this.engagementOverviewComponent.setItemPeoplePickerPPMD(itemId, dealTeamRow.item.index);
          }
          else if (List == this.dealTeamList) {

            this.projectOverviewComponent.setItemPeoplePicker(itemId, dealTeamRow.item.index);
            this.mainService.createListItem_DDT_CCL(AppSettings.dealTeamAuditLog, addDealTeamAuditObject).subscribe((AuditLog) => {
              this._updateDealTeamMembers(dealTeamRow,true,dealTeamRow.item.usersSelected[0].mail);
            });
          }
        })
        // .catch(error => {
        // });
    }
    else if (List === AppSettings.dealTeamTableList) {
      var isNameChanged=false;
      this.mainService.getAuditLogDealTeamMemebers_CCL(AppSettings.dealTeamTableList, this.myDealFormId).subscribe((response) => {
        var dealTeamName;
        response.forEach((element:any) => {
          if (objectToSend.Name != element.Name && dealTeamRow.item.itemId == element.ID) {
            dealTeamName = element.Name.Title;
            const removeDealTeamAuditObject: object = {
              DealTeamMember: element.Name,
              FormId: objectToSend.FormID,
              CreatedBy: this.currentUserName,
              Action: DynamicTableAction.Delete,
              FormName: AppSettings.closingChecklistForm
            };
            
            forkJoin([this.mainService.createListItemsEHO(AppSettings.dealTeamAuditLog, [removeDealTeamAuditObject,addDealTeamAuditObject])
              ])
            .subscribe(([resp]) => {
              isNameChanged=true;
                });
            // forkJoin([this.mainService.createListItem_DDT_CCL(AppSettings.dealTeamAuditLog, removeDealTeamAuditObject),
            //   this.mainService.createListItem_DDT_CCL(AppSettings.dealTeamAuditLog, addDealTeamAuditObject)])
            // .subscribe(([respadd,respremove]) => {
            //     });
          }
        });
        var initiatorEmail = "";
        var dealTeamRowItemId = (dealTeamRow.item.itemId as any)?.ID || dealTeamRow.item.itemId
        objectToSend.ID = dealTeamRowItemId;
        this.mainService.updateListItem_DealWbs(List, dealTeamRowItemId, objectToSend).subscribe((response) => {
        if (response) {
          if(isNameChanged)
            initiatorEmail = objectToSend.Email
          this._updateDealTeamMembers(dealTeamRow,isNameChanged,initiatorEmail);
        }
      });

    });
  }
  else{
    var dealTeamRowItemId = (dealTeamRow.item.itemId as any)?.ID || dealTeamRow.item.itemId
    this.mainService.updateListItem_DDT_CCL(List, dealTeamRowItemId, objectToSend,"").subscribe((response) => {
      return response;
    });
  }
  }
  // private _updateDealTeamMembers() {
  //   this.mainService.getFormDealTeamMembers(AppSettings.dealTeamTableList, this.myDealFormId)
  //     .then((dealTeamMembers:any) => {
  //       let dealTeamMembersObject = { DealTeamMembersId: { results: [] } };
  //       if (dealTeamMembers.length > 0) {
  //         dealTeamMembersObject = { DealTeamMembersId: { results: dealTeamMembers } };
  //       }
  //       this.saveInProgress = true;
  //       Promise.all([this.mainService.updateListItem_CCF(AppSettings.myDealFormList, this.myDealFormId, dealTeamMembersObject,""),
  //       this.mainService.updateListItem_CCF(AppSettings.closingChecklistForm, this.formId, dealTeamMembersObject,"")])
  //         .then(() => {
  //           this.saveInProgress = false;
  //         })
  //         .catch(error => {
  //         });
  //     })
  //     .catch(error => {
  //     });
  // }
  private _updateDealTeamMembers(responseDealTeamTable:any,sendNotification:boolean=false,notifierEmail:string="") {

    const myDealDataObject =  { DealTeamMembersDisplayNames : "",DealTeamMembers:""};
    let dealTeamMembers:any = [];
    let dealTeamMemberEmails:any = [];
     if (this.dealTeamValues && this.dealTeamValues.length > 0) {
         this.dealTeamValues.forEach( (item:any) => {
           dealTeamMembers = dealTeamMembers.concat(item.usersSelected[0].displayName);
           dealTeamMemberEmails = dealTeamMemberEmails.concat(item.usersSelected[0].mail);
         });
         const concatenatedString = dealTeamMembers.join(';#');
         myDealDataObject.DealTeamMembersDisplayNames = concatenatedString;
         const emailString = dealTeamMemberEmails.join(';#');
         myDealDataObject.DealTeamMembers =emailString;
     }
      this.saveInProgress = true;
      forkJoin([ this.mainService.updateForm_CCL(AppSettings.myDealFormList, this.myDealFormId, myDealDataObject, this.MyDealRangeKey),
                    this.mainService.updateForm_CCL(AppSettings.closingChecklistFormList, this.formId, myDealDataObject,this.initialClosingCheckList.Created,false,sendNotification,notifierEmail,this.engagementId)])
      .subscribe( () => {
        this.saveInProgress = false;
      })
      ,catchError((error:any) => {
        this.saveInProgress = false;
        throw error;
      });
  }

  private _createDealTeamObject(dealTeamRow: PeoplepickerDynamicTableItem) {
    let dealteamObject = {};
    return dealteamObject={
      Name:dealTeamRow.usersSelected[0].mail,
      DisplayName:dealTeamRow.usersSelected[0].displayName,
      FormID:this.complianceFormId.toString(),
      CreatedBy:this.currentUserName,
      ModifiedBy:this.currentUserName,
      ID:this.complianceFormId.toString()
    }
    
  }

  private _createDealTeamTableObject(dealTeamRow: PeoplepickerDynamicTableItem) {
    const dealteamObject:any = new DealTeamObject();
    dealteamObject.Name = dealTeamRow.usersSelected[0].displayName;
    dealteamObject.Email = dealTeamRow.usersSelected[0].mail;
    dealteamObject.FormID = this.myDealFormId;
    dealteamObject.Role = dealTeamRow.optionsSelectedFirstColumn.value;
    dealteamObject.PursuitRole = AppSettings.dealTeamPursuitRoleId.toString();
    dealteamObject.SwiftRole = AppSettings.dealTeamSwiftRoleId.toString();
    // dealteamObject.PursuitRoleId = Number(dealTeamRow.optionsSelectedSecondColumn.key);
    // dealteamObject.SwiftRoleId =  Number(dealTeamRow.optionsSelectedThirdColumn.key);
    return dealteamObject;
  }

  private _createDTTLObject(dttlRow: FreeTextTableItem) {
    const dttlObject = new FreeTextObject();
    dttlObject.FormID = this.complianceFormId.toString();
    dttlObject.DTTLInvolved = dttlRow.freeTextOptionOne.name;
    dttlObject.CreatedBy=this.currentUserName;
    dttlObject.ModifiedBy=this.currentUserName;
    return dttlObject;
  }

  private _createCounterpartiesObject(counterpartiesRow: FreeTextTableItem) {
    const counterpartiesObject = new FreeTextObject();
    counterpartiesObject.FormID = this.complianceFormId.toString();
    counterpartiesObject.Name = counterpartiesRow.freeTextOptionOne.name;
    counterpartiesObject.TickerSymbol = counterpartiesRow.freeTextOptionTwo.name;
    counterpartiesObject.PubliclyTradedEntity = counterpartiesRow.optionsSelectedFirstColumn.value;
    counterpartiesObject.RestrictedEntity = counterpartiesRow.optionsSelectedSecondColumn.value;
    counterpartiesObject.CreatedBy=this.currentUserName;
    counterpartiesObject.ModifiedBy=this.currentUserName;
    return counterpartiesObject;
  }
  private _setFormId() {
    this.formId = Number(this.route.snapshot.queryParams.id);
  }

  private _isDCFMember(groups: Array<string>): boolean {
    const complianceGroup = AppSettings.dcfComplianceGroup;
    return !!groups.find(group => group === complianceGroup);
  }

  private _isDCFMemberInAdGroup(email: string, groups: Array<any>): boolean {

    return !!groups.find(group => group.userPrincipalName.toLowerCase() === email.toLowerCase());
  }

  private _setValidators(validators: Array<any>) {
    Object.keys(this.appFormGroup.controls).forEach(formControlGroupName => {
      const formGroup: FormGroup = this.appFormGroup.get(formControlGroupName) as FormGroup;
      this.sharePointMapper.setFormControlGroupValidators(formGroup, validators);
    });
  }

  private _initialSetValidators(formStatus: string, formActionGroup: string, isFinanceForm: boolean) 
  {
    this.ComplianceService.getAsteriskList().subscribe((response:any) => {
      if ((formStatus == "Pending" || formStatus == "Rejected") && (formActionGroup == "DealTeamSubmit" || formActionGroup == "DealTeamReview" || formActionGroup == "DealTeamUpdate")) {
        let obj = AppSettings.removeValidationField;
        for (var i = 0; i < obj.length; i++) {
          const requiredIndex = response.findIndex((el:any) => {
            return el.FieldName === String(obj[i].toString());
          });
          response.splice(requiredIndex, 1);
        }
      }
      if (!this.isFinanceForm) {
        let obj = AppSettings.removeFinanceValidationField;
        for (var i = 0; i < obj.length; i++) {
          const requiredIndex = response.findIndex((el:any) => {
            return el.FieldName === String(obj[i].toString());
          });
          response.splice(requiredIndex, 1);
        }
      }
      //Added to apply the mandatory fields
       response.forEach((element:any) => {
         if(element.FieldName=="FinanceCrossBorderCountryId"){
           element.FieldName="FinanceCrossBorderCountry"
         }
       });
    this._setValidators(response);
    this.initialFormValidators = response;   
    });
  }

  //Deal Team list
  private _setDealTeamTableValues(dealTeamValues: Array<PeoplepickerDynamicTableItem>) {
    if (this.projectOverviewComponent.getUpdateDealTeam()) {
      this.projectOverviewComponent.setDealTeamValues(dealTeamValues);
    }
  }

  //Series 24 list 
  private _setDealTeamValues(pS24Values: Array<PeoplepickerDynamicTableItem>) {
    if (this.engagementOverviewComponent.getUpdatedPS24()) {
      this.engagementOverviewComponent.setDealTeamValues(pS24Values);
    }
  }

  //PPMD list
  private _setPPMDValues(ppmdValues: Array<PeoplepickerDynamicTableItem>) {
    if (this.engagementOverviewComponent.getUpdatedPPMD()) {
      this.engagementOverviewComponent.setPPMDValues(ppmdValues);
    }
  }

  //CounterParties list
  private _setCounterpartiesValues(counterpartiesValues: Array<FreeTextTableItem>) {
    if (this.engagementOverviewComponent.getUpdatedCounterParties()) {
      this.engagementOverviewComponent.setCounterpartiesValues(counterpartiesValues);
    }
  }

  //DTTL List
  private _setDTTLValues(dttlValues: Array<FreeTextTableItem>) {
    if (this.crossBorderComponent.getUpdatedDTTL()) {
      this.crossBorderComponent.setDTTLValues(dttlValues);
    }
  }

  private _handlerFormStatus() {
    if ((this.hasPermission('ReadOnly') && !this.hasPermission('Edit'))) {
      this._setFormAsReadonly();
    }
  }

  private _handlerRetrieve(responseFormItem:any,resMyDeal:any) {
    if (this.formId) {
      this.retrieveForm(responseFormItem,resMyDeal);
    } else {
      this._disableSupervisorySignoffFormGroup();
      this._checkFieldsDependencies();
      this._setDealTeamTableValues(this.dealTeamValues);
      this._setDealTeamValues(this.pS24Values);
      this._setPPMDValues(this.ppmdValues);
      this._handlerFormStatus();
      this.spinnerService.hideAutoSaveSpinner();
    }
  }

  private _isInGroup(email: string, group:any): boolean {
    return group.find((user: any) => {
      if (user && email&&user.Email) {
        return user.Email.toLowerCase() === email.toLowerCase();
      }
      return false;
    });

  }
  private _isInAdGroup(email: string, group: Array<any>): boolean {
    if (group.length != 0 && email != null && email != undefined)
      return group.find(user => user.userPrincipalName.toLowerCase() === email.toLowerCase());
    else
      return false;
    //return group.find(user => user.Email === email);
  }

  private _areDealTeamMembersBelongToDCFGroup(pS24Values: any, dcfUsers:any): boolean {
    let valid = true;
    if (pS24Values) {
      pS24Values.forEach((user:any) => {
        //const email = user.usersSelected[0].EntityData.Email;
        const email = user.Name;
        // if (!this._isInAdGroup(email, JSON.parse(localStorage.getItem("adGroupMember")) != null ? JSON.parse(localStorage.getItem("adGroupMember")) : this.adGroupMember.value)) {
          if (!this._isInAdGroup(email,this.adGroupMember.value)) {
          // if (!this._isInGroup(email, dcfUsers)) {
          //user.usersSelected[0].LeftDCF = true;
          user.LeftDCF = true;
          valid = false;
        }
      });
      //this._setDealTeamValues(pS24Values);
    }
    return valid;
  }
  
  private _areUsersBelongToDCFGroup(
    series24email: string,
    pS24Values: any,
    dcfUsers:any,
    series24users:any): Observable<boolean> {
    return from(this._isSeries24Selected(series24email,series24users)).pipe(
      map(isSelected => {
        const series24Valid = isSelected;
        const dealTeamValid = this._areDealTeamMembersBelongToDCFGroup(pS24Values, dcfUsers);
        return series24Valid && dealTeamValid;
      })
    );
  }
  private _isSeries24ValidUser(email: string, dcfUsers:any, series24users:any): boolean {
    let valid = true;
    if (email && this._isInGroup(email, series24users)) {
      if (!this._isInAdGroup(email, dcfUsers)) {
        this.engagementOverviewComponent.setSeries24WarningMessage(true);
        valid = false;
      } else {
        this.engagementOverviewComponent.setSeries24WarningMessage(false);
      }
    } else {
      this.appFormGroup.get(formGroups.myDealFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.series24)).setValue(null);
      this.engagementOverviewComponent.setSeries24WarningMessage(false);
    }
    return valid;
  }

  private _isSeries24ValidUser_CCF(email: string, series24users:any): boolean {
    let valid = true;
    let adGroup=localStorage.getItem("adGroupMember")?.toString();
    if (email && this._isInGroup(email, series24users)) {
     if (!this._isInAdGroup(email, adGroup!=null?JSON.parse(adGroup):this.adGroupMember.value)) {
       this.engagementOverviewComponent?.setSeries24WarningMessage(true);
        valid = false;
      } else {
       this.engagementOverviewComponent?.setSeries24WarningMessage(false);
      }
    } else {
      this.appFormGroup.get(formGroups.myDealFormGroup)
      .get(this.sharePointMapper.getFormControlColumnName(FormListFields.series24)).setValue(null);
      this.engagementOverviewComponent?.setSeries24WarningMessage(false);
    }
    return valid;
  }

  private _checkFieldsDependencies() {
    this.clientOverViewComponent.evaluateClientType(this
      .appFormGroup.get(formGroups.clientOverViewFormGroup).get('clientType').value);

    this.clientOverViewComponent.evaluateReferralSource(this
      .appFormGroup.get(formGroups.clientOverViewFormGroup).get('referralSource').value);

    this.clientOverViewComponent.evaluateClientLocation(this
      .appFormGroup.get(formGroups.clientOverViewFormGroup).get('clientLocation').value);

    this.dealOverviewComponent.evaluateIsCompetitivePitch(this
      .appFormGroup.get(formGroups.myDealFormGroup).get('isCompetitivePitch').value);

    this.datesTimelineComponent.evaluateSimultaneousSignClose(this
      .appFormGroup.get(formGroups.timelineContentFormGroup).get('simultaneousSignClose').value);

    this.qualityOfEarningsComponent.evaluatesellSideQofEPerformed(this
      .appFormGroup.get(formGroups.qualityOfEarningsFormGroup).get('sellsideQofEPerformed').value);
    // if(this.isFinanceForm){
    this.financeComponent.evaluateFinanceDeloitteEntity(this
      .appFormGroup.get(formGroups.financeFormGroup).get('financeDeloitteEntity').value);

    this.financeComponent.evaluateFinanceFutureExpenses(this
      .appFormGroup.get(formGroups.financeFormGroup).get('financeFutureExpenses').value);

    this.financeComponent.evaluateFinanceFuturePayments(this
      .appFormGroup.get(formGroups.financeFormGroup).get('financeFuturePayments').value);

    this.financeComponent.evaluateFinanceCrossBorder(this
      .appFormGroup.get(formGroups.financeFormGroup).get('financeCrossBorderEngagement').value);
    // }
    this.engagementOverviewComponent.evaluateIsThisClientPublicEntity(this
      .appFormGroup.get(formGroups.engagementOverviewFormGroup).get('isClientPublicEntity').value);

    this.engagementOverviewComponent.evaluateIsPreviousSeries24(this
      .appFormGroup.get(formGroups.engagementOverviewFormGroup).get('werePreviousSeries24').value);

    this.engagementOverviewComponent.evaluateIsonPPMDDCFUser(this
      .appFormGroup.get(formGroups.engagementOverviewFormGroup).get('pPMDDCFUser').value);

    this.engagementOverviewComponent.evaluateIsPreviousPPMD(this
      .appFormGroup.get(formGroups.engagementOverviewFormGroup).get('werePreviousPPMD').value);

    this.marketingAndCommunicationsComponent.evaluatePitchBook(this
      .appFormGroup.get(formGroups.marketingAndComunicationsFormGroup).get('pitchBookSeries24').value);

    this.marketingAndCommunicationsComponent.evaluateTeaser(this
      .appFormGroup.get(formGroups.marketingAndComunicationsFormGroup).get('teaserSeries24').value);

    this.marketingAndCommunicationsComponent.evaluateCimMemorandumPPM(this
      .appFormGroup.get(formGroups.marketingAndComunicationsFormGroup).get('cimMemorandumPPMSeries24').value);

    this.marketingAndCommunicationsComponent.evaluateTombstone(this
      .appFormGroup.get(formGroups.marketingAndComunicationsFormGroup).get('tombstoneSeries24').value);

    this.marketingAndCommunicationsComponent.evaluateCaseStudies(this
      .appFormGroup.get(formGroups.marketingAndComunicationsFormGroup).get('caseStudiesSeries24').value);

    this.marketingAndCommunicationsComponent.evaluatePressRelease(this
      .appFormGroup.get(formGroups.marketingAndComunicationsFormGroup).get('pressReleaseSeries24').value);

    this.booksAndRecordsComponent.evaluateDidProjectGoICSelection(this
      .appFormGroup.get(formGroups.booksAndRecordsFormGroup).get('didProjectGoIC').value);

    this.feesComponent.evaluateRetainerReceived(this
      .appFormGroup.get(formGroups.feesFormGroup).get('retainerReceived').value);

    this.feesComponent.evaluateSuccessFeePaidFull(this
      .appFormGroup.get(formGroups.feesFormGroup).get('successFeePaidFull').value);

    this.feesComponent.evaluateFinderReferralFeeOwed(this
      .appFormGroup.get(formGroups.feesFormGroup).get('expensesBilled').value);
  }

  private _openModal(modalType: string) {
    this.modalType = modalType;
    this.notifypopupComponent.openPopup("ClosingChecklist", modalType);
  }

  private _setFormAsReadonly() {
    Object.keys(this.appFormGroup.controls).forEach(formGroupName => {
      const formGroup = this.appFormGroup.get(formGroupName) as FormGroup;
      Object.keys(formGroup.controls).forEach(formControlName => {
        const elem = formGroup.get(formControlName) as CustomFormControl;
        if (this.formStatus == "Pending" && this.formActionGroup == "Series24" && (formControlName == "series24ReviewedTransaction" || formControlName == "series24ReviewedPersonnelHours")) {
          elem.setReadOnly(false);
        }
        else {
          if((formControlName == "series24ReviewedTransaction" || formControlName == "series24ReviewedPersonnelHours")){
            elem.isReadonlySupervisorySection();
          }
          else{
            elem.setReadOnly(true);
          }
          
        }

      });
    });
  }

  private _isFormDiscontinuedByDealTeam(discontinuedFormActionGroup: string) {
    return discontinuedFormActionGroup === FormActionGroupValue.dealTeam && this.formStatus === FormStatusValue.discontinued;
  }

  private _disableSupervisorySignoffFormGroup() {
    this.appFormGroup.get(formGroups.supervisorySignoffFormGroup).disable();
  }

  private _enableASupervisorySignoffFormGroup() {
    this.appFormGroup.get(formGroups.supervisorySignoffFormGroup).enable();
  }

  private _handlerReactivateFormSection(discontinuedOn: string, discontinuedBy: string) {
    if (this.hasPermission('Reactivate')) {
      //this.reactivateFormComponent.setReactivateSection(discontinuedOn, discontinuedBy);            
      this.reactivateFormComponent.setReactivateSection();
      this.sidebarComponent.showReactivateFormSideMenu();
    }
  }
  private _handlerDealTeamSignoffSection(responsedealTeam :any, dealTeamReviewData:any, Series24:any) {
    if (this.hasPermission('ShowDealTeamSignoff')) {
      this.showDealTeamSignoffsSection = true;
     // responsedealTeam=this._loadActualDealTeamList(responsedealTeam,1);
      this.dealTeamSignOffsComponent.populateDealTeamSignoff(responsedealTeam, dealTeamReviewData, Series24);
      this.sidebarComponent.showDealTeamSignoffSideMenu();
      if (this.hasPermission('EditDealTeamSignoff')) {
        this.hasDealTeamSignoffApprovalAccess = true;
      }
    }
  }

  // private _handlerSupervisorySignoffSection() {
  //   this._disableSupervisorySignoffFormGroup();
  //   this.changeDetector.detectChanges();
  //   //if (this.checkVisibilitySupervisorySignoffSetion()) {
  //     //const signoffEditable = this._isSupervisorySignoffEditable();
  //     //this.sidebarComponent.showSignoff();
  //     if (this.hasPermission('EditSignoffSection')) {
  //       this._enableASupervisorySignoffFormGroup();
  //     }
  //   //}
  // }
  private _handlerSubmitForReviewSection() {
    if (this.hasPermission('ShowSubmitForReviewSection')) {
      this.ShowSubmitForReviewSection = true;
      this.sidebarComponent.showSubmitForReviewSideMenu();
    }
  }

  private _handlerSeries24ApprovalSection(series24FinalApproval:any, Series24ReviewedTransaction:any, Series24ReviewedPersonnelHours:any) {
    if (this.hasPermission('ShowSignoffSection')) {
      this.showSeries24FinalApprovalSection = true;
      this.series24FinalApproval = this.setSeries24FinalApproval(series24FinalApproval);
      this.sidebarComponent.showSeries24ApprovalSideMenu();

      //handle supervisorySignOff section
      this._disableSupervisorySignoffFormGroup();
      if (this.hasPermission('EditSignoffSection')) {
        this._enableASupervisorySignoffFormGroup();
          this.supervisorySignoffComponent.onSeries24ReviewedTransactionSelection(Series24ReviewedTransaction);
          this.supervisorySignoffComponent.onSeries24ReviewedPersonnelHoursSelection(Series24ReviewedPersonnelHours);
        // handle series24 approval permission
        this.hasSeries24ApprovalAccess = this.hasPermission("EditSignoffSection");

      }
    }
  }

  setSeries24FinalApproval(series24FinalApproval:any) {
    if (this.series24FinalApproval.series24Signature) {
      this.series24Signature = true;
      this.series24FinalApproval.series24ApprovalDate =
        this._formatAdminReviewDate(
          this.series24FinalApproval.series24ApprovalDate
        );
    }
    return series24FinalApproval;
  }
  private _formatAdminReviewDate(approvalDate: string): string {
    
    let dateObject = new Date(approvalDate);
    dateObject=this.dateService.ConvertDateFormatToUTC(dateObject,approvalDate);
    return this.datePipe.transform(dateObject, "dd MMM y hh:mm a",'CDT') + " CST";
//     const dateObject = this.dateFormatterService.formatDateOnRetrieve(approvalDate);
// return `${this.dateService._FormatDateCCL(dateObject.toString())}`;
  
  }
  private _handlerAutoSave() {
    if (this.appContext.autoSaveEnabled && this.hasPermission('AutoSave')) {
      this.isAutoSaveEnabled=this.appContext.autoSaveEnabled;
      this._executeAutoSave();
    } else {
      this.isAutoSaveEnabled=false;
      this._handlerSaveButton();
    }
    //}
  }

  private _handlerSaveButton() {
    switch (this.formActionGroup) {
      case FormActionGroupValue.dealTeam:
        this.saveButtonVisible = true;
        break;
      case FormActionGroupValue.series24:
        this.saveButtonVisible = true;
        break;
    }
  }
  isCommentsModified(){
    let isAdded:any=false;
    if(environment.CCLComments.toString()!=""){
      isAdded=true;
    }
    return isAdded;
  }
  isValidFormToTrigger():any{
    let isAutoSaveTrigger=false;  
    let differences = this._findDifferencesBeforeSaveData();
    if(Object.entries(differences.complianceDifferences).toString()!="" ||
    Object.entries(differences.ccFormDifferences).toString()!="" ||
    Object.entries(differences.valuationMetricsDifferences).toString()!="" ||
    Object.entries(differences.myDealDifferences).toString()!="" ||Object.entries(differences.dealMarketingDifferences).toString()!=""){isAutoSaveTrigger=true;}
    //Tables List check for differences
    isAutoSaveTrigger=!isAutoSaveTrigger?this.findDifferenceOfTables():isAutoSaveTrigger;
    //Comments check for differences
    isAutoSaveTrigger=!isAutoSaveTrigger?this.isCommentsModified():isAutoSaveTrigger;
    return isAutoSaveTrigger;
  }
  checkFormCompletedOrDiscontinued():any{
    let isCompletedOrDiscontinued=false;
    if(this.formStatus=="Completed"||this.formStatus=="Discontinued"){
      isCompletedOrDiscontinued=true;
    }
    return isCompletedOrDiscontinued;
  }
  private _executeAutoSave() {
    this.subscription = interval(this.appContext.autoSaveInterval).subscribe(value => {
      let IsFormValidToTriggerAutoSave=this.isValidFormToTrigger();
      let IsFormCompleted=this.checkFormCompletedOrDiscontinued();
      if(IsFormValidToTriggerAutoSave&& !IsFormCompleted){
      this.spinnerService.showAutoSaveSpinner();
      }
     let emptyForm= this._validateEmptyForm();
        if (!emptyForm && !this.executingWorkflowChangeAction && IsFormValidToTriggerAutoSave && !IsFormCompleted) {  
            this.saveForm()
            .subscribe({
                next: (responseCode: any) => {          
                  if (responseCode !== responseCodes.fail) {
                    this._showAutosaveAlertMessage(responseCode);
                  } else {
                    this._stopAutosave();
                  }
                },
                error: (err: any) => {
                  this._stopAutosave();
                  this._openModal(modalTypes.error);
                }
              })
              environment.CCLComments="";
        }
        else if(IsFormCompleted){
          this._stopAutosave_CCL();
        }
      
      timer(this.appContext.autoSaveSpinningWheelInterval).subscribe(x => { this.spinnerService.hideAutoSaveSpinner() });
    });
  }
  _validateEmptyForm():any {
      const changesObject:any = Object.assign({}, this._setSharepointObject(), this._setSharepointObjectMyDeal());
      let emptyForm = true;
      if (this.complianceFormId) {
        emptyForm = false;
      } else {
        Object.keys(changesObject).forEach(field => {
          const value = changesObject[field];

          if (this._isValidFieldValue(value)) {
            emptyForm = false;
          }
        });
      }
      return emptyForm;
    
  }
  private _isValidFieldValue(value:any) {
    if (value === '' || typeof (value) === undefined || value === null || (value.results && value.results.length === 0)) {
      return false;
    }
    return true;
  }
  private _findDifferencesBeforeSaveData() {
    const currentComplianceObject = this._setSharepointObject();
    const currentSharepointObjectMyDeal = this._setSharepointObjectMyDeal();


    const currentSharepointValuationMetrics = this._setSharepointObjectValuationMetrics();

    const currentSharepointDealMarketing = this._setSharepointObjectDealMarketingSection();
    //const currentCCFormSharePointObject =  this._setSharepointObjectCCForm();

    const currentClosingCheckListObject = this._setSharepointClosingCheckObject();

    const complianceDifferences = this._getDifferences(this.initialSharepointObject, currentComplianceObject);
    const myDealDifferences = this._getDifferences(this.initialMyDealObject, currentSharepointObjectMyDeal);

    const valuationMetricsDifferences = this._getValuationMetricsDifferences(this.initialValuationMetrics, currentSharepointValuationMetrics);
    const dealMarketingDifferences = this._getBuyerDealMarketingDifference(this.initialBuyerDealMarketing, currentSharepointDealMarketing);

    const ccFormDifferences = this._getCCDifferences(this.initialCCFormObject, currentClosingCheckListObject);
    return { complianceDifferences, myDealDifferences, ccFormDifferences, valuationMetricsDifferences, dealMarketingDifferences };

  }
findDifferenceOfTables(){
  let isTablesModified=false;
    const currentpS24List = this.engagementOverviewComponent.getDealTeamRowsComplete_CCL();
     const currentppmdList = this.engagementOverviewComponent.getDealTeamRowsCompleteppmd_CCL();
     const currentCounterpartiesList = this.engagementOverviewComponent.getCounterPartiesRowsComplete();
     const currentDttlValues = this.crossBorderComponent.getDttlValues_CCL();
     const currentDealTeamTable = this.projectOverviewComponent.getDealTeamRowsComplete_CCL();
     if(this.initialpS24Values.length!=currentpS24List.length||this.initialPpmdValue.length!=currentppmdList.length||this.initialcounterpartiesValues.length!=currentCounterpartiesList.length||
      this.initialdttlValues.length!=currentDttlValues.length||this.initialDealTeamTable.length!=currentDealTeamTable.length){
        isTablesModified=true;
      }
      if(currentDealTeamTable.length>0){
        for (let i = 0; i < this.initialDealTeamTable.length; i++) {
          const initialValue = this.initialDealTeamTable[i];
          const changesValue = currentDealTeamTable[i];
          if (Object.entries(initialValue).toString() !== Object.entries(changesValue).toString()) {
            isTablesModified=true;
          } 
         else if (Object.entries(initialValue.optionsSelectedFirstColumn).toString() !== Object.entries(changesValue.optionsSelectedFirstColumn).toString()||
          Object.entries(initialValue.usersSelected[0]).toString() !== Object.entries(changesValue.usersSelected[0]).toString()) {
            isTablesModified=true;
          } 
        }
      }
      return isTablesModified;
}
  private _setSharepointClosingCheckObject() {

    let sharepointObject = new SharepointClosingCheckListObject();
    sharepointObject = {
      ...sharepointObject,
      //dcf
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.dcfFeeStrcutureFormGroup) as FormGroup),
      BreakpointQuestion: (this.appFormGroup.get(formGroups.dcfFeeStrcutureFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.breakpointQuestion)).value),
      BreakpointValue: (this.appFormGroup.get(formGroups.dcfFeeStrcutureFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.breakpointValue)).value),


      //client  Overview section
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.clientOverViewFormGroup) as FormGroup),
      //Deal Overview Section
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.dealOverViewFormGroup) as FormGroup),
      // Dates Timeline Section
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.timelineContentFormGroup) as FormGroup),
        PitchedDate:
        this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.timelineContentFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.pitchedDate)).value),
        SignedEngagementLetter:
        this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.timelineContentFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.signedEngagementLetter)).value),
        EnteredMarket:
        this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.timelineContentFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.enteredMarket)).value),
        ReceivedIoiTermSheets:
        this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.timelineContentFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.receivedIoiTermSheets)).value),
        ReceivedLOIs:
        this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.timelineContentFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.receivedLOIs)).value),
        SignedLOI:
        this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.timelineContentFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.signedLOI)).value),
        TransactionClose:
        this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.timelineContentFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.transactionClose)).value),
        SignDate:
        this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.timelineContentFormGroup)
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.signDate)).value),
       
      //Quality Of Earning Section
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.qualityOfEarningsFormGroup) as FormGroup),
      //Finance Section
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.financeFormGroup) as FormGroup),
      // Valuation Metrics
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.valuationMetricsFormGroup) as FormGroup),
      // Indemnification
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.indemnificationFormGroup) as FormGroup),

      // buyerdealMarketing
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.buyerDealMarketingFormGroup) as FormGroup),

      // Deal Structure
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.dealStructureFormGroup) as FormGroup)


    };
    return sharepointObject;
  }

  private _getDifferences(initialData:any, currentData:any) {
    const differences:any = {};
    for (const input in initialData) {
      if (initialData[input] !== currentData[input]) {
        differences[input] = currentData[input];
      }
    }
    return differences;
  }
  private _getValuationMetricsDifferences(initialData:any, currentData:any) {
    let differences:any = new SharepointValuationObject();
    for (const input in initialData) {
      if (initialData[input] !== currentData[input] && this.validateNullAndEmptyFields(initialData, currentData, input)) {
        differences[input] = currentData[input];
      }
    }
    return differences;
    //return this.ComplianceServiceHelper.CreateValuationDifferenceObject(differences);
  }

  private _getBuyerDealMarketingDifference(initialData:any, currentData:any) {
    let differences:any = new SharepointDealMarketingObject();
    for (const input in initialData) {
      if (initialData[input] !== currentData[input]
        && this.validateNullAndEmptyFields(initialData, currentData, input)
      ) {
      differences[input] = currentData[input];
      }
    }
     return differences;
    //return this.ComplianceServiceHelper.CreateBuyerDealMarketingDifferenceObject(differences);
  }
  private _getCCDifferences(initialData:any, currentData:any) {
    let differences:any = new SharepointClosingCheckListObject();
    for (const input in initialData) {
      if (initialData[input] !== currentData[input]
        && this.validateNullAndEmptyFields(initialData, currentData, input)) { 
        differences[input] = currentData[input];
      }
    }
    return differences;
    //return this.ComplianceServiceHelper.CreateCCFormDifferenceObject(differences);
  }
validateNullAndEmptyFields(initial:any,current:any,input:any):any{
  let res=true;
if(initial[input]==null||initial[input]==""){
  if(current[input]==""||current[input]==null)
    {
      res=false;
    }
}
return res;
}
  private _getResponseCodeOk(): string {
    let code = responseCodes.ok;
    switch (true) {
      case this.conflictInputsOnSave:
        code = responseCodes.conflicts;
        break;
      case this.overwriteInputs:
        code = responseCodes.incomingUpdates;
        break;
    }
    return code;
  }
  private _showAutosaveAlertMessage(code:any) {
    let autoSaveMessage;
    const currentTimestamp:any = this.dateService.FormatDate(new Date().toString());
    switch (code) {
      case responseCodes.ok:
        autoSaveMessage = AppSettings.autosaveOk.replace('{timestamp}', currentTimestamp);
        this.alertMessageType = alertTypes.success;
        //this.alert.displayAlertMessage(autoSaveMessage);
        this.alertService.success(autoSaveMessage,alertOptions);
        break;
      case responseCodes.incomingUpdates:
        autoSaveMessage = AppSettings.autosaveWithUpdates.replace('{timestamp}', currentTimestamp);
        this.alertMessageType = alertTypes.success;
        //this.alert.displayAlertMessage(autoSaveMessage);
        this.alertService.success(autoSaveMessage,alertOptions);
        break;
      case responseCodes.conflicts:
        autoSaveMessage = AppSettings.autosaveWithConflicts.replace('{timestamp}', currentTimestamp);
        this.alertMessageType = alertTypes.warning;
       // this.alert.displayAlertMessage(autoSaveMessage);
        this.alertService.warn(autoSaveMessage,alertOptions);
        break;
      case responseCodes.tryAgain:
        setTimeout(() => this._subscribeAutosave(), AppSettings.forceItemReleaseInterval);
        break;
      case responseCodes.formUpdated:
        this.executingWorkflowChangeAction = true;
        this._openModal(modalTypes.formUpdated);
        this._stopAutosave();
        break;
    }
  }

  private _stopAutosave() {
    if (this.subscription) {
      this.subscription.unsubscribe();   
    }
  }
  private _stopAutosave_CCL() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.alertService.info("AutoSave Stopped!",alertOptions);
    }
  }
  private _subscribeAutosave() {
    this.saveForm().subscribe(responseCode => {
      if (responseCode !== responseCodes.fail) {
        this._showAutosaveAlertMessage(responseCode);
      }
    });
  }

  private _populateDocumentTypes(responseDocumentTypes: Array<DocumentTypeItem>) {
    this.financeComponent.populateDocumentTypes(responseDocumentTypes, this.formId);
  }
  private _areSubListCreated(valuationList: any, dealMarketingList: any) {
    let valuationsItemId = (valuationList!=undefined && valuationList!=null) ? valuationList.ID : null;
    let dealItemId = (dealMarketingList!=undefined && dealMarketingList!=null) ? dealMarketingList.ID : null;
    let areCreated: boolean = dealItemId && valuationsItemId;
    return areCreated;
  }

  private _fillProjectOverview() {
    this.sharePointMapper.addMapping('DealTeamTable', 'dealteamTable');
  }

  private _fillClientOverView() {
    this.sharePointMapper.addMapping('ClientType', 'clientType');
    this.sharePointMapper.addMapping('SponsorName', 'sponsorName');
    this.sharePointMapper.addMapping('SubsidiaryBeingSold', 'subsidiaryBeingSold');
    this.sharePointMapper.addMapping('ClientRelationship', 'clientRelationship');
    this.sharePointMapper.addMapping('ReferralSource', 'referralSource');
    this.sharePointMapper.addMapping('ReferralSourceSubType', 'referralSourceSubType');
    //this.sharePointMapper.addMapping('ClientLocationId', 'clientLocationId');
    this.sharePointMapper.addMapping('ClientLocation', 'clientLocation');
    //this.sharePointMapper.addMapping('ClientCountryId', 'clientCountryId');
    this.sharePointMapper.addMapping('ClientCountry', 'clientCountry');
    //this.sharePointMapper.addMapping('IndustryId', 'industryId');
  }

  private _fillDealOverView() {
    this.sharePointMapper.addMapping('DealType', 'dealType');
    this.sharePointMapper.addMapping('CrossBorderEngagement', 'crossBorderEngagement');
    this.sharePointMapper.addMapping('FirstProcessRun', 'firstProcessRun');
    this.sharePointMapper.addMapping('FirstInstitutionalCapital', 'firstInstitutionalCapital');
    //this.sharePointMapper.addMapping('IsCompetitivePitch', 'isCompetitivePitch');
    this.sharePointMapper.addMapping('NumberOfParticipants', 'numberOfParticipants');
  }

  private _fillDcfFeeStrcuture() {
    this.sharePointMapper.addMapping('FeeType', 'feeType');
    this.sharePointMapper.addMapping('Retainer', 'retainer');
    this.sharePointMapper.addMapping('BreakpointQuestion', 'breakpointQuestion');
    this.sharePointMapper.addMapping('BreakpointValue', 'breakpointValue');
    this.sharePointMapper.addMapping('BaseFeeRadio', 'baseFeeRadio');
    this.sharePointMapper.addMapping('BaseFeePercentage', 'baseFeePercentage');
    this.sharePointMapper.addMapping('BaseFeeNumerical', 'baseFeeNumerical');
    this.sharePointMapper.addMapping('IncentiveFeeRadio', 'incentiveFeeRadio');
    this.sharePointMapper.addMapping('IncentiveFeePercentage', 'incentiveFeePercentage');
    this.sharePointMapper.addMapping('IncentiveFeeNumerical', 'incentiveFeeNumerical');
    this.sharePointMapper.addMapping('MinimumSucessFee', 'minimumSucessFee');
    this.sharePointMapper.addMapping('MinimumFeeApply', 'minimumFeeApply');
    this.sharePointMapper.addMapping('DescribeFeeStructure', 'describeFeeStructure');

  }

  private _fillBuyerDealMarketing() {

    this.sharePointMapper.addMapping('BuyerParentCompany', 'buyerParentCompany');
    this.sharePointMapper.addMapping('BuyerType', 'buyerType');
    this.sharePointMapper.addMapping('InternationalBuyer', 'internationalBuyer');
    this.sharePointMapper.addMapping('BuyerCountry', 'buyerCountry');
    this.sharePointMapper.addMapping('IdentifiedTierPitch', 'identifiedTierPitch');
    this.sharePointMapper.addMapping('BuyerRelationship', 'buyerRelationship');
    this.sharePointMapper.addMapping('DeloitteLCSP', 'deloitteLCSP');
    this.sharePointMapper.addMapping('PreemptiveBidder', 'preemptiveBidder');
    this.sharePointMapper.addMapping('PreemptiveFinalPurchaser', 'preemptiveFinalPurchaser');
    this.sharePointMapper.addMapping('PreemptiveOffer', 'preemptiveOffer');
    this.sharePointMapper.addMapping('PremiumOverInitialOffer', 'premiumOverInitialOffer');
    this.sharePointMapper.addMapping('MarketingProcessType', 'marketingProcessType');
    this.sharePointMapper.addMapping('DescribeDealMarketing', 'describeDealMarketing');
    this.sharePointMapper.addMapping('ApproachedUSStrategic', 'approachedUSStrategic');
    this.sharePointMapper.addMapping('ApproachedIntlStrategic', 'approachedIntlStrategic');
    this.sharePointMapper.addMapping('ApproachedPEGNoPortCo', 'approachedPEGNoPortCo');
    this.sharePointMapper.addMapping('ApproachedPEG', 'approachedPEG');
    this.sharePointMapper.addMapping('ApproachedTotal', 'approachedTotal');
    this.sharePointMapper.addMapping('SentCIMUSStrategic', 'sentCIMUSStrategic');
    this.sharePointMapper.addMapping('SentCIMIntlStrategic', 'sentCIMIntlStrategic');
    this.sharePointMapper.addMapping('SentCIMPEGNoPortCo', 'sentCIMPEGNoPortCo');
    this.sharePointMapper.addMapping('SentCIMPEG', 'sentCIMPEG');
    this.sharePointMapper.addMapping('SentCIMTotal', 'sentCIMTotal');
    this.sharePointMapper.addMapping('SubmittedIOIUSStrategic', 'submittedIOIUSStrategic');
    this.sharePointMapper.addMapping('SubmittedIOIIntlStrategic', 'submittedIOIIntlStrategic');
    this.sharePointMapper.addMapping('SubmittedIOIPEGNoPortCo', 'submittedIOIPEGNoPortCo');
    this.sharePointMapper.addMapping('SubmittedIOIPEG', 'submittedIOIPEG');
    this.sharePointMapper.addMapping('SubmittedIOITotal', 'submittedIOITotal');
    this.sharePointMapper.addMapping('AttendedMPUSStrategic', 'attendedMPUSStrategic');
    this.sharePointMapper.addMapping('AttendedMPIntlStrategic', 'attendedMPIntlStrategic');
    this.sharePointMapper.addMapping('AttendedMPPEGNoPortCo', 'attendedMPPEGNoPortCo');
    this.sharePointMapper.addMapping('AttendedMPPEG', 'attendedMPPEG');
    this.sharePointMapper.addMapping('AttendedMPTotal', 'attendedMPTotal');
    this.sharePointMapper.addMapping('SubmittedLOIUSStrategic', 'submittedLOIUSStrategic');
    this.sharePointMapper.addMapping('SubmittedLOIIntlStrategic', 'submittedLOIIntlStrategic');
    this.sharePointMapper.addMapping('SubmittedLOIPEGNoPortCo', 'submittedLOIPEGNoPortCo');
    this.sharePointMapper.addMapping('SubmittedLOIPEG', 'submittedLOIPEG');
    this.sharePointMapper.addMapping('SubmittedLOITotal', 'submittedLOITotal');

  }

  private _fillDealStructure() {
    this.sharePointMapper.addMapping('LegalStructure', 'legalStructure');
    this.sharePointMapper.addMapping('ContingentTotalEVPct', 'contingentTotalEVPct');
    this.sharePointMapper.addMapping('TimeUntilContingentPayment', 'timeUntilContingentPayment');
    this.sharePointMapper.addMapping('TriggerType', 'triggerType');
    this.sharePointMapper.addMapping('StockConsideration', 'stockConsideration');
    this.sharePointMapper.addMapping('StockConsiderationTotalEVPct', 'stockConsiderationTotalEVPct');
    this.sharePointMapper.addMapping('RolloverEquity', 'rolloverEquity');
    this.sharePointMapper.addMapping('RolloverPreTaxPct', 'rolloverPreTaxPct');
    this.sharePointMapper.addMapping('RolloverAfterTaxPct', 'rolloverAfterTaxPct');
    this.sharePointMapper.addMapping('ManagementOptionPool', 'managementOptionPool');
    this.sharePointMapper.addMapping('SponsorTransactionClosingFee', 'sponsorTransactionClosingFee');
    this.sharePointMapper.addMapping('ManagementFee', 'managementFee');
    this.sharePointMapper.addMapping('ManagementFeePct', 'managementFeePct');
    this.sharePointMapper.addMapping('ManagementFeeQty', 'managementFeeQty');
    this.sharePointMapper.addMapping('TotalLeverage', 'totalLeverage');
    this.sharePointMapper.addMapping('EquityContributionTotalPct', 'equityContributionTotalPct');
    this.sharePointMapper.addMapping('EquityContributionClosingPct', 'equityContributionClosingPct');

  }

  private _fillQualityOfEarnings() {
    this.sharePointMapper.addMapping('SellsideQofEPerformed', 'sellsideQofEPerformed');
    this.sharePointMapper.addMapping('QofEStage', 'qofEStage');
    this.sharePointMapper.addMapping('WhatFirmPerformed', 'whatFirmPerformed');
    this.sharePointMapper.addMapping('MainContact', 'mainContact');
    this.sharePointMapper.addMapping('QofEDeloitteBranded', 'qofEDeloitteBranded');
  }

  private _fillAdditionalCommentary() {
    this.sharePointMapper.addMapping('AdditionalComments', 'additionalComments');
  }

  private _filltimelineContent() {
    this.sharePointMapper.addMapping('PitchedDate', 'pitchedDate');
    this.sharePointMapper.addMapping('SignedEngagementLetter', 'signedEngagementLetter');
    this.sharePointMapper.addMapping('EnteredMarket', 'enteredMarket');
    this.sharePointMapper.addMapping('ReceivedIoiTermSheets', 'receivedIoiTermSheets');
    this.sharePointMapper.addMapping('ReceivedLOIs', 'receivedLOIs');
    this.sharePointMapper.addMapping('SignedLOI', 'signedLOI');
    this.sharePointMapper.addMapping('TransactionClose', 'transactionClose');
    this.sharePointMapper.addMapping('SimultaneousSignClose', 'simultaneousSignClose');
    this.sharePointMapper.addMapping('SignDate', 'signDate');
    this.sharePointMapper.addMapping('CommentAffectingTimeline', 'commentAffectingTimeline');
  }

  private _fillFinance() {
    this.sharePointMapper.addMapping('FinanceFileUpload', 'financeFileUpload');
    this.sharePointMapper.addMapping('FinanceEnterpriseValue', 'financeEnterpriseValue');
    this.sharePointMapper.addMapping('FinanceDeloitteEntity', 'financeDeloitteEntity');
    this.sharePointMapper.addMapping('FinanceDeloitteEntityDescription', 'financeDeloitteEntityDescription');
    this.sharePointMapper.addMapping('FinanceFutureExpenses', 'financeFutureExpenses');
    this.sharePointMapper.addMapping('FinanceFutureExpensesAmount', 'financeFutureExpensesAmount');
    this.sharePointMapper.addMapping('FinanceFuturePayments', 'financeFuturePayments');
    this.sharePointMapper.addMapping('FinanceFuturePaymentsType', 'financeFuturePaymentsType');
    this.sharePointMapper.addMapping('FinanceAssetSaleDeal', 'financeAssetSaleDeal');
    this.sharePointMapper.addMapping('FinanceCrossBorderEngagement', 'financeCrossBorderEngagement');
    this.sharePointMapper.addMapping('FinanceCrossBorderCountry', 'financeCrossBorderCountry');
    this.sharePointMapper.addMapping('FinanceProviderName', 'financeProviderName');
    this.sharePointMapper.addMapping('FinanceProviderNameType', 'financeProviderNameType');
    this.sharePointMapper.addMapping('FinanceDealLeagueTable', 'financeDealLeagueTable');
    this.sharePointMapper.addMapping('FinanceDealLeagueTableDesc', 'financeDealLeagueTableDesc');
    this.sharePointMapper.addMapping('FinanceDealGlobalDeloitteSite', 'financeDealGlobalDeloitteSite');
    this.sharePointMapper.addMapping('FinanceDealGlobalDelSiteDesc', 'financeDealGlobalDelSiteDesc');
    this.sharePointMapper.addMapping('FinanceDealAnnouncement', 'financeDealAnnouncement');
    this.sharePointMapper.addMapping('FinanceDealAnnouncementDesc', 'financeDealAnnouncementDesc');
    this.sharePointMapper.addMapping('FinanceDealCaseStudy', 'financeDealCaseStudy');
    this.sharePointMapper.addMapping('FinanceDealCaseStudyDesc', 'financeDealCaseStudyDesc');
  }

  private _fillIndemnification() {
    this.sharePointMapper.addMapping('CapNumerical', 'capNumerical');
    this.sharePointMapper.addMapping('CapPercentageEV', 'capPercentageEV');
    this.sharePointMapper.addMapping('CapPercentageClosing', 'capPercentageClosing');
    this.sharePointMapper.addMapping('SurvivalMonths', 'survivalMonths');
    this.sharePointMapper.addMapping('EscrowNumerical', 'escrowNumerical');
    this.sharePointMapper.addMapping('EscrowTotalEV', 'escrowTotalEV');
    this.sharePointMapper.addMapping('EscrowClosing', 'escrowClosing');
    this.sharePointMapper.addMapping('ReleaseMonths', 'releaseMonths');
    this.sharePointMapper.addMapping('ReconciliationEscrow', 'reconciliationEscrow');
    this.sharePointMapper.addMapping('IfOtherWhat', 'ifOtherWhat');
    this.sharePointMapper.addMapping('DeductibleNumerical', 'deductibleNumerical');
    this.sharePointMapper.addMapping('DeductibleEV', 'deductibleEV');
    this.sharePointMapper.addMapping('DeductibleClosing', 'deductibleClosing');
    this.sharePointMapper.addMapping('BasketType', 'basketType');
    this.sharePointMapper.addMapping('SpecialIndemnification', 'specialIndemnification');
    this.sharePointMapper.addMapping('SpecialIndemnificationTopic', 'specialIndemnificationTopic');
  }

  private _fillValuationMetrics() {

    this.sharePointMapper.addMapping('ClosingConsideration', 'closingConsideration');
    this.sharePointMapper.addMapping('ContingentConsideration', 'contingentConsideration');
    this.sharePointMapper.addMapping('TotalConsideration', 'totalConsideration');
    this.sharePointMapper.addMapping('Ebitda', 'ebitda');
    this.sharePointMapper.addMapping('EbitdaPeriod', 'ebitdaPeriod');
    this.sharePointMapper.addMapping('EbitdaMultipleTotal', 'ebitdaMultipleTotal');
    this.sharePointMapper.addMapping('EbitdaMultipleClosing', 'ebitdaMultipleClosing');
    this.sharePointMapper.addMapping('FinalEvPitchRange', 'finalEvPitchRange');
    this.sharePointMapper.addMapping('IfBelowWhyOtherReason', 'ifBelowWhyOtherReason');
    this.sharePointMapper.addMapping('IfBelowWhy', 'ifBelowWhy');
    this.sharePointMapper.addMapping('PitchEBITDAMultipleRangeFirst', 'pitchEBITDAMultipleRangeFirst');
    this.sharePointMapper.addMapping('PitchEBITDAMultipleRangeSecond', 'pitchEBITDAMultipleRangeSecond');
    this.sharePointMapper.addMapping('MultipleRangeMidpoint', 'multipleRangeMidpoint');
    this.sharePointMapper.addMapping('ImpliedValuationPremium', 'impliedValuationPremium');
    this.sharePointMapper.addMapping('HitBreakpointEL', 'hitBreakpointEL');
    this.sharePointMapper.addMapping('ElBreakpoint', 'elBreakpoint');
    this.sharePointMapper.addMapping('ValuationPremiumOverBp', 'valuationPremiumOverBp');
    this.sharePointMapper.addMapping('IoiAboveBreakpoint', 'ioiAboveBreakpoint');
    this.sharePointMapper.addMapping('LoiAboveBreakpoint', 'loiAboveBreakpoint');
    this.sharePointMapper.addMapping('DescribeDealStructure', 'describeDealStructure');
    this.sharePointMapper.addMapping('IOIValueClosingLow', 'iOIValueClosingLow');
    this.sharePointMapper.addMapping('IOIValueClosingHigh', 'iOIValueClosingHigh');
    this.sharePointMapper.addMapping('IOIValueContingent', 'iOIValueContingent');
    this.sharePointMapper.addMapping('IOIValueTotalHigh', 'iOIValueTotalHigh');
    this.sharePointMapper.addMapping('IOIValueClosingAvg', 'iOIValueClosingAvg');
    this.sharePointMapper.addMapping('IOIValueTotalAvg', 'iOIValueTotalAvg');
    this.sharePointMapper.addMapping('LOIValueClosingLow', 'lOIValueClosingLow');
    this.sharePointMapper.addMapping('LOIValueClosingHigh', 'lOIValueClosingHigh');
    this.sharePointMapper.addMapping('LOIValueContingent', 'lOIValueContingent');
    this.sharePointMapper.addMapping('LOIValueTotalHigh', 'lOIValueTotalHigh');
    this.sharePointMapper.addMapping('LOIValueClosingAvg', 'lOIValueClosingAvg');
    this.sharePointMapper.addMapping('LOIValueTotalAvg', 'lOIValueTotalAvg');
    this.sharePointMapper.addMapping('NumericalPriceIncClosingLow', 'numericalPriceIncClosingLow');
    this.sharePointMapper.addMapping('NumericalPriceIncClosingHigh', 'numericalPriceIncClosingHigh');
    this.sharePointMapper.addMapping('NumericalPriceIncContingent', 'numericalPriceIncContingent');
    this.sharePointMapper.addMapping('NumericalPriceIncTotalHigh', 'numericalPriceIncTotalHigh');
    this.sharePointMapper.addMapping('NumericalPriceIncClosingAvg', 'numericalPriceIncClosingAvg');
    this.sharePointMapper.addMapping('NumericalPriceIncTotalAvg', 'numericalPriceIncTotalAvg');
    this.sharePointMapper.addMapping('PercentagePriceIncClosingLow', 'percentagePriceIncClosingLow');
    this.sharePointMapper.addMapping('PercentagePriceIncClosingHigh', 'percentagePriceIncClosingHigh');
    this.sharePointMapper.addMapping('PercentagePriceIncContingent', 'percentagePriceIncContingent');
    this.sharePointMapper.addMapping('PercentagePriceIncTotalHigh', 'percentagePriceIncTotalHigh');
    this.sharePointMapper.addMapping('PercentagePriceIncClosingAvg', 'percentagePriceIncClosingAvg');
    this.sharePointMapper.addMapping('PercentagePriceIncTotalAvg', 'percentagePriceIncTotalAvg');
    this.sharePointMapper.addMapping('LoiClosedConsideration', 'loiClosedConsideration');
    this.sharePointMapper.addMapping('LoiClosedContingent', 'loiClosedContingent');
    this.sharePointMapper.addMapping('LoiTotal', 'loiTotal');
    this.sharePointMapper.addMapping('ValNumericalClosedConsideration', 'valNumericalClosedConsideration');
    this.sharePointMapper.addMapping('ValNumericalTotal', 'valNumericalTotal');
    this.sharePointMapper.addMapping('ValPercentageClosedConsideration', 'valPercentageClosedConsideration');
    this.sharePointMapper.addMapping('ValPercentageTotal', 'valPercentageTotal');
  }
}
