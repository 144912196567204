import { Injectable } from '@angular/core';
import { Spcrud } from './spcrud';
import { AppSettings } from '../app-settings';
import { FormStatus } from '../models/form-status';
import { MainServiceHelper } from './main-service-helper';
import { CurrentUser } from '../models/current-user-data';
// import { reject } from 'q';
import { BaseService } from './base.service';
import { RootSiteSpcrud } from './root-site-spcrud';
import { Observable, catchError, from, map, observable, of, share, throwError } from 'rxjs';
import { NewdealService } from 'src/app/services/newdeal.service';
import { SpinnerService } from './spinner.service';
import { AdminService } from 'src/app/services/admin.service';
import { NewDealSetupServiceHelper } from './new-deal-setup-service-helper';
import { AppContext } from 'src/app/models/app-context';
import { EngagementHealthObjectServiceService } from 'src/app/services/engagement-health-object-service.service';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { DealwbsService } from 'src/app/services/dealwbs.service';
import { environment } from 'src/environments/environment';
import { ClosingCheckListService } from 'src/app/services/closingchecklist.service';
@Injectable({
    providedIn: 'root'
})

export class MainService extends BaseService {

  formId: number;
  complianceID:number;
  myDealFormId: number;
  currentUser: CurrentUser;
  myDealS24List:any=[];
  myDealList:any=[];
  myDealTeamTableList:any=[];
  appConfigValueList:any=[];
  assignedMyDealIds: number[];

  dealTeamRoleList:any;
  dealTeamPursuitRoleList:any;
  dealTeamSwiftRoleList:any;
  closingchecklistadditionalcomments:any;
  closingchecklistdata:any;
  
  constructor( spcrud: Spcrud, rootSiteSpcrud: RootSiteSpcrud,
               private mainServiceHelper: MainServiceHelper,private newDealService:NewdealService,private spinnerService:SpinnerService,private adminService:AdminService,private EHOService:EngagementHealthObjectServiceService,private commonHelperService:CommonHelperService,
              private dealWbsService: DealwbsService,private CCLService:ClosingCheckListService) {
                super(spcrud, rootSiteSpcrud);
              }

  createCommentListItem(listName: string, sharepointObject: any): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    return spcrud.create(listName, sharepointObject)
      .then((response:any) => {
        return response;
      }).catch((error:any) => {
        //return reject(error);
      });
  }
  createCommentListItem_Observables(listName: string, sharepointObject: any): Observable<any> {
    let appendURL=this.CCLService.getAppendURL(listName);
    return from(this.CCLService.SaveCommentsDetails(appendURL,sharepointObject))
    .pipe(
      map((results) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error saving form data:', err);
      throw err;
    })
    ) 
  }
  getCurrentUserData(): Promise<any> {
    let userId=localStorage.getItem("userProfileId")?.toString();
    return this.newDealService.GetUserRoleGroups(userId).then( (response:any) => {
        this.currentUser = this.mainServiceHelper.createCurrentUserObject(response);
        return this.currentUser;
      }).catch((err:any) => {
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hide();
        console.log("Error" + err);
      });
  }
  getCurrentUserData_Observable(): Observable<any> {
  let userId=localStorage.getItem("userProfileId")?.toString();
  return this.dealWbsService.GetUserRoleGroups(userId).pipe( 
    map((response:any) => {
      this.currentUser = this.mainServiceHelper.createCurrentUserObject(response);
      return this.currentUser;
    }),
    catchError((err:any) => {
      if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
      this.spinnerService.hide();
      console.log("Error" + err);
      throw err;
    })
);
}
  getCurrentUserDataEHO():Observable<any>{
    let userId=localStorage.getItem("userProfileId")?.toString();
   return from(this.EHOService.GetUserRoleGroupsEHO(userId)).pipe(
      map((response: any) => {
        this.currentUser = this.mainServiceHelper.createCurrentUserObject(response);
        return this.currentUser; 
    }),
    catchError((err: any) => {
      console.log('Error fetching User data:', err);
      throw err; // Re-throw the error to propagate it through the Observable stream
    })
  );
  }

  getCurrentUserData_CCF(): Promise<any> {
    const spcrud = this.getSpcrud();
    return spcrud.getCurrentUser().then((response:any) => {
      this.currentUser = this.mainServiceHelper.createCurrentUserObject(response.d);
      return this.currentUser;
    }).catch((error: any) => {
    });
  }
  

  createListItemEHO(listName: string, sharepointObject: any): Observable<any> {   
    let appendURL=this.EHOService.getAppendURL(listName);
    return from(this.newDealService.SaveFormDetails(appendURL,sharepointObject)).pipe(
      map((results: any) => {
       
        return results;
      }),
      catchError((err: any) => {
        console.log('Error saving form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
    
  }
  createListItemsEHO(listName: string, sharepointObject: any): Observable<any> {   
    let appendURL=this.EHOService.getAppendURL(listName);
    return from(this.newDealService.SaveFormDetailsList(appendURL,sharepointObject)).pipe(
      map((results: any) => {
       
        return results;
      }),
      catchError((err: any) => {
        console.log('Error saving form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
    
  } 
  createListItem(listName: string, sharepointObject: any): Promise<any> {   
    let appendURL=this.newDealService.getAppendURL(listName);
    return new Promise((resolve, reject) => {
	  this.newDealService.SaveFormDetails(appendURL,sharepointObject)
    .then((results: any) => {
     resolve(results);
    })
    .catch((err: any) => {
	  reject(err);
    console.log('Error saving form data:', err);
    })
    });
  }

  createListItem_DealWbs(listName: string, sharepointObject: any): Observable<any> {   
    let appendURL=this.dealWbsService.getAppendURL(listName);
    return from(this.dealWbsService.SaveFormDetails(appendURL,sharepointObject))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error saving form data:', err);
      throw err;
    })
    ) 
  }

  createListItem_CCF(listName: string, sharepointObject: any): Observable<any> {
    let appendURL=this.CCLService.getAppendURL(listName);
    return from(this.CCLService.SaveFormDetails(appendURL,sharepointObject))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error saving form data:', err);
      throw err;
    })
    ) 
  }

  getDealTeamAuditListItem(listName: string, formId: number): Observable<any> {
      return from(this.EHOService.DealTeamAuditLog(listName, formId));
  }
  createEngagementListData(listName: string, sharepointObject: any): Promise<any> {   
    let appendURL=this.newDealService.getAppendURL(listName);
    return this.newDealService.SaveEngagementDetails(appendURL,sharepointObject)
    .then((results: any) => {
      return results;
    })
    .catch((err: any) => {
      console.log('Error saving form data:', err);
    });
    
  }


  createForm(listName: string, sharepointObject: any): Promise<any> {
    return this.createListItem(listName, sharepointObject)
    .then( (response:any) => {
      this.formId = response.ID;
      return response;
    }).catch((error:any) => {
    });
  }
  updateListItem_DealWBS(listName: string, itemId: number, sharepointObject: any): Observable<any> {
    let appendURL=this.dealWbsService.getAppendURL(listName);
    return from(this.dealWbsService.UpdateDealWBSFormDetails(appendURL,listName,sharepointObject)).pipe(
      map((results: any) => { 
        return results;
      }),
      catchError((err: any) => {
        console.log('Error Updating form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  }

  createForm_CCF(listName: string, sharepointObject: any): any {
     this.createListItem_CCF(listName, sharepointObject)
    .subscribe( response => {
      this.formId = response;
      return this.formId;
    });
    // .catch((error:any) => {
    //   console.log('Error creating form data:',error);
    // });
  }
  updateForm_CCF(listName: string, formId: number, sharepointObject: any,rangeKey:any,initiateWorkflow:boolean=false,sendCCLFormEmail:boolean=false,dealTeamUSerEmail:any=""): Observable<any> {      
    const userEmail = localStorage.getItem("userProfileId");
    let appendedURL=this.CCLService.getAppendURL(listName);
   const data =JSON.stringify({formId:formId.toString(),updateData:this.cleanJsonObject(sharepointObject),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendCCLFormEmail:sendCCLFormEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail}) 
    return from(this.CCLService.UpdateFormDetails_CCL(appendedURL,data,listName)).pipe(
      map((results: any) => { 
        return true;
      }),
      catchError((err: any) => {
        console.log('Error Updating form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  }
  updateListItem_CCFCompliance(listName: string, itemId: number, sharepointObject: any,rangeKey:any,initiateWorkflow:boolean=false,sendCCLFormEmail:boolean=false,dealTeamUSerEmail:any="",engagementId:any=""): Observable<any> {
    const userEmail = localStorage.getItem("userProfileId");
    let appendedURL=this.CCLService.getAppendURL(listName);
    const data =JSON.stringify({ID:itemId.toString(),formId:this.complianceID.toString(),updateData:this.cleanJsonObject(sharepointObject),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendCCLFormEmail:sendCCLFormEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail}) 
    return from(this.CCLService.UpdateFormDetails_CCL(appendedURL,data,listName)).pipe(
      map((results: any) => { 
        return true;
      }),
      catchError((err: any) => {
        console.log('Error Updating form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  }

  updateListItem(listName: string, itemId: number, sharepointObject: any): Promise<any> {
    let appendURL=this.newDealService.getAppendURL(listName);
    return this.newDealService.UpdateNewDealFormDetails(appendURL,itemId,sharepointObject)
    .then((results: any) => {
      return true;
    })
    .catch((err: any) => {
      console.log('Error Updating form data:', err);
    });
  }

  updateListItem_DealWbs(listName: string, itemId: number, sharepointObject: any): Observable<any> {
    let appendURL=this.dealWbsService.getAppendURL(listName);
    return from(this.dealWbsService.UpdateFormDetails_DealWbs(appendURL,sharepointObject,listName))
    .pipe(
      map((results: any) => {
      return true;
    })
    ,catchError((err: any) => {
      console.log('Error Updating form data:', err);
      throw err;
    })
    );
  }
  updateListItem_CCForm(listName: string, itemId: number, sharepointObject: any): Observable<any> {
    let appendURL=this.CCLService.getAppendURL(listName);
    return from(this.CCLService.UpdateFormDetails_CCForm(appendURL,sharepointObject,listName))
    .pipe(
      map((results: any) => {
      return true;
    })
    ,catchError((err: any) => {
      console.log('Error Updating form data:', err);
      throw err;
    })
    );
  }
  updateListItem_DDT_CCL(listName: string, itemId: number, sharepointObject: any,rangeKey:any,initiateWorkflow:boolean=false,sendCCLFormEmail:boolean=false,dealTeamUSerEmail:any="",engagementId:any=""): Observable<any> {
    let appendURL=this.CCLService.getAppendURL(listName);
    const userEmail = localStorage.getItem("userProfileId");
    let resObject={Name:sharepointObject.Name,DisplayName:sharepointObject.DisplayName,ModifiedBy:sharepointObject.ModifiedBy};
    const data =JSON.stringify({ID:itemId.toString(),formId:sharepointObject.FormID,updateData:this.cleanJsonObject(resObject),rangeKey:"",initiateWorkflow:initiateWorkflow,sendCCLFormEmail:sendCCLFormEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail})
    return from(this.CCLService.UpdateFormDetails_CCL(appendURL,data,listName))
    .pipe(
      map((results: any) => {
      return true;
    })
    ,catchError((err: any) => {
      console.log('Error Updating form data:', err);
      throw err;
    })
    );
  }

  updateListItem_CCF(listName: string, itemId: number, sharepointObject: any,rangeKey:any,initiateWorkflow:boolean=false,sendCCLFormEmail:boolean=false,dealTeamUSerEmail:any="",engagementId:any=""): Observable<any> {
    const userEmail = localStorage.getItem("userProfileId");
    let appendedURL=this.CCLService.getAppendURL(listName);
    const data =JSON.stringify({formId:itemId.toString(),updateData:this.cleanJsonObject(sharepointObject),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendCCLFormEmail:sendCCLFormEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail}) 
    return from(this.CCLService.UpdateFormDetails_CCL(appendedURL,data,listName)).pipe(
      map((results: any) => { 
        return true;
      }),
      catchError((err: any) => {
        console.log('Error Updating form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  }
  createListItem_DDT_CCL(listName: string, sharepointObject: any): Observable<any> {   
    let appendURL=this.CCLService.getAppendURL(listName);
    return from(this.CCLService.SaveFormDetailsDDT_CCL(appendURL,sharepointObject,listName))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error saving form data:', err);
      throw err;
    })
    ) 
  }
  updateForm_CCL(listName: string, formId: number, sharepointObject: any,rangeKey:any,initiateWorkflow:boolean=false,sendCCLEmail:boolean=false,dealTeamUSerEmail:any="",engagementId:any=""): Observable<any> {
    let appendURL=this.CCLService.getAppendURL(listName);
    const userEmail = localStorage.getItem("userProfileId");    
    const data =JSON.stringify({formId:formId.toString(),updateData:this.cleanJsonObject(sharepointObject),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendCCLFormEmail:sendCCLEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail,engagementId:engagementId}) 

    return from(this.CCLService.UpdateFormDetails_CCL(appendURL,data, listName))
    .pipe( map((results: any) => {
      return true;
    })
    ,
    catchError((err: any) => {
      console.log('Error Updating form data:', err);
      throw err;
    })
  );
  }


  updateForm_DealWbs(listName: string, formId: number, sharepointObject: any,rangeKey:any,initiateWorkflow:boolean=false,sendDealWbsEmail:boolean=false,dealTeamUSerEmail:any="",engagementId:any=""): Observable<any> {
    let appendURL=this.dealWbsService.getAppendURL(listName);
    const userEmail = localStorage.getItem("userProfileId");    
    const data =JSON.stringify({formId:formId.toString(),updateData:this.cleanJsonObject(sharepointObject),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendDealWbsEmail:sendDealWbsEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail,engagementId:engagementId}) 

    return from(this.dealWbsService.UpdateFormDetails_DealWbs(appendURL,data, listName))
    .pipe( map((results: any) => {
      return true;
    })
    ,
    catchError((err: any) => {
      console.log('Error Updating form data:', err);
      throw err;
    })
  );
  }
  

  updateDealWbsForm(listName: string, formId: any, sharepointObject: any,rangeKey:any,initiateWorkflow:boolean=false,sendNewDealEmail:boolean=false,dealTeamUSerEmail:any="",engagementId:any=""): Observable<any> {      
    const userEmail = localStorage.getItem("userProfileId");    
    const data =JSON.stringify({formId:formId.toString(),updateData:this.cleanJsonObject(sharepointObject),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendNewDealEmail:sendNewDealEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail,engagementId:engagementId}) 
    return from(this.dealWbsService.UpdateForm(listName, data)).pipe(
      map((results: any) => { 
        return results;
      }),
      catchError((err: any) => {
        console.log('Error Updating form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  }
  updateComplianceEHO(formId: number, sharepointObject: any,rangeKey:any) {      
    const userEmail = localStorage.getItem("userProfileId");
    const data =JSON.stringify({formId:formId.toString(),updateData:this.cleanJsonObject(sharepointObject),rangeKey:rangeKey,initiator:userEmail}) 
  this.EHOService.UpdateEHODetails(data).subscribe({
      next:(results: any) => { 
        return results;
      },
      error:(err: any) => {
        console.log('Error Updating form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      }
  });
  
  }
  updateFormEHO(listName: string, formId: number, sharepointObject: any,rangeKey:any,initiateWorkflow:boolean=false,sendNewDealEmail:boolean=false,dealTeamUSerEmail:any=""): Observable<any> {      
    const userEmail = localStorage.getItem("userProfileId");
    const data =JSON.stringify({formId:formId,updateData:this.cleanJsonObject(sharepointObject),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendNewDealEmail:sendNewDealEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail}) 
    return from(this.newDealService.UpdateForm(listName, data)).pipe(
      map((results: any) => { 
        return true;
      }),
      catchError((err: any) => {
        console.log('Error Updating form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  
  }
  updateForm(listName: string, formId: any, sharepointObject: any,rangeKey:any,initiateWorkflow:boolean=false,sendNewDealEmail:boolean=false,dealTeamUSerEmail:any="",engagementId:any=""): Promise<any> {      
    const userEmail = localStorage.getItem("userProfileId");    
    const data =JSON.stringify({formId:formId,updateData:this.cleanJsonObject(sharepointObject),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,sendNewDealEmail:sendNewDealEmail,dealTeamUserEmail:dealTeamUSerEmail,initiator:userEmail,engagementId:engagementId}) 
    return new Promise((resolve, reject) => {
      this.newDealService.UpdateForm(listName, data)
        .then((response:any) => {
            resolve(true);
    }).catch((error:any) => {
           reject(error);
    })});
  }

  cleanJsonObject(obj:any) {
    for (const key in obj) {
      if (obj[key] === null||obj[key] === undefined) {
        obj[key] = '';
      } else if (typeof obj[key] === 'object') {
        this.cleanJsonObject(obj[key]);
      }
    }
    return obj;
  }
  deleteListItemEHO(listName: string, formId:string,itemId: string): Observable<any> {   
    let appendURL=this.EHOService.getAppendURL(listName);
    let requestObject = JSON.stringify({formId:formId,itemId:itemId});
    return from(this.newDealService.DeleteListItem(appendURL,requestObject)).pipe(
      map((results: any) => {
       
        return results;
      }),
      catchError((err: any) => {
        console.log('Error fetching documents data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
     
  }
  deleteListItem(listName: string, formId:string,itemId: string): Promise<any> {   
    let appendURL=this.newDealService.getAppendURL(listName);
    let requestObject = JSON.stringify({formId:formId,itemId:itemId});
    return new Promise((resolve, reject) => {
      this.newDealService.DeleteListItem(appendURL,requestObject)
    .then((results: any) => {
       resolve(results);
    })
    .catch((err: any) => {
      console.log('Error deleting list data:', err);
      reject(err);
    })});
  }

  deleteListItem_CCLCompliance(listName: string,formid:any, itemId: number): Observable<any> {
    let appendURL=this.CCLService.getAppendURL(listName);
    // let requestObject = JSON.stringify({formId:itemId});
    return from(this.CCLService.DeleteListItemCompliance(appendURL,formid,itemId))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error deleting list data:', err);
      throw err;
    })
  )  
  }

  deleteListItem_DealWbs(listName: string, formId:string,itemId: string): Observable<any> {   
    let appendURL=this.dealWbsService.getAppendURL(listName);
    let requestObject = JSON.stringify({formId:formId,itemId:itemId});
    return from(this.dealWbsService.DeleteListItem(appendURL,requestObject))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error deleting list data:', err);
      throw err;
    })
  )  
  }
  deleteListItem_DDT_CCL(listName: string, formId:string,itemId: string): Observable<any> {   
    let appendURL=this.CCLService.getAppendURL(listName);
    return from(this.CCLService.DeleteListItemCompliance(appendURL,formId,itemId))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error deleting list data:', err);
      throw err;
    })
  )  
  }
  

  deleteListItem_CCL(listName: string, itemId: number): Observable<any> {
    let appendURL=this.CCLService.getAppendURL(listName);
    let requestObject = JSON.stringify({formId:itemId});
    return from(this.CCLService.DeleteListItem(appendURL,requestObject))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error deleting list data:', err);
      throw err;
    })
  )  
  }
  
  deleteReviewItem_CCL(listName: string,formID:number,itemId: any,): Observable<any> {
    let appendURL=this.CCLService.getAppendURL(listName);
    let requestObject = JSON.stringify({FormID:formID.toString(),ID:itemId.toString()});
    return from(this.CCLService.DeleteListItem(appendURL,requestObject))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error deleting list data:', err);
      throw err;
    })
  )  
  }

  retrieveForm(listName: string, formId: number): Promise<any> | any{
    if (formId) {
      return this.getListItem(listName, formId).then((response:any) => {
      this.formId = response.ID;
      this.myDealFormId = response[AppSettings.myDealSiteColumn];
      return response;
      });
    }
    return null;
  }

  retrieveForm_CCF(listName: string, formId: number): Observable<any> {
    return from(this.CCLService.getDynamoTableList(formId,listName,environment.ClosingChecklistForm))
    .pipe(
      map((response: any) => {
        this.formId = response.ID==undefined?response[0].ID:response.ID;
        this.myDealFormId=response.MyDealID==undefined?response[0].MyDealID:response.MyDealID;
      return response;
    })
    ,catchError((err: any) => {
      console.log('Error fetching data:', err);
      throw err;
    })
    )
  }

  getListItem(listName: string, formId: number): Promise<any> | any {
    const spcrud = this.getSpcrud(listName);
    const options = {filter: 'Id eq ' + formId};
    return spcrud.read(listName, options)
    .then( (response:any) => {
      return response.d.results[0];
    }).catch((error:any) => {
    });
  }
  getListItem_DealWBS(listName: string, formId: number): Observable<any> {
    let appendedURL=this.dealWbsService.getAppendURL(listName);
    return from(this.dealWbsService.GetDealWbsListDetails(formId,listName,appendedURL)).pipe(
      map((results: any) => {
        return results;
      }),
      catchError((err: any) => {
        console.log('Error fetching Get data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  }

  getListItems(listName: string): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    return spcrud.read(listName)
    .then( (response:any) => {
      return response.d.results;
    }).catch((error:any) => {
    });
  }
  

  getFormStatus(formId: number,formStatus:string,formActionGroup:string): FormStatus {
    const formStatusObj = new FormStatus();
    if (formId) {
      formStatusObj.formStatus = formStatus;
      formStatusObj.formActionGroup = formActionGroup;
      formStatusObj.validateStatus();
    }
    else{
      formStatusObj.formStatus = AppSettings.initialFormStatus;
      formStatusObj.formActionGroup =  AppSettings.initialFormActionGroup;
      formStatusObj.validateStatus();
    }
    return formStatusObj;
  }
  getFormStatus_DealWBS(listName: string, formId: number):Observable<any> {
      const formStatus:any = new FormStatus();
      if (formId) {
        return from(this.dealWbsService.GetDealWbsDetails(formId,listName)).pipe(
          map((results: any) => {
            formStatus.formStatus = results[0].FormStatus;
            formStatus.formActionGroup = results[0].FormActionGroup;
            formStatus.validateStatus();
            return formStatus;
          }),
          catchError((err: any) => {
            console.log('Error fetching Get data:', err);
            throw err; // Re-throw the error to propagate it through the Observable stream
          })
        );
      } else {
          formStatus.formStatus = AppSettings.initialFormStatus;
          formStatus.formActionGroup =  AppSettings.initialFormActionGroup;
         return from(formStatus);
      }
  
  }
  getFormStatus_DealWBS_Bck(listName: string, formId: number):Promise<any> {
    const spcrud = this.getSpcrud(listName);
    return new Promise<any>((resolve, reject) => {
      const formStatus = new FormStatus();
      if (formId) {
          const options = {filter: 'Id eq ' + formId, select: 'FormStatus, FormActionGroup'};
          spcrud.read(listName, options)
          .then( (response:any) => {
            formStatus.formStatus = response.d.results[0].FormStatus;
            formStatus.formActionGroup = response.d.results[0].FormActionGroup;
            formStatus.validateStatus();
            resolve(formStatus);
          }).catch((error:any) => {
            reject(error);
          });
      } else {
          formStatus.formStatus = AppSettings.initialFormStatus;
          formStatus.formActionGroup =  AppSettings.initialFormActionGroup;
          resolve(formStatus);
      }
    });
  }

  getFormStatus_Closing_Checklist(listName: string, formId: number): Observable<any> {
    const formStatus:any = new FormStatus();
    if (formId) {
      return from(this.CCLService.GetClosingCheckListFormDetails(formId,listName)).pipe(
        map((results: any) => {
          formStatus.formStatus = results[0].FormStatus;
          formStatus.formActionGroup = results[0].FormActionGroup;
          return formStatus;
        }),
        catchError((err: any) => {
          console.log('Error fetching Get data:', err);
          throw err; // Re-throw the error to propagate it through the Observable stream
        })
      );
    } else {
        formStatus.formStatus = AppSettings.initialFormStatus;
        formStatus.formActionGroup =  AppSettings.initialFormActionGroup;
       return from(formStatus);
    }
  }
  getFormStatus_Bck(listName: string, formId: number): Promise<FormStatus> {
    return new Promise<FormStatus>((resolve, reject) => {
      const formStatus = new FormStatus();
      if (formId) {
         this.newDealService.GetFormStatusBck(formId).then( (response:any) => {
            formStatus.formStatus = response[0]?.FormStatus;
            formStatus.formActionGroup = response[0]?.FormActionGroup;
            formStatus.validateStatus();
            resolve(formStatus);
          }).catch((error:any) => {
            reject(error);
          });
      } else {
          formStatus.formStatus = AppSettings.initialFormStatus;
          formStatus.formActionGroup =  AppSettings.initialFormActionGroup;
          formStatus.validateStatus();
          resolve(formStatus);
      }
    });
  }

  getFormDealTeamMembers(listName: string, formId: number): Promise<Array<number>> {
    const spcrud = this.getSpcrud(listName);
    const optionsDealTeamTable = {
      filter: 'Form/Id eq ' + formId,
      select: 'Id, Name/Id',
      expand: 'Name'};
    return spcrud.read(listName, optionsDealTeamTable)
    .then( (response:any) => {
      const results = response.d.results;
      const dealTeamMembers = results.map((item:any) => {
        return item.Name.Id;
      });
      return dealTeamMembers;
    }).catch((error:any) => {
    });
  }
  getAuditLogDealTeamMemebersEHO(listName: string, formId: number): Observable<Array<any>> {
    return from(this.EHOService.getDealTeamAuditLogData(listName, formId));
  }
  getAuditLogDealTeamMemebers(listName: string, formId: number): Promise<Array<any>> {
    return this.newDealService.getDealTeamAuditLogData(listName,formId)
    .then((results: any) => {
      return results;
    }).catch((error:any) => {
    });
  }

  getAuditLogDealTeamMemebers_DealWbs(listName: string, formId: number): Observable<any> {
    return from(this.dealWbsService.getDealTeamAuditLogData(listName,formId))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error fetching documents data:', err);
      throw err;
    })
    )
  }

  getAuditLogDealTeamMemebers_CCL(listName: string, formId: number): Observable<any> {
    return from(this.CCLService.getDealTeamAuditLogData(listName,formId))
    .pipe(
      map((results: any) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error fetching documents data:', err);
      throw err;
    })
    )
  }

  isDealTeamMember(listName: string, formId: number, userId: number): Promise<any> {
    const spcrud = this.getSpcrud(listName);
    const options = {
      filter: 'Form/Id eq ' + formId + 'and Name/Id eq ' + userId
     };
    return spcrud.read(listName, options)
    .then( (response:any) => {
      if (response.d.results.length > 0) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error:any) => {
    });
  }

  isDealTeamMember_DealWbs(formId:any, userId: any):Observable<any>{
    return from(this.dealWbsService.getDealTeamTableList(formId)).pipe(
      map((res:any)=>{
        this.myDealTeamTableList=res;
      return res.find((user:any)=>{
        if(user && userId&&user.Email)
          return user.Email.toLowerCase()==userId.toLowerCase()
      return false;
      }
      );}),
    catchError((err: any) => {
      console.log('Error fetching documents data:', err);
      throw err; // Re-throw the error to propagate it through the Observable stream
    })
    )
  }

  getValidationsListItems(): any {
    this.newDealService.getAsteriskList().subscribe({
      next: (result: any) => {
       return result;
        
      },
      error: (err: any) => {
        console.log("Error" + err)
      }
    })
  }

  getValidationsListItems_CCF(): Promise<any> {
    const listName = AppSettings.validationsList;
    const spcrud = this.getSpcrud(listName);
    const options = {
        top: 500,
        select: 'Id, FieldName, Validator, Value'
     };
    return spcrud.read(listName, options)
    .then( (response : any) => {
        return response.d.results;
    });
  }

  getGroupUsers(groupName: string): Promise<any> {
    const spcrud = this.getSpcrud();
    return spcrud.getGroupUsers(groupName)
    .then( (response:any) => {
      return response.d.results;
    }).catch((error:any) => {
    });
  }

  reactivateForm(formId:any,rangeKey:any): Promise<any> {
    let currentUserName = localStorage.getItem("AuditorFullName");
    if(currentUserName==null||currentUserName==undefined) currentUserName="";
    const objectToSend = this.mainServiceHelper.createReactivateFormObject(currentUserName);      
    return this.updateForm(AppSettings.formListName,formId,objectToSend,rangeKey,true);    
  }

  reactivateForm_DealWbs(formId:any,rangeKey:any): Observable<any> {
    let currentUserName = localStorage.getItem("AuditorFullName");
    if(currentUserName==null||currentUserName==undefined) currentUserName="";
    const objectToSend = this.mainServiceHelper.createReactivateFormObject(currentUserName);      
    return this.updateForm_DealWbs(AppSettings.dealWbsSetupFormList,formId,objectToSend,rangeKey,true);    
  }

  reactivateForm_Closing_Checklist(formId:any,rangeKey:any): Observable<any> {
    let currentUserName = localStorage.getItem("AuditorFullName");
    if(currentUserName==null||currentUserName==undefined) currentUserName="";
    const objectToSend = this.mainServiceHelper.createReactivateFormObject(currentUserName);      
    return this.updateForm_CCL(AppSettings.closingChecklistFormList,formId,objectToSend,rangeKey,true);    
  }

  //This promise method needs to be removed. Being referenced in newdeal form.
  getDocumentTypes(): Promise<any> {
    return new Promise((resolve, reject) => {
      const appendURL = this.newDealService.getAppendURL(AppSettings.documentTypesList);
  
      // Use the promise-based GetDropDownData method
      this.newDealService.GetDropDownData(appendURL)
        .then((results: any) => {
          const documentTypeList = results.map((item: any) => {
            const documentTypeItem = this.mainServiceHelper.createDocumentTypeObject(
              item.DocumentTypeId, item.ID, item.DocumentTypeTitle, item.Required, false);
            return documentTypeItem;
          });
          resolve(documentTypeList);
        })
        .catch((err: any) => {
          console.log('Error fetching documents data:', err);
          reject(err);
        });
    });
  }
  
  getDocumentTypesObservables(formType:any): Observable<any> {
    let appendURL ='';
    let lambdaUrl = '';
    if(formType == AppSettings.FormTypes.NewDealSetupForm)
    {
      lambdaUrl = environment.Newdealform;
      appendURL = this.newDealService.getAppendURL(AppSettings.newdealDocumentTypesList);
    }
    else
    {
      lambdaUrl = environment.DealWBSForm;
      appendURL = this.newDealService.getAppendURL(AppSettings.dealWbsDocumentTypesList);
    }
    return from(this.dealWbsService.GetDropDownData(appendURL,"DealWbs")).pipe(
      map((results: any) => {
        const documentTypeList = results.map((item: any) => {
          const documentTypeItem = this.mainServiceHelper.createDocumentTypeObject(
            item.DocumentTypeId, item.ID, item.DocumentTypeTitle, item.Required, false);
          return documentTypeItem;
        });
        return documentTypeList;
      }),
      catchError((err: any) => {
        console.log('Error fetching documents data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
}

  getFormAdditionalDocumentTypes(formId: number): Observable<any> 
  {
    const appendURL = this.newDealService.getAppendURL(AppSettings.additionalDocumentTypesList);
  return from(this.newDealService.GetDropDownData(appendURL,formId)).pipe(
    map((results: any) => {
      const documentTypeList = results.map((item: any) => {
        const documentTypeItem = this.mainServiceHelper.createDocumentTypeObject(
          item.DocumentTypeId, item.ID, item.DocumentType, item.Required, false
        );
        return documentTypeItem;
      });
      return documentTypeList;
    }),
    catchError((err: any) => {
      console.log('Error fetching documents data:', err);
      throw err; // Re-throw the error to propagate it through the Observable stream
    })
  );

  }

  //This promise method needs to be removed.Being referenced for newdeal form
  getAppConfigurationValue(formType:any): Promise<any> {
    let keys=["AutoSaveIntervalInMilliseconds","EnableAutoSave","ExcludedFromAdminRotationCycle","AutoSaveSpinningWheelInterval","MaxLengthComments"];
    
      return this.newDealService.GetAppConfigurationDetails(formType).toPromise().then((data: any) => {
        this.appConfigValueList = data;
        this.spinnerService.hide();
        return this.getAppConfigValues(keys);
      }).catch((err:any) => {
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hide();
        console.log("Error" + err);
      });
    
  }

  getAppConfigurationValueObservable(formType:any): Observable<any> {
    let keys=["AutoSaveIntervalInMilliseconds","EnableAutoSave","ExcludedFromAdminRotationCycle","AutoSaveSpinningWheelInterval"];
    
    return this.newDealService.GetAppConfigurationDetails(formType).pipe(
      map((response: any) => { 
        this.appConfigValueList = response; 
        return this.getAppConfigValues(keys);
      }),
      catchError((err: any) => {
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hide();
        console.log("Error" + err);
        throw err;
      })
    );
  }

  getAppConfigurationValue_DealWbs(key:any): Promise<any> {

    let keys=["AutoSaveIntervalInMilliseconds","EnableAutoSave","ExcludedFromAdminRotationCycle","AutoSaveSpinningWheelInterval"];
    
      return this.dealWbsService.GetAppConfigurationDetails().then((data: any) => {
        this.appConfigValueList = data;
        return this.getAppConfigValues(keys);
      }).catch((err:any) => {
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hide();
        console.log("Error" + err);
      });
  }

  getAppConfigValues(keys:any):AppContext{
    let autoSaveSpinningWheelInterval=0;let autoSaveIntervalInMilliseconds=0;let enableAutoSave=false;let excludedFromAdminRotationCycle ="";let maxLengthComments=0;
    this.appConfigValueList.forEach((x:any)=>{
      if(keys[0]==x.Title){ autoSaveIntervalInMilliseconds = isNaN(parseFloat(x.Value))?AppSettings.autoSaveIntervalDefault:parseFloat(x.Value)}
      if(keys[1]==x.Title){ enableAutoSave =  this.ConvertStringToBoolean(x.Value)}
      if(keys[2]==x.Title){ excludedFromAdminRotationCycle =x.Value}
      if(keys[3]==x.Title){ autoSaveSpinningWheelInterval = isNaN(parseFloat(x.Value))?AppSettings.autoSaveSpinningWheelIntervalDefault:parseFloat(x.Value)}
      if(keys[4]==x.Title){ maxLengthComments = isNaN(parseFloat(x.Value))?AppSettings.maxLengthComments:parseFloat(x.Value)}
      })
      
     return this.createAppContext(autoSaveIntervalInMilliseconds,enableAutoSave,excludedFromAdminRotationCycle,autoSaveSpinningWheelInterval,maxLengthComments);
  }
  ConvertStringToBoolean(data: any) {
    switch (data) {
      case "true":
      case "TRUE":
        return true;
        break;
      default:
        return false;
        break;

    }
  }
  createAppContext(autoSaveInterval: number, autoSaveEnabled: boolean, excludedFromAdminRotationCycle: string,autoSaveSpinningWheelInterval:number,maxLengthComments:number): AppContext {
    const appContext: AppContext = {
      autoSaveInterval,
      autoSaveEnabled,
      excludedFromAdminRotationCycle,
      autoSaveSpinningWheelInterval,
      maxLengthComments
    };
    return appContext;
  }
  getcommentsListItem(listName: string, formId: number): Observable<any> {   
    return this.newDealService.getCommentsData(listName, formId).pipe(
      map((response: any) => {      
        return response;
      }),
      catchError((error: any) => {
        return throwError(error); // You can also use throwError here
      })
    );
  

  }
  updateRotationAssignment(listName: string, adminName: string, sharepointObject: any): Promise<any> {          
    return this.newDealService.updateGroupData(listName,adminName, sharepointObject)
        .then((response:any) => {
            return true;
    }).catch((error:any) => {
      //reject(error);
    });
  }
  getAssignedMyDeals(currentUserEmail: any): Observable<any> {
    return this.newDealService.getDealTeamTableList(null,currentUserEmail)
  }

  getDealTeamUserReviews(listName:string,formId:number):Promise<any>{
    const spcrud = this.getSpcrud(listName);
    const options = {filter: "Form/Id eq " + formId, select: "DealTeamUser/Title,DealTeamUser/Id,Status/SignOffStatus,*", expand: "DealTeamUser,Status/SignOffStatus"};
    return spcrud.read(listName, options)
      .then( (response:any) => {
        return response.d.results;
  
      }).catch((error:any) => {
      });
  }
  getDealTeamUserReviewsCCL(listName: string, formId: any): Observable<any> {
    return from(this.CCLService.GetClosingChecklistDealTeamSignOff(listName,formId))
    .pipe(
      map((results) => {
      return results;
    })
    ,catchError((err: any) => {
      console.log('Error saving form data:', err);
      throw err;
    })
    ) 
  }


  getDealTeamStatusItem(status:any):Observable<any> {
    let appendedURL=this.CCLService.getAppendURL(AppSettings.closingChecklistSignOffStatus);
    return from(this.CCLService.GetSignOffByStatus(status)).pipe(
      map((res:any)=>{
      return res;
      }),
    catchError((err: any) => {
      console.log('Error fetching  data:', err);
      throw err; // Re-throw the error to propagate it through the Observable stream
    })
    )
   
}

removeDealTeamReviews(reviews:any):Observable<any>{
  var countReviews = reviews.length;
  if (countReviews > 0) {
      for (var review in reviews) {
          var currentReview = reviews[review];
          var reviewId = currentReview.ID;
          this.deleteReviewItem_CCL(AppSettings.closingChecklistDealTeamSignOff,this.formId,reviewId).subscribe(()=> {
              countReviews--;
              if (countReviews == 0) {
                  return of(true);
              }
              return of(false); // Added line to remove error
          })
      }
      return of(false); // Added line to remove error
  } else {
      return of(false);
      //return of();
  }
  
}

deleteReviewsAfterReturnForRework(formId: any): Observable<any> {
  return this.getDealTeamUserReviewsCCL(AppSettings.closingChecklistDealTeamSignOff, formId).pipe(
    map(dealTeamReviewData => {
      return this.removeDealTeamReviews(dealTeamReviewData);
    }),
    catchError(error => {
     
      return of(false); 
    })
  );}

getDTTL(listName: string, formId: number): Promise<Array<number>> {
  const spcrud = this.getSpcrud(listName);
  const optionsDTTLTable = {
    filter: 'Form/Id eq ' + formId,
    select: 'Id, DTTLInvolved',
  };
  return spcrud.read(listName, optionsDTTLTable)
  .then( (response:any) => {
    const results = response.d.results;
    const dealTeamMembers = results.map((item:any) => {
      return item.Name.Id;
    });
    return dealTeamMembers;
  }).catch((error:any) => {
  });
}
getDTTL_Compliance(listName: string, formId: number): Promise<Array<number>> {
  const spcrud = this.getSpcrud(listName);
  const optionsDTTLTable = {
    filter: 'Form/Id eq ' + formId,
    select: 'Id, DTTLInvolved',
  };
  return spcrud.read(listName, optionsDTTLTable)
  .then( (response:any) => {
    const results = response.d.results;
    const dealTeamMembers = results.map((item:any) => {
      return item.Name.Id;
    });
    return dealTeamMembers;
  }).catch((error:any) => {
  });
}
  
}
