<div class="modal " tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog " role="document">
    <div *ngIf="(!showDynamicTable) && TableName!='CrossBorderDocumentTypes'" class="modal-content custom-content " id="add-content-popup" style="margin-top: 11%;">
      <div class="">
        <button type="button" class="close close-size" (click)="closePopup()" data-dismiss="modal"
          aria-label="Close"><span aria-hidden="true">&times;</span>
        </button>
        <div class="header">
          <div class="headerManagement">{{TableName}}</div> 
        </div>
        <br>
        <div [ngSwitch]="TableName">          
          <div *ngSwitchCase="'CrossBorderCountries'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <p class="col-sm-2 col-form-label attribute-bold" for="Title" >Title <span style="color:red">*</span></p>
                <!-- <div class="col-sm-10"> -->
                  <input type="text" style="margin-top: 0%;"   class="form-control control-width" id="Title" name="Title" placeholder="Enter Country"
                    [(ngModel)]="contentManagementData.Title" [disabled]="isDisabled">
                <!-- </div> -->
              </div>
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>
          <div *ngSwitchCase="'AppConfigurations'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row div-margin">
                <p class="col-sm-2 col-form-label attribute-bold" for="Title" >Title <span style="color:red">*</span></p>
                <!-- <div class="col-sm-10"> -->
                  <input type="text" style="margin-top: 0%;"   class="form-control control-width" id="Title" name="Title" placeholder="Enter Title"
                    [(ngModel)]="contentManagementData.Title" [disabled]="isDisabled">
                <!-- </div> -->
              </div>
              <div class="form-group row div-margin">
                <p class="col-sm-2 col-form-label attribute-bold" for="Value" >Value<span *ngIf="isTextControl" style="color:red">*</span></p>
                <div *ngIf="isTextControl"> 
                  <input type="text" style="margin-top: 0%;"   class="form-control control-width" id="Value" name="Value" placeholder="Enter Value"
                    [(ngModel)]="contentManagementData.Value" >
                 </div>
                  <div *ngIf="!isTextControl" class="form-group row check-box-margin">
                <div class="form-check check-left">
                  <input type="checkbox" class="form-check-input" id="Required" name="Required"
                    [(ngModel)]="contentManagementData.Value" unchecked>
                  <label class="col-sm-2 col-form-label" for="Required" class="form-check-label">Enable</label>
                </div>
              </div>
              </div>
              
             
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>

          <div *ngSwitchCase="'CrossBorderIndustries'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <p class="col-sm-2 col-form-label attribute-bolds width" for="IndustryTitle" >Industry Title: <span style="color:red">*</span></p>
                <!-- <div class="col-sm-10"> -->
                  <input type="text" class="form-control margin-left control-width" id="IndustryTitle" name="IndustryTitle" 
                    placeholder="Enter Industry Title" [(ngModel)]="contentManagementData.IndustryTitle" [disabled]="isDisabled">
                <!-- </div> -->
              </div>
              <div class="form-group row">
                <!-- <label class="col-sm-2 col-form-label" for="Representative">Representative:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="Representative" name="Representative"
                    placeholder="Enter Representative" [(ngModel)]="contentManagementData.Representative">
                </div> -->
        
                <p class="col-sm-2 col-form-label attribute-bolds width" for="Representative" >Representative: <span style="color:red">*</span></p>
                <div>
                    <select id="ddlRepresentative" 
                    [(ngModel)]="contentManagementData.Representative" name="Representative"
                     class="form-select form-control representativewidth">
                        <option value="" selected disabled>Select Representative</option>
                        <option *ngFor="let title of RepresenatativeList" [value]="title.Name">
              {{title.Name}}
            </option>
                      </select>
                    </div>
                
              </div>
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>

          <div *ngSwitchCase="'CrossBorderTemplates'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row div-margin">
                <p class="col-sm-2 col-form-label file-upload-width" for="DocumentName" >Upload Document: <span style="color:red">*</span></p>
                <!-- <div class="col-sm-10"> -->
                  <div class="row">
                    <div class="col-xs-5"> 
                  <input type="text" style="width: 87%;margin-top: -1%;" class="form-control margin-left-upload control-width input-control-margin" id="Name" name="Name" placeholder="Upload Document"
                    [(ngModel)]="contentManagementData.Name" [disabled]="true">
                     <!-- <span id="uploadInfoID">
                      <img style="width: 5%;
                      margin-left: 104%;
                      margin-top: -19%;" src="/assets/img/infoicon.png" /></span> 
                       <div class="hide">Replace existing document with new will delete old record</div>-->
                       </div> 
                    <div class="col-xs-5 upload-margin" >  
                       <input type="file" #fileInput
                       id="file" name="file" style="color: rgba(0, 0, 0, 0);width: 32%;"
                    (change)="handleFileInput($event.target)" onclick="this.value = null">
                  </div>
                  </div>
                <!-- </div> -->
              </div>
              <div class="form-group row div-margin">
                <p class="col-sm-2 col-form-label file-upload-width" for="Title" >Document Title:<span style="color:red">*</span>  </p>
                <!-- <div class="col-sm-10"> -->
                  <input type="text" class="form-control margin-left control-width input-control-margin" id="Title" name="Title" placeholder="Enter Title"
                    [(ngModel)]="contentManagementData.Title">
                <!-- </div> -->
              </div>
              <div class="form-group row div-margin">
                 <p class="col-sm-2 col-form-label file-upload-width" for="DocumentType" >Document Type Name: <span style="color:red">*</span> </p>
                <!-- <div class="col-sm-10">
                  <input type="text" class="form-control" id="DocumentType" name="DocumentType"
                    placeholder="Enter Document Type" [(ngModel)]="contentManagementData.DocumentType">
                </div> -->
                <div>
                  <select id="ddlDocumentType"
                  [(ngModel)]="contentManagementData.DocumentType" name="DocumentType"
                   class="form-select form-control representativewidth input-control-margin">
                      <option value="" selected disabled>Select DocumentType</option>
                      <option *ngFor="let title of DocTypeList" [value]="title.DocumentTypeTitle">
            {{title.DocumentTypeTitle}}
          </option>
                    </select>
                  </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>

          <div *ngSwitchCase="'ValidationFieldsCrossBorder'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row div-margin">
                <p class="col-sm-2 col-form-label attribute-bolds width" for="Title" >Title: <span style="color:red">*</span></p>
                <!-- <div class="col-sm-10"> -->
                  <input type="text" style="margin-top:-1%" class="form-control control-width" id="Title" name="Title" placeholder="Enter Title"
                    [(ngModel)]="contentManagementData.Title" [disabled]="isDisabled">
                <!-- </div> -->
              </div>
              
              <div class="form-group row div-margin" >
                <p class="col-sm-2 col-form-label attribute-bolds width" for="RequiredFields" >Required Fields: <span style="color:red">*</span></p>
                <!-- <div class="col-sm-10"> -->
                  <!-- <input type="text" class="form-control" id="RequiredFields" name="RequiredFields"
                    placeholder="Enter Required Fields" [(ngModel)]="contentManagementData.RequiredFields"> -->
                    <textarea class="form-control resizeTextArea control-width input-control-margin" id="RequiredFields" name="RequiredFields" placeholder="Enter Required Fields"
                    [(ngModel)]="contentManagementData.RequiredFields"></textarea>
                <!-- </div> -->
              </div>
              <div class="form-group row div-margin">
                <p class="col-sm-2 col-form-label attribute-bolds width" for="RequiredGroupFields" >Required Group Fields: <span style="color:red">*</span></p>
                <!-- <div class="col-sm-10"> -->
                  <input type="text" style="margin-top: -1%;" class="form-control control-width" id="RequiredGroupFields" name="RequiredGroupFields"
                    placeholder="Enter Required Group Fields" [(ngModel)]="contentManagementData.RequiredGroupFields">
                <!-- </div> -->
              </div>
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>

          <div *ngSwitchCase="['FormType','FormGroups'].includes(TableName) ? TableName : !TableName">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <p class="col-sm-2 col-form-label attribute-bold" for="IndustryTitle" >Name: <span style="color:red">*</span></p>
                <!-- <div class="col-sm-10"> -->
                  <input type="text" style="margin-top: 0%;" class="form-control control-width" id="Name" name="Name" placeholder="Enter Name"
                    [(ngModel)]="contentManagementData.Name">
                <!-- </div> -->
              </div>
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>

          <div *ngSwitchCase="'CrossBorderAdditionalDocumentTypes'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="FormID">Form Id:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="FormID" name="FormID"
                    placeholder="Enter Form Id" [(ngModel)]="contentManagementData.FormID">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="DocumentType">Document Type:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="DocumentType" name="DocumentType"
                    placeholder="Enter Document Type" [(ngModel)]="contentManagementData.DocumentType">
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>

          <div *ngSwitchCase="'CrossBorderApprovalReviewStatus'"  class="divID">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="DocumentType">Form Id:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="FormID" name="FormID"
                    placeholder="Enter Form Id" [(ngModel)]="contentManagementData.FormID" disabled="true">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="DocumentType">Document Type:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="DocumentType" name="DocumentType"
                    placeholder="Enter Document Type" [(ngModel)]="contentManagementData.DocumentType" disabled="true">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="BusinessReviewer">Business Reviewer:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="BusinessReviewer" name="BusinessReviewer"
                    placeholder="Enter Business Reviewer" [(ngModel)]="contentManagementData.BusinessReviewer" disabled="true">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="BusinessReviewStatus">Business Review Status:</label>
                <div class="col-sm-10">
                  <select id="BusinessReviewStatus" name="BusinessReviewStatus" class="form-control" 
                  placeholder="Enter Business Review Status" [(ngModel)]="contentManagementData.BusinessReviewStatus">                  
                    <option value="N/A">N/A</option>
                    <option value="Reviewed">Reviewed</option>                    
                    <option value="Pending">Pending</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="QRMReviewer">QRM Reviewer:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="QRMReviewer" name="QRMReviewer"
                    placeholder="Enter QRM Reviewer" [(ngModel)]="contentManagementData.QRMReviewer" disabled="true">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="QRMReviewStatus">QRM Review Status:</label>
                <div class="col-sm-10">
                  <select id="QRMReviewStatus" name="QRMReviewStatus" class="form-control" 
                  placeholder="Enter QRM Review Status" [(ngModel)]="contentManagementData.QRMReviewStatus">
                  <option value="N/A">N/A</option>
                    <option value="Reviewed">Reviewed</option>                    
                    <option value="Pending">Pending</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="ComplianceReviewer">Compliance Reviewer:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="ComplianceReviewer" name="ComplianceReviewer"
                    placeholder="Enter Compliance Reviewer" [(ngModel)]="contentManagementData.ComplianceReviewer" disabled="true">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="ComplianceReviewStatus">Compliance Review Status:</label>
                <div class="col-sm-10">
                  <select id="ComplianceReviewStatus" name="ComplianceReviewStatus" class="form-control" 
                  placeholder="Enter Compliance Review Status" [(ngModel)]="contentManagementData.ComplianceReviewStatus">                  
                  <option value="N/A">N/A</option>
                    <option value="Reviewed">Reviewed</option>                    
                    <option value="Pending">Pending</option>
                  </select>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>

          <div *ngSwitchCase="'CrossBorderAttachments'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="DocumentType">Form Id:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="FormID" name="FormID"
                    placeholder="Enter Form Id" [(ngModel)]="contentManagementData.FormID">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="DocumentType">Document Type:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="DocumentType" name="DocumentType"
                    placeholder="Enter Document Type" [(ngModel)]="contentManagementData.DocumentType">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="Title">Document Title:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="Title" name="Title" placeholder="Enter Document Title"
                    [(ngModel)]="contentManagementData.Title">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="Name">Document  Name:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="Name" name="Name" placeholder="Enter Document Name"
                    [(ngModel)]="contentManagementData.Name">
                </div>
              </div>

              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>

          <div *ngSwitchCase="'CrossBorderComments'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="FormID">Form Id:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="FormID" name="FormID"
                    placeholder="Enter Form Id" [(ngModel)]="contentManagementData.FormID">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="DocumentType">Document Type:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="DocumentType" name="DocumentType"
                    placeholder="Enter Document Type" [(ngModel)]="contentManagementData.DocumentType">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="CommentAuthor">Comment Author:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="CommentAuthor" name="CommentAuthor" placeholder="Enter Comment Author"
                    [(ngModel)]="contentManagementData.CommentAuthor">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="CommentType">Comment Type:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="CommentType" name="CommentType" placeholder="Enter Comment Type"
                    [(ngModel)]="contentManagementData.CommentType">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="Comment">Comment :</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="Comment" name="Comment" placeholder="Enter Comment"
                    [(ngModel)]="contentManagementData.Comment">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="ReviewStatus">Review Status :</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="ReviewStatus" name="ReviewStatus" placeholder="Enter Review Status"
                    [(ngModel)]="contentManagementData.ReviewStatus">
                </div>
              </div>

              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>

          <div class="divID" *ngSwitchCase="'CrossBorderForm'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <label class="col-sm-12 col-form-label" for="FormID">Form Id:</label>
                <div class="col-sm-12">
                  <input type="text" class="form-control" id="FormID" name="FormID"
                    placeholder="Enter Form Id" [(ngModel)]="contentManagementData.FormID"  [disabled]="true">
                </div>
              </div>
          <br>
          <div class="form-group row">
            <label class="col-sm-12 col-form-label" for="EnterpriseValue">What is the expected enterprise value for the Company (in USD $)? <span class="red-asterik"></span></label>
            <br> 
            <div class="col-sm-12">
                <input type="radio" name="EnterpriseValue" [(ngModel)]="contentManagementData.EnterpriseValue" value="0M-25M" class="input-data-option readonly-radio">
                <span class="margin-right-20" style="font-weight: bold;"> $0M - $25M</span>
                <br/>
                <input type="radio" name="EnterpriseValue" [(ngModel)]="contentManagementData.EnterpriseValue" value="25M-50M" class="input-data-option readonly-radio">
                <span class="margin-right-20" style="font-weight: bold;"> $25M - $50M</span>
                <br/>
                <input type="radio" name="EnterpriseValue" [(ngModel)]="contentManagementData.EnterpriseValue" value="50M-75M" class="input-data-option readonly-radio">
                <span class="margin-right-20" style="font-weight: bold;"> $50M - $75M</span>
                <br/>
                <input type="radio" name="EnterpriseValue" [(ngModel)]="contentManagementData.EnterpriseValue" value="75M-100M" class="input-data-option readonly-radio">
                <span class="margin-right-20" style="font-weight: bold;"> $75M - $100M</span>
                <br/>
                <input type="radio" name="EnterpriseValue" [(ngModel)]="contentManagementData.EnterpriseValue" value="100M+" class="input-data-option readonly-radio">
                <span class="margin-right-20" style="font-weight: bold;"> $100M+</span>
                <br/>
            </div>
        </div>
        <br>
          <div class="form-group row">
            <div class="col-sm-12">
            <textarea class="form-control"  
            name="ExpectedCompanyValueComments" placeholder="You may add comments here..." rows="3" type="text"
            id="ExpectedCompanyValueComments" [(ngModel)]="contentManagementData.ExpectedCompanyValueComments"></textarea>
            </div> <br>
        </div><br />
              <div class="form-group row">
                <label class="col-sm-12 col-form-label" for="CompanySubsectorComments">In what subsector does the Company operate? <span class="red-asterik"></span></label>
                
                <div class="col-sm-12">
                  <input type="text" class="form-control" id="CompanySubsectorComments" name="CompanySubsectorComments"
                     [(ngModel)]="contentManagementData.CompanySubsectorComments">
                </div>
              </div>
              <br>
              <div class="form-group row">
                <label class="col-sm-12 col-form-label" for="ShareholderObjectivesComments">What are the shareholders objectives for the transaction? Will they be remaining with the Company post-transaction? <span class="red-asterik"></span></label>
                <div class="col-sm-12">
                  <input type="text" class="form-control" id="ShareholderObjectivesComments" name="ShareholderObjectivesComments"
                     [(ngModel)]="contentManagementData.ShareholderObjectivesComments">
                </div>
              </div>
              <br>
              <div class="form-group row">
                <label class="col-sm-12 col-form-label" for="GeographConcentrationComments">What is the Company's customer and geographical concentration? Is it a multinational company? <span class="red-asterik"></span></label>
                <div class="col-sm-12">
                  <input type="text" class="form-control" id="GeographConcentrationComments" name="GeographConcentrationComments"
                     [(ngModel)]="contentManagementData.GeographConcentrationComments">
                </div>
              </div>
              <br>
              <div class="form-group row">
                <label class="col-sm-12 col-form-label" for="USCounterpartiesContactComments">What potential counterparties would the Member Firm like to contact in the United States?</label>
                <div class="col-sm-12">
                  <input type="text" class="form-control" id="USCounterpartiesContactComments" name="USCounterpartiesContactComments"
                     [(ngModel)]="contentManagementData.USCounterpartiesContactComments">
                </div>
              </div>
              <br>

              <!-- <div class="form-group row">
                <div class="col-lg-12">
                  <h2>In Discussion</h2>
                </div>
              </div> 


              <br>-->
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>
          <div class="divID" *ngSwitchCase="'MyDeal'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <label class="col-sm-12 col-form-label" for="ID">Form Id:</label>
                <div class="col-sm-12">
                  <input type="text" class="form-control" id="ID" name="ID"
                    placeholder="Enter Form Id" [(ngModel)]="contentManagementData.ID"  [disabled]="true">
                </div>
              </div>
          <br>
              <div class="form-group row">
                <label class="col-sm-12 col-form-label" for="EngagementName">Project Name <span class="red-asterik"></span></label>
                
                <div class="col-sm-12">
                  <input type="text" class="form-control" id="EngagementName" name="EngagementName"
                     [(ngModel)]="contentManagementData.EngagementName">
                </div>
              </div>
              <br>
              <div class="form-group row">
                <label class="col-sm-12 col-form-label">Series 24 </label>
                <div class="col-sm-12">
                    <app-dropdown
                    [optionsList]="currents24Options"
                    [userLeftDCF]="series24LeftDCF"
                    [keySelected]="contentManagementData.Series24"
                    (optionSelected)="onCurrentSeries24Selection($event)">
                  </app-dropdown>
                </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-12 col-form-label" for="WbsCode">WBS<span class="red-asterik"></span> </label>
                
                <div class="col-sm-12">
                  <input type="text" class="form-control" id="WbsCode" name="WbsCode"
                     [(ngModel)]="contentManagementData.WbsCode">
                </div>
            </div>
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>
          <div class="divID" *ngSwitchCase="'NewDealSetupForm'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <label class="col-sm-12 col-form-label" for="ID">Form Id:</label>
                <div class="col-sm-12">
                  <input type="text" class="form-control" id="ID" name="ID"
                    placeholder="Enter Form Id" [(ngModel)]="contentManagementData.ID"  [disabled]="true">
                </div>
              </div>
          <br>
             
              <div class="form-group row">
                <label class="col-sm-12 col-form-label">LCSP<span class="red-asterik"></span> </label>
          
              <div class="col-xs-8">
                <app-peoplepicker [peoplePickerUserList]="leadClientServicePartner"
                    [dealTeamTable]="false"
                    [excludedUsers]="leadClientServicePartner"
                    (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event, 'leadClientServicePartner')"
                    (removedPeoplePickerUser)="onRemovedPeoplePickerUser($event, 'leadClientServicePartner')">
                </app-peoplepicker>
            </div>
          </div>
          <br>
            <div class="form-group row">
              <label class="col-sm-12 col-form-label" for="SwiftCode">Swift Code <span class="red-asterik"></span></label>
                
              <div class="col-sm-12">
                <input type="text" class="form-control" id="SwiftCode" name="SwiftCode"
                       [(ngModel)]="contentManagementData.SwiftCode">
              </div>
            </div>
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>
          <div *ngSwitchCase="'CrossBorderRecommendedTemplates'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="DocumentType">Form Id:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="FormID" name="FormID"
                    placeholder="Enter Form Id" [(ngModel)]="contentManagementData.FormID">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="DocumentType">Document Type:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="DocumentType" name="DocumentType"
                    placeholder="Enter Document Type" [(ngModel)]="contentManagementData.DocumentType">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="Title">Document Title:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="Title" name="Title" placeholder="Enter Document Title"
                    [(ngModel)]="contentManagementData.Title">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="Name">Document  Name:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="Name" name="Name" placeholder="Enter Document Name"
                    [(ngModel)]="contentManagementData.Name">
                </div>
              </div>

              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>

          <div *ngSwitchCase="'UserGroups'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="Role">Role:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="Role" name="Role" placeholder="Enter Role"
                    [(ngModel)]="contentManagementData.Role">
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>


          <div *ngSwitchCase="'CrossBorderRedLineAttachments'">
            <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="FormID">Form Id:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="FormID" name="FormID"
                    placeholder="Enter Form Id" [(ngModel)]="contentManagementData.FormID" required>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="DocumentType">Document Type:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="DocumentType" name="DocumentType"
                    placeholder="Enter Document Type" [(ngModel)]="contentManagementData.DocumentType" required>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="Name">Document  Name:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="Name" name="Name" placeholder="Enter Document Name"
                    [(ngModel)]="contentManagementData.Name" required>
                </div>
              </div>

              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>



          <div *ngSwitchDefault>
            <h3>Invalid Form Type</h3>
            <h4>{{ContentType}} >> {{TableName}}</h4>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Popup Height Style Changes -->
    <div *ngIf="(!showDynamicTable) && TableName=='CrossBorderDocumentTypes'" class="modal-content custom-content " id="add-content-popup" style="margin-top: 11%;">
      <div class="">
        <button type="button" class="close close-size" (click)="closePopup()" data-dismiss="modal"
          aria-label="Close"><span aria-hidden="true">&times;</span>
        </button>
        <div class="header">
          <div class="headerManagement">{{TableName}}</div> 
        </div>
        <br>
        <div [ngSwitch]="TableName">          
          
          <div *ngSwitchCase="'CrossBorderDocumentTypes'">
          <!-- <h4>{{TableName}}</h4> -->
            <form (ngSubmit)="submitContentData()">

              <div class="form-group row div-margin">
                <p class="col-sm-2 col-form-label attribute-doc-bolds" for="numericField" >Document Type Order <span style="color:red">*</span></p>
                <!-- <div class="col-sm-10"> -->
                  <input type="number" class="form-control control-width input-control-margin" id="DocumentTypeOrder" name="DocumentTypeOrder"
                    placeholder="Enter Document Type Order" [(ngModel)]="contentManagementData.DocumentTypeOrder" [disabled]="isDisabled">
                <!-- </div> -->
              </div>

              <div class="form-group row div-margin">
                <p class="col-sm-2 col-form-label attribute-doc-bolds" for="textBoxField" >Document Type Title: <span style="color:red">*</span></p>
                <!-- <div class="col-sm-10"> -->
                  <input type="text" style="margin-top: -1%;" class="form-control control-width" id="DocumentTypeTitle" name="DocumentTypeTitle"
                    placeholder="Enter Document Type Title" [(ngModel)]="contentManagementData.DocumentTypeTitle" [disabled]="isDisabled">
                <!-- </div> -->
              </div>


              <div class="form-group row div-margin">
                <p class="col-sm-2 col-form-label attribute-doc-bolds" for="Guidance" >Guidance: <span style="color:red">*</span></p>
                <!-- <div class="col-sm-10"> -->
                  <textarea class="form-control resizeTextArea control-width input-control-margin" id="Guidance" name="Guidance" placeholder="Enter Guidance"
                    [(ngModel)]="contentManagementData.Guidance"></textarea>
                <!-- </div> -->
              </div>

              <div class="form-group row div-margin">
                <p class="col-sm-2 col-form-label attribute-doc-bolds" for="DependentDocumentTypes" >Dependent Document
                  Types:</p>
                <!-- <div class="col-sm-10"> -->
                  <input type="text" style="margin-top: -1%;" class="form-control control-width" id="DependentDocumentTypes" name="DependentDocumentTypes"
                    placeholder="Enter Dependent Document Types"
                    [(ngModel)]="contentManagementData.DependentDocumentTypes">
                <!-- </div> -->
              </div>
              <div class="form-group row check-box-margin">
                <div class="form-check check-left">
                  <input type="checkbox" class="form-check-input" id="Required" name="Required"
                    [(ngModel)]="contentManagementData.Required" unchecked>
                  <label class="col-sm-2 col-form-label" for="Required" class="form-check-label">Required</label>
                </div>
              </div>


              <div class="form-group row check-box-margin">
                <div class="form-check check-left">
                  <input type="checkbox" class="form-check-input" id="BusinessReviewApplies"
                    name="BusinessReviewApplies" [(ngModel)]="contentManagementData.BusinessReviewApplies" unchecked>
                  <label class="col-sm-2 col-form-label" for="BusinessReviewApplies" class="form-check-label">Business
                    Review Applies:</label>
                </div>
              </div>

              <div class="form-group row check-box-margin">
                <div class="form-check check-left">
                  <input type="checkbox" class="form-check-input" id="QRMReviewApplies" name="QRMReviewApplies"
                    [(ngModel)]="contentManagementData.QRMReviewApplies" unchecked>
                  <label class="col-sm-2 col-form-label" for="QRMReviewApplies" class="form-check-label">QRM Review
                    Applies:</label>
                </div>
              </div>

              <div class="form-group row check-box-margin">
                <div class="form-check check-left">
                  <input type="checkbox" class="form-check-input" id="ComplianceReviewApplies"
                    name="ComplianceReviewApplies" [(ngModel)]="contentManagementData.ComplianceReviewApplies"
                    unchecked>
                  <label class="col-sm-2 col-form-label" for="ComplianceReviewApplies"
                    class="form-check-label">Compliance Review Applies:</label>
                </div>
              </div>


              <div class="form-group row check-box-margin">
                <div class="form-check check-left">
                  <input type="checkbox" class="form-check-input" id="RequiresFinalVersion" name="RequiresFinalVersion"
                    [(ngModel)]="contentManagementData.RequiresFinalVersion" unchecked>
                  <label class="col-sm-2 col-form-label" for="RequiresFinalVersion" class="form-check-label">Requires
                    Final Version:</label>
                </div>
              </div>
              <br>
              <button type="submit" class="btn btn-primary">{{buttonType}}</button>
            </form>
          </div>



          <div *ngSwitchDefault>
            <h3>Invalid Form Type</h3>
            <h4>{{ContentType}} >> {{TableName}}</h4>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showDynamicTable" class="modal-content custom-content " id="add-content-popup" style="margin-top: 11%;">
        <div>
          <button type="button" class="close close-size" (click)="closePopup()" data-dismiss="modal"
            aria-label="Close"><span aria-hidden="true">&times;</span>
          </button>
          <div class="header">
            <div class="headerManagement">{{TableName}}</div> 
          </div>
          <br>
          <div>
            <div>
              <form (ngSubmit)="submitContentData()">
                <div class="form-group row div-margin dynamic-form">
                  <div *ngFor="let field of editTemplate.Fields" class="form-field">
                    <label *ngIf="buttonType=='Update' && field.showInEdit==true" class="col-form-label attribute-bold" for="{{field.displayName}}">
                      {{field.displayName}} <span *ngIf="field.required" style="color:red">*</span>
                    </label>
                    <label *ngIf="buttonType=='Save' && field.showInAdd==true" class="col-form-label attribute-bold" for="{{field.displayName}}">
                      {{field.displayName}} <span *ngIf="field.required" style="color:red">*</span>
                    </label>

                    <!-- textbox -->
                    <input *ngIf="field.controltype == 'textbox' && buttonType=='Update' && field.showInEdit==true" type="text" class="form-control" id="{{field.field}}" name="{{field.field}}" placeholder="{{field.placeholder}}"
                    [(ngModel)]="contentManagementData[field.field]" [disabled]="buttonType=='Update' && field.disabled">
                    <!-- checkbox -->
                    <input *ngIf="field.controltype == 'checkbox' && buttonType=='Update' && field.showInEdit==true" type="checkbox" class="form-check-input" id="{{field.field}}" name="{{field.field}}" placeholder="{{field.placeholder}}"
                    [(ngModel)]="contentManagementData[field.field]">

                    <!-- textbox -->
                    <input *ngIf="!field.numericCheckRequired && field.controltype == 'textbox' && buttonType=='Save' && field.showInAdd==true" type="text" class="form-control" id="{{field.field}}" name="{{field.field}}" placeholder="{{field.placeholder}}"
                    [(ngModel)]="contentManagementData[field.field]" [disabled]="buttonType=='Update' && field.disabled">

                    <input *ngIf="field.numericCheckRequired == true && field.controltype == 'textbox' && buttonType=='Save' && field.showInAdd==true" type="text" class="form-control" id="{{field.field}}" name="{{field.field}}" placeholder="{{field.placeholder}}"
                    [(ngModel)]="contentManagementData[field.field]" [disabled]="buttonType=='Update' && field.disabled"
                    onkeypress="if ( isNaN( String.fromCharCode(event.keyCode) )) return false;" maxlength="4">

                    <!-- checkbox -->
                    <input *ngIf="field.controltype == 'checkbox' && buttonType=='Save' && field.showInAdd==true" type="checkbox" class="form-check-input" id="{{field.field}}" name="{{field.field}}" placeholder="{{field.placeholder}}"
                    [(ngModel)]="contentManagementData[field.field]">
                  </div>
                </div>
                <br>
                <button type="submit" class="btn btn-primary">{{buttonType}}</button>
              </form>
            </div>
          </div>
        </div>
    </div>

  </div>
</div>





<!-- ITS Admin Approval Popup -->

<div class="modal " tabindex="-1" role="dialog" [ngStyle]="{'display':displayVerifyStyle}">
  <div class="modal-dialog " role="document">
    <div class="modal-content custom-new">
          <div class="">
              
              <button type="button" class="close close-sizes" (click)="closeVerificationPopup()" data-dismiss="modal"
                  aria-label="Close"><span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="ITSheader">
              <div class="ITAdminheaderManagement">{{TableName}}: Verify Actions</div> 
            </div>
         
<div style="margin-top:2%;">
  <p class="per-info">Below are the modifications happened to {{DocTypeTitle}}:</p>
<div class="role-style">
<p>* {{verifyDescription}}</p>

</div>
</div>

<div class="form-group row div-margins" style="margin-top: 12%;">
  <p class="col-sm-2 col-form-label attribute-doc-bolds" for="Comments" >Comments:</p>
  <!-- <div class="col-sm-10"> -->
    <textarea class="form-control resizeTextArea control-width input-control-margin" id="Comments" name="Comments" placeholder="Enter Comments"
      [(ngModel)]="ITSAdminComments"></textarea>
  <!-- </div> -->
</div>
<div style="margin-top: 4%;margin-left: 30%;">
  <span>
  <button class="btn btn-primary btnApproved" type="button"  (click)="isITSApproved($event)" >Approve</button>
</span>
  <span>
    <button class="btn btn-primary btnRejected" type="button"  (click)="isITSRejected($event)" >Reject</button>
  </span>
</div>
      </div>
  </div>
</div>


<app-notify-popup></app-notify-popup>

