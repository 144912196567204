import { Component, OnInit, ViewChild,ViewChildren,QueryList } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { AddUserPopupComponent } from '../add-user-popup/add-user-popup.component'; 
import { AppSettings } from 'src/shared/app-settings';
import { NgbdSortableHeaderDirective, SortDirection, SortEvent } from 'src/shared/directives/sorteable.directive';
import { PaginationComponent } from 'src/shared/components/pagination/pagination.component';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { PaginationValues } from 'src/shared/models/pagination-values';
import { UserManagementSort } from '../../models/userManagement-sort';
import { UserManagementTableFilterItem } from '../../models/userManagement-table-filter-item';
import { UserManagementItem } from '../../models/userManagementItem';
import { DatePipe } from '@angular/common';
import { UserTableFilterItem } from '../../models/user-table-filter-item';
import { environment } from 'src/environments/environment';
import { NotifyPopupComponent } from 'src/shared/components/alerts/notify-popup/notify-popup.component';
import { ContentService } from 'src/app/services/content.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { ScriptLoaderService } from 'src/app/services/script-loader.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';

 
@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss']
})
export class UserManagementComponent implements OnInit {
 // userData: any;
  formTypes: any;
  AdminRole:any="";
  formGroups: any;
  isDisabled:any=true;
  partitionDeleteValue:any;
  isITSAdmin:any=false;
  isDeleteUser:any=true;
  isAdminUser:any=false;
  formTypeValue:any="Cross Border form";
  duplicateUserRestrictFlag:any=false;
  isAdminDeleteUser:any=true;
  selectedFormType: any = "";
  selectedFormGroup: any = "";
  selectedPageSize:any;
  isAddingUser:any=false;
  formGroupDDLDisabled: boolean = true;
  checkPermissionDisabled: boolean = true;
  UserReportData: Array<any> = [];
  showTable: boolean = false;
    @ViewChild(AddUserPopupComponent, { static: true }) adduserpopupComponent: AddUserPopupComponent;
    @ViewChild(NotifyPopupComponent, { static: true }) notifypopupComponent: NotifyPopupComponent;

    userManagements: Array<UserManagementItem>;
    userManagementPerPage : Array<UserManagementItem>;
    filterList: Array<UserManagementTableFilterItem> = [];
    targetField: string;
    targetFilter:any;
    fieldOptionValues: Array<string> = [];
    initialUserManagementList: Array<UserManagementItem> = [];
    pageSizeListValues: Array<any> = [];
    @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
    
    @ViewChild('pagination', {static: false})
    pagination: PaginationComponent;
    userManagementExportToExcel:Array<any>=[];
    userManagementPageDetails:Array<any>=[];
    showDisable:boolean;
    pageDropdownDisable: boolean;
  constructor(public adminService: AdminService,public contentService:ContentService,private spinnerService:SpinnerService,public commonHelperService:CommonHelperService,public globalHelperService:GlobalHelperService) { }

  ngOnInit(): void {
   //this.GetFormTypes();   
   this.spinnerService.showAutoSaveSpinner();
   if(localStorage.getItem("isUserInAdmin")?.toString()=="true")
   {
      localStorage.setItem("CurrentAccessPage","Admin");
      this.globalHelperService.currentAccessPage="Admin";
      this.commonHelperService.state = this.commonHelperService.getSearchState();
   this.commonHelperService.state = this.commonHelperService.getSearchState();
      this.GetFormTypes();
      this.ApplyBrowserScroll();   
      this.spinnerService.hideAutoSaveSpinner();
   }
   else
   {
      this.spinnerService.hideAutoSaveSpinner();
      this.VerifyUserManagementAccess();
      //window.location.href = AppSettings.DashboardURL;
   }
  }
  VerifyUserManagementAccess(){
    let userId=localStorage.getItem("userProfileId")?.toString();
    this.adminService.GetUserRoleGroups(userId).subscribe(
      {
        next: (result: any) => {
          result=result.filter((s:any)=>s.Title!=null);
          this.isAdminUser = this.isInAdminGroup(result,false);
          if(this.isAdminUser)
          {
            localStorage.setItem("isUserInAdmin","true");
          } 
          else{
            localStorage.setItem("isUserInAdmin","false");
            window.location.href =AppSettings.DashboardURL;
            return;
          }  
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
          console.log("Error" + err)
        }
      })
  }
  ApplyBrowserScroll(){
    document.body.style.overflow="auto";
  }
  getUserGroups() {
    this.showTable = true;

    this.adminService.GetUserGroups(this.selectedFormGroup,true).subscribe(
      {
        next: (result: any) => {          
          // this.userData = result.filter(function (el) {
          //   return el.Status === true;
          // });
result.forEach((v:any)=>{
if(v.FirstName!=""||v.LastName!=""){
  v.fullName=v.LastName+", "+v.FirstName;
}
});
        //  this.reportDashboards = result;
          this.initialUserManagementList=result;
          this.setUserManagement(this.initialUserManagementList,this.pageSizeListValues);
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
          this.duplicateUserRestrictFlag=false;
          console.log("Error" + err)
        }
      })
  } 
  GetUsersListsInGroups(){
    let userId=localStorage.getItem("userProfileId")?.toString();
    this.adminService.GetUserRoleGroups(userId).subscribe(
      {
        next: (result: any) => {
          result=result.filter((s:any)=>s.Title!=null);
          this.isAdminUser = this.isInAdminGroup(result,true);   
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
          console.log("Error" + err)
        }
      })
  }
  CheckAdminUser() {
    this.GetUsersListsInGroups();
  }
   isInAdminGroup(userGroups:any,checkFormType:boolean):boolean {
    let isAdmin_flag=false;
    this.isITSAdmin=false;
    if(checkFormType)
    {
      userGroups.forEach((u:any)=>{   
        if(u.Role=="Cross Border Administrators"&&this.formTypeValue=="Cross Border"){isAdmin_flag=true;}
          if (u.Role == "NewDeal Administrators" && this.formTypeValue == "New Deal") { isAdmin_flag = true; }
          if (u.Role == "MyDeal Administrators" && this.formTypeValue == "My Deal") { isAdmin_flag = true; }
          if (u.Role == "DealWBS Administrators" && this.formTypeValue == "Deal WBS") { isAdmin_flag = true; }
          if (u.Role == "Termination Administrators" && this.formTypeValue == "Termination") { isAdmin_flag = true; }
          if (u.Role == "ClosingCheckList Administrators" && this.formTypeValue == "Closing Checklist") { isAdmin_flag = true; }
        if(u.Role=="ITS_Administrator"){ isAdmin_flag=true;this.isITSAdmin=true;}
       });
    }
    else{
      userGroups.forEach((u:any)=>{   
        if(u.Role=="Cross Border Administrators"){isAdmin_flag=true;}
          if (u.Role == "NewDeal Administrators") { isAdmin_flag = true; }
          if (u.Role == "MyDeal Administrators") { isAdmin_flag = true; }
          if (u.Role == "DealWBS Administrators") { isAdmin_flag = true; }
          if (u.Role == "ClosingCheckList Administrators") { isAdmin_flag = true; }
          if (u.Role == "Termination Administrators") { isAdmin_flag = true; }
          if(u.Role=="ITS_Administrator"){ isAdmin_flag=true;this.isITSAdmin=true;}
       });
    }
       this.spinnerService.hideAutoSaveSpinner();
    if(isAdmin_flag){this.AdminRole="Administrators";}else{this.AdminRole="";}
    if ( this.AdminRole== "Administrators") {this.isAdminUser = true; this.isAdminDeleteUser=false;}else{this.isAdminUser=false;this.isAdminDeleteUser=true;}
    return this.isAdminUser;
  }
  GetFormGroups() {
    var formTypeID = this.selectedFormType;
    this.checkPermissionDisabled=true;
      if (this.formTypeValue.includes("Cross Border") || this.formTypeValue.includes("New Deal") || this.formTypeValue.includes("My Deal") || this.formTypeValue.includes("Deal WBS") || this.formTypeValue.includes("Termination") || this.formTypeValue.includes("Closing Checklist")){        
        this.adminService.GetFormGroups().subscribe(
        {
          next: (result: any) => {
            this.formGroupDDLDisabled = false;
           this.checkPermissionDisabled=false;
            if(this.formTypeValue.includes("Cross Border"))
            {
              result = result.filter((x:any)=>x.FormTypeID == formTypeID || x.FormTypeID == null);
            }
            else if (this.formTypeValue.includes("Closing Checklist")) {
              //Added below logic to hide the Closing Checklist S24 Group, need to use this in Version 1 Form.
              result = result.filter((x: any) => x.FormTypeID == formTypeID && x.Name != "ClosingCheckList_Series24");
            }
            else
            {
              result = result.filter((x:any)=>x.FormTypeID == formTypeID);
            }
            result.sort((a:any, b:any) => (a.Name < b.Name ? -1 : 1));
            this.formGroups = this.getContentMangementActiveLists(result);
            this.spinnerService.hideAutoSaveSpinner();
          },
          error: (err: any) => {
            console.log("Error" + err)
          }
        })
    }
    else{
      this.showTable=false;
      this.formGroupDDLDisabled = false;
      this.formGroups=[];
      this.spinnerService.hideAutoSaveSpinner();
    }
  }
  getContentMangementActiveLists(data:any){
    let resData:any=[];
    data.forEach((items:any)=>{
     if(items.isActive!="No"){
     resData.push(items);
    }
    });
    return resData;
  }
getSelectedFormType(){
this.formTypes.forEach((x:any)=>{
  if(x.ID==this.selectedFormType){this.formTypeValue=x.Name;}
});
}
  onFormTypeChange() {
    this.isDisabled=false;
      this.showTable = false;      
      this.selectedFormGroup= "";
      this.formGroups=[];
    this.getSelectedFormType();
    this.CheckAdminUser();
      if (this.formTypeValue == "Cross Border" || this.formTypeValue == "New Deal" || this.formTypeValue == "My Deal" || this.formTypeValue.includes("Deal WBS") || this.formTypeValue == "Termination" || this.formTypeValue.includes("Closing Checklist") ) {
      this.spinnerService.showAutoSaveSpinner();
      this.GetFormGroups();
    } 
    else {
      this.showTable = false;      
      this.selectedFormGroup= "";
      this.formGroups=[];
    }
  }
  onFormGroupChange() {
   this.clearFilters();
   this._resetSortIcons();
    if (this.selectedFormGroup != "") {   
      this.spinnerService.showAutoSaveSpinner();
      let formGroup=this.getUserRole(this.selectedFormGroup); 
      if(formGroup=="ITS_Administrator"||formGroup=="CrossBorder_ContentManagement_Business_Administrator"){
        if(!this.isITSAdmin){this.duplicateUserRestrictFlag=true;this.isAdminDeleteUser=true;}
      }
      else{
        if(this.isAdminUser){this.duplicateUserRestrictFlag=false;this.isAdminDeleteUser=false;}
      }
      this.getUserGroups();
    } else {
      this.showTable = false;
    }

  }
  GetFormTypes() {
    this.adminService.GetFormTypes().subscribe(
      {
        next: (result: any) => {
          //result.forEach((v:any)=>v.Name=v.Name+" form");
          this.formTypes = this.commonHelperService.getActiveRecords(result);
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
          console.log("Error" + err);
        }
      })
  }
  openAddUserPopup(event:any) {
    this.isAddingUser=true;
    this.adduserpopupComponent.openPopup("Add");
  }
  downloadUserReport()
  {
    this.adminService.GetUserReportDetails(this.selectedFormType).subscribe(
      {
        next: (result: any) => {        
          console.log(result);
          this.UserReportData = [];
          if(result!=null && result.length > 0)
          {
              result.forEach((item:any) =>{
                if(item.FormType==null){
                  let userDetail ={
                    UserGroup : item.Role,
                    Name :(item.FirstName!=null && item.LastName!=null) ? 
                          item.LastName+", "+item.FirstName :
                          ((item.FirstName ==null)?item.LastName :item.FirstName) ,
                    Title : item.Title,
                    Email : item.Email                  
                  };
                  this.UserReportData.push(userDetail);
                }
               else if(this.selectedFormType==item.FormType){
                let userDetail ={
                  UserGroup : item.Role,
                  Name :(item.FirstName!=null && item.LastName!=null) ? 
                        item.LastName+", "+item.FirstName :
                        ((item.FirstName ==null)?item.LastName :item.FirstName) ,
                  Title : item.Title,
                  Email : item.Email                  
                };
                this.UserReportData.push(userDetail);
              }
              });
              if(this.UserReportData.length > 0)
              {
                this.generateExcel(this.UserReportData);
              }
          }
        },
        error: (err: any) => {
          if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err)
        }
      })
  }
  public generateExcel(jsonData: any[]): void {
    jsonData = jsonData.map(doc => Object.values(doc));
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    worksheet['A1'].v = "UserGroup";
    worksheet['B1'].v = "Name";
    worksheet['C1'].v = "Title";
    worksheet['D1'].v = "Email";
    
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(data, 'UserReport_' + new Date().getTime() + ".xlsx");
  }

  openCheckPermissionPopup(event:any) {
    this.isAddingUser=true;
    this.adduserpopupComponent.openPopup("Permissions");
  }
showMsgs(msg:any,msgType:any){
  this.notifypopupComponent.openPopup(msg,msgType)
}
  DeleteUser(partitionKeyValue:any){
    this.isAddingUser=true;
    this.partitionDeleteValue=partitionKeyValue;
    this.showMsgs("DeleteOperation","Info");  
  }
  
  addUserDetails(event:any){ 
    this.spinnerService.showAutoSaveSpinner();
   // this.duplicateUserRestrictFlag=true;
    // if(event.length>0){
    //   var isUserExist= this.validateUser(event);
    //   if(!isUserExist){
     var role= this.getUserRole(this.selectedFormGroup);     
     let userGrouplist:Array<any>=[];    
     event.forEach((element:any)=>{
      var fullname= element.displayName?.split(/,/); 
    let userGroup={
      Email:element?.mail.trim().toLowerCase(),
      FirstName:fullname[1]?.trim() == undefined ? '':fullname[1]?.trim(),
      LastName:fullname[0]?.trim()== undefined ? '':fullname[0]?.trim(),
      Status:true,
      FormGroupID:this.selectedFormGroup,
      FormType:this.selectedFormType,
      ID:'',
      Role:role,
      Title:element?.jobTitle==null?"Perf User":element?.jobTitle
   };
   userGrouplist.push(userGroup);
  });
    
    this.adminService.AddUser(userGrouplist).subscribe(
      {
        next: (result: any) => {        
        this.getUserGroups();      
        setTimeout(()=>{ 
          this.showMsgs("Admin","Success");
        }, 700); 
        },
        error: (err: any) => {
          if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err)
        }
      })
  //   }
  //   else{
  //     this.duplicateUserRestrictFlag=false;
  //     this.adminService.hideSpinner();
  //     this.showMsgs("Admin","Warning");
  //   }
  // }
  //   else{
  //     this.duplicateUserRestrictFlag=false;
  //     this.adminService.hideSpinner();
  //     this.showMsgs('Admin','Info');
  //   }
    
  }
  getUserRole(userGroupID:any){
    let selectedUserRoleName:any;
    if(userGroupID!=null&&userGroupID!=undefined&&userGroupID!=""){
    this.formGroups.forEach((v:any)=>{
    if(v.ID==userGroupID){
      selectedUserRoleName=v.Name;
    }
    });   
    }
    return selectedUserRoleName;
  };
  CloseUserDetails(event:any){
    this.isAddingUser=false;
  }
  addNotifyDetails(event:any){
    this.isAddingUser=false;
  }
  deleteConfirmDetails(event:any){
    this.isAddingUser=false;
    if(event.isOkayClicked){
      this.spinnerService.showAutoSaveSpinner();
      this.adminService.DeleteUser(this.partitionDeleteValue).subscribe(
        {
          next: (result: any) => {         
            this.getUserGroups();         
          setTimeout(()=>{
            this.showMsgs("DeleteOperation","Success");
          }, 700);  
          },
          error: (err: any) => {
            if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
            this.spinnerService.hideAutoSaveSpinner();
            console.log("Error" + err)
            this.showMsgs("DeleteOperation","Error");
          }
        })
    }
  }
  //custom logic
  onChangePaginationValues(paginationValues: PaginationValues) {
    this.commonHelperService.state.page = paginationValues.page;
    this.commonHelperService.state.pageSize = paginationValues.pageSize;
    this._setUsersToCurrentPage();
    this.setMultiSelectedCheckBoxPages(paginationValues.page,paginationValues.pageSize);
    //Suvishna Commented
    //this.setMultiSelectedCheckBoxPages(paginationValues.page,paginationValues.pageSize);
    // When selected the multiple selected text box,
    //  only the forms specific to the particular page must be selected,
    //   even if the items per page increased, 
    //   it should select only the forms in that relevant page.
    //    If the user navigates to the other page , 
    //    no forms should have to be selected until that page multi select is clicked .
    //     Until the relevant page is not deselected , 
    //     it should remain selected only.
    //      The following selected files should be able to exported in the excel sheet

}
private setMultiSelectedCheckBoxPages(pageNumber:any,pageSize:any) {
 
  if(this.userManagementPageDetails.length>0)
  {
    if(this.userManagementPageDetails.find(s => s.PageSize!=pageSize && s.IsMultiSelect==true))
    {
      this.userManagementExportToExcel.length=0;
    }
    this.userManagementPageDetails.forEach(s => {
      if(s.IsMultiSelect==true && s.PageSize!=pageSize)
      {
        s.PageSize=pageSize
        const page = s.PageNumber;
        const pageSize1 = s.PageSize;
        this.userManagementPerPage =
        this.userManagements.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        this.userManagementPerPage.forEach(Item => {
          if(!this.userManagementExportToExcel.find(e => e.ID == Item.ID))
          {
            let reportDashboardItem = {
              ID:Item.ID,
              FirstName: Item.FirstName,
              Status:Item.Status,
              LastName:Item.LastName,
              Role:Item.Role,
              FormGroupID:Item.FormGroupID,
              FormType:Item.FormType,
              Email:Item.Email
              
            };
          this.userManagementExportToExcel.push(reportDashboardItem);
          }
    });
      }
       
    });
    this._setUsersToCurrentPage();
  }
  const checkbox = document.getElementById(
    'chk_MultiCheckBox',
  ) as HTMLInputElement | null;
 // checkbox.checked = false;
  if(this.userManagements.find(e => e.Status == true))
  {
  this.userManagementPageDetails.forEach(v => {
    if(v.IsMultiSelect==true && v.PageNumber==pageNumber)
    {
      //checkbox.checked = true;
    }
  });
}

}
private _setUsersToCurrentPage() {
    const page = this.commonHelperService.state.page;
    const pageSize = this.commonHelperService.state.pageSize;
    this.userManagementPerPage =
      this.userManagements.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
}
setUserManagement(
  reportDashboardList: Array<UserManagementItem>,
  pageSizeListValues: Array<any>) {
    pageSizeListValues=[{id:0,value:25},{id:1,value:50},{id:2,value:100}];
    let filteredReportsList = this._filterClosedDeals(reportDashboardList);
   // this.initialReportDashboardList = reportDashboardList;
    this.commonHelperService.state.sortData ={sortColumn: 'fullName', sortDirection: 'asc'};
    this.commonHelperService.state.page = 1;
    this.commonHelperService.state.pageSize = 25;
       const sortData = this.commonHelperService.state.sortData;
    const filterData = this.commonHelperService.state.filterDataList;
    filteredReportsList = this._getEngagementsMatchsFilterCriteria(filteredReportsList, filterData);
    this.userManagements = this.commonHelperService.sort(
        sortData.sortColumn, sortData.sortDirection, reportDashboardList);
    this._updateSortArrow(sortData.sortColumn, sortData.sortDirection);
    this._setUsersToCurrentPage();
    this._setPaginationValues(pageSizeListValues);
   // this.duplicateUserRestrictFlag=false;
    this.pageDropdownDisable = reportDashboardList.length > 0 ? false : true;
    this.spinnerService.hideAutoSaveSpinner();
}
private _getEngagementsMatchsFilterCriteria(
  ReportDashboard: Array<UserManagementItem>,
  filterList: Array<UserTableFilterItem>): Array<UserManagementItem> {
  let newReportDashboardList: Array<UserManagementItem> = [];
  if (filterList.length > 0) {
    ReportDashboard.forEach(eng => {
      if (this._isEngagementMatchFilterCriteria(eng, filterList)) {
        newReportDashboardList.push(eng);
      }
    });
  } else {
    newReportDashboardList = ReportDashboard;
  }
  return newReportDashboardList;
}
private _isEngagementMatchFilterCriteria(
  ReportDashboard: any,
  filterList: Array<UserTableFilterItem>): boolean {
  let match = true;
  filterList.forEach(filter => {
    const field = filter.field;
    const values = filter.valueList;
     if (values.length && !values.find((v:any) => v === ReportDashboard[field].toString())) {
      
      match = false;
     // return false;
    }
  });
  return match;
}
private _filterClosedDeals(reportsList: Array<UserManagementItem>): Array<UserManagementItem> {
  let filteredreportsList;
  filteredreportsList = reportsList;
  
  return filteredreportsList;
}
private _updateSortArrow(sortColumn: string, sortDirection: SortDirection) {
 //this._resetSortIcons(sortColumn);
  //this._showSortDirectionArrow(sortColumn, sortDirection);
}
private _setPaginationValues(values:any) {
  
  this.pageSizeListValues = values.map((element:any) => {
    const parseValue = element.value.toString();
    const dropdownItem: DropdownItem = {
      key: parseValue,
      value: parseValue  + ' items per page'
    };
    return dropdownItem;
  });
  this.pageSizeListValues.forEach((v:any)=>{
    if(v.key==25)
    this.selectedPageSize=v.key;
  });
  
  //this.pagination.setPaginationValues(this.pageSizeListValues);
  
}
onPageSizeValueChange(event:any){
  const values: PaginationValues = {
    page: this.commonHelperService.state.page,
    pageSize: parseInt(this.selectedPageSize)
  };
this.onChangePaginationValues(values);
}
sortTable({column}: SortEvent) {
  this.userManagementPageDetails.length=0;
  const newDirection = this._getSortDirection(column);
  this._resetSortIcons(column);
  this.userManagements = this.commonHelperService.sort(column, newDirection, this.userManagements);
  this._setUsersToCurrentPage();
  this._setSortOptions(column, newDirection);
  this.showDisable=true;
  this.userManagementExportToExcel.length=0;
  const checkbox = document.getElementById(
    'chk_MultiCheckBox',
  ) as HTMLInputElement | null;
  //checkbox.checked = false;

}
private _getSortDirection(column: string): SortDirection {
  const header = this._getHeader(column);
  return header.direction === 'asc' ? 'desc' : 'asc';
}
isFilterApply(field: string) {
  let filterApply = false;
  this.filterList.forEach(filter => {
    if (filter.field === field && filter.valueList.length) {
      filterApply = true;
    }
  });
  return filterApply;
}
  clearFilters(){
      if (this.targetFilter) {
          this.targetFilter.valueList = [];
          this._updateUsersList();
          this.showDisable=true;
      this.userManagementExportToExcel.length=0;
      const checkbox = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement | null;
      //checkbox.checked = false;
    
    this.userManagementPageDetails.length=0
        }
  }
  clearAllFilters(FromSearchForm:any) {
      this._resetSortIcons();
      this.filterList = [];
      this.targetField = "";
      this.userManagementExportToExcel.length = 0;
      this.userManagementPageDetails.length=0;
      const checkbox = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement | null;
     // checkbox.checked = false;
      this.showDisable=true;
     
      if(FromSearchForm==true)
      {
        this.commonHelperService.searchTerm = '';
      }
      this.commonHelperService.state.sortData ={sortColumn: 'fullName', sortDirection: 'asc'};
      
      this.commonHelperService.filterDataList = [];
      this.commonHelperService.state.page = 1;
      this.commonHelperService.state.pageSize = 25;
      // this.showDealClosedState.emit(false);
     // this.currentEngagementList = this._filterClosedDeals(false, this.initialEngagementsList);
      this.userManagements = this._filterClosedDeals(this.initialUserManagementList);
      this._updateSortArrow(this.commonHelperService.state.sortData.sortColumn, this.commonHelperService.state.sortData.sortDirection);
      this._setUsersToCurrentPage();
  }
  private _updateUsersList() {
      this.userManagements = this._isSomeFilterApply() ?
        this._getUserMatchsFilterCriteria(this.initialUserManagementList, this.filterList) :
        this.initialUserManagementList;
  
      const sortData = this.commonHelperService.state.sortData;
      this.userManagements = this.commonHelperService.sort(
        sortData.sortColumn, sortData.sortDirection, this.userManagements);
      this._setUsersToCurrentPage();
    }
  openFilterPopover(field: string) {
      this.targetField = field;
      this.targetFilter = this._getFilter(field);
      this.fieldOptionValues = this._mapUserListWithFilterList(this.initialUserManagementList, field);
  }
  report:any;
  private _getFilter(field: string): any {
    
    // if(this.filterList.length){
    //   this.report=this.filterList.filter(f => f.field === field)[0];
    // }
    // return this.report;
      return this.filterList.length ? this.filterList.filter(f => f.field === field)[0] : null;
  }
  private _mapUserListWithFilterList(
      listReports: Array<UserManagementItem>,
      field: string): Array<string> {
      const arrayOptionsList:any=[];
      listReports.forEach((report:any) => {
        const value = report[field];
        if (this._isValidValue(arrayOptionsList, value, field, listReports)) {
          arrayOptionsList.push(report[field]);
        }
      });
      return arrayOptionsList;
  }
  private _isValidValue(
      arrayOptionsList: Array<string>,
      value: string,
      field: string,
      listreports: Array<UserManagementItem>): boolean {
        return (value !== '' && value !== null &&
              !this._inArray(arrayOptionsList, value) &&
              this._existUserForValue(listreports, field, value));
  }
  private _inArray(optionFields: Array<string>, option: string): boolean {
      return Boolean(optionFields.find(o => o === option));
  }
  private _existUserForValue(
      listReports: Array<UserManagementItem>,
      field: string,
      value: string): boolean {
      const filterArrayClone = [...this.filterList];
      const filterRemovedCurrentTarget = filterArrayClone.filter(f => f.field !== field);
      const filteredValues = this._getUserMatchsFilterCriteria(listReports, filterRemovedCurrentTarget);
      const searchValue = filteredValues.find((v:any) => v[field] === value) ? true : false;
      return searchValue;
  }
  private _getUserMatchsFilterCriteria(
      reports: Array<UserManagementItem>,
      filterList: Array<UserManagementTableFilterItem>): Array<UserManagementItem> {
      let newReportList: Array<UserManagementItem> = [];
      if (filterList.length > 0) {
          reports.forEach(rep => {
          if (this._isUserMatchFilterCriteria(rep, filterList)) {
              newReportList.push(rep);
             
          }
        });
      } else {
          newReportList = reports;
      }
      return newReportList;
  }
  private _isUserMatchFilterCriteria(
      report: any,
      filterList: Array<UserManagementTableFilterItem>): boolean {
      let match = true;
      filterList.forEach((filter:any) => {
        const field = filter.field;
        const values = filter.valueList;
        if (values.length && !values.find((v:any) => v === report[field].toString())) {
        
          match = false;
         // return false;
        }
      });
      return match;
  } 
  sortTableOnFilterModal(column: string, direction: SortDirection) {
      this._resetSortIcons(column);
      this._showSortDirectionArrow(column, direction);
      this._setUsersToCurrentPage();
      this.userManagements = this.commonHelperService.sort(column, direction, this.userManagements);
      this._setSortOptions(column, direction);
  }
  private _setSortOptions(column: string, direction: SortDirection) {
      const sortItem: UserManagementSort = {
        sortColumn: column,
        sortDirection: direction
      };
      this.commonHelperService.sortData = sortItem;
  }
  private _resetSortIcons(column?: string) {
      this.headers.forEach(header => {
        if ((column && header.sortable !== column) || !column) {
          header.direction = '';
        }
      });
  }
  private _showSortDirectionArrow(sortColumn: string, sortDirection: SortDirection) {
      const header = this._getHeader(sortColumn);
      header.direction = sortDirection;
      header.rotate();
  }
  isChecked(value: string): boolean {
      let checked = false;
      if (this.targetFilter) {
        this.targetFilter.valueList.forEach((v:any) => {
          if (v === value.toString()) {
            checked = true;
          }
        });
      }
      return checked;
  }
  private _getHeader(column: string): NgbdSortableHeaderDirective {
      return this.headers.filter(h => h.sortable === column)[0];
  }
  onClickFilterCheckbox(event:any) {
      const checkbox = event.currentTarget;
      const valueSelected = checkbox.id;
      const isChecked = checkbox.checked;
  
      this._updateFilterList(valueSelected, isChecked);
      this._updateUserList();
  }
  private _updateUserList(){
          this.userManagements = this._isSomeFilterApply() ?
            this._getUserMatchsFilterCriteria(this.initialUserManagementList, this.filterList) :
            this.initialUserManagementList;
      
          const sortData = this.commonHelperService.state.sortData;
          this.userManagements = this.commonHelperService.sort(
            sortData.sortColumn, sortData.sortDirection, this.userManagements);
          this._setUsersToCurrentPage();
  }
  private _isSomeFilterApply() {
      let filterApply = false;
      this.filterList.forEach(filter => {
        if (filter.valueList.length) {
          filterApply = true;
        }
      });
      return filterApply;
  }
  
  private _updateFilterList(valueSelected: string, isChecked: string) {
      const existFilter = this._getFilter(this.targetField);
      if (isChecked) {
        this._addFilterItem(existFilter, valueSelected);
      } else {
        this._removeFilterItem(existFilter, valueSelected);
      }
      this.showDisable=true;
      this.userManagementExportToExcel.length=0;
      const checkbox = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement | null;
      //checkbox.checked = false;
   
    this.userManagementPageDetails.length=0
      this._setTargetFilter(existFilter);
  }
  
    private _setTargetFilter(existFilter: UserManagementTableFilterItem) {
      this.targetFilter = existFilter;
      this.commonHelperService.filterDataList = this.filterList;
  
  }
  private _addFilterItem(existFilter: UserManagementTableFilterItem, valueSelected: string) {
      if (existFilter) {
        existFilter.valueList.push(valueSelected);
      } else {
        const newFilter = this._createFilter(valueSelected);
        this.filterList.push(newFilter);
        existFilter = newFilter;
      }
  }
  
    private _removeFilterItem(existFilter: UserManagementTableFilterItem, valueSelected: string) {
      if (existFilter) {
        existFilter.valueList = this._removeFilter(existFilter.valueList, valueSelected);
      }
  }
  private _removeFilter(valueList: Array<string>, valueSelected: string): Array<string> {
      return valueList.filter(v => v !== valueSelected);
  }
  private _createFilter(valueSelected: string): UserManagementTableFilterItem {
      const newFilter = {
        field: this.targetField,
        valueList: [valueSelected]
      };
      return newFilter;
  }
  
}
