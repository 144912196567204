<div class="table-responsive table-body heightStyles" [ngStyle]="getModalStyles()">
  <table id="engagement-table" class="table-striped">
    <thead>
      <tr>
        <th scope="col">
          <span
            class="th-label"
            sortable="engagementId"
            (sort)="sortTable($event)">
              Engagement ID
          </span>
         <app-popover #popoverNewDealId
            [active]="isFilterApply('engagementId')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('engagementId')">
         </app-popover>
        </th>
        <th scope="col">
          <span
            class="th-label"
            sortable="engagementName"
            (sort)="sortTable($event)">
              Engagement Name
          </span>
         <app-popover #popoverEngagementName
            [active]="isFilterApply('engagementName')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('engagementName')">
          </app-popover>
        </th>
        <th>
          <span class="th-label" sortable="adminAssigned" (sort)="sortTable($event)">
            Admin Assigned
          </span>
          <app-popover #popoveradminAssigned
          [active]="isFilterApply('adminAssigned')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('adminAssigned')">
        </app-popover>
        </th>
        <!-- <th scope="col">
          <span
            class="th-label"
            sortable="clientName"
            (sort)="sortTable($event)">
              Client Name
          </span>
         <app-popover #popoverClientName
            [active]="isFilterApply('clientName')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('clientName')">
          </app-popover>
        </th>
        <th scope="col">
          <span
            class="th-label"
            sortable="engagementStatus"
            (sort)="sortTable($event)">
            Engagement Status
          </span>
          <app-popover #popoverEngagementStatus
            [active]="isFilterApply('engagementStatus')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('engagementStatus')">
          </app-popover>
        </th> -->
        <!-- <th scope="col">
          <span
            class="th-label"
            sortable="complianceStatus"
            (sort)="sortTable($event)">
              Compliance Status
          </span>
          <app-popover #popoverComplianceStatus
            [active]="isFilterApply('complianceStatus')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('complianceStatus')">
          </app-popover>
        </th> -->
        <th scope="col">       
          <span
          class="th-label"
          sortable="status"
          (sort)="sortTable($event)">
          New Deal Form Status
        </span>
        <app-popover #popoverstatus
        [active]="isFilterApply('status')"
        [keepOpen]="true"
        [openOnFocus]="false"
        [tooltipTemplate]="popContent"
        [tooltipIcon]="'fas fa-filter th-filter'"
        [triggers]="'manual'"
        [autoClose]="'outside'"
        (click)="openFilterPopover('status')">
        </app-popover>
      </th>
      <th scope="col">       
        <span
        class="th-label"
        sortable="dealWBSStatus"
        (sort)="sortTable($event)">
        Deal WBS Form Status
      </span>
      <app-popover #popoverdealWBSStatus
      [active]="isFilterApply('dealWBSStatus')"
      [keepOpen]="true"
      [openOnFocus]="false"
      [tooltipTemplate]="popContent"
      [tooltipIcon]="'fas fa-filter th-filter'"
      [triggers]="'manual'"
      [autoClose]="'outside'"
      (click)="openFilterPopover('dealWBSStatus')">
      </app-popover>
    </th>
    <th scope="col">       
      <span
      class="th-label"
      sortable="closingChecklistStatus"
      (sort)="sortTable($event)">
      Closing Checklist Form Status
    </span>
    <app-popover #popoverclosingChecklistStatus
    [active]="isFilterApply('closingChecklistStatus')"
    [keepOpen]="true"
    [openOnFocus]="false"
    [tooltipTemplate]="popContent"
    [tooltipIcon]="'fas fa-filter th-filter'"
    [triggers]="'manual'"
    [autoClose]="'outside'"
    (click)="openFilterPopover('closingChecklistStatus')">
    </app-popover>
  </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let engagement of currentEngagementListPerPage">
        <td>
          <span>
            {{engagement.engagementId}}
          </span>
        </td>
        <td>
          <span
          class="link-label"
            (click)="openNewDealForm(engagement.engagementHealthObjectItemId)">
            {{engagement.engagementName==null||engagement.engagementName=='' ? 'Empty' : engagement.engagementName}}
          </span>
        </td>
        <td>
            <span *ngIf="engagement.adminUserName" class="deal-team-name">{{engagement.adminAssigned}}</span>
          <span *ngIf="!engagement.adminUserName">{{engagement.adminAssigned}}</span>
        </td>
        <!-- <td>
          <span>{{engagement.clientName}}</span>
        </td>
        <td>
          <span
            [ngStyle]="{'color': engagement.engagementStatusColor}">
            {{engagement.engagementStatus}}
          </span>
        </td> -->
        <!-- <td>
          <span
            [ngStyle]="{'color': engagement.complianceStatusColor}">
            {{engagement.complianceStatus}}
          </span>
        </td> -->
     
          <td>
            <!-- <ul style="list-style: none; margin-bottom: 0;padding-inline-start: 0px !important;"> -->
              <!-- <li *ngFor="let title of engagement.status">{{title}}</li> -->
              <!-- <p>{{engagement.status}} </p>
            </ul> -->
            <span style="list-style: none; margin-bottom: 0;padding-inline-start: 0px !important;"
            class="link-label"
              (click)="openNewDealForm_ED(engagement.newDealItemId)">
              {{engagement.status}}
            </span>
          </td>
          <td>
           <span *ngIf="engagement.dealWBSId"
            class="link-label"
            (click)="openDealWbsForm(engagement.dealWBSId)">
            {{engagement.dealWBSStatus}}
          </span>
          <ul *ngIf="!engagement.dealWBSId" style="list-style: none; margin-bottom: 0;padding-inline-start: 0px !important;">
            <p>{{engagement.dealWBSStatus}}</p>
          </ul>
          </td>
          <td>
            <span *ngIf="engagement.closingChecklistId"
          class="link-label"
            (click)="openCCFForm(engagement.closingChecklistId)">
            {{engagement.closingChecklistStatus}}
          </span>
          <ul *ngIf="!engagement.closingChecklistId" style="list-style: none; margin-bottom: 0;padding-inline-start: 0px !important;">
            <p>{{engagement.closingChecklistStatus}}</p>
          </ul>
          </td>
       
      </tr>
    </tbody>
  </table>
  </div>
  
  <span *ngIf="currentEngagementList && currentEngagementList.length === 0" class="no-matches">No engagements matched your search.</span>
  
  <div class="row">
    <div class="col-xs-5" style="margin-left:0%">
      <app-pagination #pagination
        *ngIf="currentEngagementList"
        [page]="engagementTableService.state.page"
        [pageSize]="engagementTableService.state.pageSize"
        [paginationListValues]="pageSizeListValues"
        [listElementsSize]="currentEngagementList.length"
        (paginationValues)="onChangePaginationValues($event)">
      </app-pagination>
    </div>
    <div class="col-md-5">
      <div class="divPageSizeType" id="divPageSizeType">
        <select id="ddlPageSize" (change)="onPageSizeValueChange($event)" [(ngModel)]="selectedPageSize"
          class="form-select form-control" [disabled]="pageDropdownDisable">
          <option value="" selected disabled>Select your option</option>
          <option *ngFor="let pages of pageSizeListValues" [value]="pages.key">
            {{pages.value}}
          </option>
        </select>
      </div>
    </div>
  </div>
  
  
  
  <ng-template #popContent>
    <div class="filter-container">
      <div class="filter-body">
        <div
          class="row sort-row"
          (click)="sortTableOnFilterModal(targetField, 'asc')">
            <i class="fas fa-sort-alpha-down sort-icon"></i>
            <span>A on Top</span>
        </div>
        <div
          class="row sort-row"
          (click)="sortTableOnFilterModal(targetField, 'desc')">
            <i class="fas fa-sort-alpha-down-alt sort-icon"></i>
            <span>Z on Top</span>
        </div>
        <hr>
        <div class="row">
          <div
            [ngClass]="{'clear-filter-container-enable': isFilterApply(targetField)}"
            (click)="clearFilters()">
              <i class="fas fa-filter filter-icon"></i>
              <span
                class="filter-icon-span"
                [ngClass]="{'filter-icon-span-enable': !isFilterApply(targetField)}">
                  Clear Filters from Value
              </span>
          </div>
        </div>
        <div *ngFor="let value of fieldOptionValues"
          class="row">
          <label class="checkbox-label">
            <input
              type="checkbox"
              class="checkbox-option-field"
              id="{{value}}"
              [checked]="isChecked(value)"
              (click)="onClickFilterCheckbox($event)">
            {{value}}
          </label>
        </div>
      </div>
    </div>
  </ng-template>
  