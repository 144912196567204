import { environment } from "src/environments/environment";
import { BlockedFileTypes } from 'src/shared/models/blocked-file-types';
import { DropdownItem } from "./models/dropdown-item";
import { DocumentTypeItem } from "./models/document-type-item";
import { AttachmentItem } from "./models/attachment-item";
import { FormListFields } from "src/app/closing-checklist-form/shared/models/form-list-fields";

export class AppSettings {
  // static closingChecklistSignOffStatus(closingChecklistSignOffStatus: any) {
  //   throw new Error('Method not implemented.');
  // } ClosingChecklistSignOffStatus commented by Surendar
  // Engagement Dashboard lists

static associatesAnalystsGroup(currentAccessPage:string){
  if(currentAccessPage=="DealWBSForm"){
    return (environment.userGroupPreffix + ' DealWBS Associates and Analysts').trim();
 }
 else if(currentAccessPage=="NewDealForm"){
    return (environment.userGroupPreffix + ' NewDeal Associates and Analysts').trim();
 }
 else{
    return (environment.userGroupPreffix + ' NewDeal Associates and Analysts').trim();
 }
}
  static adminsGroup(currentAccessPage:string){
    if(currentAccessPage=="DealWBSForm"){
      return (environment.userGroupPreffix + ' DealWBS Administrators').trim();
   }
   else if(currentAccessPage=="NewDealForm"){
    return 'NewDeal Administrators';
   }
   else{
     return 'MyDeal Administrators';
   }
  }
  static FileExtensionLists = "pdf,doc,docx,txt,xls,xlsx,xlsm,msg,png,jpeg,zip,csv,jpg,gif,tif,tiff,ppt,pptx";
  static closingCheckListAdminsGroup: string = (environment.userGroupPreffix + ' ClosingCheckList Administrators').trim();
  static myDeal: string = 'MyDeal' + environment.customListSuffix;
  static nonBankLender: string = "Non-Bank Lender";
  static bankLender: string = "Bank Lender";
  static privateEquityGroup = "Private Equity Group";
// New Deal lists

  static engagementIdInitialValue = 100000;
  //static pageSizeDefault = 25;
    static validFormStatus = ['Draft', 'Pending', 'Discontinued', 'Completed'];
    static initialFormStatus = 'Draft';
    static initialFormActionGroup = 'DealTeamMembers';

    //static appConfigurarionList: string = 'AuditFormAppConfiguration' + "environment.customListSuffix";
    
    static forceItemReleaseInterval = 1000;
    static pageSizeDefault = 100;
    static EngagementDefaultPageSize = 25;
    static crossBorder: string = environment.redirectUrl+'crossborderform' ;
    static newDeal: string = environment.redirectUrl+'newdeal' ;
    static dealWbsForm: string = environment.redirectUrl+'dealWbsForm' ;
    static CAFForm: string = environment.redirectUrl+'CAFForm' ;
    static briefingForm:string=environment.redirectUrl+'briefingForm';
    static ClosingCheckListForm: string = environment.redirectUrl+'closingCheckListForm' ;
    static ClosingCheckListFormDasboard: string = environment.redirectUrl+'closingChecklistDashboard' ;
    static DashboardURL: string = environment.redirectUrl+'Dashboard' ;
    static AdminURL: string = environment.redirectUrl+'admin' ;
    static ContentURL: string = environment.redirectUrl+'content' 
    static Engagementdashboard: string = environment.redirectUrl+'engagementDashboard' ;
    static ClosingChecklistDashboard: string = environment.redirectUrl+'closingChecklistDashboard' ;
    static EnagagementHealthObject: string = environment.redirectUrl + 'engagementHealthObject';
    static terminationForm: string = environment.redirectUrl + 'terminationForm';
    static terminationFormDashboard: string = environment.redirectUrl + 'terminationFormDashboard';
   static crossBorderBaseUrl = environment.siteCollectionURL+environment.subSite;  
   static DocumentManager:string = 'DocumentManager';   
   static GetSelectedADGroupDetails(search: any) {
    return "https://graph.microsoft.com/v1.0/users?$filter=startswith(givenName,'" + search + "')or startswith(displayName,'" + search.trim() + "')or startswith(mail,'" + search + "')  or startswith(surname,'" + search + "') or startswith(givenName,'" + search + "') or startswith(userPrincipalName,'" + search + "')&$top=700";
  }
  static GetDealADGroupDetails() {
    if (localStorage.getItem("EnvironmentFlag")?.toString().toLowerCase() != "prd") {
      return "https://graph.microsoft.com/v1.0/groups/712dc294-e8a5-4cde-85ad-243e9c7a4c43/members?$select=displayName,jobTitle,userPrincipalName&$top=300";
    }
    else {
      return "https://graph.microsoft.com/v1.0/groups/cfb660ea-e955-421f-bb47-d7d091376443/members?$select=displayName,jobTitle,userPrincipalName&$top=300";
    }
  }
  static GetLCSPADGroupDetails() {
    if (localStorage.getItem("EnvironmentFlag")?.toString().toLowerCase() != "prd") {
      // return environment.documentUrl+'/api/document/SearchTest';
      return environment.documentUrl+'/api/document/SearchTest?criteria=';
    }
    else {
      // return environment.documentUrl+'/api/document/SearchTest';
      return environment.documentUrl+'/api/document/SearchTest?criteria=';
    }
  }

   static docEncryptionUrl:string= "https://sadvisorysharedservices.deloitte.com/api/document/ValidateUploadedFile";//For Non-Prod
     //static docEncryptionUrl:string= "https://advisorysharedservices.deloitte.com/api/document/ValidateUploadedFile";//For Prod
    
    //static adminsGroup: string = (environment.userGroupPreffix + ' MyDeal Administrators').trim();

    // Autosave const
    static autoSaveIntervalDefault = 60000; // Default is 1 minute (60000 milliseconds)
    static autoSaveSpinningWheelIntervalDefault=3000;// Default is 3 seconds (3000 milliseconds)
    static forceAutosaveMaxRetry = 7;
    static forceAutosaveIntervalRetry = 4000;
    static maxLengthComments = 280;

    // API error codes
    static currencyError = 409;
    static sessionForbiddenError = 403;

    // Autosave messages
    static autosaveOk = 'Autosave executed at {timestamp}';
    static autosaveWithUpdates = 'Autosave executed at {timestamp}, updates from another session were retrieved and highlighted.';
    static autosaveWithConflicts = 'Autosave executed at {timestamp}, but the highlighted fields were overwritten.';

    // Error messages
    // tslint:disable-next-line: max-line-length
    static concurrencyError = 'Unfortunately, the changes were not saved since this form is being edited by another user. Please try again later.';
    static genericError = 'There has been an error. Please reload the page and try again.';
    static sessionTimeOutError = 'The session has timed out. Please reload the page and try again.';

    // new deal setup lists
    static newDealPageSource:string = 'NewDeal';
    static dealWbsPageSource:string = 'DealWbs';
    static closingCheckListPageSource:string = 'ClosingCheckList';
    static formListName: string = 'NewDealSetupForm';
    static competitorsCustomList: string = 'NewDealCompetitorsOptions'  + environment.customListSuffix;
    static competitorsList: string = 'NewDealCompetitorsList' + environment.customListSuffix;
    static validationsList: string = 'NewDealValidationFields' 
    static securityList: string = 'NewDealSecurity' + environment.customListSuffix;
    static newdealDocumentTypesList: string = 'NewDealDocumentTypes';
    static dealWbsDocumentTypesList: string = 'DealWBSDocumentTypes';
    static documentTypesList: string = 'NewDealDocumentTypes';
    static additionalDocumentTypesList: string = 'NewDealAdditionalDocumentTypes';
    static newdealAttachmentsList: string = 'NewDealAttachments';
    static dealWbslAttachmentsList: string = 'DealWbsAttachments';
    static closingCheckListAttachmentsList: string = 'ClosingChecklistAttachments';
    static appConfigurarionList: string = 'NewDealAppConfiguration' + environment.customListSuffix;
    static tooltipsList: string = 'NewDealTooltips' + environment.customListSuffix;
    static marketOfferingMapValuesList = 'NewDealMarketOfferingMap';
    static adminReviewRotationAssignmentList: string = 'NewDealAdminReviewRotationAssignment';
    static newDealReviewComments: string = 'NewDealReviewComments'; 
    static newDealAttachementFolder:string = 'NewDealAttachments';
    static dealWbsAttachementFolder:string = 'DealWbsAttachments';
    // my deal lists
    static myDealFormList: string = 'MyDeal';
    static salesOfficeCustomList: string = 'MyDealSalesOffice';
    static deloitteUSEntityCustomList: string = 'MyDealDeloitteUSEntity';
    static dealTeamRole: string = 'MyDealRoles'
    static dealTeamPursuitRole: string = 'MyDealPursuitRoles';
    static dealTeamSwiftRole: string = 'MyDealSwiftRoles';
    static dealTeamTableList: string = 'MyDealDealTeam'
    static myDealSiteColumn: string = 'MyDeal' + 'ID';

    // engagement health lists
    static engagementObjectList: string = 'EngagementHealthObject' + environment.customListSuffix;

    // Form default values
    static deloitteUSEntityDefaultValue = 37;
    static engagementTypeDefaultValue = 'Client Pursuit';
    static integratedMarketOfferingDefaultValue = 'M&A Transaction CFMO';
    static materialDefaultValue = 'Management Consulting';
    static attestStatusDefaultValue = 'Non-Attest';
    static deloitteOnlineUsers ='Deloitte OnLine Users'
    static placeholderPreviousS24 = 'Add new Series 24 here...';
    static placeholderPreviousPPMD = 'Add new PPMD here...';
    static placeholderDealTeam = 'Add new Deal team members here...'
  
//     US CIAM LCSP Contacts 1 = 56ab1808-efac-4b7e-8167-da441b8d5d93
// US CIAM LCSP Contacts = ab3005f4-682d-4ee4-9125-7784cd7d474f

//session related messages
static tokenExpiredText="tokenexpired";
static sessionExpiredMessage ="Session Expired. Page will be reloaded.";

    // Groups
    static closingCheckListAdminGroup:string = 'ClosingCheckList Administrators';
    static closingCheckListseries24Group:string = 'ClosingCheckList_Series24';
    static closingCheckListBusinessAdminGroup:string = 'ClosingCheckList_Business';
    static closingChecklistVisitorsGroup:string = 'Closing Checklist Visitors';

    static series24Group: string = 'MyDeal_S24';
    static myDealAdminsGroup: string = 'MyDeal Administrators';
    static dealWbsAdminsGroup: string ='DealWBS Administrators'
    static adminReviewersGroup: string =  'Admin Reviewers'; 
    static dealWbsAssociatesAnalystsGroup: string = 'DealWBS Associates and Analysts';
    static dcfComplianceGroup: string = (environment.userGroupPreffix + ' DCF Compliance').trim();
    static businessAdminsGroup: string = (environment.userGroupPreffix + ' ClosingCheckList_Business').trim();
    static dealTeamMembersGroup = 'DealTeamMember';

    static attachmentsFolderName = 'NewDealSetup_';
    static defaultDateField = 'FinancialYearEnd';
    static NewDealvalidFormStatus = ['Draft', 'Pending', 'Discontinued', 'Completed'];

    static dealTeamRoleValue = 'PPMD';
    static dealTeamPursuitRoleValue = 'N/A';
    static dealTeamSwiftRoleValue = 'Engagement Partner';

    static defaultfirstColumnValue = 'N/A';
    static defaultSecondColumnValue = 'Other';
    static defaultThirdColumnValue = 'N/A';

    static maxUrlLength = 290;
    static invalidFileNameCharactersList = ['#', '{', '}', '%', '~', '&'];
    static blockedFileTypes: Array<BlockedFileTypes> = [BlockedFileTypes.exe, BlockedFileTypes.hta, BlockedFileTypes.json];    
    //static apiDocValidationUrl =environment.documentUrl+'/api/document/ValidateUploadedFile/';

//auditLog
    static dealTeamAuditLog='DealTeamAuditLog';
   
    //swiftroles
    static madatoryDealTeamSwiftRoles: Array<string> = ['Engagement Partner' ,
                                                        'Engagement Manager',
                                                        'Billing Partner']

    static rootSiteListArray: Array<string> = [AppSettings.myDealFormList, AppSettings.salesOfficeCustomList,
                                               AppSettings.deloitteUSEntityCustomList,
                                               AppSettings.dealTeamRole,
                                               AppSettings.dealTeamPursuitRole,
                                               AppSettings.dealTeamSwiftRole,
                                               AppSettings.dealTeamTableList,
    AppSettings.engagementObjectList, AppSettings.dealTeamAuditLog];
    static dealTealUserSearchLog = 'DealTealUserSearchLog'

    //Static DDLS DataList New Deal

  static ClientTypeListArray: Array<DropdownItem> = [{ key: "Prospective", value: "Prospective" }, { key: "Sold-To", value: "Sold-To" }];

  static OwnerTypeL1ListArray: Array<DropdownItem> = [{ key: "Adjustment", value: "Adjustment" }, { key: "Deloitte Memberfirms", value: "Deloitte Memberfirms" },
  { key: "Public Sector", value: "Public Sector" }, { key: "Public Entities", value: "Public Entities" }, { key: "Private Entities", value: "Private Entities" }];

  static OwnerTypeL2ListArray: Array<DropdownItem> = [{ key: "Adjustment", value: "Adjustment" }, { key: "Deloitte Memberfirms", value: "Deloitte Memberfirms" },
  { key: "Governments", value: "Governments" }, { key: "NPOs", value: "NPOs" }, { key: "State Owned Entities", value: "State Owned Entities" },
  { key: "Public Owned Entities", value: "Public Owned Entities" }, { key: "Quasi Public Entities", value: "Quasi Public Entities" },
  { key: "Cooperatives", value: "Cooperatives" }, { key: "Personal Holdings & Trusts", value: "Personal Holdings & Trusts" }, { key: "Privately Owned Entities", value: "Privately Owned Entities" }];
  
  static OwnerTypeL3ListArray: Array<DropdownItem> = [{ key: "Adjustment", value: "Adjustment" }, { key: "Deloitte Memberfirms", value: "Deloitte Memberfirms" },
  { key: "PrOE Indigenous Bands", value: "PrOE Indigenous Bands" }, { key: "GOVT - National", value: "GOVT - National" }, { key: "GOVT - Local", value: "GOVT - Local" },
  { key: "GOVT - International", value: "GOVT - International" }, { key: "Charity", value: "Charity" },
  { key: "Not for Profit Organizations", value: "Not for Profit Organizations" }, { key: "Union", value: "Union" }, { key: "National", value: "National" },
  { key: "Regional", value: "Regional" }, { key: "Local", value: "Local" },
  { key: "International", value: "International" }, { key: "Public Corporation", value: "Public Corporation" }, { key: "GOVT - Regional", value: "GOVT - Regional" },
  { key: "PuOE-REITS", value: "PuOE-REITS" }, { key: "Health Authority", value: "Health Authority" },
  { key: "Association", value: "Association" }, { key: "Public Educational Institution", value: "Public Educational Institution" }, { key: "Cooperative", value: "Cooperative" },
  { key: "Personal (Including Tax)", value: "Personal (Including Tax)" }, { key: "Sole Proprietorship", value: "Sole Proprietorship" },
  { key: "Trust / Estate", value: "Trust / Estate" }, { key: "Family Business Trust", value: "Family Business Trust" }, { key: "Private Corporation", value: "Private Corporation" },
  { key: "Limited Partnerships", value: "Limited Partnerships" },{ key: "PrOE - Partnership", value: "PrOE - Partnership" }];

  static GlobalClientPortfolioListArray: Array<DropdownItem> = [{ key: "GCJR", value: "GCJR" }, { key: "GSR", value: "GSR" },
  { key: "GSA", value: "GSA" }, { key: "GCJA", value: "GCJA" }];

  static AttestStatusListArray: Array<DropdownItem> = [{ key: "Attest Loss with Restrictions", value: "Attest Loss with Restrictions" }, { key: "Attest Pursuit", value: "Attest Pursuit" },
  { key: "Attest Reocurring", value: "Attest Reocurring" }, { key: "Non-Attest", value: "Non-Attest" }];

  static AttestStatusParentListArray: Array<DropdownItem> = [{ key: "Attest Loss with Restrictions", value: "Attest Loss with Restrictions" }, { key: "Attest Pursuit", value: "Attest Pursuit" },
  { key: "Attest Reocurring", value: "Attest Reocurring" }, { key: "Non-Attest", value: "Non-Attest" }];

  static EntityOnWhichPerformsAttestWorkListArray: Array<DropdownItem> = [{ key: "Affiliate", value: "Affiliate" }, { key: "Client", value: "Client" },
  { key: "Investment Advisor, Sponsor or Founder", value: "Investment Advisor, Sponsor or Founder" }];

  static DescDesignationListArray: Array<DropdownItem> = [{ key: "Restricted", value: "Restricted" }, { key: "Watchlist", value: "Watchlist" },
  { key: "Reverse Restricted", value: "Reverse Restricted" }, { key: "Relationship", value: "Relationship" }];

  static ReasonForBypassingProceduresListArray: Array<DropdownItem> = [{ key: "Client Bankruptcy", value: "Client Bankruptcy" }, { key: "Data Issue", value: "Data Issue" },
  { key: "Other Client Situation", value: "Other Client Situation" }];

  static StatusListArray: Array<DropdownItem> = [{ key: "Pending", value: "Pending" }, { key: "Engaged", value: "Engaged" },
  { key: "Preliminary", value: "Preliminary" }, { key: "Prospect", value: "Prospect" }];

  static PublicTradedSecuritiesListArray: Array<DropdownItem> = [{ key: "Public", value: "Public" }, { key: "Private", value: "Private" },
  { key: "N/A", value: "N/A" }];

  static FirmTypeListArray: Array<DropdownItem> = [{ key: "Private Company-Corporate (>$1B)", value: "Private Company-Corporate (>$1B)" }, { key: "Private Company-Family (<$1B)", value: "Private Company-Family (<$1B)" },
  { key: "Private Company-International", value: "Private Company-International" }, { key: "Public Company-Domestic", value: "Public Company-Domestic" },{ key: "Public Company-International", value: "Public Company-International" }, 
  { key: "Private Equity Group", value: "Private Equity Group" },{ key: "Portfolio Company", value: "Portfolio Company" }, { key: "Bank Lender", value: "Bank Lender" },{ key: "Non-Bank Lender", value: "Non-Bank Lender" }];

  static IndustryListArray: Array<DropdownItem> = [{ key: "Business Services", value: "Business Services" }, { key: "Consumer", value: "Consumer" },
  { key: "Energy & Resources", value: "Energy & Resources" }, { key: "Financial Institutions", value: "Financial Institutions" },{ key: "Industrial Products", value: "Industrial Products" }, { key: "Life Sciences & Healthcare", value: "Life Sciences & Healthcare" },{ key: "TMT", value: "TMT" }];

  static SourceListArray: Array<DropdownItem> = [{ key: "Wealth Manager", value: "Wealth Manager" }, { key: "Law Firm", value: "Law Firm" },
  { key: "PEG", value: "PEG" }, { key: "Accountant", value: "Accountant" },{ key: "Former Client", value: "Former Client" }, { key: "MROC", value: "MROC" },{ key: "Deloitte (Global)", value: "Deloitte (Global)" }, { key: "Deloitte (US)", value: "Deloitte (US)" },
  { key: "Trade Association (YPO, etc.)", value: "Trade Association (YPO, etc.)" }, { key: "Trade Show", value: "Trade Show" },{ key: "DCF", value: "DCF" }];

  static TransactionTypeListArray: Array<DropdownItem> = [{ key: "Buyside", value: "Buyside" }, { key: "Sellside", value: "Sellside" },
  { key: "Capital Raise", value: "Capital Raise" }, { key: "Buyside/Capital Raise", value: "Buyside/Capital Raise" },{ key: "ESOP Recapitalization", value: "ESOP Recapitalization" }, { key: "Majority or Minority Recap", value: "Majority or Minority Recap" },
  { key: "Sellside/ESOP Recapitalization", value: "Sellside/ESOP Recapitalization" },{ key: "Strategic Advisory", value: "Strategic Advisory" },{ key: "Strategic Alternatives", value: "Strategic Alternatives" },{ key: "Other: Joint Venture", value: "Other: Joint Venture" },{ key: "Other: Restructuring", value: "Other: Restructuring" }];

  static WhereWorkMemberFirmsListArray: Array<DropdownItem> = [{ key: "Home / Local", value: "Home / Local" }, { key: "US", value: "US" },
  { key: "Other", value: "Other" }];

  static M_AConfidentialSubsidiaryListArray: Array<DropdownItem> = [{ key: "DCF CONF-CNSR: Auto", value: "DCF CONF-CNSR: Auto" }, { key: "DCF CONF-CNSR: Business Services", value: "DCF CONF-CNSR: Business Services" },
  { key: "DCF CONF-CNSR: Consumer Products", value: "DCF CONF-CNSR: Consumer Products" }, { key: "DCF CONF-CNSR: Tran/Hosp/Svcs", value: "DCF CONF-CNSR: Tran/Hosp/Svcs" },{ key: "DCF CONF-CNSR: Retail/Wholesale", value: "DCF CONF-CNSR: Retail/Wholesale" }, { key: "DCF CONF-ER&I: Oil/Gas& Chem", value: "DCF CONF-ER&I: Oil/Gas& Chem" },
  { key: "DCF CONF-ER&I: Power/Utilities", value: "DCF CONF-ER&I: Power/Utilities" }, { key: "DCF CONF-ER&I: Ind Prod/Constr", value: "DCF CONF-ER&I: Ind Prod/Constr" },
  { key: "DCF CONF-FS: Banking/Cap Mkts", value: "DCF CONF-FS: Banking/Cap Mkts" }, { key: "DCF CONF-FS: Insurance", value: "DCF CONF-FS: Insurance" },{ key: "DCF CONF-FS: Real Estate", value: "DCF CONF-FS: Real Estate" }, { key: "DCF CONF-FS: Invest Mgmt", value: "DCF CONF-FS: Invest Mgmt" },
  { key: "DCF CONF-LSHC: Health Care", value: "DCF CONF-LSHC: Health Care" }, { key: "DCF CONF-LSHC: Life Sciences", value: "DCF CONF-LSHC: Life Sciences" },
  { key: "DCF CONF-OTHER", value: "DCF CONF-OTHER" }, { key: "DCF CONF-TMT: Telecom/Media/Ent", value: "DCF CONF-TMT: Telecom/Media/Ent" },{ key: "DCF CONF-TMT: Technology", value: "DCF CONF-TMT: Technology" }];

  static CategoryListArray: Array<DropdownItem> = [{ key: "Mergers, Acquisitions & Divestitures", value: "Mergers, Acquisitions & Divestitures" }, { key: "Accounting & Reporting Transformation", value: "Accounting & Reporting Transformation" },
  { key: "Assurance", value: "Assurance" }, { key: "Corporate Restructuring", value: "Corporate Restructuring" },{ key: "Securitization", value: "Securitization" }, { key: "Valuation", value: "Valuation" },{ key: "Forensic & Investigations", value: "Forensic & Investigations" }, { key: "Internal Audit", value: "Internal Audit" },{ key: "Regulatory & Compliance", value: "Regulatory & Compliance" }, { key: "Cyber Risk", value: "Cyber Risk" },
  { key: "Finance & Operations", value: "Finance & Operations" }, { key: "Strategic Risk", value: "Strategic Risk" }];

  static SubCategoryListArray: Array<DropdownItem> = [{ key: "MA&D - Corporate Finance", value: "MA&D - Corporate Finance" }, { key: "A&RT - Financial Integrity", value: "A&RT - Financial Integrity" },
  { key: "A&RT - Accounting Advisory", value: "A&RT - Accounting Advisory" }, { key: "A&RT - Cost Transparency & Alignment", value: "A&RT - Cost Transparency & Alignment" },{ key: "A&RT - Integration, Divestitures & Readiness", value: "A&RT - Integration, Divestitures & Readiness" }, { key: "Corp. Restructuring - Company Advisory", value: "Corp. Restructuring - Company Advisory" },{ key: "Corp. Restructuring - Lender / Creditor Advisory", value: "Corp. Restructuring - Lender / Creditor Advisory" }, { key: "Corp. Restructuring - Bankruptcy Accounting & Compliance", value: "Corp. Restructuring - Bankruptcy Accounting & Compliance" },{ key: "Cyber Risk - Secure", value: "Cyber Risk - Secure" },
  { key: "Cyber Risk - Cyber Strategy & Governance", value: "Cyber Risk - Cyber Strategy & Governance" },{ key: "Cyber Risk - Resilient", value: "Cyber Risk - Resilient" }, { key: "Cyber Risk - Vigilant", value: "Cyber Risk - Vigilant" },{ key: "F&O - Operations & Treasury Advsry Svcs (OTAS)", value: "F&O - Operations & Treasury Advsry Svcs (OTAS)" }, { key: "F&O - Extended Enterprise Risk Mgmt (EERM)", value: "F&O - Extended Enterprise Risk Mgmt (EERM)" },{ key: "F&I - Forensic Investigations", value: "F&I - Forensic Investigations" },
  { key: "F&I - Litigation Dispute Consulting", value: "F&I - Litigation Dispute Consulting" },{ key: "F&I - Discovery", value: "F&I - Discovery" }, { key: "F&I - Business Intelligence", value: "F&I - Business Intelligence" },{ key: "IA - IA Outsourcing", value: "IA - IA Outsourcing" }, { key: "IA - IA Cosourcing", value: "IA - IA Cosourcing" },{ key: "IA - Strategy, Assmts, Innovation & Transform", value: "IA - Strategy, Assmts, Innovation & Transform" },
  { key: "IA - Information Technology Internal Audit", value: "IA - Information Technology Internal Audit" },{ key: "MA&D - M&A Transaction Services", value: "MA&D - M&A Transaction Services" }, { key: "R&C - Compliance Program Management", value: "R&C - Compliance Program Management" },{ key: "R&C - Risk & Capital", value: "R&C - Risk & Capital" }, { key: "R&C - Regulatory Risk & Compliance Services", value: "R&C - Regulatory Risk & Compliance Services" },{ key: "R&C - Anti-Money Laundering (AML)", value: "R&C - Anti-Money Laundering (AML)" },
  { key: "R&C - Regulatory Remediations", value: "R&C - Regulatory Remediations" },{ key: "Securitization - Technology Solutions", value: "Securitization - Technology Solutions" }, { key: "Securitization - Transaction Support", value: "Securitization - Transaction Support" },{ key: "Securitization - PRDA - Software", value: "Securitization - PRDA - Software" }, { key: "Strategic Risk - Brand and Customer Experience Solutions", value: "Strategic Risk - Brand and Customer Experience Solutions" },{ key: "Strategic Risk - Crisis Management Solutions", value: "Strategic Risk - Crisis Management Solutions" },
  { key: "Strategic Risk - Strategic Risk Solutions", value: "Strategic Risk - Strategic Risk Solutions" },{ key: "Valuation - Capital Asset Advisory", value: "Valuation - Capital Asset Advisory" }, { key: "Valuation - Transaction Compliance Valuation", value: "Valuation - Transaction Compliance Valuation" },{ key: "Valuation - Transaction & Modeling Advisory", value: "Valuation - Transaction & Modeling Advisory" }, { key: "Assurance - Third Party Assurance", value: "Assurance - Third Party Assurance" },{ key: "Not Assigned", value: "Not Assigned" }];

  static OfferingListArray: Array<DropdownItem> = [{ key: "0254 - FAS Service Offering 1 (Buy Side Services)", value: "0254 - FAS Service Offering 1 (Buy Side Services)" }, { key: "0255 - FAS Service Offering 2 (Infraestructure Advisory)", value: "0255 - FAS Service Offering 2 (Infraestructure Advisory)" },
  { key: "0256 - FAS Service Offering 3 (Capital Advisory Services)", value: "0256 - FAS Service Offering 3 (Capital Advisory Services)" }, { key: "0257 - FAS Service Offering 4 (Corporate Development Advisory Services)", value: "0257 - FAS Service Offering 4 (Corporate Development Advisory Services)" },{ key: "0260 - FAS Service Offering 7 (Sell Side Services)", value: "0260 - FAS Service Offering 7 (Sell Side Services)" }];

  static EngagementIndustrySectorListArray: Array<DropdownItem> = [{ key: "CNSR: Automotive", value: "CNSR: Automotive" }, { key: "CNSR: Consumer Products", value: "CNSR: Consumer Products" },
  { key: "CNSR: Retail, Wholesale & Distribution", value: "CNSR: Retail, Wholesale & Distribution" }, { key: "CNSR: Transportation, Hospitality & Services", value: "CNSR: Transportation, Hospitality & Services" },{ key: "ER&I: Industrial Products & Construction", value: "ER&I: Industrial Products & Construction" }, { key: "ER&I: Oil, Gas & Chemicals", value: "ER&I: Oil, Gas & Chemicals" },{ key: "ER&I: Power and Utilities", value: "ER&I: Power and Utilities" },
  { key: "FS: Banking & Capital Markets", value: "FS: Banking & Capital Markets" }, { key: "FS: Insurance", value: "FS: Insurance" },{ key: "FS: Investment Management", value: "FS: Investment Management" }, { key: "FS: Real Estate", value: "FS: Real Estate" },{ key: "GPS: Civil Government", value: "GPS: Civil Government" },
  { key: "GPS: Defense, Security & Justice", value: "GPS: Defense, Security & Justice" }, { key: "GPS: Federal Health", value: "GPS: Federal Health" },{ key: "GPS: State, Local & Higher Education", value: "GPS: State, Local & Higher Education" }, { key: "LSHC: Health Care", value: "LSHC: Health Care" },{ key: "LSHC: Life Sciences", value: "LSHC: Life Sciences" },
  { key: "OTH: Holding Companies", value: "OTH: Holding Companies" }, { key: "OTH: Individuals and Estates", value: "OTH: Individuals and Estates" },{ key: "OTH: Unclassified Industries", value: "OTH: Unclassified Industries" }, { key: "TMT: Technology", value: "TMT: Technology" },{ key: "TMT: Telecom, Media and Entertainment", value: "TMT: Telecom, Media and Entertainment" }];

  static FeeTypeListArray: Array<DropdownItem> = [{ key: "Contingency CTS", value: "Contingency CTS" }, { key: "Contingency Other", value: "Contingency Other" },
  { key: "Contingent - MLTN (CTS only)", value: "Contingent - MLTN (CTS only)" }, { key: "Contingent - SA (CTS only)", value: "Contingent - SA (CTS only)" },{ key: "Contingent - Should (CTS only)", value: "Contingent - Should (CTS only)" }, { key: "Cost Plus Fixed Fee", value: "Cost Plus Fixed Fee" },
  { key: "Fixed", value: "Fixed" }, { key: "Fixed - Traditional", value: "Fixed - Traditional" },{ key: "Fixed - Value (CTS only)", value: "Fixed - Value (CTS only)" }, { key: "I2I Phase I Team", value: "I2I Phase I Team" },
  { key: "I2I Phase I VBB", value: "I2I Phase I VBB" }, { key: "I2I Value Influenced", value: "I2I Value Influenced" },{ key: "I2I VBB", value: "I2I VBB" }];

  static MemberFirmRevenueListArray: Array<DropdownItem> = [{ key: "AMERICAS: US (US/USI)", value: "AMERICAS: US (US/USI)" }, { key: "AMERICAS: USI ONLY", value: "AMERICAS: USI ONLY" },
  { key: "AMERICAS: CANADA", value: "AMERICAS: CANADA" }, { key: "AMERICAS: MEXICO", value: "AMERICAS: MEXICO" },{ key: "AMERICAS: S. AMERICA", value: "AMERICAS: S. AMERICA" }, { key: "APAC: AUSTRALIA", value: "APAC: AUSTRALIA" },
  { key: "APAC: HONG KONG / CHINA", value: "APAC: HONG KONG / CHINA" }, { key: "APAC: INDIA", value: "APAC: INDIA" },{ key: "APAC: JAPAN", value: "APAC: JAPAN" }, { key: "APAC: SOUTHEAST ASIA", value: "APAC: SOUTHEAST ASIA" },
  { key: "ASIA PACIFIC: CHINA", value: "ASIA PACIFIC: CHINA" }, { key: "ASIA PACIFIC: JAPAN", value: "ASIA PACIFIC: JAPAN" },{ key: "ASIA PACIFIC: OTHER", value: "ASIA PACIFIC: OTHER" }, { key: "AFRICA", value: "AFRICA" },
  { key: "EMEA: BELGIUM", value: "EMEA: BELGIUM" }, { key: "EMEA: CENTRAL EUROPE", value: "EMEA: CENTRAL EUROPE" },{ key: "EMEA: DENMARK", value: "EMEA: DENMARK" }, { key: "EMEA: FRANCE", value: "EMEA: FRANCE" },
  { key: "EMEA: GERMANY", value: "EMEA: GERMANY" }, { key: "EMEA: IRELAND", value: "EMEA: IRELAND" },{ key: "EMEA: ITALY", value: "EMEA: ITALY" }, { key: "EMEA: MIDDLE EAST", value: "EMEA: MIDDLE EAST" },
  { key: "EMEA: NETHERLANDS", value: "EMEA: NETHERLANDS" }, { key: "EMEA: SPAIN", value: "EMEA: SPAIN" },{ key: "EMEA: UNITED KINGDOM", value: "EMEA: UNITED KINGDOM" }, { key: "MIDDLE EAST", value: "MIDDLE EAST" }];

  //Closing Checklist Dropdown values

  static buyerTypeOptions: Array<DropdownItem> = [{ key: "Private Company - Family (<1BN Rev)", value: "Private Company - Family (<1BN Rev)" },
    { key: "Private Company - Corporate (>1BN Rev)", value: "Private Company - Corporate (>1BN Rev)" },
    { key: "Private Company - International", value: "Private Company - International" },
    { key: "Private Equity Group", value: "Private Equity Group" },
    { key: "Portfolio Company", value: "Portfolio Company" },
    { key: "Bank Lender", value: "Bank Lender" },
    { key: "Non-Bank Lender", value: "Non-Bank Lender" },
    { key: "Public Company - Domestic", value: "Public Company - Domestic" },
    { key: "Public Company - International", value: "Public Company - International" }
  ];
  static buyerRelationshipOptions: Array<DropdownItem> = [
    { key: "Existing DCF Relationship", value: "Existing DCF Relationship" },
    { key: "Deloitte Client/Relationship", value: "Deloitte Client/Relationship" },
    { key: "No Existing Relationship", value: "No Existing Relationship" },
    { key: "Client (Seller) Relationship", value: "Client (Seller) Relationship" }
  ];
  static marketProcessTypeOptions: Array<DropdownItem> = [
    { key: "Full Process", value: "Full Process" },
    { key: "Preemptive Only", value: "Preemptive Only" },
    { key: "Limited Process/Fireside Chats", value: "Limited Process/Fireside Chats" },
    { key: "Other", value: "Other" }
  ];
  static legalStructureOptions: Array<DropdownItem> = [
    { key: "Asset Sale", value: "Asset Sale" },
    { key: "Stock Sale", value: "Stock Sale" },
    { key: "Merger", value: "Merger" },
    { key: "Stock 338(h)(10)", value: "Stock 338(h)(10)" },
    { key: "Other", value: "Other" }
  ];
  static managementFeeOptions: Array<DropdownItem> = [
    { key: "Yes - %", value: "Yes - %" },
    { key: "Yes - $", value: "Yes - $" },
    { key: "No", value: "No" },
    { key: "N/A - Strategic Deal", value: "N/A - Strategic Deal" }
  ];

  static counterpartiesOptions: Array<DropdownItem> = [
    { key: "YES", value: "YES" },
    { key: "NO", value: "NO" }
  ];
  static isXBEngagementAnOptions: Array<DropdownItem> = [
    { key: "Outbound Cross Border (DCF Prime)", value: "Outbound Cross Border (DCF Prime)" },
    { key: "Inbound Cross Border (DCF Subcontractor)", value: "Inbound Cross Border (DCF Subcontractor)" }
  ];
  // static currents24Options: Array<DropdownItem> = [
  //   { key: "7", value: "Ponnada, Siva " },
  //   { key: "14829", value: "Raghuwanshi, Shalu " },
  //   { key: "10319", value: "Rajendhiran, Rajesh  " }
  // ];
  static clientTypeOptions: Array<DropdownItem> = [
    { key: "PEG-Backed", value: "PEG-Backed " },
    { key: "Family/Entrepreneur Owned", value: "Family/Entrepreneur Owned" },
    { key: "Corporate - Private", value: "Corporate - Private" },
    { key: "Corporate - Public", value: "Corporate - Public" }
  ];
  static clientRelationshipOptions: Array<DropdownItem> = [
    { key: "Existing Deloitte Client", value: "Existing Deloitte Client" },
    { key: "Prior Deloitte Client", value: "Prior Deloitte Client" },
    { key: "Restricted Entity Client", value: "Restricted Entity Client" },
    { key: "New Client", value: "New Client" }
  ];
  static referralSourceOptions: Array<DropdownItem> = [
    { key: "DCF", value: "DCF" },
    { key: "DCF - MROC", value: "DCF - MROC" },
    { key: "Wealth Manager", value: "Wealth Manager" },
    { key: "Deloitte US (Other SL)", value: "Deloitte US (Other SL)" },
    { key: "Deloitte CF INTL", value: "Deloitte CF INTL" },
    { key: "Law Firm", value: "Law Firm" },
    { key: "PEG", value: "PEG" },
    { key: "Accountant (Non Deloitte)", value: "Accountant (Non Deloitte)" },
    { key: "Former Client", value: "Former Client" },
    { key: "Trade Association (YPO, Vistage, etc.)", value: "Trade Association (YPO, Vistage, etc.)" },
    { key: "Trade Show", value: "Trade Show" }
  ];
  static industryOptions: Array<DropdownItem> = [
    { key: "TMT", value: "TMT" },
    { key: "Industrial Products", value: "Industrial Products" },
    { key: "Life Sciences & Healthcare", value: "Life Sciences & Healthcare" },
    { key: "Business Services", value: "Business Services" },
    { key: "Consumer", value: "Consumer" },
    { key: "Energy & Resources", value: "Energy & Resources" },
    { key: "Financial Institutions ", value: "Financial Institutions " }
  ];
  static dealTypeOptions: Array<DropdownItem> = [
    { key: "Sellside", value: "Sellside" },
    { key: "Buyside", value: "Buyside" },
    { key: "Buyside/Capital Raise", value: "Buyside/Capital Raise" },
    { key: "Capital Raise", value: "Capital Raise" },
    { key: "Strategic Advisory", value: "Strategic Advisory" },
    { key: "ESOP Advisory", value: "ESOP Advisory" },
    { key: "Fairness Opinion", value: "Fairness Opinion" },
    { key: "Other", value: "Other" }
  ];
  static complianceDealTypeOptions: Array<DropdownItem> = [
    { key: "Sell Side", value: "Sell Side" },
    { key: "Buy Side", value: "Buy Side" },
    { key: "Capital Raise – Equity Only", value: "Capital Raise – Equity Only" },
    { key: "Capital Raise – Debt Only", value: "Capital Raise – Debt Only" },
    { key: "Capital Raise – Bank Loan (Debt Provided only by Federal or State Chartered Bank(s))", value: "Capital Raise – Bank Loan (Debt Provided only by Federal or State Chartered Bank(s))" },
    { key: "Capital Raise – Debt and Equity", value: "Capital Raise – Debt and Equity" },
    { key: "Consultative", value: "Consultative" },
    { key: "Fairness Opinion", value: "Fairness Opinion" }
  ];
  static numberOfParticipantsOptions: Array<DropdownItem> = [
    { key: "Unknown", value: "Unknown" },
    { key: "1", value: 1 },
    { key: "2", value: 2 },
    { key: "3", value: 3 },
    { key: "4", value: 4 },
    { key: "5", value: 5 },
    { key: "6", value: 6 },
    { key: "7", value: 7 },
    { key: "8", value: 8 },
    { key: "9", value: 9 },
    { key: "10", value: 10 }
  ];
  

   //EngagementDashboard
   static engagementAvailableForms: string = 'EngagementAvailableForms' + environment.customListSuffix;
   static engagementHealthList: string = 'EngagementHealthObject' + environment.customListSuffix;
   static newDealSetupFormList: string = 'NewDealSetupForm' + environment.customListSuffix;
   static paginationValuesList: string = 'EngagementDashboardPaginationValues' + environment.customListSuffix;
    // user profile link
  static dpnProfileLink = 'https://people.deloitteresources.com/dpn/index.html#/Profile/{userId}';

  static newDealSetupForm:string='New Deal Setup Form';
  // My Deal DealTeam list
  static myDealDealTeamList: string = 'MyDealDealTeam' + environment.customListSuffix;  
 // dealwbs data
 static dealWbsSetupFormList: string = 'DealWbsSetupForm' + environment.customListSuffix;
 static dealWbsSetupForm:string='Deal WBS Setup Form';
 static dealWbsvalidationsList: string = 'DealWbsValidationFields'
 // closingchecklist data
 static closingChecklistFormList: string = 'ClosingCheckListForm';
 static closingChecklistForm:string = 'Closing Checklist Form';
 static closingCheckListValidationList = 'ClosingCheckListValuations';
 static closingCheckList: string = 'ClosingCheckListForm' + environment.customListSuffix;

 // Closing CheckList Report Dashboard lists
 static reportDashboardFormList: string = 'ClosingChecklistFormReport' + environment.customListSuffix;
 static xborderDashboardUrl: string = 'CrossBorderFormReport' + environment.customListSuffix;
  //groups
  static closingchecklistGroup: string = (environment.userGroupPreffix + ' ClosingCheckList Administrators').trim();
  static newDealAdminsGroup: string = (environment.userGroupPreffix + ' NewDeal Administrators').trim();
  static associateAnalystGroup: string = (environment.userGroupPreffix + ' NewDeal Associates and Analysts').trim();
  static newDealBaseUrl = environment.redirectUrl.concat(environment.subSite);
  static reportDashboardBaseUrl = environment.redirectUrl.concat(environment.reportdashboardsubSite);
  static xborderDashboardBaseUrl = environment.redirectUrl.concat(environment.xborderdashboardsubSite);
  static closingCheckListBaseUrl = environment.redirectUrl.concat(environment.closingchecklistformsubSite);
  
//EHO Configs
static staticDealTeamAuditLog='DealTeamAuditLog';
static crossBorderFormList: string = 'CrossBorderForm' + environment.customListSuffix;
static cafFormList: string = 'CAFFormList' + environment.customListSuffix;
static myDealAdminGroup: string ='MyDeal Administrators'
static attachmentsListNewDeal: string = 'NewDealAttachments'          + environment.customListSuffix;
static attachmentsFolderNameNewDeal =   'NewDealSetup_';

// caf data
static attachmentsListCaf =             'CAFAttachments'              + environment.customListSuffix;
static attachmentsFolderNameCaf =       'CAF_';
static attachmentsListCrossBorder =             'CrossBorderAttachments'      + environment.customListSuffix;
  static attachmentsFolderNameCrossBorder =       'CrossBorder_';
  static myDealUrl = `MyDeal${environment.customListSuffix}.aspx#/main?item_id={formId}`;
  static initialAttachmentsToShow = 5;
    static myDealSiteColumnQuery: string = 'MyDeal' + environment.customListSuffix;

static GetEnvironmentURL(envType:string, localenv:string): string{
  if(envType!="local") return environment.apiUrl;
  return localenv;
}
public static PageSource = {
  EngagementDashboard :"EngagementDashboard",  
  TerminationDashboard :"TerminationDashboard",    
}
public static FormTypes = {
  NewDealSetupForm :"NewDealSetupForm",  
  DealWbsSetupForm :"DealWbsSetupForm", 
  ClosingCheckListForm: "ClosingCheckListForm"  
}
public static ContentManagementFormTypes = {
  NewDeal :"New Deal",  
  DealWbs :"Deal WBS",
  ClosingChecklist :"Closing Checklist",    
}
//Deal WBS Setup 
static billingPlanTypeDefaultValue = 'Periodic';
  static billingPlanOtherValue = 'SWIFT Milestone Billing - Svcs';
  static billingPlanName = 'billingPlanType';
  static billingIfName = 'billingIfMilestone';
  static billingIntName = 'billingIntervals';
  static pursuitRoleNA: number;
  static swiftRoleNA: number;
  static roleNA: number;
  static defaultValueRoleNA = 'N/A';
  static deloitteUSEntityList: string = 'MyDealDeloitteUSEntity' + environment.customListSuffix;
  static salesOfficeList: string = 'MyDealSalesOffice' + environment.customListSuffix;
 public static myDealTooltipList: string = 'MyDealTooltips' + environment.customListSuffix;
 public static dealWbsSetupTooltipsList: string = 'DealWbsSetupTooltips' + environment.customListSuffix;


  static isSubcontractingArrangementDefaultValue = 'YES';
  static allowInvoiceConsolidationDefaultValue = 'YES';
  static invoiceLayoutDefaultValue = 'Summary page only';
  static paymentTermsDefaultValue = 'Due and payable upon receipt';
  static fxRateDefaultValue = 'Variable';
  static contractTypeDefaultValue = 'Deloitte T&M SBR';
  static resourceRateTypeDefaultValue = 'National Bill Rate';
  static usiResourceRateTypeDefaultValue = 'Non Discountable';
  static budgetGroupDefaultValue = 'Budget Group 01';
  static plannedCostDefaultValue = '10000';
  static totalPlannedHoursDefaultValue = '1000';
  static eventIndicatorDefaultValue = 'NO';

  static resourceRateType: Array<DropdownItem> = [{ key: "National Bill Rate", value: "National Bill Rate" }, { key: "Local Bill Rate", value: "Local Bill Rate" }];
  static usiResourceRateType: Array<DropdownItem> = [{ key: "Discountable", value: "Discountable" }, { key: "Non Discountable", value: "Non Discountable" }];
  static budgetGroup: Array<DropdownItem> = [{ key: "Budget Group 01", value: "Budget Group 01" }, { key: "Budget Group 02", value: "Budget Group 02" }, { key: "Budget Group 03", value: "Budget Group 03" }, { key: "Budget Group 04", value: "Budget Group 04" }, { key: "Budget Group 05", value: "Budget Group 05" }, { key: "Budget Group 06", value: "Budget Group 06" }
    , { key: "Budget Group 07", value: "Budget Group 07" }, { key: "Budget Group 08", value: "Budget Group 08" }, { key: "Budget Group 09", value: "Budget Group 09" }, { key: "Budget Group 10", value: "Budget Group 10" }];
  static invoiceLayout: Array<DropdownItem> = [{ key: "Summary page only", value: "Summary page only" }, { key: "Summary page and detail page - monthly summary", value: "Summary page and detail page - monthly summary" }, { key: "Summary page and detail page - quarterly summary", value: "Summary page and detail page - quarterly summary" }, { key: "Summary page and detail page - yearly summary", value: "Summary page and detail page - yearly summary" }];
  static invoiceMedium: Array<DropdownItem> = [{ key: "Email", value: "Email" }, { key: "Print", value: "Print" }];
  static fxRate: Array<DropdownItem> = [{ key: "Variable", value: "Variable" }, { key: "Fixed", value: "Fixed" }];
  static paymentTerms: Array<DropdownItem> = [{ key: "Due and payable upon receipt", value: "Due and payable upon receipt" }, { key: "Within 1 day due net", value: "Within 1 day due net" }, { key: "10 days 4%, 20/2%, 30 net", value: "10 days 4%, 20/2%, 30 net" }, { key: "Within 7 days due net", value: "Within 7 days due net" }, { key: "Within 10 days due net", value: "Within 10 days due net" },
  { key: "Within 12 days due net", value: "Within 12 days due net" }, { key: "Within 15 days due net", value: "Within 15 days due net" }, { key: "Within 21 days due net", value: "Within 21 days due net" }, { key: "Within 25 days due net", value: "Within 25 days due net" },
  { key: "Within 30 days due net", value: "Within 30 days due net" }, { key: "Within 45 days due net", value: "Within 45 days due net" }, { key: "Within 52 days due net", value: "Within 52 days due net" }, { key: "Within 60 days due net", value: "Within 60 days due net" },
  { key: "Within 70 days due net", value: "Within 70 days due net" }, { key: "Within 90 days due net", value: "Within 90 days due net" }, { key: "Payable immediately due net", value: "Payable immediately due net" }, { key: "Within 15 days 2%, within 45 days due net", value: "Within 15 days 2%, within 45 days due net" },
  { key: "Due 4th month", value: "Due 4th month" }, { key: "Due in the 14th of next month", value: "Due in the 14th of next month" }, { key: "Due and payable in 1 day", value: "Due and payable in 1 day" }, { key: "Due on the 21st of next month", value: "Due on the 21st of next month" },
  { key: "Due 24th of the month", value: "Due 24th of the month" }, { key: "Due 1st of the month", value: "Due 1st of the month" }, { key: "Due 14th of the month", value: "Due 14th of the month" }, { key: "Due 21st of the month", value: "Due 21st of the month" },
  { key: "15 days 2%, 30 net", value: "15 days 2%, 30 net" }, { key: "2%/10, net 45", value: "2%/10, net 45" }, { key: "Due upon receipt", value: "Due upon receipt" }];
  static whereWorkMemberFirms: Array<DropdownItem> = [{ key: "Home / Local", value: "Home / Local" }, { key: "US", value: "US" }, { key: "Other", value: "Other" }];
 

  //Closing_Checklist
  //compliance lists
  static complianceDTTLList: string = 'ComplianceDTTLInvolved' + environment.customListSuffix;
  static compliancePreviousSeries24: string = 'CompliancePreviousSeries24' + environment.customListSuffix;
  static compliancePreviousPPMD: string = 'CompliancePreviousPPMD' + environment.customListSuffix;
  static closingCheckListCountries:string = 'ClosingCheckListCountries'+environment.customListSuffix;
  static closingCheckListIndustries:string = 'ClosingCheckListIndustries'+environment.customListSuffix;
  static complianceFormList: string = 'Compliance' + environment.customListSuffix;
  static complianceCounterpartiesList: string = 'ComplianceCounterParties' + environment.customListSuffix;
  static closingCheckListValuations:string='ClosingCheckListValuations'+environment.customListSuffix;
  static closingCheckListDealMarketingCount:string='ClosingCheckListDealMarketingCount'+environment.customListSuffix;
  static closingChecklistAdditionalComments:string = 'ClosingChecklistAdditionalComments'+environment.customListSuffix;
  static closingCheckListFeeStructureBreakpoints:string = 'ClosingCheckListFeeStructureBreakpoints'+environment.customListSuffix;
  static closingChecklistDealTeamSignOff:string = 'ClosingChecklistDealTeamSignOff'+environment.customListSuffix;
  static ClosingTooltipList: string = 'ClosingTooltipList' + environment.customListSuffix;
  static closingChecklistSignOffStatus:string = 'ClosingChecklistSignOffStatus'+environment.customListSuffix;
  static pegBacked: string = "PEG-Backed";
  static familyEntrepreneurOwned: string = 'Family/Entrepreneur Owned';
  static corporatePrivate: string = "Corporate - Private";
  static corporatePublic: string = "Corporate - Public";
  static dCFMROC: string = "DCF - MROC";



  //Closing_Checklist
    static attachmentsList:  string ="FinanceClosingChecklistAttachments"
    static strategicAdvisory:string = "Strategic Advisory";
    static documentType :DocumentTypeItem={
      "documentTypeId": 1,
      "documentTypeItemId": 1,
      "documentTypeTitle": "Final Fee Calculation Letter",
      "documentTypeRequired": true,
      "documentTypeAdditional": false,
      "documentTypeAttachments": [],
      equals: function (other: DocumentTypeItem): boolean {
        throw new Error('Function not implemented.');
      },
      isDocumentTypeIdEquals: function (otherDocumentTypeId: number): boolean {
        throw new Error('Function not implemented.');
      },
      isDocumentTypeAttachmentsEquals: function (otherDocumentTypeAttachments: AttachmentItem[]): boolean {
        throw new Error('Function not implemented.');
      }
  };
  static closingCheckListLocations: string = 'ClosingCheckListLocations' + environment.customListSuffix;
  static reviewCommentsList: string = 'ClosingChecklistAdditionalComments' + environment.customListSuffix;
  static dealMarketingObject: any =
    [FormListFields.buyerName, FormListFields.buyerCountry, FormListFields.buyerType, FormListFields.preemptiveFinalPurchaser, FormListFields.preemptiveOffer, FormListFields.buyerRelationship, FormListFields.marketingProcessType, FormListFields.buyerParentCompany, FormListFields.internationalBuyer, FormListFields.identifiedTierPitch, FormListFields.approachedUSStrategic, FormListFields.deloitteLCSP, FormListFields.preemptiveBidder,
    FormListFields.approachedIntlStrategic, FormListFields.approachedPEG, FormListFields.approachedPEGNoPortCo, FormListFields.describeDealMarketing, FormListFields.attendedMPPEG, FormListFields.sentCIMUSStrategic, FormListFields.sentCIMIntlStrategic, FormListFields.sentCIMPEGNoPortCo, FormListFields.sentCIMPEG,
      , FormListFields.submittedIOIIntlStrategic, FormListFields.submittedIOIUSStrategic, FormListFields.submittedIOIPEGNoPortCo, FormListFields.submittedIOIPEG, FormListFields.attendedMPPEG, FormListFields.attendedMPPEGNoPortCo, FormListFields.attendedMPUSStrategic, FormListFields.attendedMPIntlStrategic, FormListFields.submittedLOIIntlStrategic, FormListFields.submittedLOIPEG, FormListFields.submittedLOIPEGNoPortCo, FormListFields.submittedLOIUSStrategic];

    static dealTeamPursuitRoleId = "Other";
    static dealTeamSwiftRoleId = "N/A";  
    static signOffReviewStatus = "Reviewed";
    static signOffNAStatus = "N/A";
    static removeValidationField:Array<string> =
  [FormListFields.series24ReviewedTransaction,FormListFields.series24ReviewedPersonnelHours,FormListFields.series24TransactionComments,FormListFields.series24WBSCodeComments];
    static removeFinanceValidationField:Array<string> =[FormListFields.financeFileUpload,
    FormListFields.financeEnterpriseValue,
    FormListFields.financeDeloitteEntity,
    FormListFields.financeDeloitteEntityDescription,
    FormListFields.financeFutureExpensesAmount,
    FormListFields.financeFuturePayments,
    FormListFields.financeFuturePaymentsType,
    FormListFields.financeAssetSaleDeal,
    FormListFields.financeCrossBorderEngagement, FormListFields.financeCrossBorderCountry,
    FormListFields.financeProviderName,
    FormListFields.financeProviderNameType, FormListFields.financeDealLeagueTable,
    FormListFields.financeDealLeagueTableDesc, FormListFields.financeDealGlobalDeloitteSite,
    FormListFields.financeDealGlobalDelSiteDesc, FormListFields.financeDealAnnouncement,
    FormListFields.financeDealAnnouncementDesc, FormListFields.financeDealCaseStudy,
    FormListFields.financeDealCaseStudy,
    FormListFields.financeDealCaseStudyDesc,
    FormListFields.financeFutureExpenses,
    FormListFields.financeFutureExpenses,
  ]


  //Static DDLS DataList Closing Checklist
  static EBITDAPeriodListArray: Array<DropdownItem> = [{ key: "TTM", value: "TTM" }, { key: "Current Year Expected", value: "Current Year Expected" },
  { key: "Previous Year", value: "Previous Year" }, { key: "N/A", value: "N/A" }];
  static FinalEvPitchRangeListArray: Array<DropdownItem> = [{ key: "Above", value: "Above" }, { key: "Within", value: "Within" },
  { key: "Below", value: "Below" }, { key: "N/A", value: "N/A" }];
  static IfBelowWhyListArray: Array<DropdownItem> = [{ key: "Company performance", value: "Company performance" }, { key: "Market conditions", value: "Market conditions" },
  { key: "Other", value: "Other" }, { key: "N/A", value: "N/A" }];
  
  static ReconciliationEscrowArrayList: Array<DropdownItem> = [{ key: "Special Indemnification", value: "Special Indemnification" }, { key: "Post-Closing Adjustment", value: "Post-Closing Adjustment" },
  { key: "Other", value: "Other" }, { key: "None", value: "None" }];
  static BasketTypeArrayList: Array<DropdownItem> = [{ key: "True Deductible", value: "True Deductible" }, { key: "Tipping", value: "Tipping" },
  { key: "Combo", value: "Combo" }, { key: "None", value: "None" }];

  static StageArrayList : Array<DropdownItem> = [{ key: "N/A", value: "N/A" }, { key: "Pre-LOI", value: "Pre-LOI" }, { key: "Pre-IOI", value: "Pre-IOI" }, 
  { key: "Post-LOI", value: "Post-LOI" }, { key: "Pre-Market", value: "Pre-Market" }]
  
  static PeriodicOrFlatArrayList : Array<DropdownItem> = [{ key: "Periodic Retainer", value: "Periodic Retainer" }, { key: "Flat Fee", value: "Flat Fee" }]
  static EngagementFeeBasedArrayList : Array<DropdownItem> = [{ key: "T&M", value: "T&M" }, { key: "Milestone", value: "Milestone" }, { key: "Success", value: "Success" }]
  
  static FinanceFuturePaymentsArrayList : Array<DropdownItem> = [{ key: "Escrows", value: "Escrows" }, { key: "Earn outs", value: "Earn outs" }, { key: "Other", value: "Other" }]
  static FinanceProviderNameTypeArrayList : Array<DropdownItem> = [{ key: "Private - Family (<$1BN of Rev)", value: "Private - Family (<$1BN of Rev)" }, { key: "Private - Corporate ($>1BN of Rev)", value: "Private - Corporate ($>1BN of Rev)" }, { key: "Private - International", value: "Private - International" }
  , { key: "Public Company - Domestic", value: "Public Company - Domestic" }, { key: "Public Company - International", value: "Public Company - International" }
  , { key: "Private Equity Group", value: "Private Equity Group" }, { key: "Portfolio Company", value: "Portfolio Company" }, { key: "Bank Lender", value: "Bank Lender" } , { key: "Non-Bank Lender", value: "Non-Bank Lender" }
  ]
}
