import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TerminationDashboardItem  } from './models/terminationDashboardItem';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { NgbdSortableHeaderDirective, SortDirection, SortEvent } from 'src/shared/directives/sorteable.directive';
import { PaginationComponent } from 'src/shared/components/pagination/pagination.component';
import { ReportDashboardSort } from '../models/reportDashboard-sort';
import { PaginationValues } from 'src/shared/models/pagination-values';
import { DatePipe } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { AppSettings } from 'src/shared/app-settings';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';
import { DateService } from '../services/date.service';
import { ReportDashboardTableFilterItem } from '../models/reportDashboard-table-filter-item';
import { EngagementTableFilterItem } from '../models/engagement-table-filter-item';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { TerminationFormService } from '../services/terminationform.service';
import { TerminationDashboardTableFilterItem } from './models/terminationDashboard-table-filter-item';
import { CommonHelperService } from '../commonHelper/common-helper.service';
import { TerminationNotifyPopupComponent } from '../termination-form/termination-form-popup/termination-form-popup.component';
import { ScriptLoaderService } from '../services/script-loader.service';
import { GlobalHelperService } from '../globalHelper/global-helper.service';

@Component({
  selector: 'app-termination-dashboard',
  templateUrl: './termination-dashboard.component.html',
  styleUrls: ['./termination-dashboard.component.scss']
})
export class TerminationDashboardComponent implements OnInit {
  isColumnfilterApply:any = false;
  filterList: Array<ReportDashboardTableFilterItem> = [];
  initialTerminationDashboardListForSearch: Array<TerminationDashboardItem> = [];
  onSearchApplyOnFilteredData:Array<TerminationDashboardItem> = [];
  pageDropdownDisable: boolean;
  terminationDashboardsPerPage: Array<TerminationDashboardItem>;
  pageSizeListValues: Array<any> = [];
  selectedPageSize: any;
  terminationDashboards: Array<any>;
  @ViewChild(TerminationNotifyPopupComponent, { static: true }) terminationNotifyPopupComponent: TerminationNotifyPopupComponent;
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  targetField: string;
  reportDashboardExportToExcel: Array<any> = [];
  terminationDashboardPageDetails: Array<any> = [];
  showDisable: boolean;
  initialTerminationDashboardList: Array<TerminationDashboardItem> = [];
  targetFilter: ReportDashboardTableFilterItem;
  fieldOptionValues: Array<string> = [];
  AdminRole:any="";
  isTFAdmin:boolean=false;
  URL:any=AppSettings.terminationForm + '?id=';
  tooltipText:any={
    'Series24':'Name of Series 24 Licensed Supervisor at the time of termination',
    'TerminationReasonMatch':'Does the reason for termination match the reason contained within Talent file',
    'WasThreatContacted':'The insider threat team was contacted to confirm there are no open cases for the Terminated Individual.',
    'InternalOpenThreat':'Were there any open internal threat cases for this Terminated Individual?',
    'OutlookRemovedDate':'Date the Terminated Individual was removed from Outlook: US National Corporate Finance',
    'JakiePolsonNotifiedDate':'Date Jackie Polson (or team) was notified a PPMD or client facing PPMD was being terminated (if applicable)',
    'AllowedToTermedDate':'Date the Terminated Individual’s OBA(s) were removed from DCF’s list of active OBA’s.',
    'PSTToTermedClosedDeniedDate':'Date the Terminated Individual’s PST(s) were removed from DCF’s list of active PST’s.',
    'DCFKCRemovedDate':'Date the Terminated Individual was removed from DCF Knowledge Center',
    'DateTheEmployeeRemovedFlowPerms':'Date the Terminated Individual was removed from the Dflow permissions spreadsheet. (if applicable)',
    'DateSupportTicketOpened':'Date a support ticket was opened with DFlow to remove the Terminated Individual from open sites (if applicable)',
    'TermedEmployeeMovedDate':'Date the terminated individual was moved from the ‘Current Employees’ folder to the ‘Terminated Employees’ folder in  Associated Persons',
    'CSIRetiredDate': 'Date the Terminated Individual was “retired” in CSI',
    'RunSmarshEmailSpotCheck': 'Run Smarsh email spot check for the two weeks prior to termination date',
    'SupervisionGroupRemovedDate': 'Date the Terminated Individual was removed from Supervision Group structure in Smarsh',
    'SmarshTextCaptureRemovedDate': 'Date the Terminated Individual’s Deloitte mobile phone was submitted for removal from Smarsh text message capture?',
    'IdentifyIndividualTermedDate': 'Information has been added to the Term tab of the New Hire & Termed spreadsheet in Associated Persons',
    'SupervisorOrgChartRemovalDate': 'Date the Terminated Individual was removed from Supervisory organizational chart',
    'NotifiedBrokerageFirmsDate': 'Notified brokerage firm(s) and/or Terminated Individual to discontinue duplicate statements, confirmations, and, where applicable, broker data feed(s). ',
    'SentIndividualSignedCopy': 'Sent individual a signed copy of Termination Letter and Form U5',
    'TrackingNumber': 'Tracking number of Termination letter and Form U5',
    
  }
  searchTerm: any;
  formID:any="Dashboard";
  constructor(public adminService: AdminService, private datePipe: DatePipe,private dateService: DateService,
      private spinnerService: SpinnerService, private terminationFormService: TerminationFormService,
      public commonHelperService: CommonHelperService,public globalHelperService:GlobalHelperService
    ) { }

  ngOnInit(): void {   
    this.spinnerService.showAutoSaveSpinner();
    localStorage.setItem("CurrentAccessPage", "TerminationDashboard");
    this.globalHelperService.currentAccessPage="TerminationDashboard";
    this.commonHelperService.state = this.commonHelperService.getSearchState();
    this.removeBrowserScroll();
    this.ValidateUserAccess();
  }

  ValidateUserAccess() {
     this.IsUserTerminationFormAdmin();
  }
  VerifyADGroup(){
  let userId=localStorage.getItem("userProfileId")?.toString();
    this.adminService.GetDealADGroupDetails().subscribe({
      next: (profile: any) => {
        if (this._isDCFMemberInAdGroup(userId,profile.value)) {
         localStorage.setItem('isADGroupUser',"true");
         this.LoadTFDashboard();
         }
         else{
          this.spinnerService.hideAutoSaveSpinner();
          localStorage.setItem("isADGroupUser","false");
          this.showMsgs("termination", "InValidUser");
        } 
      },
      error: (err: any) => {
        this.spinnerService.hideAutoSaveSpinner();
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        console.log("Error" + err);
      }
    });
   }
   showMsgs(msg: any, msgType: any) {
    this.terminationNotifyPopupComponent.openPopup(msg, msgType)
  }
   private _isDCFMemberInAdGroup(email:any,groups:Array<any>): boolean {
    return !!groups.find(group => group.userPrincipalName.toLowerCase() === email.toLowerCase());
   }
  IsUserTerminationFormAdmin() {
    let userId = localStorage.getItem("userProfileId")?.toString();
    this.terminationFormService.GetUserRoleGroups(userId).subscribe(
      {
        next: (result: any) => {
          result = result.filter((s: any) => s.Title != null)
          this.isTFAdmin = this.isInTFAdminGroup(result);
          if (this.isTFAdmin) { this.VerifyADGroup(); }
          else{ this.spinnerService.hideAutoSaveSpinner();this.showMsgs("termination", "InValidUser");}
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          console.log("Error" + err)
        }
      })
  }

  LoadTFDashboard() {
    this.terminationFormService.GetFormDetails().subscribe(
      {
      next:(res:any)=>{        
            this.initialTerminationDashboardList = this.terminationDashboardsPerPage = this.MapToTerminationDashboard(res);
            this.terminationDashboards = this.terminationDashboardsPerPage;
            this.initialTerminationDashboardListForSearch = this.initialTerminationDashboardList;
            this.pageSizeListValues = [{ id: 0, value: "All"},{ id: 1, value: 10  }, { id: 2, value: 25 }, { id: 3, value: 50 }, { id: 4, value:100 }];
            this.commonHelperService.state.page = 1;
            this.commonHelperService.state.pageSize =100;            
            this.terminationDashboards = this.commonHelperService.sort('ID', 'desc', this.terminationDashboards);
            this._setPaginationValues(this.pageSizeListValues);
            this.spinnerService.hideAutoSaveSpinner();
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err)
        }
      }      
    ); 
  }

  isInTFAdminGroup(userGroups: any) {
    let isAdmin_flag = false;
    userGroups.forEach((u: any) => {
      if (u.Role == "Termination Administrators") {
        isAdmin_flag = true;
      }
    });
    return isAdmin_flag;
  }

  removeBrowserScroll(){
    document.body.style.overflow="hidden";
  }



  MapToTerminationDashboard(res:any):Array<TerminationDashboardItem>{
    var terminationDashboardItems:TerminationDashboardItem[] = [];
    var terminationDashboardItem: any;
    res.forEach((item:any)=>{
      terminationDashboardItem = {
        ID: item.ID,
        Link:this.URL+item.ID,
        LastName: item.LastName==null?"-":item.LastName,
        FirstName:item.FirstName==null?"-":item.FirstName,
        FormStatus:item.FormStatus==null?'-':item.FormStatus,
        TerminationDate: item.TerminationDate == null ? '-' : this.dateService.formatDateToMMDDYYYY(item.TerminationDate),
        VoluntaryTermination:item.VoluntaryTermination==null?"-":item.VoluntaryTermination,
        StatusOfIndividual:item.StatusOfIndividualRegOrNRF==null?"-":item.StatusOfIndividualRegOrNRF,
        Series24:item.Series24==null||item.Series24=="#;"?"-":item.Series24.includes("#;")?item.Series24.split("#;")[0]: item.Series24,
        TerminationReasonMatch:item.TerminationReasonMatch==null?"-":item.TerminationReasonMatch,
        WasThreatContacted:item.WasThreatContacted==null?"-":item.WasThreatContacted,
        InternalOpenThreat:item.InternalOpenThreat==null?"-":item.InternalOpenThreat,
        OutlookRemovedDate:item.OutlookRemovedDate== null ? '-' : this.dateService.formatDateToMMDDYYYY(item.OutlookRemovedDate),
        JakiePolsonNotifiedDate:item.JakiePolsonNotifiedDate== null || item.JakiePolsonNotifiedDate== "" ? item.JakiePolsonNotifiedDateNAValue==null?"-":item.JakiePolsonNotifiedDateNAValue : this.dateService.formatDateToMMDDYYYY(item.JakiePolsonNotifiedDate),
        AllowedToTermedDate:item.AllowedToTermedDate== null||item.AllowedToTermedDate== ""? item.AllowedToTermedDateNAValue==null?"-":item.AllowedToTermedDateNAValue : this.dateService.formatDateToMMDDYYYY(item.AllowedToTermedDate),
        PSTToTermedClosedDeniedDate:item.PSTToTermedClosedDeniedDate== null || item.PSTToTermedClosedDeniedDate== ""? item.PSTToTermedClosedDeniedDateNAValue==null?"-":item.PSTToTermedClosedDeniedDateNAValue : this.dateService.formatDateToMMDDYYYY(item.PSTToTermedClosedDeniedDate),
        DCFKCRemovedDate:item.DCFKCRemovedDate== null ? '-' : this.dateService.formatDateToMMDDYYYY(item.DCFKCRemovedDate),
        DateTheEmployeeRemovedFlowPerms:item.DateTheEmployeeRemovedFlowPerms== null ||item.DateTheEmployeeRemovedFlowPerms== "" ? item.DateTheEmployeeRemovedFlowPermsNAValue==null?"-":item.DateTheEmployeeRemovedFlowPermsNAValue : this.dateService.formatDateToMMDDYYYY(item.DateTheEmployeeRemovedFlowPerms),
        DateSupportTicketOpened:item.DateSupportTicketOpened== null||item.DateSupportTicketOpened=="" ? item.DateSupportTicketOpenedNAValue==null?"-":item.DateSupportTicketOpenedNAValue : this.dateService.formatDateToMMDDYYYY(item.DateSupportTicketOpened),
        CSIRetiredDate:item.CSIRetiredDate == null ? '-' : this.dateService.formatDateToMMDDYYYY(item.CSIRetiredDate),
        RunSmarshEmailSpotCheck:item.RunSmarshEmailSpotCheck==null?"-":item.RunSmarshEmailSpotCheck,
        SupervisionGroupRemovedDate:item.SupervisionGroupRemovedDate == null ? '-' : this.dateService.formatDateToMMDDYYYY(item.SupervisionGroupRemovedDate),
        SmarshTextCaptureRemovedDate:item.SmarshTextCaptureRemovedDate == null || item.SmarshTextCaptureRemovedDate =="" ? item.SmarshTextCaptureRemovedDateNAValue==null?"-":item.SmarshTextCaptureRemovedDateNAValue : this.dateService.formatDateToMMDDYYYY(item.SmarshTextCaptureRemovedDate),
        SupervisorOrgChartRemovalDate:item.SupervisorOrgChartRemovalDate == null ? '-' : this.dateService.formatDateToMMDDYYYY(item.SupervisorOrgChartRemovalDate),
        NotifiedBrokerageFirmsDate:item.NotifiedBrokerageFirmsDate== null || item.NotifiedBrokerageFirmsDate== "" ? item.NotifiedBrokerageFirmsNAValue==null?"-":item.NotifiedBrokerageFirmsNAValue : this.dateService.formatDateToMMDDYYYY(item.NotifiedBrokerageFirmsDate),
        IdentifyIndividualTermedDate:item.IdentifyIndividualTermedDate== null ||item.IdentifyIndividualTermedDate== "" ? item.IdentifyIndividualTermedDateNAValue==null?"-":item.IdentifyIndividualTermedDateNAValue : this.dateService.formatDateToMMDDYYYY(item.IdentifyIndividualTermedDate),
        TermedEmployeeMovedDate:item.TermedEmployeeMovedDate== null||item.TermedEmployeeMovedDate== "" ? item.TermedEmployeeMovedDateNAValue==null?"-":item.TermedEmployeeMovedDateNAValue : this.dateService.formatDateToMMDDYYYY(item.TermedEmployeeMovedDate),
        SentIndividualSignedCopy:item.SentIndividualSignedCopy==null?"-":item.SentIndividualSignedCopy,
        TrackingNumber:item.TrackingNumber==null||item.TrackingNumber==""?"-":item.TrackingNumber,
        ShippingMethod:item.ShippingMethod==null||item.ShippingMethod==""?"-":item.ShippingMethod,
        SignatureName:item.SignatureName==null|| item.SignatureName==""?"-":item.SignatureName,
        SignatureDate: item.SignatureDate == null || item.SignatureDate == "" ? '-' : this.dateService.FormatDate(new Date(item.SignatureDate).toString()),
        CreatedBy:item.CreatedBy==null?"-":item.CreatedBy,
        ModifiedBy:item.ModifiedBy==null?"-":item.ModifiedBy,
        Created: item.Created == null ? '-' : this.dateService.FormatDate(item.Created),
        Modified: item.Modified == null ? '-' : this.dateService.FormatDate(item.Modified),
        isSelected:false
      }
      terminationDashboardItems.push(terminationDashboardItem)
    })
    return terminationDashboardItems;
  }

  onCheckboxChange(checkbox: any) {
    this.setPagenationMultiSelect(checkbox.target.checked);
    if (checkbox.target.checked) {
      this.terminationDashboardsPerPage.forEach((item:any)=> {

        let reportDashboardItem = {
            ID: item.ID == null ? '-' : item.ID,
            Link: item.ID == null ? '-' : this.URL + item.ID,
            LastName: item.LastName == null ? '-' : item.LastName,
            FirstName: item.FirstName == null ? '-' : item.FirstName,
            FormStatus: item.FormStatus ==null?'-':item.FormStatus,
            TerminationDate: item.TerminationDate == null || item.TerminationDate == "" ? '-' : item.TerminationDate,
            VoluntaryTermination: item.VoluntaryTermination == null ? '-' : item.VoluntaryTermination,
            StatusOfIndividual: item.StatusOfIndividual == null ? '-' : item.StatusOfIndividual,
            Series24: item.Series24 == null ? '-' : item.Series24.includes("#;")?item.Series24.split("#;")[0]: item.Series24,
            TerminationReasonMatch: item.TerminationReasonMatch == null ? '-' : item.TerminationReasonMatch,
            WasThreatContacted: item.WasThreatContacted == null ? '-' : item.WasThreatContacted,
            InternalOpenThreat: item.InternalOpenThreat == null ? '-' : item.InternalOpenThreat,
            OutlookRemovedDate: item.OutlookRemovedDate == null || item.OutlookRemovedDate == "" ? '-' : item.OutlookRemovedDate,
            JakiePolsonNotifiedDate:item.JakiePolsonNotifiedDate== null||item.JakiePolsonNotifiedDate== ""?"-":item.JakiePolsonNotifiedDate ,
            AllowedToTermedDate:item.AllowedToTermedDate== null||item.AllowedToTermedDate== ""?"-":item.AllowedToTermedDate ,
            PSTToTermedClosedDeniedDate:item.PSTToTermedClosedDeniedDate== null|| item.PSTToTermedClosedDeniedDate== ""?"-":item.PSTToTermedClosedDeniedDate ,
            DCFKCRemovedDate: item.DCFKCRemovedDate == null || item.DCFKCRemovedDate == "" ? '-' : item.DCFKCRemovedDate,
            DateTheEmployeeRemovedFlowPerms: item.DateTheEmployeeRemovedFlowPerms == null || item.DateTheEmployeeRemovedFlowPerms == "" ? '-' : item.DateTheEmployeeRemovedFlowPerms,
            DateSupportTicketOpened: item.DateSupportTicketOpened == null || item.DateSupportTicketOpened == "" ? '-' : item.DateSupportTicketOpened,
            CSIRetiredDate: item.CSIRetiredDate == null || item.CSIRetiredDate == "" ? '-' : item.CSIRetiredDate,
            RunSmarshEmailSpotCheck: item.RunSmarshEmailSpotCheck == null ? '-' : item.RunSmarshEmailSpotCheck,
            SupervisionGroupRemovedDate: item.SupervisionGroupRemovedDate == null || item.SupervisionGroupRemovedDate == "" ? '-' : item.SupervisionGroupRemovedDate,
            SmarshTextCaptureRemovedDate: item.SmarshTextCaptureRemovedDate == null || item.SmarshTextCaptureRemovedDate == "" ? '-' : item.SmarshTextCaptureRemovedDate,
            SupervisorOrgChartRemovalDate: item.SupervisorOrgChartRemovalDate == null || item.SupervisorOrgChartRemovalDate == "" ? '-' : item.SupervisorOrgChartRemovalDate,
            NotifiedBrokerageFirmsDate: item.NotifiedBrokerageFirmsDate == null ||item.NotifiedBrokerageFirmsDate == ""? '-' : item.NotifiedBrokerageFirmsDate,
            IdentifyIndividualTermedDate: item.IdentifyIndividualTermedDate == null || item.IdentifyIndividualTermedDate == "" ? '-' : item.IdentifyIndividualTermedDate,
            TermedEmployeeMovedDate: item.TermedEmployeeMovedDate == null || item.TermedEmployeeMovedDate == "" ? '-' : item.TermedEmployeeMovedDate,
            SentIndividualSignedCopy: item.SentIndividualSignedCopy == null ? '-' : item.SentIndividualSignedCopy,
            TrackingNumber: item.TrackingNumber == null ? '-' : item.TrackingNumber,
            ShippingMethod: item.ShippingMethod == null ? '-' : item.ShippingMethod,
            SignatureName: item.SignatureName == null ? '-' : item.SignatureName,
            SignatureDate: item.SignatureDate == null || item.SignatureDate == "" ? '-' : item.SignatureDate,
            Created: item.Created == null || item.Created == "" ? '-' : item.Created,
            CreatedBy: item.CreatedBy == null ? '-' : item.CreatedBy,
            Modified: item.Modified == null || item.Modified == "" ? '-' : item.Modified,
            ModifiedBy: item.ModifiedBy == null ? '-' : item.ModifiedBy,
            isSelected: false
        };
        if (!this.reportDashboardExportToExcel.find(e => e.ID === item.ID)) {
          this.reportDashboardExportToExcel.push(reportDashboardItem);
        }

      });
      this.terminationDashboards.forEach(item => {
        if (this.reportDashboardExportToExcel.find(e => e.ID === item.ID))
        item.isSelected = true;
      });
      //enable the export option
      this.showDisable = true;
      if (this.reportDashboardExportToExcel.length > 0) {
        this.showDisable = false;
      }
    }
    else {
      this.terminationDashboardsPerPage.forEach(item => {
        this.reportDashboardExportToExcel = this.reportDashboardExportToExcel.
          filter(e => e.ID !== item.ID);
      })

      this.terminationDashboards.forEach(item => {
        if (this.terminationDashboardsPerPage.find(e => e.ID === item.ID))
        item.isSelected = false;
      });

      //enable the export option
      this.showDisable = true;
      if (this.reportDashboardExportToExcel.length > 0) {
        this.showDisable = false;
      }
    }
  }


  private setPagenationMultiSelect(checkboxStatus: any) {
    if (this.terminationDashboardPageDetails.length > 0) {
      this.terminationDashboardPageDetails.forEach(v => {
        if (v.PageNumber == this.commonHelperService.state.page) {
          v.IsMultiSelect = checkboxStatus
        }
        else {
          let reportPageItem = {
            PageNumber: this.commonHelperService.state.page,
            PageSize: this.commonHelperService.state.pageSize,
            IsMultiSelect: checkboxStatus
          };
          this.terminationDashboardPageDetails.push(reportPageItem);
        }
      });
    }
    else {
      let reportPageItem = {
        PageNumber: this.commonHelperService.state.page,
        PageSize: this.commonHelperService.state.pageSize,
        IsMultiSelect: checkboxStatus
      };
      this.terminationDashboardPageDetails.push(reportPageItem);
    }
  }
  


  
  onCheckboxChanged(event: any) {
    if (this.terminationDashboards.find(e => e.ID == event && e.isSelected == false)) {
      let reportDashboardItem: any;
      this.terminationDashboards.forEach((item:any)=> {
        if (item.ID == event) {
          reportDashboardItem = {
            ID: item.ID==null ? '-' : item.ID,
            Link:item.ID==null ? '-' : this.URL+item.ID, 
            LastName: item.LastName==null ? '-' : item.LastName,
            FirstName:item.FirstName==null ? '-' : item.FirstName,
            FormStatus:item.FormStatus==null?'-':item.FormStatus,
            TerminationDate: item.TerminationDate==null || item.TerminationDate == "" ? '-' : item.TerminationDate,
            VoluntaryTermination:item.VoluntaryTermination==null ? '-' : item.VoluntaryTermination,
            StatusOfIndividual:item.StatusOfIndividual==null ? '-' : item.StatusOfIndividual,
            Series24:item.Series24==null ? '-' :item.Series24.includes("#;")?item.Series24.split("#;")[0]: item.Series24,
            TerminationReasonMatch:item.TerminationReasonMatch==null ? '-' : item.TerminationReasonMatch,
            WasThreatContacted:item.WasThreatContacted==null ? '-' : item.WasThreatContacted,
            InternalOpenThreat:item.InternalOpenThreat==null ? '-' : item.InternalOpenThreat,
            OutlookRemovedDate:item.OutlookRemovedDate==null || item.OutlookRemovedDate == "" ? '-' : item.OutlookRemovedDate,
            JakiePolsonNotifiedDate:item.JakiePolsonNotifiedDate== null||item.JakiePolsonNotifiedDate== ""?"-":item.JakiePolsonNotifiedDate ,
            AllowedToTermedDate:item.AllowedToTermedDate== null||item.AllowedToTermedDate== ""?"-":item.AllowedToTermedDate ,
            PSTToTermedClosedDeniedDate:item.PSTToTermedClosedDeniedDate== null|| item.PSTToTermedClosedDeniedDate== ""?"-":item.PSTToTermedClosedDeniedDate ,
            DateTheEmployeeRemovedFlowPerms: item.DateTheEmployeeRemovedFlowPerms == null || item.DateTheEmployeeRemovedFlowPerms == "" ? '-' : item.DateTheEmployeeRemovedFlowPerms,
            DateSupportTicketOpened: item.DateSupportTicketOpened == null || item.DateSupportTicketOpened == "" ? '-' : item.DateSupportTicketOpened,
            DCFKCRemovedDate:item.DCFKCRemovedDate==null || item.DCFKCRemovedDate == "" ? '-' : item.DCFKCRemovedDate,
            CSIRetiredDate:item.CSIRetiredDate==null || item.CSIRetiredDate == "" ? '-' : item.CSIRetiredDate,
            RunSmarshEmailSpotCheck:item.RunSmarshEmailSpotCheck==null ? '-' : item.RunSmarshEmailSpotCheck,
            SupervisionGroupRemovedDate:item.SupervisionGroupRemovedDate==null || item.SupervisionGroupRemovedDate == "" ? '-' : item.SupervisionGroupRemovedDate,
            SmarshTextCaptureRemovedDate:item.SmarshTextCaptureRemovedDate==null || item.SmarshTextCaptureRemovedDate == "" ? '-' : item.SmarshTextCaptureRemovedDate,
            SupervisorOrgChartRemovalDate:item.SupervisorOrgChartRemovalDate==null || item.SupervisorOrgChartRemovalDate =="" ? '-' : item.SupervisorOrgChartRemovalDate,
            NotifiedBrokerageFirmsDate:item.NotifiedBrokerageFirmsDate==null || item.NotifiedBrokerageFirmsDate == ""? '-' : item.NotifiedBrokerageFirmsDate,
            IdentifyIndividualTermedDate:item.IdentifyIndividualTermedDate==null || item.IdentifyIndividualTermedDate =="" ? '-' : item.IdentifyIndividualTermedDate,
            TermedEmployeeMovedDate:item.TermedEmployeeMovedDate==null || item.TermedEmployeeMovedDate == "" ? '-' : item.TermedEmployeeMovedDate,
            SentIndividualSignedCopy:item.SentIndividualSignedCopy==null ? '-' : item.SentIndividualSignedCopy,
            TrackingNumber:item.TrackingNumber==null ? '-' : item.TrackingNumber,
            ShippingMethod:item.ShippingMethod==null ? '-' : item.ShippingMethod,
            SignatureName:item.SignatureName==null ? '-' : item.SignatureName,
            SignatureDate:item.SignatureDate==null || item.SignatureDate == "" ? '-' : item.SignatureDate,
            Created:item.Created==null || item.Created == "" ? '-' : item.Created,
            CreatedBy:item.CreatedBy==null ? '-' : item.CreatedBy,
            Modified:item.Modified==null || item.Modified == "" ? '-' : item.Modified,
            ModifiedBy:item.ModifiedBy==null ? '-' : item.ModifiedBy,
            isSelected:false
          };
        }
      })

      this.reportDashboardExportToExcel.push(reportDashboardItem);
      this.terminationDashboards.forEach(v => {
        if (v.isSelected == false && v.ID == event)
          v.isSelected = true;
      });
      if (!this.terminationDashboardsPerPage.find(v => v.isSelected == false)) {
        const checkbox = document.getElementById(
          'chk_MultiCheckBox',
        ) as HTMLInputElement;
        checkbox.checked = true;
        if (this.terminationDashboardPageDetails.length != 0) {
          this.terminationDashboardPageDetails.forEach(v => {
            if (v.PageNumber == this.commonHelperService.state.page) {
              v.IsMultiSelect = true
            }
            else {
              let reportPageItem = {
                PageNumber: this.commonHelperService.state.page,
                PageSize: this.commonHelperService.state.pageSize,
                IsMultiSelect: true
              };
              this.terminationDashboardPageDetails.push(reportPageItem);
            }
          });
        }
        else {
          let reportPageItem = {
            PageNumber: this.commonHelperService.state.page,
            PageSize: this.commonHelperService.state.pageSize,
            IsMultiSelect: true
          };
          this.terminationDashboardPageDetails.push(reportPageItem);
        }
      }
    }
    else {
      this.reportDashboardExportToExcel = this.reportDashboardExportToExcel.
        filter(e => e.ID !== event);
      this.terminationDashboards.forEach(v => {
        if (v.isSelected == true && v.ID == event)
          v.isSelected = false;
      });
      const checkbox = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement;
      checkbox.checked = false;
      this.terminationDashboardPageDetails.forEach(v => {
        if (v.PageNumber == this.commonHelperService.state.page) {
          v.IsMultiSelect = false
        }
      });
    }
    this.showDisable = true;
    if (this.reportDashboardExportToExcel.length > 0) {
      this.showDisable = false;
    }
    else {
      this.terminationDashboardPageDetails.length = 0;
    }
  }

  onChangePaginationValues(paginationValues: PaginationValues) {
    this.commonHelperService.state.page = paginationValues.page;
    this.commonHelperService.state.pageSize = paginationValues.pageSize;
    this._setReportsDashboardToCurrentPage();
    this.setMultiSelectedCheckBoxPages(paginationValues.page, paginationValues.pageSize);
    //Suvishna Commented
    //this.setMultiSelectedCheckBoxPages(paginationValues.page,paginationValues.pageSize);
    // When selected the multiple selected text box,
    //  only the forms specific to the particular page must be selected,
    //   even if the items per page increased, 
    //   it should select only the forms in that relevant page.
    //    If the user navigates to the other page , 
    //    no forms should have to be selected until that page multi select is clicked .
    //     Until the relevant page is not deselected , 
    //     it should remain selected only.
    //      The following selected files should be able to exported in the excel sheet

  }

  getToolTipText(fieldName:string){
    var res =  this.tooltipText[fieldName];
    return res;
  }

  clearFilters() {
    if (this.targetFilter) {
      this.targetFilter.valueList = [];
      this._updateReportList();
      this.showDisable = true;
      this.reportDashboardExportToExcel.length = 0;
      const checkbox = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement;
      checkbox.checked = false;
      this.terminationDashboards.forEach(v => {
        if (v.isSelected == true)
          v.isSelected = false;
      });
      this.terminationDashboardPageDetails.length = 0
    }
  }

  private _updateReportList() {
    this.terminationDashboards = this._isSomeFilterApply() ?
      this._getReportsMatchsFilterCriteria(this.initialTerminationDashboardList, this.filterList) :
      this.initialTerminationDashboardList;

    const sortData = this.commonHelperService.state.sortData;
    this.terminationDashboards = this.commonHelperService.sort(
      sortData.sortColumn, sortData.sortDirection, this.terminationDashboards);
    this._setReportsDashboardToCurrentPage();
  }

  isChecked(value: string): boolean {
    let checked = false;
    if (this.targetFilter) {
      this.targetFilter.valueList.forEach(v => {
        if (v === value.toString()) {
          checked = true;
        }
      });
    }
    return checked;
  }

  private _updateFilterList(valueSelected: string, isChecked: string) {
    const existFilter = this._getFilter(this.targetField);
    if (isChecked) {
      this._addFilterItem(existFilter, valueSelected);
    } else {
      this._removeFilterItem(existFilter, valueSelected);
    }
    this.showDisable = true;
    this.reportDashboardExportToExcel.length = 0;
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement;
    checkbox.checked = false;
    this.terminationDashboards.forEach(v => {
      if (v.isSelected == true)
        v.isSelected = false;
    });
    this.terminationDashboardPageDetails.length = 0
    this._setTargetFilter(existFilter);
  }


  private _setTargetFilter(existFilter: ReportDashboardTableFilterItem) {
    this.targetFilter = existFilter;
    this.commonHelperService.filterDataList = this.filterList;

  }

  private _removeFilterItem(existFilter: ReportDashboardTableFilterItem, valueSelected: string) {
    if (existFilter) {
      existFilter.valueList = this._removeFilter(existFilter.valueList, valueSelected);
    }
  }

  private _removeFilter(valueList: Array<string>, valueSelected: string): Array<string> {
    return valueList.filter(v => v !== valueSelected);
  }

  private _addFilterItem(existFilter: ReportDashboardTableFilterItem, valueSelected: string) {
    if (existFilter) {
      existFilter.valueList.push(valueSelected);
    } else {
      const newFilter = this._createFilter(valueSelected);
      this.filterList.push(newFilter);
      existFilter = newFilter;
    }
  }

  onClickFilterCheckbox(event: any) {
    const checkbox = event.currentTarget;
    const valueSelected = checkbox.id;
    const isChecked = checkbox.checked;

    this._updateFilterList(valueSelected, isChecked);
    this._updateReportDashboardList();
  }

  addNotifyDetails(event:any){
    if(event=="Dashboard"){
      document.location.href = environment.redirectUrl+"home";
    }
    
  }

  private _updateReportDashboardList() {
    this.onSearchApplyOnFilteredData=[];
    this.terminationDashboards = this._isSomeFilterApply() ?
      this._getReportsMatchsFilterCriteria(this.initialTerminationDashboardList, this.filterList) :
      this.initialTerminationDashboardListForSearch;

    const sortData = this.commonHelperService.state.sortData;
    this.terminationDashboards = this.commonHelperService.sort(
      sortData.sortColumn, sortData.sortDirection, this.terminationDashboards);
    this._setReportsDashboardToCurrentPage();
    if(this.initialTerminationDashboardListForSearch.length!=this.terminationDashboards.length)
    this.onSearchApplyOnFilteredData=this.terminationDashboardsPerPage;
  }

  private _createFilter(valueSelected: string): ReportDashboardTableFilterItem {
    const newFilter = {
      field: this.targetField,
      valueList: [valueSelected]
    };
    return newFilter;
  }

  sortTableOnFilterModal(column: string, direction: SortDirection) {
    this._resetSortIcons(column);
    this._showSortDirectionArrow(column, direction);
    this._setReportsDashboardToCurrentPage();
    this.terminationDashboards = this.commonHelperService.sort(column, direction, this.terminationDashboards);
    this._setSortOptions(column, direction);
  }
  private _showSortDirectionArrow(sortColumn: string, sortDirection: SortDirection) {
    const header = this._getHeader(sortColumn);
    header.direction = sortDirection;
    header.rotate();
  }

  onPageSizeValueChange(event: any) {
    if(this.selectedPageSize=="All")
    {
    const values: PaginationValues = {
      page: this.commonHelperService.state.page,
      pageSize: parseInt(this.initialTerminationDashboardList.length.toString())
    };
    this.onChangePaginationValues(values);
  }
  else{
    const values: PaginationValues = {
      page: this.commonHelperService.state.page,
      pageSize: parseInt(this.selectedPageSize)
    };
    this.onChangePaginationValues(values);
  }
  }

  private setMultiSelectedCheckBoxPages(pageNumber: any, pageSize: any) {

    if (this.terminationDashboardPageDetails.length > 0) {
      if (this.terminationDashboardPageDetails.find(s => s.PageSize != pageSize && s.IsMultiSelect == true)) {
        this.reportDashboardExportToExcel.length = 0;
      }
      this.terminationDashboardPageDetails.forEach(s => {
        if (s.IsMultiSelect == true && s.PageSize != pageSize) {
          s.PageSize = pageSize
          const page = s.PageNumber;
          const pageSize1 = s.PageSize;
          this.terminationDashboardsPerPage =
            this.terminationDashboards.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
          this.terminationDashboardsPerPage.forEach(v => {
            if (!this.reportDashboardExportToExcel.find(e => e.ID == v.ID)) {
              let reportDashboardItem = {
                ID: v.ID==null ? '-' : v.ID,
                Link:v.ID==null ? '-' :this.URL+v.ID, 
                LastName: v.LastName==null ? '-' : v.LastName,
                FirstName:v.FirstName==null ? '-' : v.FirstName,
                FormStatus: v.FormStatus==null?'-':v.FormStatus,
                TerminationDate: v.TerminationDate==null || v.TerminationDate == "" ? '-' : v.TerminationDate,
                VoluntaryTermination:v.VoluntaryTermination==null ? '-' : v.VoluntaryTermination,
                StatusOfIndividual:v.StatusOfIndividual==null ? '-' : v.StatusOfIndividual,
                Series24:v.Series24==null ? '-' : v.Series24.includes("#;")?v.Series24.split("#;")[0]: v.Series24,
                TerminationReasonMatch:v.TerminationReasonMatch==null ? '-' : v.TerminationReasonMatch,
                WasThreatContacted:v.WasThreatContacted==null ? '-' : v.WasThreatContacted,
                InternalOpenThreat:v.InternalOpenThreat==null ? '-' : v.InternalOpenThreat,
                OutlookRemovedDate:v.OutlookRemovedDate==null || v.OutlookRemovedDate == "" ? '-' : v.OutlookRemovedDate,
                JakiePolsonNotifiedDate:v.JakiePolsonNotifiedDate== null||v.JakiePolsonNotifiedDate== ""?"-":v.JakiePolsonNotifiedDate ,
                AllowedToTermedDate:v.AllowedToTermedDate== null||v.AllowedToTermedDate== ""?"-":v.AllowedToTermedDate ,
                PSTToTermedClosedDeniedDate:v.PSTToTermedClosedDeniedDate== null|| v.PSTToTermedClosedDeniedDate== ""?"-":v.PSTToTermedClosedDeniedDate ,
                DCFKCRemovedDate: v.DCFKCRemovedDate == null || v.DCFKCRemovedDate == "" ? '-' : v.DCFKCRemovedDate,
                DateTheEmployeeRemovedFlowPerms: v.DateTheEmployeeRemovedFlowPerms == null || v.DateTheEmployeeRemovedFlowPerms == "" ? '-' : v.DateTheEmployeeRemovedFlowPerms,
                DateSupportTicketOpened: v.DateSupportTicketOpened == null || v.DateSupportTicketOpened == "" ? '-' : v.DateSupportTicketOpened,
                CSIRetiredDate:v.CSIRetiredDate==null || v.CSIRetiredDate == "" ? '-' : v.CSIRetiredDate,
                RunSmarshEmailSpotCheck:v.RunSmarshEmailSpotCheck==null ? '-' : v.RunSmarshEmailSpotCheck,
                SupervisionGroupRemovedDate:v.SupervisionGroupRemovedDate==null || v.SupervisionGroupRemovedDate == "" ? '-' : v.SupervisionGroupRemovedDate,
                SmarshTextCaptureRemovedDate:v.SmarshTextCaptureRemovedDate==null || v.SmarshTextCaptureRemovedDate == "" ? '-' : v.SmarshTextCaptureRemovedDate,
                SupervisorOrgChartRemovalDate:v.SupervisorOrgChartRemovalDate==null || v.SupervisorOrgChartRemovalDate =="" ? '-' : v.SupervisorOrgChartRemovalDate,
                NotifiedBrokerageFirmsDate:v.NotifiedBrokerageFirmsDate==null ? '-' : v.NotifiedBrokerageFirmsDate,
                IdentifyIndividualTermedDate:v.IdentifyIndividualTermedDate==null || v.IdentifyIndividualTermedDate =="" ? '-' : v.IdentifyIndividualTermedDate,
                TermedEmployeeMovedDate:v.TermedEmployeeMovedDate==null || v.TermedEmployeeMovedDate == "" ? '-' : v.TermedEmployeeMovedDate,
                SentIndividualSignedCopy:v.SentIndividualSignedCopy==null ? '-' : v.SentIndividualSignedCopy,
                TrackingNumber:v.TrackingNumber==null ? '-' : v.TrackingNumber,
                ShippingMethod:v.ShippingMethod==null ? '-' : v.ShippingMethod,
                SignatureName:v.SignatureName==null ? '-' : v.SignatureName,
                SignatureDate:v.SignatureDate==null || v.SignatureDate == "" ? '-' : v.SignatureDate,
                Created:v.Created==null || v.Created == "" ? '-' : v.Created,
                CreatedBy:v.CreatedBy==null ? '-' : v.CreatedBy,
                Modified:v.Modified==null || v.Modified == "" ? '-' : v.Modified,
                ModifiedBy:v.ModifiedBy==null ? '-' : v.ModifiedBy,
                isSelected:false
                
              };
              this.reportDashboardExportToExcel.push(reportDashboardItem);
            }
          });
          this.terminationDashboards.forEach(v => {
            if (this.reportDashboardExportToExcel.find(e => e.ID === v.ID)) {
              v.isSelected = true;
            }
            else {
              v.isSelected = false;
            }
          });
        }

      });
      this._setReportsDashboardToCurrentPage();
    }
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement;
    checkbox.checked = false;
    if (this.terminationDashboards.find(e => e.isSelected == true)) {
      this.terminationDashboardPageDetails.forEach(v => {
        if (v.IsMultiSelect == true && v.PageNumber == pageNumber) {
          checkbox.checked = true;
        }
      });
    }

  }

  openFilterPopover(field: string) {
    this.targetField = field;
    this.targetFilter = this._getFilter(field);
    this.fieldOptionValues = this._mapReportDashboardListWithFilterList(this.initialTerminationDashboardList, field);    
    if(this.searchTerm==undefined || this.searchTerm.trim()=="" || this.searchTerm=="ALL"){
      if(this.targetField=="ID")
        this.fieldOptionValues = this._mapReportDashboardListWithFilterList(this.initialTerminationDashboardList, field).sort((a:any,b:any)=>b-a);
      else
        this.fieldOptionValues = this._mapReportDashboardListWithFilterList(this.initialTerminationDashboardList, field).sort();
    }
    else{
      var fieldoptions= this.initialTerminationDashboardList.filter((x:any)=> x.LastName!=null && x.LastName!=undefined && x.LastName.toLowerCase().includes(this.searchTerm.toLowerCase()));
      if(this.targetField=="ID")
        this.fieldOptionValues = this._mapReportDashboardListWithFilterList(fieldoptions, field).sort((a:any,b:any)=>b-a);
      else
        this.fieldOptionValues = this._mapReportDashboardListWithFilterList(fieldoptions, field).sort();

    }
  }
  private _getFilter(field: string): any {
    return this.filterList.length ? this.filterList.filter(f => f.field === field)[0] : null;
  }

  private _mapReportDashboardListWithFilterList(
    listReports: Array<TerminationDashboardItem>,
    field: string): Array<string> {
    const arrayOptionsList: any = [];
    listReports.forEach((report: any) => {
      const value = report[field];
      if (this._isValidValue(arrayOptionsList, value, field, listReports)) {
        arrayOptionsList.push(report[field]);
      }
    });    
      return arrayOptionsList;      
  }

  private _isValidValue(
    arrayOptionsList: Array<string>,
    value: string,
    field: string,
    listreports: Array<TerminationDashboardItem>): boolean {
    return (value !== '' && value !== null &&
      !this._inArray(arrayOptionsList, value) &&
      this._existReportForValue(listreports, field, value));
  }

  private _existReportForValue(
    listReports: Array<TerminationDashboardItem>,
    field: string,
    value: string): boolean {
    const filterArrayClone = [...this.filterList];
    const filterRemovedCurrentTarget = filterArrayClone.filter(f => f.field !== field);
    const filteredValues = this._getReportsMatchsFilterCriteria(listReports, filterRemovedCurrentTarget);
    const searchValue = filteredValues.find((v: any) => v[field] === value) ? true : false;
    return searchValue;
  }

  private _getReportsMatchsFilterCriteria(
    reports: Array<TerminationDashboardItem>,
    filterList: Array<ReportDashboardTableFilterItem>): Array<TerminationDashboardItem> {
    let newReportList: Array<TerminationDashboardItem> = [];
    if (filterList.length > 0) {
      reports.forEach(rep => {
        if (this._isReportMatchFilterCriteria(rep, filterList)) {
          newReportList.push(rep);

        }
      });
    } else {
      newReportList = reports;
    }
    return newReportList;
  }

  private _isReportMatchFilterCriteria(
    report: any,
    filterList: Array<ReportDashboardTableFilterItem>): boolean {
    let match = true;
    try {
      filterList.forEach(filter => {
        const field = filter.field;
        const values = filter.valueList;
        if (values.length && !values.find(v => v === report[field].toString())) {
          match = false;
          //return false;
        }
      });
      return match;
    }
    catch (e) {
      match = false;
    }
    return match;
  }

  private _inArray(optionFields: Array<string>, option: string): boolean {
    return Boolean(optionFields.find(o => o === option));
  }

  isFilterApply(field: string) {
    let filterApply = false;
    this.filterList.forEach(filter => {
      if (filter.field === field && filter.valueList.length) {
        filterApply = true;
      }
    });
    return filterApply;
  }

  sortTable({ column }: SortEvent) {
    this.terminationDashboardPageDetails.length = 0;
    const newDirection = this._getSortDirection(column);
    this._resetSortIcons(column);
    this.terminationDashboards = this.commonHelperService.sort(column, newDirection, this.terminationDashboards);
    this._setReportsDashboardToCurrentPage();
    this._setSortOptions(column, newDirection);
    this.showDisable = true;
    this.reportDashboardExportToExcel.length = 0;
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement;
    checkbox.checked = false;
    this.terminationDashboards.forEach(v => {
      if (v.isSelected == true)
        v.isSelected = false;
    });
  }

  private _setSortOptions(column: string, direction: SortDirection) {
    const sortItem: ReportDashboardSort = {
      sortColumn: column,
      sortDirection: direction
    };
    this.commonHelperService.sortData = sortItem;
  }

  private _getHeader(column: string): NgbdSortableHeaderDirective {
    return this.headers.filter(h => h.sortable === column)[0];
  }

  private _getSortDirection(column: string): SortDirection {
    const header = this._getHeader(column);
    return header.direction === 'asc' ? 'desc' : 'asc';
  }

  clearSearchFilters() {
    this.isColumnfilterApply=false;
    this.getSearchResults("ALL");
    this.clearAllFilters(true);
  }

  clearAllFilters(FromSearchForm: any) {
    this._resetSortIcons();
    this.filterList = [];
    this.targetField = "";
    this.reportDashboardExportToExcel.length = 0;
    this.terminationDashboardPageDetails.length = 0;
    const checkbox = document.getElementById(
      'chk_MultiCheckBox',
    ) as HTMLInputElement;
    checkbox.checked = false;
    this.showDisable = true;
    this.terminationDashboards.forEach(v => {
      v.isSelected = false;
    });
    if (FromSearchForm == true) {
      this.commonHelperService.searchTerm = '';
    }
    this.terminationDashboards = this.commonHelperService.sort('ID', 'desc', this.terminationDashboards);

    this.commonHelperService.filterDataList = [];
    this.commonHelperService.state.page = 1;
    this.commonHelperService.state.pageSize = CommonHelperService.pageSizeDefault;
    this.selectedPageSize = CommonHelperService.pageSizeDefault;            
    this._setReportsDashboardToCurrentPage();
    this.onSearchApplyOnFilteredData = [];
   
  }

  private _resetSortIcons(column?: string) {
    this.headers.forEach((header:any) => {
      if ((column && header.sortable !== column) || !column) {
        header.direction = '';
      }
    });
  }

  setTerminationDashboard(
    reportDashboardList: Array<TerminationDashboardItem>,
    pageSizeListValues: Array<any>) {
    pageSizeListValues = [{ id: 0, value: "All" },{ id: 1, value: 10 }, { id: 2, value: 25 }, { id: 3, value: 50 }, { id: 4, value: 100 }];    
    //this.initialReportDashboardList = reportDashboardList;
    this.commonHelperService.state.page = 1;
    this.commonHelperService.state.pageSize = 100;
    const sortData = this.commonHelperService.state.sortData;    
    this.terminationDashboards = this.commonHelperService.sort(
      sortData.sortColumn, sortData.sortDirection, reportDashboardList);    
    this._setReportsDashboardToCurrentPage();
    this._setPaginationValues(pageSizeListValues);
    this.pageDropdownDisable = reportDashboardList.length > 0 ? false : true;
    this.spinnerService.hideAutoSaveSpinner();
  }
 
  private _setReportsDashboardToCurrentPage() {
    const page = this.commonHelperService.state.page;
    const pageSize = this.commonHelperService.state.pageSize;
    this.terminationDashboardsPerPage =
      this.terminationDashboards.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
     
  }
  private _setPaginationValues(values: any) {
    this.pageSizeListValues = values.map((element: any) => {
      const parseValue = element.value.toString();
      const dropdownItem: DropdownItem = {
        key:'',
        value:''
      };
      if(parseValue=="All"){
         dropdownItem.key=parseValue,
         dropdownItem.value=parseValue+' Items'
      
      }
      else{
        dropdownItem.key=parseValue,
        dropdownItem.value=parseValue+' items per page'
    }
      return dropdownItem;
    });
    this.selectedPageSize = this.selectedPageSize == undefined ? CommonHelperService.pageSizeDefault : this.selectedPageSize;
    // this.pageSizeListValues.forEach((v: any) => {
    //  if (v.key == 100)
    //   this.selectedPageSize = v.key;
    // });
    // if (this.pagination) {
    //   this.pagination.setPaginationValues(this.pageSizeListValues);
    // }
  }  
  onSearchResult(result: any) {
    //this.clearAllFilters(false);
    //API Call
    //this.getDashboardDetails(result == "" ? "ALL" : result);

    if(!this._isSomeFilterApply())
        this.onSearchApplyOnFilteredData = [];
        
  // Customised search Functionality
   this.getSearchResults(result == "" ? "ALL" : result);
  }
  getSearchResults(searchValue:any){
    this.searchTerm = searchValue
    let searchArray:any=[];
    if(searchValue=="ALL"&&!this.isColumnfilterApply){
      searchArray=this.initialTerminationDashboardListForSearch;
    }
    else{
      if(this.onSearchApplyOnFilteredData.length>0){
        if(searchValue=="ALL"){
         searchArray=this.onSearchApplyOnFilteredData;
        }
        else{
        // searchArray=this.onSearchApplyOnFilteredData.filter(x=>x.projectName.toLowerCase().includes(searchValue.toLowerCase()));
        searchArray = this.onSearchApplyOnFilteredData.filter((item) => {
          // Apply filter conditions on each column
          return (
            item.LastName!=null && item.LastName!=undefined && item.LastName.toLowerCase().includes(searchValue.toLowerCase())            
          );
        });
        }
      }
      else{
        // searchArray=this.initialReportDashboardListForSearch.filter(x=>x.projectName.toLowerCase().includes(searchValue.toLowerCase()));
        searchArray = this.initialTerminationDashboardListForSearch.filter((item) => {
          // Apply filter conditions on each column
          return (
            item.LastName!=null && item.LastName!=undefined && item.LastName.toLowerCase().includes(searchValue.toLowerCase())            
          );
        });
      }
      
    }
   
    this.setTerminationDashboard(searchArray, this.pageSizeListValues);
  }

  private _isSomeFilterApply() {
    this.isColumnfilterApply = false;
    this.filterList.forEach(filter => {
      if (filter.valueList.length) {
        this.isColumnfilterApply = true;
      }
      // else
      // {
      //   this.isColumnfilterApply = false;
      // }
    });
    return this.isColumnfilterApply;
  }

  exportToExcel() {

    // for (let i = 0; i < this.reportDashboardExportToExcel.length; i++) {
    //   delete this.reportDashboardExportToExcel[i].ID;
    // }
    if(this.reportDashboardExportToExcel.length==0){
      var reportDashboardItem;
      this.terminationDashboards.forEach((item:any)=> {
          reportDashboardItem = {
            ID: item.ID==null ? '-' : item.ID,
            Link:item.ID==null ? '-' : this.URL+item.ID, 
            LastName: item.LastName==null ? '-' : item.LastName,
            FirstName:item.FirstName==null ? '-' : item.FirstName,
            FormStatus:item.FormStatus==null?'-':item.FormStatus,
            TerminationDate: item.TerminationDate==null || item.TerminationDate == "" ? '-' : item.TerminationDate,
            VoluntaryTermination:item.VoluntaryTermination==null ? '-' : item.VoluntaryTermination,
            StatusOfIndividual:item.StatusOfIndividual==null ? '-' : item.StatusOfIndividual,
            Series24:item.Series24==null ? '-' : item.Series24.includes("#;")?item.Series24.split("#;")[0]: item.Series24,
            TerminationReasonMatch:item.TerminationReasonMatch==null ? '-' : item.TerminationReasonMatch,
            WasThreatContacted:item.WasThreatContacted==null ? '-' : item.WasThreatContacted,
            InternalOpenThreat:item.InternalOpenThreat==null ? '-' : item.InternalOpenThreat,
            OutlookRemovedDate:item.OutlookRemovedDate==null || item.OutlookRemovedDate == "" ? '-' : item.OutlookRemovedDate,
            JakiePolsonNotifiedDate:item.JakiePolsonNotifiedDate== null||item.JakiePolsonNotifiedDate== ""?"-":item.JakiePolsonNotifiedDate ,
            AllowedToTermedDate:item.AllowedToTermedDate== null||item.AllowedToTermedDate== ""?"-":item.AllowedToTermedDate ,
            PSTToTermedClosedDeniedDate:item.PSTToTermedClosedDeniedDate== null|| item.PSTToTermedClosedDeniedDate== ""?"-":item.PSTToTermedClosedDeniedDate ,
            DCFKCRemovedDate: item.DCFKCRemovedDate == null || item.DCFKCRemovedDate == "" ? '-' : item.DCFKCRemovedDate,
            DateTheEmployeeRemovedFlowPerms: item.DateTheEmployeeRemovedFlowPerms == null || item.DateTheEmployeeRemovedFlowPerms == "" ? '-' : item.DateTheEmployeeRemovedFlowPerms,
            DateSupportTicketOpened: item.DateSupportTicketOpened == null || item.DateSupportTicketOpened == "" ? '-' : item.DateSupportTicketOpened,
            CSIRetiredDate:item.CSIRetiredDate==null || item.CSIRetiredDate == "" ? '-' : item.CSIRetiredDate,
            RunSmarshEmailSpotCheck:item.RunSmarshEmailSpotCheck==null ? '-' : item.RunSmarshEmailSpotCheck,
            SupervisionGroupRemovedDate:item.SupervisionGroupRemovedDate==null || item.SupervisionGroupRemovedDate == "" ? '-' : item.SupervisionGroupRemovedDate,
            SmarshTextCaptureRemovedDate:item.SmarshTextCaptureRemovedDate==null || item.SmarshTextCaptureRemovedDate == "" ? '-' : item.SmarshTextCaptureRemovedDate,
            SupervisorOrgChartRemovalDate:item.SupervisorOrgChartRemovalDate==null || item.SupervisorOrgChartRemovalDate =="" ? '-' : item.SupervisorOrgChartRemovalDate,
            NotifiedBrokerageFirmsDate:item.NotifiedBrokerageFirmsDate==null || item.NotifiedBrokerageFirmsDate=="" ? '-' : item.NotifiedBrokerageFirmsDate,
            IdentifyIndividualTermedDate:item.IdentifyIndividualTermedDate==null || item.IdentifyIndividualTermedDate =="" ? '-' : item.IdentifyIndividualTermedDate,
            TermedEmployeeMovedDate:item.TermedEmployeeMovedDate==null || item.TermedEmployeeMovedDate == "" ? '-' : item.TermedEmployeeMovedDate,
            SentIndividualSignedCopy:item.SentIndividualSignedCopy==null ? '-' : item.SentIndividualSignedCopy,
            TrackingNumber:item.TrackingNumber==null ? '-' : item.TrackingNumber,
            ShippingMethod:item.ShippingMethod==null ? '-' : item.ShippingMethod,
            SignatureName:item.SignatureName==null ? '-' : item.SignatureName,
            SignatureDate:item.SignatureDate==null || item.SignatureDate == "" ? '-' : item.SignatureDate,
            Created:item.Created==null || item.Created == "" ? '-' : item.Created,
            CreatedBy:item.CreatedBy==null ? '-' : item.CreatedBy,
            Modified:item.Modified==null || item.Modified == "" ? '-' : item.Modified,
            ModifiedBy:item.ModifiedBy==null ? '-' : item.ModifiedBy,
            isSelected:false
          };
          this.reportDashboardExportToExcel.push(reportDashboardItem);

      })

    }
    this.exportExcel(this.reportDashboardExportToExcel, 'terminationDashboardReport');

  }

  public exportExcel(jsonData: any[], fileName: string): void {
    jsonData.forEach(x=>{
      delete x.isSelected;
    })
    jsonData = jsonData.map(doc => Object.values(doc));
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    for (let i = 1; i <= this.reportDashboardExportToExcel.length; i++) {
      let cell_address = {c: 1, r: i}; // 0 is the first column (ID), i is the row number
      if (!worksheet[XLSX.utils.encode_cell(cell_address)]) {
        worksheet[XLSX.utils.encode_cell(cell_address)] = {};
      }
      worksheet[XLSX.utils.encode_cell(cell_address)].l = { Target: AppSettings.terminationForm + '?id=' + this.reportDashboardExportToExcel[i - 1].ID, Tooltip: `Click to open ${this.reportDashboardExportToExcel[i - 1].ID}` };
      
    }
  
    worksheet['A1'].v = "ID";
    worksheet['B1'].v = "Link";
    worksheet['C1'].v = "Last Name";
    worksheet['D1'].v = "First Name";
    worksheet['E1'].v = "Form Status";    
    worksheet['F1'].v = "Date of Termination";
    worksheet['G1'].v = "Voluntary Termination ";
    worksheet['H1'].v = "Status of Individual";
    worksheet['I1'].v = "Name of Series 24 Licensed Supervisor at the time of termination";
    worksheet['J1'].v = "Does the reason for termination match the reason contained within Talent file";
    worksheet['K1'].v = "The insider threat team was contacted to confirm there are no open cases for the Terminated Individual.";
    worksheet['L1'].v = "Were there any open internal threat cases for this Terminated Individual?";
    worksheet['M1'].v = "Date the Terminated Individual was removed from Outlook: US National Corporate Finance";
    worksheet['N1'].v = "Date Jackie Polson (or team) was notified a PPMD or client facing PPMD was being terminated (if applicable)";
    worksheet['O1'].v = "Date the Terminated Individual's OBA(s) were removed from DCF's list of active OBA's.";
    worksheet['P1'].v = "Date the Terminated Individual's PST(s) were removed from DCF's list of active PST's.";
    worksheet['Q1'].v = "Date the Terminated Individual was removed from DCF Knowledge Center";
    worksheet['R1'].v = "Date the Terminated Individual was removed from the Dflow permissions spreadsheet. (if applicable)";
    worksheet['S1'].v = "Date a support ticket was opened with DFlow to remove the Terminated Individual from open sites (if applicable)";
    worksheet['T1'].v = "Date the Terminated Individual was “retired” in CSI";
    worksheet['U1'].v = "Run Smarsh email spot check for the two weeks prior to termination date";
    worksheet['V1'].v = "Date the Terminated Individual was removed from Supervision Group structure in Smarsh";
    worksheet['W1'].v = "Date the Terminated Individual's Deloitte mobile phone was submitted for removal from Smarsh text message capture?";
    worksheet['X1'].v = "Date the Terminated Individual was removed from Supervisory organizational chart";
    worksheet['Y1'].v = "Notified brokerage firm(s) and/or Terminated Individual to discontinue duplicate statements, confirmations, and, where applicable, broker data feed(s)";
    worksheet['Z1'].v = "Information has been added to the Term tab of the New Hire & Termed spreadsheet in Associated Persons";
    worksheet['AA1'].v = "Date the terminated individual was moved from the ‘Current Employees' folder to the ‘Terminated Employees’ folder in  'Associated Persons'";
    worksheet['AB1'].v = "Sent individual a signed copy of Termination Letter and Form U5";
    worksheet['AC1'].v = "Tracking number of Termination letter and Form U5";
    worksheet['AD1'].v = "Shipping Method Used";
    worksheet['AE1'].v = "Signature Name";
    worksheet['AF1'].v = "Signature Date";
    worksheet['AG1'].v = "Created";
    worksheet['AH1'].v = "Created By";
    worksheet['AI1'].v = "Modified";
    worksheet['AJ1'].v = "Modified By";
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(data, 'Result_export_' + new Date().getTime() + ".xlsx");
    this.reportDashboardExportToExcel=[];

    // const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([{A:1,B:2},{B:2,C:3}], {header:['C']})
    //  //XLSX.utils.json_to_sheet(jsonData,{header:Header});
    // const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    // const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    // this.saveExcelFile(excelBuffer, fileName);
  }

}
