import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { AppSettings } from 'src/shared/app-settings';
import { DocumentTypeItem } from 'src/shared/models/document-type-item';
import { FileItem } from 'src/app/models/file-item';
import { FilesService } from '../../services/files.service';
import { AttachmentToDeleteItem } from '../../models/attachment-to-delete-item';
import { SharepointFileItem } from '../../models/sharepoint-file-item';
import { AttachmentItem } from '../../models/attachment-item';
import { FormValidations } from '../../utils/form-validations';
import { FormGroup } from '@angular/forms';
import { Spcrud } from '../../services/spcrud';
import { NewDealSetupServiceHelper } from 'src/shared/services/new-deal-setup-service-helper';
import { NewdealService } from 'src/app/services/newdeal.service';
import { NotifyPopupComponent } from '../alerts/notify-popup/notify-popup.component';
import { catchError, map, switchMap } from 'rxjs';
import { NewDealNotifyPopupComponent } from '../alerts/newdeal-notify-popup/newdeal-notify-popup.component';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { DealWbsSetupServiceHelper } from 'src/app/deal-wbs-setup/shared/services/deal-wbs-setup-service-helper';
import { DealWbsNotifyPopupComponent } from '../alerts/deal-wbs-notify-popup/deal-wbs-notify-popup.component';
const modalTypes  = {
  additionalDocumentTypes: 'additional-document-types',
  deleteAttachment: 'delete-attachment',
  fileNameUploading: 'file-name-uploading',
  fileOverwrite: 'file-overwrite',
  fileNameBlocked: 'file-name-blocked',
  fileNameTooLong: 'file-name-too-long',
  fileEncrypted: 'file-encrypted',
  fileEmpty: 'file-empty'
};

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  documentTypesValues: Array<DocumentTypeItem> = [];
  additionalDocumentTypesAdded: Array<DocumentTypeItem> = [];
  additionaldocumentTypesToAdd: Array<DocumentTypeItem>;
  documentTypesToShow: Array<DocumentTypeItem> = [];
  invalidFileNameCharactersList:any = [];
  blockedFileTypes:any = [];
  filesPendingToBeUploaded:any = [];
  additionalDocumentTypesAvailable = true;
  formId: number;
  modalType: string;
  folderUrl: string;
  folderName: string;
  attachmentToDeleteItem: AttachmentToDeleteItem = new AttachmentToDeleteItem();
  sharepointFileItem: SharepointFileItem = new SharepointFileItem();
  fileFullURL: string;
  
  @Input() formType:any;

  @Input() disabled: boolean;

  @Input() addAdditionalDocumentTypes: boolean;

  @Input() removeDocuments: boolean;

  @Input() formGroup: FormGroup;

  @Input() formSubmitted: boolean;
  @Input() showDealWBSpopup: boolean;
  @Input() showNewDealpopup: boolean;
  @Input() showCCLListpopup: boolean;

  @Output() selectedFile = new EventEmitter<boolean>();

  @Output() additionalDocumentTypeListItemToStore = new EventEmitter<any>();

  @Output() uploadedAttachments = new EventEmitter<any>();
  @ViewChild(NewDealNotifyPopupComponent, { static: true }) notifypopupComponent: NewDealNotifyPopupComponent;
  @ViewChild(DealWbsNotifyPopupComponent, { static: true }) DealWbsNotifyPopupComponent: NewDealNotifyPopupComponent;

  constructor( private fileService: FilesService,
               private spcrud: Spcrud,
               private newDealSetupServiceHelper: NewDealSetupServiceHelper,
               private NewdealService : NewdealService  ,
               private spinnerService : SpinnerService ,
               private dealWbsSetupServiceHelper :DealWbsSetupServiceHelper       
               ) { }

  ngOnInit() {
    this.invalidFileNameCharactersList = AppSettings.invalidFileNameCharactersList;
    this.blockedFileTypes = AppSettings.blockedFileTypes;
  }

  populateDocumentTypes(responseDocumentTypes: Array<DocumentTypeItem>, formId: number) {
    this.formId = formId;
    this._setFormUrl();
    this.documentTypesValues = responseDocumentTypes;

    this._filterDocumentTypesToShow();
    this.additionaldocumentTypesToAdd = this._filterAdditionalDocumentTypes();
    this.evaluateAdditionalDocumentTypes();
  }
  updateExternalRemovedDocuments(responseAttachments: any) {
    let responseDocTypeItems = this.dealWbsSetupServiceHelper.createAttachmentsObject(responseAttachments,this.documentTypesValues);
    let currentDocTypeItems = this.documentTypesValues;
    if (responseDocTypeItems.length != currentDocTypeItems.length) {
      currentDocTypeItems.forEach(cdoc => {
        const index = this._getDocumentTypeItemIndex(cdoc.documentTypeId);
        if (index !== -1 && !responseDocTypeItems.some((d:any) => d.documentTypeId == cdoc.documentTypeId)) {
          this.documentTypesValues[index].documentTypeAttachments = [];
        }
      });
    }
  }
  populateAdditionalDocumentTypes(responseAdditionalDocumentTypes: Array<DocumentTypeItem>) {
    this.additionalDocumentTypesAdded = this._excludeNotVisibleDocumentTypes(responseAdditionalDocumentTypes);
    if (responseAdditionalDocumentTypes.length > 0) {
      this._filterDocumentTypesToShow();
      this.additionaldocumentTypesToAdd = this._filterAdditionalDocumentTypes();
      this.evaluateAdditionalDocumentTypes();
    }
  }

  onFileSelected(fileSelected: any, documentTypeItemId: number, documentTypeId: number,docTitle:string) {
    this.sharepointFileItem.fileItem = fileSelected;
    this.sharepointFileItem.documentTypeItemId = documentTypeItemId;
    this.sharepointFileItem.documentTypeId = documentTypeId;
    this.sharepointFileItem.documentTitle = docTitle;
    if (this._getFileIndex(fileSelected.name) !== -1) 
    {
      if(this.showDealWBSpopup)
        {
          this.DealWbsNotifyPopupComponent.openPopup('DealWbs',modalTypes.fileNameUploading);            
        }
      if(this.showNewDealpopup)
        this.notifypopupComponent.openPopup('NewDeal',modalTypes.fileNameUploading);            
    } else {
      this.selectedFile.emit(true);
    }

  }
  
removeDocumentFromUI(fileName:any,documentTypeId:any)
{
  const index = this._getDocumentTypeIndex(documentTypeId);
  const attachmentIndex = this._getAttachmentIndex(index,fileName);
  this.documentTypesValues[index].documentTypeAttachments.splice(attachmentIndex, 1);
  this._filterDocumentTypesToShow();  
}

  saveFileToLibrary(formId: number) {
    this.formId = formId;
    this._setFormUrl();
    const file = this.sharepointFileItem.fileItem;
    this.filesPendingToBeUploaded.push(file);
    const documentTypeItemId = this.sharepointFileItem.documentTypeItemId;
    const documentTypeId = this.sharepointFileItem.documentTypeItemId;
    const fileName = file.name;    
    const docTitle = this.sharepointFileItem.documentTitle;            
    const isUploading = true;  
    var isFileUploadFailed = false;
    if (fileName.length <= AppSettings.maxUrlLength) {
      this._isFileUploaded(this.documentTypesValues, fileName, documentTypeItemId).then(replaceAction => {
        if (replaceAction.fileUploaded) 
        {
          if (replaceAction.overwriteAllowed) 
          {           
            if(this.showDealWBSpopup)              
              this.DealWbsNotifyPopupComponent.openPopup('DealWbs',modalTypes.fileOverwrite);              
            if(this.showNewDealpopup)                           
               this.notifypopupComponent.openPopup('NewDeal',modalTypes.fileOverwrite);
          } else {
            this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));         
            if(this.showDealWBSpopup)
              {                                
                this.DealWbsNotifyPopupComponent.openPopup('DealWbs',modalTypes.fileNameBlocked);
              }
            if(this.showNewDealpopup)                           
               this.notifypopupComponent.openPopup('NewDeal',modalTypes.fileNameBlocked);                        
          }
        } else {

          this.showAttachments(fileName, documentTypeId, isUploading);
          this.fileService.validateEncryptedFile(file).then((isValid) => {            
            if (isValid==true) 
            {
              var uploadRequestObj =this.prepareRequestObj('file',this.formId.toString(),fileName,docTitle);
              var attachmentRequestObj =this.prepareRequestObj('attachment',this.formId.toString(),fileName,docTitle);
              this.uploadFile(uploadRequestObj,attachmentRequestObj,file,documentTypeId);         
            }
            else if(isValid==false)
            {
              this.removeDocumentFromUI(fileName,documentTypeId);              
              if(this.showNewDealpopup)                           
                this.notifypopupComponent.openPopup('NewDeal',modalTypes.fileEncrypted);  
              else if(this.showDealWBSpopup)
              {                                
                this.DealWbsNotifyPopupComponent.openPopup('DealWbs',modalTypes.fileEncrypted);
              }    
              this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));                            
            }
             else 
             {
              this.removeDocumentFromUI(fileName,documentTypeId);              
              if(this.showNewDealpopup)                                           
              this.notifypopupComponent.openPopup('NewDeal',modalTypes.fileEmpty);
              else if(this.showDealWBSpopup)
                {                                
                  this.DealWbsNotifyPopupComponent.openPopup('DealWbs',modalTypes.fileEmpty);
                }
              isFileUploadFailed = true;
              this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));      
            }    
          });                    
        }
      });      
    } else {
      this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));      
      if(this.showDealWBSpopup)        
          this.DealWbsNotifyPopupComponent.openPopup('DealWbs',modalTypes.fileNameTooLong);                  
      if(this.showNewDealpopup)                                                 
          this.notifypopupComponent.openPopup('NewDeal',modalTypes.fileNameTooLong);
    }
  }

  showAttachments(fileName: string, documentTypeId: number, isUploading: boolean) {
    const url = this.fileService.generateFileUrl(fileName, this.folderUrl);
    const index = this._getDocumentTypeIndex(documentTypeId);
    this._setAttachmentItem(fileName, url, isUploading, index);
  }


  prepareRequestObj(uploadType:any,formId:any,fileName:any,docType:any):any
  {
    var fileUploadInput:any = {};
    
    fileUploadInput['FormID'] = this.formId.toString();
    fileUploadInput['FileName'] = fileName;    
    if(uploadType=='file')
    {               
      if(this.formType == AppSettings.FormTypes.NewDealSetupForm)
      {
        fileUploadInput['FormType'] = 'NewDeal';
        fileUploadInput['CurrentAttachmentEnviroment'] = AppSettings.newDealAttachementFolder;
      }
      else
      {
        fileUploadInput['FormType'] = 'DealWbs';
        fileUploadInput['CurrentAttachmentEnviroment'] = AppSettings.dealWbsAttachementFolder;
      }        
    }
    if(uploadType=='attachment')
    {      
      fileUploadInput['DocumentType'] = docType;      
      fileUploadInput['Title'] = fileName; 
      fileUploadInput['CreatedBy'] = localStorage.getItem("AuditorFullName")?.toString();  
      fileUploadInput['Created'] = "";
      fileUploadInput['ID'] = "";
    }
    return fileUploadInput;
  }

  uploadFile(uploadRequestObj:any,attachmentRequestObj:any,file:any,documentTypeId:any):any 
  {
    return this.fileService.uploadFile(uploadRequestObj).subscribe({
    next: (fileMeta: any) => 
    {
      this.NewdealService.uploadFileWithPresignedURL(fileMeta,file).subscribe(
        {
          next:()=>
          {
            this.fileService.addAttachement(attachmentRequestObj,this.formType).subscribe(
              {
                next: (response2: any) => 
                {
                  this.showAttachments(uploadRequestObj.FileName, documentTypeId, false);
                  this._cleanFilesPendingToBeUploaded(this._getFileIndex(uploadRequestObj.FileName))
                },error:(err2:any)=>{}
              }
            )
          },error:()=>{},
        }
      )      
    },
    error: (err: any) => {  
      console.log("Error" + err)
      if (err.status == 403 && err.error.stringKey==AppSettings.tokenExpiredText) 
        { 
          this._openSessionExpiredModal();
        }
    }
  }
  );    
  }


  private _openSessionExpiredModal() {
    this.notifypopupComponent.openPopup(AppSettings.tokenExpiredText);
  }

  openAdditionalDocumentTypesModal() {
    if(this.showDealWBSpopup)      
        this.DealWbsNotifyPopupComponent.openPopup('DealWbs',modalTypes.additionalDocumentTypes);             
    if(this.showNewDealpopup)                                                 
        this.notifypopupComponent.openPopup('NewDeal',modalTypes.additionalDocumentTypes);
            
  }

  addAdditionalDocumentType(data:any) {
    const documentTypeId = data.documentTypeId;
    const i = data.index;
    const index = this.documentTypesValues.map(item => item.documentTypeItemId).indexOf(documentTypeId);
    const documentTypeItemId = this.documentTypesValues[index].documentTypeItemId;
    const documentTypeToAdd = this.documentTypesValues[index];
    const documentTypeTitle = this.documentTypesValues[index].documentTypeTitle;
    this.additionalDocumentTypesAdded.push(documentTypeToAdd);
    this._filterDocumentTypesToShow();
    this.additionaldocumentTypesToAdd.splice(i, 1);
    this.evaluateAdditionalDocumentTypes();
    const objectToEmit = {
      documentTyeIdToStore: documentTypeItemId,
      documentTitle:documentTypeTitle
    };
    this.additionalDocumentTypeListItemToStore.emit(objectToEmit);        
  }

  evaluateAdditionalDocumentTypes() {
    if (this.additionaldocumentTypesToAdd.length === 0) {
      this.additionalDocumentTypesAvailable = false;
    }
  }

  deleteAttachment(fileName: string, documentTypeId: number, attachmentIndex: number) {
    this.attachmentToDeleteItem.fileName = fileName;
    this.attachmentToDeleteItem.documentTypeId = documentTypeId;
    this.attachmentToDeleteItem.attachmentIndex = attachmentIndex;   
    if(this.showDealWBSpopup)      
        this.DealWbsNotifyPopupComponent.openPopup('DealWbs',modalTypes.deleteAttachment);                                                                                               
    if(this.showNewDealpopup)                                                       
        this.notifypopupComponent.openPopup('NewDeal',modalTypes.deleteAttachment);             
  }

  downloadAttachment(fileName: any) 
  {   
      let attachementFolderName = '';
      let pageSource='';
      if(this.formType == AppSettings.FormTypes.NewDealSetupForm)
      {
        attachementFolderName=AppSettings.newDealAttachementFolder;
        pageSource = 'NewDeal';
      }
      else
      {
        attachementFolderName=AppSettings.dealWbsAttachementFolder;
        pageSource = 'DealWbs';
      }     

      this.NewdealService.downloadAttachments(this.formId,fileName,attachementFolderName,pageSource).subscribe
      ({
        next: (response: any) => {
          if(response!=null && response.FileUrl!=null)
            {
                window.open(response.FileUrl, "_blank");
            }               
        },
        error: (err: any) => {         
          console.log("Error" + err)
        }
      }
      );          
  }




  deleteConfirmDetails(event:any)
  {
    if(event)
    {
    this.fileService.deleteFile(this.formId,this.attachmentToDeleteItem.fileName,this.formType).subscribe(
      {
        next: (response: any) => {
          const index = this._getDocumentTypeIndex(this.attachmentToDeleteItem.documentTypeId);
          this.documentTypesValues[index].documentTypeAttachments.splice(this.attachmentToDeleteItem.attachmentIndex, 1);
          this._filterDocumentTypesToShow();          
        },
        error: (err: any) => {         
          console.log("Error" + err)
          if (err.status == 403 && err.error.stringKey==AppSettings.tokenExpiredText) 
            { 
              this._openSessionExpiredModal();
            }
        }
      });
    }
  }
  ConfirmDetails(event:any) //Handle the popup click
  {
    if(event.isOkayClicked) // Okay is clicked on the popup
    {
        //based on the type propgate the function call
        switch(event.modalType)
        {
          case modalTypes.deleteAttachment:this.deleteConfirmDetails(true);break;
          case modalTypes.fileOverwrite:
            this.confirmOverwriteFile();
            break;          
            default:break;
        }
    }
    else //cancel clicked
    {
      switch(event.modalType)
      {        
        case modalTypes.fileOverwrite:
          this.cancelOverWriteFile();
          break;          
        default:break;
      }
    }
  }


  confirmOverwriteFile() {
    const file = this.sharepointFileItem.fileItem;
    const documentTypeItemId = this.sharepointFileItem.documentTypeItemId;
    const documentTypeId = this.sharepointFileItem.documentTypeItemId;
    const docTitle = this.sharepointFileItem.documentTitle;
    const fileName = file.name;
    const isUploading = true;   
    this.fileService.deleteFile(this.formId,fileName,this.formType).subscribe(
      {
        next: (response: any) => {  
          this.showAttachments(fileName, documentTypeId, isUploading);  
          //check file encryption before uploading the file.
          this.fileService.validateEncryptedFile(file).then((isValid) => {            
            if (isValid==true) 
            {
              var uploadRequestObj =this.prepareRequestObj('file',this.formId,fileName,docTitle);
              var attachmentRequestObj =this.prepareRequestObj('attachment',this.formId,fileName,docTitle);
              this.uploadFile(uploadRequestObj,attachmentRequestObj,file,documentTypeId);        
            }
            else if(isValid==false)
            {
              this.removeDocumentFromUI(fileName,documentTypeId);              
              if(this.showNewDealpopup)                                                                         
                  this.notifypopupComponent.openPopup('NewDeal',modalTypes.fileEncrypted);  
              else if(this.showDealWBSpopup)
                {                                
                  this.DealWbsNotifyPopupComponent.openPopup('DealWbs',modalTypes.fileEncrypted);
                }   
                this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));                                     
            }
             else 
             {
              this.removeDocumentFromUI(fileName,documentTypeId);              
              if(this.showNewDealpopup)                                                                                                      
                  this.notifypopupComponent.openPopup('NewDeal',modalTypes.fileEmpty);
              else if(this.showDealWBSpopup)
                {                                
                  this.DealWbsNotifyPopupComponent.openPopup('DealWbs',modalTypes.fileEmpty);
                }
                this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));                            
            }            
          });          
        },
        error: (err: any) => {         
          console.log("Error" + err)
        }
      }
    );

  }

  cancelOverWriteFile() {
    const file = this.sharepointFileItem.fileItem;
    const fileName = file.name;
    this._cleanFilesPendingToBeUploaded(this._getFileIndex(fileName));        
  }

  populateDocumentSection(attachmentsData: any,autosavespinnerEnabled:any) {
    if(!autosavespinnerEnabled){this.spinnerService.hide();}
    //remove unmatched files from current attchements only when file available in current attachment and not present in respose attachement.
    let responseDocTypeItems = this.newDealSetupServiceHelper.createAttachmentsObject(attachmentsData,this.documentTypesValues);
    responseDocTypeItems.forEach(doc => {
      const index = this._getDocumentTypeItemIndex(doc.documentTypeItemId);
      if(index !== -1){
        let currentFiles:any = this.documentTypesValues.find(d=>d.documentTypeItemId == doc.documentTypeId)?.documentTypeAttachments;
        let responseFiles = doc.documentTypeAttachments;
        for(let i = 0 ; i < currentFiles.length ;i++ )
        {
          let responseFileIndex = responseFiles.findIndex(f => f.attachmentName === currentFiles[i].attachmentName);
          if(responseFileIndex == -1)
          {
            this.documentTypesValues.find(d=>d.documentTypeItemId == doc.documentTypeId)?.documentTypeAttachments.splice(i,1)
          }
        }
        
      }
    });

    attachmentsData.forEach((attachment:any) => {
      const url = this.fileService.generateFileUrl(attachment.FileName, this.folderUrl);
      let attachmentID:any = this.documentTypesValues.find(d=>d.documentTypeTitle===attachment.DocumentType)?.documentTypeItemId;
      const index = this._getDocumentTypeItemIndex(attachmentID);
      if (index !== -1) {
        this._setAttachmentItem(attachment.FileName, url, false, index);
      }
    });
  }

  getFolderUrl() {
    return this.folderUrl;
  }

  setFormUrl() {
    this.folderName = AppSettings.attachmentsFolderName + this.formId;
    this.folderUrl = this._createFolderUrl();
  }

  setFormId(formId: number) {
    this.formId = formId;
  }
  
  getFilesPendingToBeUploaded() {
    return this.filesPendingToBeUploaded.length !== 0;
  }

  getDocumentTypeAttachments() {
    const documentTypesAttachmentsToEmit = this.documentTypesToShow.map( item => {
      return {documentType: item.documentTypeTitle, hasAttachments: item.documentTypeAttachments.length > 0 };
    });
    this.uploadedAttachments.emit(documentTypesAttachmentsToEmit);
  }

  getAttachmentsRowsComplete(): Array<DocumentTypeItem> {
    const rowsCompleted: Array<DocumentTypeItem> = [];
    if (this.documentTypesToShow) {
      this.documentTypesToShow.forEach(row => {
        if (row && row.documentTypeAttachments.length > 0) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }

  showAsterisk(formControlName: string): boolean {
    return FormValidations.showAsterisk(
      this.formGroup,
      formControlName,
    );
  }

  highlightRequiredField(formControlName: string): boolean {
    return FormValidations.highlightRequiredField(
      this.formSubmitted,
      this.formGroup,
      formControlName
    );
  }

  editedExternally(formControlName: string): boolean {
      return FormValidations.editedExternally(this.formGroup, formControlName);
  }

  hasMergeConflict(formControlName: string): boolean {
    return FormValidations.hasMergeConflict(this.formGroup, formControlName);
  }
  

  private _excludeNotVisibleDocumentTypes(responseAdditionalDocumentTypes: Array<DocumentTypeItem>): Array<DocumentTypeItem> {
    return responseAdditionalDocumentTypes.filter(document => this.documentTypesValues
      .find( d => d.documentTypeItemId === document.documentTypeItemId));
  }
  
  private _setFormUrl() {
    this.folderName = AppSettings.attachmentsFolderName + this.formId;
    this.folderUrl = this._createFolderUrl();
  }

  private _createFolderUrl() {
    let libraryName ="";
    if(this.formType == AppSettings.FormTypes.NewDealSetupForm)
    {
      libraryName = AppSettings.newdealAttachmentsList;
    }
    else
    {
      libraryName = AppSettings.dealWbslAttachmentsList;
    }
    libraryName = AppSettings.newdealAttachmentsList;
    return libraryName + '/' + this.folderName;
  }

  private _isFileUploaded(files: any, fileName: string,  documentTypeItemId: number): Promise<any> {    
    return new Promise<any>((resolve, reject) => {
      let fileValidUploaded: boolean;
      let attachmentdocId: any;
      let isFileUploaded:boolean=false;
      const replaceAction = {
        fileUploaded: false,
        overwriteAllowed: false        
      };

      let attachment:any;
      attachment = files.forEach((fileData: {
        documentTypeItemId: number; documentTypeAttachments: any[]; }) => {
        attachment = fileData.documentTypeAttachments.find((ele) => ele.attachmentName === fileName);        
        if (attachment) {
          attachmentdocId= fileData.documentTypeItemId;
          // Check if the filename matches
          isFileUploaded = true;          
          // Check if both filename and docID documentTypeItemId
          replaceAction.overwriteAllowed = fileData.documentTypeItemId === documentTypeItemId;      
        if (replaceAction.overwriteAllowed) {
            return attachment;
          }
        }
      });
   
      if (isFileUploaded) {
        fileValidUploaded = attachmentdocId && this.formId;
        if (!fileValidUploaded) 
        {         
        } else {
          replaceAction.fileUploaded = true;
          resolve(replaceAction);
        }
      } else {
        resolve(replaceAction);
      }
    });
  }

  private _setAttachmentItem(fileName: string, url: string, isUploading: boolean, index: number) {
    const attachmentIndex = this.documentTypesValues[index].documentTypeAttachments.map(item => item.attachmentName).indexOf(fileName);

    if (attachmentIndex !== -1) {
      this.documentTypesValues[index].documentTypeAttachments[attachmentIndex].attachmentUploading = isUploading;
    } else {
      const attachmentItem = new AttachmentItem();
      attachmentItem.attachmentName = fileName;
      attachmentItem.attachmentUrl = url;
      attachmentItem.attachmentUploading = isUploading;
      this.documentTypesValues[index].documentTypeAttachments.push(attachmentItem);
    }
    this._filterDocumentTypesToShow();
  }

  private _getDocumentTypeIndex(documentTypeId: number): number {
    return this.documentTypesValues.map(item => item.documentTypeItemId).indexOf( documentTypeId);
  } 

  private _getAttachmentIndex(index: number,fileName:any): number {
    return this.documentTypesValues[index].documentTypeAttachments.map(item=>item.attachmentName).indexOf(fileName);    
  } 

  private _getDocumentTypeItemIndex(documentTypeItemId: number): number {
    return this.documentTypesValues.map(item => item.documentTypeItemId).indexOf(documentTypeItemId);
  }

  private _getFileIndex(fileName: string): number {
    return this.filesPendingToBeUploaded.map((item:any) => item.name).indexOf(fileName);
  }

  private _cleanFilesPendingToBeUploaded(index: number) {
    this.filesPendingToBeUploaded.splice(index, 1);
  }

  private _filterAdditionalDocumentTypes() {
    return this.documentTypesValues.filter(
      documentType => !documentType.documentTypeRequired && !this._inArray(this.additionalDocumentTypesAdded, documentType));
  }

  private _filterDocumentTypesToShow() {
    this.documentTypesToShow = this.documentTypesValues.filter( documentType => {
      return documentType.documentTypeRequired;
    }).sort((a:any,b:any)=>(a.value > b.value?1:-1));
    let additionalsToAdd: Array<DocumentTypeItem> = [];
    this.additionalDocumentTypesAdded.forEach(documentType => {
      if (!this._inArray(this.documentTypesToShow, documentType)) {
        additionalsToAdd = additionalsToAdd.concat(this.documentTypesValues.filter(d => d.documentTypeItemId === documentType.documentTypeItemId));
      }
    });

    this.documentTypesToShow = this.documentTypesToShow.concat(additionalsToAdd);
    this.getDocumentTypeAttachments();
  }

  private _inArray(documentTypes: Array<DocumentTypeItem>, document: DocumentTypeItem): boolean {
    return Boolean(documentTypes.find(d => d.documentTypeItemId === document.documentTypeItemId));
  }
}
