import { Component, OnInit, OnDestroy, Inject,Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationResult } from '@azure/msal-browser';
import { TokenService } from '../services/token.service';
import { PeoplePickerService } from 'src/app/services/peoplepicker.service'; 
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Observable, Subject, forkJoin } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppSettings } from 'src/shared/app-settings';
import { AdminService } from '../services/admin.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NewdealService } from '../services/newdeal.service';
import { CommonHelperService } from '../commonHelper/common-helper.service';
import { DOCUMENT } from '@angular/common';
import { environment } from "src/environments/environment";
import { GlobalHelperService } from '../globalHelper/global-helper.service';
import {MainService} from '../../shared/services/main.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public user : any="User";
  userName:any;
  isADGroupUser:any=false;
  userEmail:any;
  isTFAdminUser:boolean=false;
  isITSAdminUser:boolean=false;
  isIframe = false;
  profileData:any;
  isUserHasAdminAccess:boolean=false;
  isContentManagementUser:boolean = false;
  isDashboardUser:boolean=false;
  isAdminUser:boolean=false;
  formName:string="";
  noImageFile:any="eyJl";
  public isAuthorized : boolean ;
  public base64:string='data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABmJLR0QA/wD/AP+gvaeTAAADi0lEQVR4nO2cTUtVURSGH9MiBUsqJYhMy8I+iAbRxASptBo47MsaBP2A/kKTIJoFFSH9C0chRaFWGEVUphlC2aiENAujLG2wrmAXO7dz7tlr7+tZD7yj+7H2etl337PXWWeDYRiGYRiGYRhGoJT5HkABmoCjQAvQDGwBqnOvTQPjwAjQD9wBxjyMsWSpAM4CD4H5mBoAuoBy9VGXGMeAUeIbnK8RoEN57CVBFXCb4g3OVzdQqZhH0NQBT0nf5AU9AWrVsgmUOuRn7srkxUtJZs2uwu1MXmpmr1bJLDBcrMmFdEsls4DoQN/kBR1XyC8IKkjnEi6phsnIdfY5/Jm8oNPOswyAR/g3ut95lp5pwr/J88Ac0Og4179YoRkMKRCFQBnKW3Rto1uU40XRqhlM2+idyvGiaNYMpm30ZuV4UdRrBtM2urrwW9RYoxlM+w7LvHK8Qqjlrz2jM4sZrYS20T+V40XxQzOYttFfleNFMa0ZTNvoD8rxohjXDKZt9GvleFEMawbTNnpAOV4Uy7qCtw2pnIVQvWtwnKt3knQgpa0+51kGQBf+jT7pPMsAKEenl+NfGiIj9wwB2vFn9CGF/IKiG32Tb6pkFhiVSPeQlsmPyWinEkg/nMZ6PQxsUMopWGqBQdzO5Mw2OOZTifTFuViTM7tcRHEE+ZkXa/AQGby6iEs5cAapRcTZrs8hO75TBHhDI/SnshqRRpdWpFUh/6ms98ifaR/yVNY7D2M0DMMwDMMwDMMobULcGa4FdgF7gN3ADmAdUJN7rSb3vqlFmgTeIDWOV0j/iGonUiFCMHojUgA6DLQBW1P63jHgPnAXuAd8TOl7S4p9wFVk9mncXZkDXgBXgL0K+XmlHrgIPEPH3EJl1EvAdqcZK3MQ6CGMDqWlZnov0EkYy2hsVgEXgJf4N/N/9Rw4D6x04IcTOoG3+DcuqUaBEwQ8ww8gd0V8G5WWHgD7U3WoSCqQP5Zf+Dcnbf0GrhHActKA9Dz7NsS1BpEDA7zQhuzKfJugpUmUnx8nF/B7kQMvRc2geHDAemDCcUIhawKpvcQiSZ/wZaQukVWqEN96XQfyefBUKIr9dFmSi/IZ7LzPb8Q8qSFJ69SnBJ9ZbsQuuSYxuifBZ5YbKh5sAj7jf530pSkUT9JpB744TihETeKhHbgRuIFchcwmHHgpaDaX43Wkm9UwDMMwDMMwDMMwDCPz/AHwMcyzZEtWEAAAAABJRU5ErkJggg==';
  public imagePath:any=this.base64;
  isAdGroupDatafetched: boolean=false;
  userData: any;  
    constructor(private myhttp: HttpClient, private msalService: MsalService, private msalBroadcastService: MsalBroadcastService, private sanitizer: DomSanitizer, private tokenService: TokenService, private authService: MsalService, private router: Router, private peoplepickerservice: PeoplePickerService, private adminService: AdminService, private newDealService: NewdealService, private commonHelperService: CommonHelperService, private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document, private mainservice: MainService, public globalHelperService: GlobalHelperService)
 {
    
 }
 login()
 {
  this.isIframe = window !== window.parent && !window.opener;
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        )
        .subscribe((result: any) => {
          const account = result.payload.account;         
          var fullname = account?.name?.split(/,/);
          var displayName = fullname.length > 1 ? fullname[1].trim() + ' ' + fullname[0].trim() :fullname[0].trim();
          //var displayName = fullname[1].trim() + ' ' + fullname[0].trim();
          this.userName=displayName;
          this.tokenService.accessToken = result.payload?.accessToken;
          localStorage.setItem("Acccess_Token_User", this.tokenService.accessToken);
          this.tokenService.idToken = result.payload?.idToken;
          localStorage.setItem("accessToken",this.tokenService.accessToken); 
          localStorage.setItem("idToken",this.tokenService.idToken); 
          localStorage.setItem("userProfileId",account?.username); 
          localStorage.setItem("userProfileName",displayName);
          this.getPeoplePicker(account.username,this.userName);
          localStorage.setItem('isAuthorized', "true");
          localStorage.setItem("fromtoken","true");
          this.VerifyADGroupAccess();
          this.VerifyContentAndUserManagementAccess();
          // let currentAccessedPage=localStorage.getItem("CurrentAccessPage")?.toString();
          let currentAccessedPage=this.globalHelperService.currentAccessPage?.toString();
          let currentAccessedFormID:any = "0";
          switch(currentAccessedPage)
          {
            case "NewDealForm":
              // currentAccessedFormID = localStorage.getItem("formId")?.toString();
              currentAccessedFormID = this.globalHelperService.formID?.toString();
              break;
            case "CrossBorderForm":
              currentAccessedFormID = localStorage.getItem("form_id")?.toString();
              break;
            case "EHO":
              currentAccessedFormID = localStorage.getItem("eho_id")?.toString();
              break;
              case "DealWBSForm":
               // currentAccessedFormID = localStorage.getItem("formId")?.toString();
               currentAccessedFormID = this.globalHelperService.formID?.toString();
                  break;
              case "TerminationForm":
                  //currentAccessedFormID = localStorage.getItem("tformId")?.toString();
                  currentAccessedFormID = this.globalHelperService.formID?.toString();
                  break;
              case "ClosingChecklistForm":
                 // currentAccessedFormID = localStorage.getItem("formId")?.toString();
                 currentAccessedFormID = this.globalHelperService.formID?.toString();
                  break;
            default:
              currentAccessedFormID = "0";
          }
          if(currentAccessedPage=="Dashboard"){
            window.location.href =AppSettings.DashboardURL;
          }
          else if (currentAccessedPage == "TerminationDashboard") {
              window.location.href = AppSettings.terminationFormDashboard;
          }
          else if (currentAccessedPage == "ClosingChecklistDashboard") {
              window.location.href = AppSettings.ClosingCheckListFormDasboard;
          }
          else if (currentAccessedPage == "TerminationForm") {
              window.location.href = currentAccessedFormID?.toString() == "0" || currentAccessedFormID?.toString() == "" || currentAccessedFormID?.toString() == null ? AppSettings.terminationForm : AppSettings.terminationForm + "?id=" + currentAccessedFormID;
          }
          else if(currentAccessedPage=="EngagementDashboard"){
            window.location.href =AppSettings.Engagementdashboard;
          }
          else if(currentAccessedPage=="ClosingChecklistDashboard"){
            window.location.href =AppSettings.ClosingChecklistDashboard;
          }
          else if(currentAccessedPage=="CrossBorderForm"){
            window.location.href =currentAccessedFormID?.toString()=="0"||currentAccessedFormID?.toString()==""||currentAccessedFormID?.toString()==null?AppSettings.crossBorder:AppSettings.crossBorder+"?item_id="+currentAccessedFormID;
          }
          else if(currentAccessedPage=="NewDealForm"){
            window.location.href =currentAccessedFormID?.toString()=="0"||currentAccessedFormID?.toString()==""||currentAccessedFormID?.toString()==null?AppSettings.newDeal:AppSettings.newDeal+"?id="+currentAccessedFormID;
          }
          else if(currentAccessedPage=="EHO"){
            window.location.href =currentAccessedFormID?.toString()=="0"||currentAccessedFormID?.toString()==""||currentAccessedFormID?.toString()==null?AppSettings.EnagagementHealthObject:AppSettings.EnagagementHealthObject+"?item_id="+currentAccessedFormID;
          }
          else if(currentAccessedPage=="DealWBSForm"){
            window.location.href =currentAccessedFormID?.toString()=="0"||currentAccessedFormID?.toString()==""||currentAccessedFormID?.toString()==null?AppSettings.dealWbsForm:AppSettings.dealWbsForm+"?id="+currentAccessedFormID;
          }
          else if(currentAccessedPage=="Admin"){
            window.location.href =AppSettings.AdminURL;
          }
          else if(currentAccessedPage=="ContentManagement"){
            window.location.href =AppSettings.ContentURL;
          }
          else if(currentAccessedPage=="ClosingChecklistForm"){             
            window.location.href =currentAccessedFormID?.toString()=="0"||currentAccessedFormID?.toString()==""||currentAccessedFormID?.toString()==null?AppSettings.ClosingCheckListForm:AppSettings.ClosingCheckListForm+"?id="+currentAccessedFormID;
          }
          else{
            window.location.href =AppSettings.Engagementdashboard;
          }
        });
 }
 
 private _isDCFMemberInAdGroup(email:any,groups:Array<any>): boolean {
    
  return !!groups.find(group => group.userPrincipalName.toLowerCase() === email.toLowerCase());
}
 GetDealADGroupDetails(): Observable<any> {
  const url = AppSettings.GetDealADGroupDetails();
  return this.myhttp.get(url, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("accessToken")
      })
  })
}
 validateUser(account : any){
  this.tokenService.validateUser().subscribe((response: any) =>
         {          
         if(response.isSuccessStatusCode)
         {          
           var fullname= account?.name?.split(/,/);
       var displayName=fullname[1].trim() +' '+ fullname[0].trim() ;      
 
        this.user=displayName;
        localStorage.setItem("username",this.user)  
        localStorage.setItem('isAuthorized', this.isUserLoggedIn() ? "true" : "false");
        this.isAuthorized=true;
        this.router.navigate(['/home']);
         }else{
           this.router.navigate(['unauthorized']);  
         }
       })
  }  

 logout()
 {
   this.msalService.logout();
   localStorage.removeItem("username");
   localStorage.removeItem("isAuthorized");
   localStorage.removeItem("userProfileName");
   localStorage.removeItem("userProfileId");
 }
 isUserLoggedIn():boolean
 {
  if (localStorage.getItem("isAuthorized")) {
    return true;
  }
  return false;
 }
 loadURLFormID(){
  let data=window.location.search.substring(1);
  let formtypeUrl = window.location.href;
  if(data!=""&&data!=undefined)
  {
      let splitData=data.split('=');
      if(formtypeUrl.includes("newdeal"))
      {
        localStorage.setItem("CurrentAccessPage","NewDealForm");
        localStorage.setItem('formId', splitData[1].toString());
        this.globalHelperService.currentAccessPage="NewDealForm";
        this.globalHelperService.formID=splitData[1].toString();
      }
      else if(formtypeUrl.includes("engagementHealthObject"))
      {
        this.globalHelperService.formID=splitData[1].toString();
        this.globalHelperService.currentAccessPage="engagementHealthObject";
        localStorage.setItem("CurrentAccessPage","EHO");
        localStorage.setItem('eho_id', splitData[1].toString());
      }
      else if(formtypeUrl.includes("dealWbsForm"))
      {
        localStorage.setItem("CurrentAccessPage","DealWBSForm");
        localStorage.setItem('formId', splitData[1].toString());
        this.globalHelperService.currentAccessPage="DealWBSForm";
        this.globalHelperService.formID=splitData[1].toString();
      }
      else if(formtypeUrl.includes("closingCheckListForm"))
      {
        localStorage.setItem("CurrentAccessPage","ClosingChecklistForm");
        localStorage.setItem('formId', splitData[1].toString());
        this.globalHelperService.currentAccessPage="ClosingChecklistForm";
        this.globalHelperService.formID=splitData[1].toString();
      }
      else if(formtypeUrl.includes("terminationForm"))
        {
          localStorage.setItem("CurrentAccessPage","TerminationForm");
          localStorage.setItem('formId', splitData[1].toString());
          this.globalHelperService.currentAccessPage="TerminationForm";
          this.globalHelperService.formID=splitData[1].toString();
        }
      else
      {
        localStorage.setItem("CurrentAccessPage","CrossBorderForm");
        localStorage.setItem('form_id', splitData[1].toString());
        this.globalHelperService.currentAccessPage="CrossBorderForm";
        this.globalHelperService.formID=splitData[1].toString();
      }      
  }
  else{
    if(formtypeUrl.includes("admin"))
    {
      localStorage.setItem("CurrentAccessPage","Admin");
      this.globalHelperService.currentAccessPage="Admin";
    }
    else if(formtypeUrl.includes("content"))
    {
      localStorage.setItem("CurrentAccessPage","ContentManagement");
      this.globalHelperService.currentAccessPage="ContentManagement";
    }
    else if(formtypeUrl.includes("engagementDashboard"))
    {
      localStorage.setItem("CurrentAccessPage","EngagementDashboard");
      this.globalHelperService.currentAccessPage="EngagementDashboard";
    }
    else if(formtypeUrl.includes("closingChecklistDashboard"))
    {
      localStorage.setItem("CurrentAccessPage","ClosingChecklistDashboard");
      this.globalHelperService.currentAccessPage="ClosingChecklistDashboard";
    }
    else if(formtypeUrl.includes("terminationFormDashboard"))
      {
        localStorage.setItem("CurrentAccessPage","TerminationDashboard");
        this.globalHelperService.currentAccessPage="TerminationDashboard";
      }
    else if(formtypeUrl.includes("closingChecklistDashboard"))
      {
        localStorage.setItem("CurrentAccessPage","ClosingChecklistDashboard");
        this.globalHelperService.currentAccessPage="ClosingChecklistDashboard";
      }
    else if(formtypeUrl.includes("Dashboard"))
    {
      localStorage.setItem("CurrentAccessPage","Dashboard");
      this.globalHelperService.currentAccessPage="Dashboard";
    }    
    else if(formtypeUrl.includes("closingCheckListForm"))
    {
      localStorage.setItem("CurrentAccessPage","ClosingChecklistForm");
      this.globalHelperService.currentAccessPage="ClosingChecklistForm";
    }    
  }
 }

  ngOnInit(): void {
    //this.login()    
    this.loadURLFormID();  
    if(localStorage.getItem("IsInvalidForm")?.toString()=="true")
    {
      localStorage.removeItem("IsInvalidForm");
      window.location.href=AppSettings.DashboardURL;
    }
    let hasDashboardAccess= localStorage.getItem("isADGroupUser");
    if(hasDashboardAccess == "true")
    {
      this.isDashboardUser = true;
    }
    let hasContentAccess = localStorage.getItem("isContentManagementUser"); 
    if(hasContentAccess == "true")
    {
      this.isContentManagementUser = true;
    }
    let hasAdminAccess = localStorage.getItem("isUserInAdmin"); 
    if(hasAdminAccess == "true")
    {
      this.isUserHasAdminAccess = true;
    }
    let hasADAccess= localStorage.getItem("isADGroupUser");
    if(hasADAccess == "true")
    {
      this.isADGroupUser = true; 
    }
    let hasTFAdminAccess=localStorage.getItem("isTFAdminUser");
    if(hasTFAdminAccess == "true")
    {
      this.isTFAdminUser = true; 
    }
    localStorage.setItem("fromtoken","false");            
  if(!this.isUserLoggedIn())
   {
   this.login()    
   }
   else{
    this.getPeoplePicker(localStorage.getItem("userProfileId"),this.userName);
    this.isAuthorized= JSON.parse(localStorage.getItem("isAuthorized") as string);
    //Commented below due to Loading the default no image 
    // this.imagePath= this.getInnerHTMLValue();
    this.VerifyContentAndUserManagementAccess();
    this.VerifyADGroupAccess();
    this.loadScript();
   }
  }
  VerifyTerminationFormAdmin() {
    //let userId = localStorage.getItem("userProfileId")?.toString();
    this.commonHelperService.getUserData().subscribe(result => {
    if(result!=null && result!=undefined)
    {
      let isItsAdmin = this.isInITSAdminGroup(result.groups);
      this.isITSAdminUser = (isItsAdmin=="true");
      let isTFAdmin = this.isInTFAdminGroup(result.groups);
      localStorage.setItem('isTFAdminUser',isTFAdmin); 
      this.isTFAdminUser = (isTFAdmin=="true");      
    }
   });
    // this.adminService.GetUserRoleGroups(userId).subscribe(
    //   {
    //     next: (result: any) => {
    //       result = result.filter((s: any) => s.Title != null)
    //       let isTFAdmin = this.isInTFAdminGroup(result);
    //       localStorage.setItem('isTFAdminUser',isTFAdmin); 
    //       this.isTFAdminUser = (isTFAdmin=="true");         
    //     },
    //     error: (err: any) => {
    //       if (err.status == 403 && err.error.stringKey=="tokenexpired") {this.commonHelperService.isTokenExpired(); }
    //       console.log("Error" + err);
    //     }
    //   });
  }

  isInTFAdminGroup(userGroups: any) {
    let isAdmin_flag = "false";
    if(userGroups!=undefined && userGroups.length > 0)
    {
      userGroups.forEach((u: any) => {
        if (u == "Termination Administrators") {
          isAdmin_flag = "true";
        }
      });
    }
    return isAdmin_flag;
  }
  isInITSAdminGroup(userGroups: any) {
    let isAdmin_flag = "false";
    if(userGroups!=undefined && userGroups.length > 0)
    {
      userGroups.forEach((u: any) => {
        if (u == "ITS_Administrator") {
          isAdmin_flag = "true";
        }
      });
    }
    return isAdmin_flag;
  }  
  VerifyADGroupAccess(){
    let userId=localStorage.getItem("userProfileId")?.toString();
    let data=window.location.search.substring(1);
    let splitData=data.split('=');
    let isFormPathValidTogetMyDealID=this.isFormPathValid();
    if(isFormPathValidTogetMyDealID && data!=""){
      let appendURL=this.adminService.getAppendURL(this.formName);
      forkJoin([
        this.adminService.GetDealADGroupDetails(),
        this.adminService.GetMyDealIDDetailsFromForm(splitData[1].toString(),this.formName,appendURL)
      ]).subscribe({
     // this.adminService.GetDealADGroupDetails().subscribe({
        next: ([profile,resMyDealID]) => {
          if(window.location.href.includes("closingCheckListForm")){

            //this.mainService.=resMyDealID;
            this.mainservice.closingchecklistdata=resMyDealID

          }
          localStorage.setItem('adGroupMember',JSON.stringify(profile.value));
          if (this._isDCFMemberInAdGroup(userId,profile.value)) {
           localStorage.setItem('isADGroupUser',"true");
           this.isADGroupUser = true;
           this.VerifyTerminationFormAdmin();
           }
           else{
            localStorage.setItem("isADGroupUser","false");
            localStorage.setItem('isTFAdminUser',"false");
            this.isADGroupUser = false;
          }          
          let res={
            isADGroupUser:this.isADGroupUser,
            myDealID:resMyDealID.length>0?resMyDealID[0].MyDealID:undefined
          }          
          this.commonHelperService.updateUserGroupMembership(res);   
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          console.log("Error" + err);
        }
      });
    }
    else{
    this.adminService.GetDealADGroupDetails().subscribe({
      next: (profile: any) => {
        localStorage.setItem('adGroupMember',JSON.stringify(profile.value));
        if (this._isDCFMemberInAdGroup(userId,profile.value)) {
         localStorage.setItem('isADGroupUser',"true");
         this.isADGroupUser = true;
         this.isDashboardUser = true;
         this.VerifyTerminationFormAdmin();
         }
         else{
          localStorage.setItem("isADGroupUser","false");
          localStorage.setItem('isTFAdminUser',"false");
          this.isADGroupUser = false;
          this.isDashboardUser = false;
        } 
        let res={
          isADGroupUser:this.isADGroupUser,
          myDealID:0
        }
        this.commonHelperService.updateUserGroupMembership(res);   
      },
      error: (err: any) => {
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        console.log("Error" + err);
      }
    });
  }
   }   
   isFormPathValid():any{
    let formPath = window.location.pathname;
    if(formPath.includes("/newdeal")){
      this.formName=AppSettings.formListName;
     return true;
    }
    else if(formPath.includes("/dealWbsForm")){
      this.formName=AppSettings.dealWbsSetupFormList;
      return true;
     }
    else if(formPath.includes("/closingCheckListForm")){
      this.formName=AppSettings.closingChecklistFormList;
      return true;
     }
    else{
      return false
    }
   }
  VerifyContentAndUserManagementAccess(){    
    this.commonHelperService.getUserData().subscribe(data => {
      if(data.length!=0)
      {
      this.userData = data;
      this.isContentManagementUser = this.isInContentManagementGroup(this.userData.groups);
      this.isUserHasAdminAccess = this.isUserAdmin(this.userData.groups);
      if(this.isUserHasAdminAccess)
      {
        localStorage.setItem("isUserInAdmin","true");
      } 
      else{
        localStorage.setItem("isUserInAdmin","false");
      } 
      if(this.isContentManagementUser)
      {
        localStorage.setItem("isContentManagementUser","true");
      } 
      else{
        localStorage.setItem("isContentManagementUser","false");
      }    
    }
    });    
  }
  isUserAdmin(userGroups:any):boolean {
    let isValidUser=false;
    userGroups.forEach((u:any)=>{   
        if (u == "CrossBorder_ContentManagement_Business_Administrator" || u == "ITS_Administrator" || u == "Cross Border Administrators" || u == "Termination Administrators" || u == "NewDeal Administrators" || u == "MyDeal Administrators" || u == "DealWBS Administrators"){
        isValidUser=true;
      }
      });
    return isValidUser;
  }
  isInContentManagementGroup(userGroups:any):boolean {
    let isValidUser=false;
    userGroups.forEach((u:any)=>{   
      if(u=="CrossBorder_ContentManagement_Business_Administrator" || u == "ITS_Administrator"){
        isValidUser=true;
      }
      });
    return isValidUser;
  }

  //isInAdminGroups(userGroups:any):boolean {
  //  let isValidUser=false;
  //  userGroups.forEach((u:any)=>{   
  //      if (u.Role == "CrossBorder_ContentManagement_Business_Administrator" || u.Role == "ITS_Administrator" || u.Role == "NewDeal Administrators" || u.Role == "MyDeal Administrators" || u.Role == "Cross Border Administrators" || u.Role == "DealWBS Administrators" || u.Role == "Termination Administrators"){
  //      isValidUser=true;
  //    }
  //    });
  //  return isValidUser;
  //}

  getInnerHTMLValue(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(localStorage.getItem("imagePath") as string);
  }
 
  getPeoplePicker(userEmail:any,userName:any){
    this.peoplepickerservice.getPeoplePickerUsers(userEmail).subscribe({
      next: (result: any) => {
        if (result != undefined && result != null && result != "") {
          result.value.forEach((e: any) => {
            if (e.profilepicture.substring(0, 4) == this.noImageFile) {
              // e.profilepicture="../../../assets/img/Shadow-image.png";
              e.profilepicture = this.base64;
            }
            else {
              e.profilepicture = "data:image/jpg;base64," + e.profilepicture;
            }
          });
          result.value.forEach((element: any) => {
            if (userEmail == element.mail) {
              this.isAuthorized = true;
              if(element.givenName!= null && element.givenName!=undefined 
                && element.surname!=null && element.surname!=undefined)
                {
                  this.user = element.givenName + " " + element.surname;
                  localStorage.setItem("AuditorFullName",element.givenName + ", " + element.surname);
                  localStorage.setItem("NewDealAuditorName",element.surname + ", " + element.givenName);
                }
                else if (element.givenName!=null && element.givenName !=undefined)
                {
                  this.user = element.givenName;
                  localStorage.setItem("AuditorFullName",element.givenName);
                  localStorage.setItem("NewDealAuditorName",element.givenName);
                }
                else 
                {
                  this.user = element.surname;
                  localStorage.setItem("AuditorFullName",element.surname);
                  localStorage.setItem("NewDealAuditorName",element.surname);
                }

                //Added for LastName,FirstName Property
                 if(element.givenName!= null && element.givenName!=undefined 
                  && element.surname!=null && element.surname!=undefined)
                  {
                    //this.user = element.givenName + " " + element.surname;
                    localStorage.setItem("AuditorName",element.surname + ", " + element.givenName);
                  }
              this.imagePath = element.profilepicture;
            }
          });
        }
      },
      error: (err: any) => {
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        console.log("Error" + err)
      }

    });
  
  }
  getUserProfile(username: any) {
    this.tokenService.getUserProfile(username).subscribe((response: any) => {

      if (response != null && response.value.length > 0) {
        if(!response.value[0].profilepicture.includes('/'))
        {
          this.imagePath=this.base64;
          localStorage.setItem('imagePath', this.base64);
        }
        else
        {
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          + response.value[0].profilepicture);
          localStorage.setItem('imagePath', 'data:image/jpg;base64,'+response.value[0].profilepicture);
        }

        localStorage.setItem('emailId', response.value[0].mail);
        
        //console.log("Profile  Pic......................"+response.value[0].profilepicture);
      }
      else {

      }
    })
  }
  showCookieConsent(){
    const el = document.getElementById('onetrust-consent-sdk') as HTMLInputElement;
   if(el!=null){ el.style.display = 'block';}
           
 }
 loadScript() {
// Load Cookie Consent related scripts
  let scriptExists = this._document.querySelector(`script[src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"]`);

  if (!scriptExists) {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`;
    script.charset = `UTF-8`;
    script.setAttribute("data-domain-script", environment.DataDomainScript);
    this._renderer2.appendChild(this._document.body, script);
  }

  let optanonScriptExists = this._document.querySelector(`script[innerHTML="function OptanonWrapper() { }"]`);

  if (!optanonScriptExists) {
    let optanonScript = this._renderer2.createElement('script');
    optanonScript.type = `text/javascript`;
    optanonScript.innerHTML = `function OptanonWrapper() { }`;
    this._renderer2.appendChild(this._document.body, optanonScript);
  }


}


}
