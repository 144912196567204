import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef,HostListener, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomFormControl } from 'src/shared/custom/custom-form-control';
import { MainService } from 'src/shared/services/main.service';
import { CurrentUser } from 'src/shared/models/current-user-data';
import { ActivatedRoute } from '@angular/router';
import { DealWbsSetupSharepointMapper } from '../shared/utils/deal-wbs-setup-sharepoint-mapper';
import { ClientInformationComponent } from '../client-information/client-information.component';
import { AppSettings } from 'src/shared/app-settings';
import { DealWbsSetupService } from '../shared/services/deal-wbs-setup.service';
import { Observable, Subscription, catchError, forkJoin, from, interval, map, observable, of, switchMap, tap } from 'rxjs';
import { SharepointValidator } from 'src/shared/utils/sharepoint-mapper';
import { FormStatusValue } from 'src/shared/models/form-status-value';
import { ModalComponent } from 'src/shared/components/modal/modal.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { SharepointObjectMyDeal } from '../models/sharepoint-object-my-deal';
import { SharepointObjectDealWbsSetup } from '../models/sharepoint-object-deal-wbs-setup';
import { DateFormatterService } from 'src/shared/utils/utils-date';
import { FormListFields } from '../models/form-list-fields';
import { EngagementInformationComponent } from '../engagement-information/engagement-information.component';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { InternationalWorkIndicatorsComponent } from '../international-work-indicators/international-work-indicators.component';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { EngagementTeamComponent } from '../engagement-team/engagement-team.component';
import { PeoplePickerSelectedRow } from 'src/shared/components/peoplepicker-dynamic-table/people-picker-selected-row.interface';
import { DynamicTableAction } from 'src/shared/models/dynamic-table-action';
import { DealTeamObject } from '../../models/dealteam-object';
import { WBSElementClassificationComponent } from '../wbs-element-classification/wbs-element-classification.component';
import { MasterEngagementDetailsComponent } from '../master-engagement-details/master-engagement-details.component';
import { InvoiceDetailsAndAddressesComponent } from '../invoice-details-and-addresses/invoice-details-and-addresses.component';
import { BillingScheduleComponent } from '../billing-schedule/billing-schedule.component';
import { ArchiveCmrComponent } from '../archive-cmr/archive-cmr.component';
import { PeoplePickerUser } from 'src/shared/models/people-picker-user';
import { PeoplepickerService } from 'src/shared/services/peoplepicker.service';
import { PricingDetailsComponent } from '../pricing-details/pricing-details.component';
import { ParticipationComponent } from '../participation/participation.component';
import { AppContext } from '../models/app-context';
import { AlertMessageComponent } from 'src/shared/components/alert-message/alert-message.component';
import { DefaultValues } from '../shared/utils/default-values';
import { DealWbsSetupServiceHelper } from '../shared/services/deal-wbs-setup-service-helper';
import { SecurityService } from 'src/shared/services/security.service';
import { AdminAction } from '../models/admin-action';
import { AdminReviewComponent } from '../admin-review/admin-review.component';
//import { ActiveDealWbsComponent } from './active-deal-wbs/active-deal-wbs.component';
import { RolesValidatorService } from '../shared/utils/deal-wbs-setup-roles-validator-service';
import { DealWbsSetupSecurityMatrix } from '../shared/utils/deal-wbs-setup-security-matrix';
import { FormActionGroupValue } from '../models/form-action-group-value';
import { ReactivateFormComponent } from 'src/shared/components/reactivate-form/reactivate-form.component';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { DocumentsComponent } from 'src/shared/components/documents/documents.component';
import { DocumentTypeItem } from 'src/shared/models/document-type-item';
import { FilesService } from 'src/shared/services/files.service';
import { AttachmentItem } from 'src/shared/models/attachment-item';
import { AdditionalDocumentTypeItemObject } from '../models/additional-document-type-object';
import { timer } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DealwbsService } from 'src/app/services/dealwbs.service';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { DealWbsNotifyPopupComponent } from 'src/shared/components/alerts/deal-wbs-notify-popup/deal-wbs-notify-popup.component';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { EngagementHealthObjectServiceService } from 'src/app/services/engagement-health-object-service.service';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/groups/cfb660ea-e955-421f-bb47-d7d091376443/members?$select=displayName,jobTitle,userPrincipalName&$top=200';
const GRAPH_ENDPOINT_firstpart = 'https://graph.microsoft.com/v1.0/groups/';
const GRAPH_ENDPOINT_secondpart='/members?$select=displayName,jobTitle,userPrincipalName&$top=200';
import { AlertService } from '../../_alert';
import { DateService } from 'src/app/services/date.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';

const responseCodes = {
  ok: 'ok',
  conflicts: 'conflicts',
  tryAgain: 'tryAgain',
  fail: 'fail',
  formUpdated: 'formUpdated',
  incomingUpdates: 'incomingUpdates',
  noDCFMember: 'no-dcf-member',
  noValidForm: 'no-valid-form',
  returnForReworkReasonEmpty: 'return-for-rework-reason-empty',
};

const alertTypes = {
  success: 'success',
  warning: 'warning',
  primary: 'primary',
  danger: 'danger'
};

const alertOptions = {
  autoClose: true,
  keepAfterRouteChange: false
}; 

const formGroups = {
  clientInformationFormGroup: 'clientInformationFormGroup',
  myDealFormGroup: 'myDealFormGroup',
  engagementInformationFormGroup: 'engagementInformationFormGroup',
  internationalWorkIndicatorsFormGroup: 'internationalWorkIndicatorsFormGroup',
  engagementTeamFormGroup: 'engagementTeamFormGroup',
  wbsElementClassificationFormGroup : 'wbsElementClassificationFormGroup',
  invoiceDetailsAndAddressesFormGroup: 'invoiceDetailsAndAddressesFormGroup',
  //masterEngagementDetailsFormGroup: 'masterEngagementDetailsFormGroup',
  // billingScheduleFormGroup: 'billingScheduleFormGroup',
  archiveCmrFormGroup: 'archiveCmrFormGroup',
  pricingDetailsFormGroup: 'pricingDetailsFormGroup',
  participationFormGroup: 'participationFormGroup',
  documentsFormGroup: 'documentsFormGroup',
  activeDealWBSFormGroup:'activeDealWBSFormGroup'
};

const modalTypes  = {
  submit: 'submit',
  mandatoryFields: 'mandatory-fields',
  retrieve: 'retrieve',
  discontinue: 'discontinue',
  discontinueReason: 'discontinue-reason',
  discontinueConfirmed: 'discontinue-confirmed',
  returnForRework: 'return-for-rework',
  returnForReworkReason: 'return-for-rework-reason',
  returnForReworConfirmed: 'return-for-rework-confirmed',
  approval: 'approval',
  access: 'access',
  noDCFMember: 'no-dcf-member',
  userLeftDcf: 'user-left-dcf-member',
  reactivateForm: 'reactivate-form',
  reactivateConfirmed: 'reactivate-confirmed',
  filesUploading: 'files-uploading',
  error: 'error',
  save: 'save',
  noValidForm: 'no-valid-form',
  formUpdated: 'form-updated',
  retryWorkflow: 'retry-workflow',
  activeWBSNotification:'activeWBSNotification',
  lcspmismatch:'lcsp-mismatch'
};

@Component({
  selector: 'app-deal-wbs-setup',
  templateUrl: './deal-wbs-setup.component.html',
  styleUrls: ['./deal-wbs-setup.component.scss']
})
export class DealWbsSetupComponent implements OnInit, OnDestroy {
  appFormGroup: any;
  currentUser: CurrentUser;
  formId: number;
  myDealFormId: any;
  isAdminReviewEditable:any=false;
  saveInProgress: boolean;
  formStatus: string;
  engagementID: any;
  formActionGroup = FormActionGroupValue.dealTeam;
  sharePointMapper: DealWbsSetupSharepointMapper;
  modalType: string;
  subscription: Subscription;
  formSubmitted: boolean;
  initialFormValidators: Array<SharepointValidator>;
  discontinueReason = '';
  returnForReworkReason = '';
  saveButtonVisible: boolean;
  documentTypesFiltered:Array<DocumentTypeItem> = [];
  documentTypesAll:Array<DocumentTypeItem>=[];
  appContext: AppContext;
  adGroupMember:any;
  initialDealWbsSetupObject: SharepointObjectDealWbsSetup;
  initialMyDealObject: SharepointObjectMyDeal;
  initialDealTeamTable: Array<PeoplepickerDynamicTableItem>;
  initialAttachments: Array<DocumentTypeItem> = [];
  initialAdditionalDocumentTypes: Array<DocumentTypeItem> = [];
  conflictInputsOnSave: boolean;
  overwriteInputs: boolean;
  differences: any;
  alertMessageType = alertTypes.success;
  executingSave = false;
  executingWorkflowChangeAction = false;
  discontinuedFormActionGroup: string;
  discontinuedFormStatus: string;
  dealTeamList: Array<PeoplepickerDynamicTableItem> = [];
  adminAssignedId: any;
  s24GroupUsers:any;
  firstColumnItemList:any;
  resAsterisk:any;
  secondColumnItemList:any;
  thirdColumnItemList:any;
  showDealWBSpopup:boolean=true;
  currentUserName:any;
  showNewDealpopup:boolean=false;
  showCCLListpopup:boolean=false;
  showN:boolean=true;
  isAutoSaveEnabled:boolean=false;
  formType:any = AppSettings.FormTypes.DealWbsSetupForm;
  initialMyDealList:any;
    initialDealWbsList: any;
    DiscontinuedOn: any;
    DiscontinuedBy: any;
  dealWBSRangeKey:any="";
  myDealRangeKey:any="";
  currentSharepointObjectDealWbs:any;
  currentSharepointObjectMyDeal:any;
  series24DisplayName:any="";
  isADGroupUser:boolean|null=false;
  @ViewChild(ClientInformationComponent, {static: true})
  clientInformationComponent: ClientInformationComponent;

  @ViewChild(EngagementInformationComponent, {static: true})
  engagementInformationComponent: EngagementInformationComponent;

  @ViewChild(ArchiveCmrComponent, {static: true})
  archiveCmrInformationComponent: ArchiveCmrComponent;

  @ViewChild(SidebarComponent, {static: false})
  sidebarComponent: SidebarComponent;

  @ViewChild(InternationalWorkIndicatorsComponent, {static: true})
  internationalWorkIndicatorsComponent: InternationalWorkIndicatorsComponent;

  @ViewChild(EngagementTeamComponent, {static: false})
  engagementTeamComponent: EngagementTeamComponent;

  @ViewChild(WBSElementClassificationComponent, {static: false})
  wbsElementClassificationComponent: WBSElementClassificationComponent;

  @ViewChild(MasterEngagementDetailsComponent, {static: true})
  masterEngagementDetailsComponent: MasterEngagementDetailsComponent;

  @ViewChild(InvoiceDetailsAndAddressesComponent, {static: true})
  invoiceDetailsAndAddressesComponent: InvoiceDetailsAndAddressesComponent;

  @ViewChild(BillingScheduleComponent, {static: true})
  billingScheduleComponent: BillingScheduleComponent;

  @ViewChild(PricingDetailsComponent, {static: true})
  pricingDetailsComponent: PricingDetailsComponent;

  @ViewChild(ParticipationComponent, {static: true})
  participationComponent: ParticipationComponent;

  @ViewChild(AdminReviewComponent, {static: false})
  adminReviewComponent: AdminReviewComponent;

   //@ViewChild(ActiveDealWbsComponent,{static: false})
   //activeDealWBSComponent:ActiveDealWbsComponent;

  @ViewChild(ReactivateFormComponent, {static: false})
  reactivateFormComponent: ReactivateFormComponent;

  @ViewChild(DocumentsComponent, {static:false})
  documentsComponent: DocumentsComponent;

  @ViewChild('Modal', {static: true})
  modal: ModalComponent;

  @ViewChild('Alert', {static: true})
  alert: AlertMessageComponent;

  @ViewChild(DealWbsNotifyPopupComponent, { static: true }) notifypopupComponent: DealWbsNotifyPopupComponent;
  //@Output()
  //isNotificationSent: EventEmitter<boolean> = new EventEmitter<boolean>();
  isNotificationSent:boolean;
  buttonsVisible: boolean;

  constructor(private mainService: MainService,
              private httpClient: HttpClient,
              private authService:MsalService,
              private peoplepickerService: PeoplepickerService,
              private route: ActivatedRoute,
              private dealWbsSetupService: DealWbsSetupService,
              private defaultValues: DefaultValues,
              private dateFormatterService: DateFormatterService,
              private changeDetector: ChangeDetectorRef,
              private spinnerService: SpinnerService,
              private securityService: SecurityService,
              private rolesValidator: RolesValidatorService,
              private dealWbsSetupServiceHelper: DealWbsSetupServiceHelper,
              private tooltipHelperService: TooltipHelperService,
              private fileService:  FilesService,
              private dealWbsService: DealwbsService,
            private dropdownService: DropdownService,
          private commonHelperService: CommonHelperService,
          private alertService:AlertService,
          private dateService: DateService,
          private EHOService:EngagementHealthObjectServiceService,
        public globalHelperService:GlobalHelperService) { }

  get formGroups() {
    return formGroups;
  }

  ngOnInit() {     
    this.UIActions();
    this.spinnerService.showAutoSaveSpinner();
    localStorage.setItem("CurrentAccessPage", "DealWBSForm");
    this.globalHelperService.currentAccessPage="DealWBSForm";
    this._setFormId();
    this._createForm();
    this._createSharepointMapper();    
    this._populateTooltips();    
    this.currentUserName = localStorage.getItem("NewDealAuditorName")?.toString();     
    this.commonHelperService.getUserGroupMembership().subscribe((res:any) => {
      this.isADGroupUser = res?.isADGroupUser;
      this.myDealFormId =  res?.myDealID;
      if(res!=null && res!=undefined)this.getAdGroup();      
    });   
  }

  ngOnDestroy() {
    this._stopAutosave();
  }
  getAdGroup() {  
    if(this.formId){
      this.commonHelperService.getUserData().subscribe(userData => {
        if(userData.length!=0)
        {
        this.currentUser = userData;
        this.mainService.currentUser=this.currentUser;
        if (this.isADGroupUser) 
          {            
          this._setFormId();
            localStorage.setItem('formId', this.formId.toString()); 
            this.globalHelperService.formID = this.formId.toString();
          this.LoadDropdownLists();
          this.initializeSecurity();
        } 
        else 
        {
          this.spinnerService.hideAutoSaveSpinner();
          this._openModal(modalTypes.noDCFMember);
          this.spinnerService.hideAutoSaveSpinner();
        }   
      }    
      });        
      }
      else{
        this._openModal(modalTypes.noValidForm);
        this.spinnerService.hideAutoSaveSpinner();
      }
  }
  UIActions(){
    document.getElementById("dashboardLink")?.scrollIntoView({behavior:'smooth'})
    document.body.style.overflow ="hidden";
   }
  Series24Name(event:any){
    this.series24DisplayName=event;
  }
  initializeSecurity() {
    // Promise.all([
      // this.mainService.retrieveForm(AppSettings.dealWbsSetupFormList, this.formId)])
    //   .then(([responseFormItem]) => { 
      forkJoin([
        this.dealWbsService.GetDealWbsDetails(this.formId),
        this.rolesValidator.validateFormRoles_DealWbs(this.currentUser.groups,this.currentUser.email,this.formId,this.myDealFormId),
        this.dealWbsService.getAsteriskList()
      ]).subscribe(([responseFormItem,roleList,responseAsterisk])=>{   
      //this.dealWbsService.GetDealWbsDetails(this.formId).subscribe((responseFormItem:any)=>{
        if(responseFormItem.length==0){
          this.spinnerService.hideAutoSaveSpinner();
          this._openModal(modalTypes.noValidForm);
          return;
        }
        this.resAsterisk=responseAsterisk;
        this.initialDealWbsList=responseFormItem = responseFormItem[0];
        if(responseFormItem!=undefined){
          this.myDealFormId = responseFormItem.MyDealID
          this.mainService.formId = this.formId;
          this.mainService.myDealFormId  = this.myDealFormId;
          // Promise.all([
            // this.rolesValidator.validateFormRoles(this.currentUser.groups, this.currentUser.id, this.formId, this.myDealFormId),
          //    this.mainService.getFormStatus_DealWBS(AppSettings.dealWbsSetupFormList, this.formId)])
          //   .then(([roleList, status]) => {
         // this.rolesValidator.validateFormRoles_DealWbs(this.currentUser.groups,this.currentUser.email,this.formId,this.myDealFormId).subscribe((roleList)=>{
            if (responseFormItem.FormStatus!=null && responseFormItem.FormStatus!="") {
              this.securityService.getPermissions("",responseFormItem.FormStatus, responseFormItem.FormActionGroup, roleList, DealWbsSetupSecurityMatrix);
              // this.securityService.getPermissions(status.formStatus, status.formActionGroup, roleList, DealWbsSetupSecurityMatrix);
              this.securityService.securityMatrixEvent.subscribe(response => {
                this.initializeForm(responseFormItem);
              });
            } else {
              this.dealWbsSetupService.updateFormOnRetryWorkflow(responseFormItem.FormStatus, this.formId,this.initialDealWbsList.Created)
              .subscribe((response) => {
                if (response) {
                  this._openModal(modalTypes.retryWorkflow);
                }
                this.spinnerService.hideAutoSaveSpinner();
              });
            }
         // })
        }
        // });
    });
  }

  hasPermission(action: string): boolean {
    return this.securityService.hasPermission(action);
 }

  initializeForm(responseFormItem:any) {
    if (this.hasPermission('Access')) {
      // this._setFolderUrl(); // setup document folder url
      this._initialSetValidators();// set-up required fields for each formcontrol by iteraing form groups
      this._createSharepointMapper(); //create mapping for sharepoint column names and formcontrol names
      this._setSharepointMapper();
      this.retrieveForm(responseFormItem);
    } else {
      this._openModal(modalTypes.access);
      this.spinnerService.hideAutoSaveSpinner();
    }
  }
  // @HostListener('contextmenu', ['$event'])
  // onRightClick(event:any) {
  // event.preventDefault();
  // }
  onKeydownMain(event:any):any {
    if (event.keyCode == 123) { // Prevent F12
      return false;
  } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) { // Prevent Ctrl+Shift+I
      return false;
  }
  else if (event.ctrlKey && event.shiftKey && event.keyCode == 74) { // Prevent Ctrl+Shift+J
    return false;
}
 }
  scrollToTop() {
    //document.querySelector('#form-body').scrollIntoView();
   let data= document.getElementById('form-body') as HTMLInputElement;
    data.scrollIntoView();
  }

  scrollToSection() {
    const url = window.location.href;
    const section = url.split('dealWbsForm')[1].split('?')[0];
    if (section && section !== 'main') {
      this.sidebarComponent.onclick('#' + section);
    }
  }

  retrieveForm(responseFormItem:any) {
    this.UIActions();
        // const folderUrl = this._getFolderUrl(); document
        if(responseFormItem!=undefined) {responseFormItem=this.appendLCSPValues(responseFormItem);}
        this.myDealFormId = responseFormItem.MyDealID;
        if (this.myDealFormId) {
          // Promise.all([
          //   this.dealWbsSetupService.retrieveMyDeal(this.myDealFormId),
          //   this.dealWbsSetupService.getDealTeamUsers(AppSettings.dealTeamTableList, this.myDealFormId),
          //   this.dealWbsSetupService.getDCFGroupMembers(),
          //   this.dealWbsSetupService.getSeries24GroupMembers(),
          //   // this.mainService.getDocumentTypes(), document
          //   // this.fileService.getFiles(folderUrl), document
          //   this._populateTooltips()
          // ])
            // .then(([responseMyDeal, responseDealTeamTable, dcfUsers,responsable24GroupMembers,responseDocumentTypes,responseAttachments]) => { document           
            forkJoin([
             // this.dealWbsService.GetMyDealDetails(this.myDealFormId),
             // this.dealWbsSetupService.getDealTeamUsers_DealWbs(this.myDealFormId),
             // this.dealWbsService.GetGroupData(AppSettings.series24Group),
              this.mainService.getDocumentTypesObservables(AppSettings.FormTypes.DealWbsSetupForm),
              this.fileService.getFiles(this.formId,AppSettings.FormTypes.DealWbsSetupForm),
              this.EHOService.GetEHOObjects(null,this.myDealFormId),
              this.mainService.getAppConfigurationValue(AppSettings.ContentManagementFormTypes.DealWbs)
            ]).subscribe({
              next:([responseDocumentTypes,responseAttachments,responseEHO,applicationContext]) => {
               let responseMyDeal=this.mainService.myDealList;
               let responseDealTeamTable=this.mainService.myDealTeamTableList;
                responseMyDeal = responseMyDeal[0];
                this.initialMyDealList = responseMyDeal;
                this.series24DisplayName=responseMyDeal.Series24DisplayNames==undefined?"":responseMyDeal.Series24DisplayNames;
             // this.s24GroupUsers = responsable24GroupMembers;
             this.s24GroupUsers = this.mainService.myDealS24List;
              this.adminAssignedId = responseMyDeal.AdminAssigned;
              this.DiscontinuedBy = responseFormItem.DiscontinuedBy;
              this.DiscontinuedOn = responseFormItem.DiscontinuedOn;
              this.engagementID = responseEHO[0].ID;
              this.appContext = applicationContext;
              // const dcfUsers = this.adGroupMember;
              this._initializeFormStatusVariables(responseFormItem);
              //this.activeDealWBSComponent.setActiveWBSNotification(responseFormItem.IsActiveWBSNotificationSent);
              if(responseFormItem.IsActiveWBSNotificationSent == 'Ready')
              {
                //this.isNotificationSent.emit(false);
                this.isNotificationSent = false;
              }
              else if(responseFormItem.IsActiveWBSNotificationSent == 'Sent'){
                this.isNotificationSent = true;
              }
              //documentTypesAll stores all Document Types
              // documentTypesFiltered excludes IFA Interfirm Agreement document type
              this.documentTypesAll=responseDocumentTypes;
              this.documentTypesFiltered=responseDocumentTypes.filter((documentType:any)=>{return documentType.documentTypeTitle!='IFA (Interfirm agreement)' });
              //this.documentTypesFiltered=responseDocumentTypes.filter((documentType:any)=>{return documentType.documentTypeId===1 }); document

              if(responseFormItem.IsThisCrossBorder=='YES')
                this._populateDocumentTypes(this.documentTypesAll);
              else
                this._populateDocumentTypes(this.documentTypesFiltered);
              // this._populateSections(responseFormItem, responseMyDeal, responseDealTeamTable,responseAttachments); document
              this._populateSections(responseFormItem, responseFormItem, responseMyDeal, responseDealTeamTable,responseAttachments);
              this._initializeFieldValues(responseFormItem, responseMyDeal);
              this._storeSharepointObjects(responseDealTeamTable);
              this._handlerAutoSave();
              this._handlerFormStatus();
              this._handlerAdminReviewSection(responseFormItem.AdminApprovalDate, responseFormItem.AdminApprovalName);
              this._handlerReactivateFormSection(responseFormItem.DiscontinuedOn, responseFormItem.DiscontinuedBy);
              // Promise.all([
              //   this._createPeoplePickerUserArrays(),
              //    this._areUsersBelongToDCFGroup(responseMyDeal.Series24Id, responseDealTeamTable,
              //     dcfUsers, responsable24GroupMembers)
              // ]).then(([]) => {
              //   this.spinnerService.hide();
              // });
               this._createPeoplePickerUserArrays().subscribe(()=>{
                 //executed
               });
              this._openModal(modalTypes.retrieve);
          }
        });
        } else {
          this._openModal(modalTypes.noValidForm);
          this.spinnerService.hideAutoSaveSpinner();
        }
        if(this.checkVisibilityActiveDealWBSSetion()){
          this.sidebarComponent.showActiveDealWBSSection();
        }
  }
  private _getFolderUrl() {
    return this.documentsComponent.getFolderUrl();
  }

  private _setFolderUrl() {
    this.documentsComponent.setFormId(this.formId);
    this.documentsComponent.setFormUrl();
  }

  private _populateDocumentTypes(responseDocumentTypes: Array<DocumentTypeItem>) {
    this.documentsComponent.populateDocumentTypes(responseDocumentTypes, this.formId);
  }
  //Below function to Call Save from SideBar
  SaveDealWbsFormDetails(event: any) {
    if (event) {
      this.onSaveForm();
    }
  }

  onSaveForm() {
    this.executingSave = true;
    this.spinnerService.showAutoSaveSpinner();
     this.mainService.getFormStatus_DealWBS(AppSettings.dealWbsSetupFormList, this.formId).subscribe((status:any) => {
      const formStatusChanged = this._formStatusChanged(status.formStatus, status.formActionGroup);
      if (!formStatusChanged) {
        this.saveForm().subscribe((code:any) => {
          if (code === responseCodes.tryAgain) {
            this._subscribeSave();
          } else if (code !== responseCodes.fail) {
            this._openModal(modalTypes.save);
          }
        });
      } else {
        this._openModal(modalTypes.formUpdated);
        this._stopAutosave();
      }
      this.spinnerService.hideAutoSaveSpinner();
     },(err:any)=>{
      this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err);
           if (err.status == 403 && err.error.stringKey==AppSettings.tokenExpiredText) 
           { 
             this._openSessionExpiredModal();
           }
     }
     );
  }

  saveForm(initiateWorkflow:boolean = false): Observable<any> {
    return from(this._retrieveLastChangesBeforeSave()).pipe(
      switchMap((changes: any) => {
          const canExecuteResponse = this._canExecuteSave(changes);
        const responseCode:any = canExecuteResponse[0];
        const canExecute = canExecuteResponse[1];
        this.dealWBSRangeKey=changes.responseDealWbs[0].Created;
        this.myDealRangeKey=changes.responseMyDeal[0].Created;
        if (canExecute) {
          this.saveInProgress = true;
          this.conflictInputsOnSave = false;
          this.overwriteInputs = false;
          this.differences = this._findDifferencesBeforeSaveData();
          return from(this._updateAppFormGroup(changes)).pipe(
            switchMap(() => from(this._updateFormItem(this.differences,initiateWorkflow)).pipe(
              map(responseFormItem => {
                if (responseFormItem) {
                  this._storeSharepointObjects(changes.responseDealTeam,changes.responseDealWbs);
                  const responseCodeOk:any = this._getResponseCodeOk();
                  this.saveInProgress = false;
                  return responseCodeOk;            
                } else {
                  return responseCodes.fail; 
                }
              })
            ))
          );
         
        } else {
        return of(responseCode);
        }
      }),
      catchError((err: any) => {
        console.log('Error updating form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
}
  onSubmit() {
    this._setValidators(this.initialFormValidators);
    this.formSubmitted = true;
    this.executingWorkflowChangeAction = true;
    this.spinnerService.showAutoSaveSpinner();
    forkJoin([
      this._validateDCFUsersBeforeUserAction(),
      this.mainService.getFormStatus_DealWBS(AppSettings.dealWbsSetupFormList,this.formId)])
      .subscribe(([areValid, status]) => {
        const canExecuteResponse = this._canExecuteActionToUpdateWorkflow(areValid, status, true);
        const responseCode = canExecuteResponse[0];
        const canExecute = canExecuteResponse[1];
        if (canExecute) {
          this._stopAutosave();
           this.saveForm().subscribe((code:any) => {
            if (code === responseCodes.tryAgain) {
              setTimeout(() => this._subscribeSubmit().subscribe(() => this.spinnerService.hideAutoSaveSpinner()), AppSettings.forceItemReleaseInterval);
            } else if (code !== responseCodes.fail) {
              this._submitForm().subscribe(() => this.spinnerService.hideAutoSaveSpinner());
            }
          });
        } else {
          this.executingWorkflowChangeAction = false;
          this._showModalByResponseCode(responseCode);
          this.spinnerService.hideAutoSaveSpinner();
        }
      })
  }
  onDiscontinue() {
    this.executingWorkflowChangeAction = true;
    this._setValidators([]);
    this.formSubmitted = true;
    if (this.appFormGroup.valid || this.appFormGroup.status === 'DISABLED') {
      this._openModal(modalTypes.discontinue);
    } else {
      this.executingWorkflowChangeAction = false;
      this._openModal(modalTypes.mandatoryFields);
    }
  }
isValidateFormInAdminReview(){
  if(this.formActionGroup!="AdminReview"){
    this.isAdminReviewEditable=true;
  }
 
}
  confirmDiscontinueReason() {
    if (this.discontinueReason.trim()) {
      this._stopAutosave();
      this.executingSave = false;
        this.spinnerService.showAutoSaveSpinner();
        //Initiate Workflow
      this.dealWbsSetupService.updateFormOnDiscontinue(this.formStatus,this.formActionGroup, this.formId, this.discontinueReason,this.initialDealWbsList.Created,true).subscribe((response) => {
        if (response) {
          this.executingSave = false;
          this._openModal(modalTypes.discontinueConfirmed);
        }
        this.spinnerService.hideAutoSaveSpinner();
      });
    } else {
      this.closeDiscontinue();
      this._openModal(modalTypes.discontinueReason);
    }
  }

  closeDiscontinue() {    
    this.discontinueReason = '';
    this.executingWorkflowChangeAction = false;
  }

  cancelDiscontinue() {    
    this.formSubmitted = false;
    this._setValidators(this.initialFormValidators);
  }

  onReturnForRework() {
    this.executingWorkflowChangeAction = true;
    this.formSubmitted = true;
    this._openModal(modalTypes.returnForRework);
  }

  closeReturnForReworkReason() {    
    this.returnForReworkReason = '';
    this.executingWorkflowChangeAction = false;
  }

  cancelReturnForRework() {
    this.closeReturnForReworkReason();
    this.formSubmitted = false;
    this._setValidators(this.initialFormValidators);
  }

  checkReasonBeforeReturn() {
    this.spinnerService.showAutoSaveSpinner();
     this.mainService.getFormStatus_DealWBS(AppSettings.dealWbsSetupFormList, this.formId).subscribe(status => {
      const reasonFilled = this.returnForReworkReason.trim() !== '';
      const canExecuteResponse = this._canExecuteActionToUpdateWorkflow(true, status, reasonFilled);
      const responseCode = canExecuteResponse[0];
      const canExecute = canExecuteResponse[1];
      if (canExecute) {
        this._stopAutosave();
        this.saveForm().subscribe((code:any) => {
          if (code === responseCodes.tryAgain) {
            setTimeout(() => this._subscribeReturn().subscribe(() => this.spinnerService.hideAutoSaveSpinner()), AppSettings.forceItemReleaseInterval);
          } else if (code !== responseCodes.fail) {
            this._returnFormForRework().subscribe(() => this.spinnerService.hideAutoSaveSpinner());
          }
        });
      } else {
        this.executingWorkflowChangeAction = false;
        this._showModalByResponseCode(responseCode);
        this.spinnerService.hideAutoSaveSpinner();
      }
     });
  }

  dealTeamRowHandler(dealTeamRow: PeoplePickerSelectedRow) {
    this._checkDealTeamTypeEvent(dealTeamRow);
  }
  crossBorderHandler(isCrossBorder:boolean){
    if(isCrossBorder)
      this._populateDocumentTypes(this.documentTypesAll);
    else
      this._populateDocumentTypes(this.documentTypesFiltered);
  }
  adminActionHandler(adminAction: string) {
    switch (adminAction) {
      case AdminAction.Approve:
        this._adminApprovalAction();
        break;
      case AdminAction.Return:
        this._adminReturnAction();
        break;
      case AdminAction.Discontinue:
        this.onDiscontinue();
        break;
      case AdminAction.Save:
        this.onSaveForm();
        break;
    }
  }

  adminActiveWBSHandler(adminActiveWBSAction:boolean){
    this.appFormGroup.get(formGroups.activeDealWBSFormGroup).get('isActiveWBSNotificationSent').setValue('Ready');
    this.spinnerService.showAutoSaveSpinner();
    this.saveForm(true).subscribe((code:any) => {
      if (code === responseCodes.ok) {
        this.spinnerService.hideAutoSaveSpinner();
        this._openModal(modalTypes.activeWBSNotification);
      }
      else{
        this.spinnerService.hideAutoSaveSpinner();
        this._openModal(modalTypes.error);
      }
    });
  }
  checkVisibilityAdminReviewSetion() {
    return this._isAdminReviewVisible() && !this._isFormDiscontinuedByDealTeam(this.discontinuedFormActionGroup);
  }

  checkVisibilityActiveDealWBSSetion() {
    return this._isActiveDealWBSVisible() && this._isActiveDealWBSEditable() &&!this._isFormDiscontinuedByDealTeam(this.discontinuedFormActionGroup);
  }

  isPendingForAdminReview(): boolean {
    return FormStatusValue.pending === this.formStatus && FormActionGroupValue.adminReview === this.formActionGroup;
  }

  reactivateFormHandler() {
    //  this.mainService.getFormStatus_DealWBS(AppSettings.dealWbsSetupFormList, this.formId).then(status => {
    //   if (this._formStatusChanged(status.formStatus, status.formActionGroup)) {
    //     this._openModal(modalTypes.formUpdated);
    //   } else {
    //     this._openModal(modalTypes.reactivateForm);
    //   }
    // });
    //   this.spinnerService.hide();
    this._openModal(modalTypes.reactivateForm);
  }

  confirmReactivateForm() {
    this.executingSave = false;
    this.executingWorkflowChangeAction = true;
    this.spinnerService.showAutoSaveSpinner();
    this.mainService.reactivateForm_DealWbs(this.formId,this.initialDealWbsList.Created).subscribe(response => {
    // this.mainService.reactivateForm().then(response => {
      if (response) {
        this._openModal(modalTypes.reactivateConfirmed);
        this.spinnerService.hideAutoSaveSpinner();
      }
    });
  }

  private _submitForm(): Observable<any> {
    this.executingSave = false;
    //Initiate Workflow
    return from( this.dealWbsSetupService.updateFormOnSubmit(this.formId,this.adminAssignedId,this.initialDealWbsList.Created,true)).pipe(
      map((results: any) => {
        if (results) {
          this._openModal(modalTypes.submit);
        }
        return results;
      }),
      catchError((err: any) => {
        console.log('Error Submit form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  }

  private _returnFormForRework(): Observable<any> {
      this.executingSave = false;
      //Initiate Workflow
    return from( this.dealWbsSetupService.updateFormOnReturnForRework(this.returnForReworkReason,this.formId,this.initialDealWbsList.Created,true)).pipe(
      map((results: any) => {
        if (results) {
          this._openModal(modalTypes.returnForReworConfirmed);
          return true;
        } else {
          return false;
        }
      }),
      catchError((err: any) => {
        console.log('Error RFR form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  }

  private _approveForm(): Observable<any> {
    this.executingSave = false;  
    //Initiate Workflow
    return from( this.dealWbsSetupService.updateFormOnAdminReviewApproval(this.formId,this.initialDealWbsList.Created,true)).pipe(
      map((results: any) => {
        if (results) {
          this._openModal(modalTypes.approval);
          return true;
        } else {
          return false;
        }
      }),
      catchError((err: any) => {
        console.log('Error Approve form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );
  }

  private _initializeFormStatusVariables(responseFormItem:any) {
    this.formStatus = responseFormItem.FormStatus;
    this.formActionGroup = responseFormItem.FormActionGroup;
    this.discontinuedFormActionGroup = responseFormItem.DiscontinuedFormActionGroup;
    this.discontinuedFormStatus = responseFormItem.DiscontinuedFormStatus;
}

  private _getResponseCodeOk(): string {
    let code = responseCodes.ok;
    switch (true) {
      case this.conflictInputsOnSave:
        code = responseCodes.conflicts;
        break;
      case this.overwriteInputs:
        code = responseCodes.incomingUpdates;
        break;
    }
    return code;
  }

  private _showModalByResponseCode(code:any) {
    switch (code) {
      case responseCodes.formUpdated:
        this.executingWorkflowChangeAction = true;
        this._openModal(modalTypes.formUpdated);
        this._stopAutosave();
        break;
      case responseCodes.noDCFMember:
        this._openModal(modalTypes.userLeftDcf);
        break;
      case responseCodes.noValidForm:
        this._openModal(modalTypes.mandatoryFields);
        break;
      case responseCodes.returnForReworkReasonEmpty:
        this.closeReturnForReworkReason();
        this._openModal(modalTypes.returnForReworkReason);
        break;
    }
  }

  private _canExecuteSave(changes:any) {
    let responseCode;
    let executeSave = true;
    switch (true) {
      case this.saveInProgress:
        responseCode = responseCodes.tryAgain;
        executeSave = false;
        break;
      case this._formStatusChanged(changes.responseDealWbs[0].FormStatus, changes.responseDealWbs[0].FormActionGroup):
        responseCode = responseCodes.formUpdated;
        executeSave = false;
        break;
    }
    return [responseCode, executeSave];
  }

  private _canExecuteActionToUpdateWorkflow(areValid:any, status:any, returnReason:any) {
    let responseCode;
    let executeAction = true;
    switch (false) {
      case areValid:
        responseCode = responseCodes.noDCFMember;
        executeAction = false;
        break;
      case !this._formStatusChanged(status.formStatus, status.formActionGroup):
        responseCode = responseCodes.formUpdated;
        executeAction = false;
        break;
      case this.appFormGroup.valid:
        responseCode = responseCodes.noValidForm;
        executeAction = false;
        break;
      case returnReason:
        responseCode = responseCodes.returnForReworkReasonEmpty;
        executeAction = false;
        break;
    }
    return [responseCode, executeAction];
  }

  private _formStatusChanged(formStatus:any, formActionGroup:any) {
    return (formStatus !== this.formStatus ||
            formActionGroup !== this.formActionGroup);
  }

  private _handlerSaveButton() {
    this.saveButtonVisible = false;
    switch (this.formActionGroup) {
      case FormActionGroupValue.adminReview:
        this.saveButtonVisible = true;
        break;
      case FormActionGroupValue.dealTeam:
        this.saveButtonVisible = true;
        break;
    }

  }

  private _checkDealTeamTypeEvent(dealTeamRow: PeoplePickerSelectedRow) {
    if (dealTeamRow.actionType === DynamicTableAction.Add) {
      this._storeDealTeamRow(dealTeamRow);
    } else {
      this._deleteDealTeamRow(dealTeamRow);
    }
  }

  private _deleteDealTeamRow(dealTeamRow: PeoplePickerSelectedRow) {
    const dealTeamAuditObject: object = {
      DealTeamMember: dealTeamRow.item.usersSelected[0].displayName,
      FormId:this.myDealFormId,
      CreatedBy:this.currentUserName,
      Action:dealTeamRow.actionType,
      FormName:AppSettings.dealWbsSetupFormList
      };
      forkJoin([this.mainService.deleteListItem_DealWbs(AppSettings.dealTeamTableList,this.myDealFormId.toString(), dealTeamRow.item.itemId.toString()),
    // this.mainService.deleteListItem(AppSettings.dealTeamTableList, dealTeamRow.item.itemId),
    this.mainService.createListItem_DealWbs(AppSettings.dealTeamAuditLog, dealTeamAuditObject)])
    .subscribe(() => {
      this._updateDealTeamMembers(this.dealTeamList);
    }),(err:any) => {
      console.log("Error" + err);
      if (err.status == 403 && err.error.stringKey==AppSettings.tokenExpiredText) 
      { 
        this._openSessionExpiredModal();
      }};
  }

  private _storeDealTeamRow(dealTeamRow: PeoplePickerSelectedRow) {
    const objectToSend = this._createDealTeamObject(dealTeamRow.item);
    const addDealTeamAuditObject: object = {
      DealTeamMember: dealTeamRow.item.usersSelected[0].displayName,
      FormID:objectToSend.FormId,
      CreatedBy:this.currentUserName,
      Action:dealTeamRow.actionType,
      FormName:AppSettings.dealWbsSetupFormList
      };
    if (!dealTeamRow.item.itemId) {
      this.mainService.createListItem_DealWbs(AppSettings.dealTeamTableList, objectToSend)
      .subscribe((itemId) => {
        this.engagementTeamComponent.setItemPeoplePicker(itemId, dealTeamRow.item.index);
       this.mainService.createListItem_DealWbs(AppSettings.dealTeamAuditLog, addDealTeamAuditObject) .subscribe((auditLog) => {

        this._updateDealTeamMembers(this.dealTeamList,true,dealTeamRow.item.usersSelected[0].mail);
      });
      })
      catchError((error:any) => {
        throw error;
      });
    } else {
      var isNameChanged=false;
      this.mainService.getAuditLogDealTeamMemebers_DealWbs(AppSettings.dealTeamTableList, this.myDealFormId) .subscribe((response) => {
        var dealTeamName;
        response.forEach((element:any) => {
          if(objectToSend.Name!=element.Name && dealTeamRow.item.itemId==element.ID)
          {
            const removeDealTeamAuditObject: object = {
              DealTeamMember: element.Name,
              FormId:objectToSend.FormId,
              CreatedBy:this.currentUserName,
              Action:DynamicTableAction.Delete,
              FormName:AppSettings.dealWbsSetupFormList
              };

              forkJoin([this.mainService.createListItem_DealWbs(AppSettings.dealTeamAuditLog, removeDealTeamAuditObject),
             this.mainService.createListItem_DealWbs(AppSettings.dealTeamAuditLog, addDealTeamAuditObject)])
           .subscribe(([respadd,respremove]) => {
              isNameChanged=true;
           });
          }
        });

        var initiatorEmail = "";
        var dealTeamRowItemId = (dealTeamRow.item.itemId as any)?.ID || dealTeamRow.item.itemId
        objectToSend.ID = dealTeamRowItemId;
          this.mainService.updateListItem_DealWbs(AppSettings.dealTeamTableList, dealTeamRowItemId, objectToSend).subscribe((response) => {
        if (response) {
          if(isNameChanged)
            initiatorEmail = objectToSend.Email
          this._updateDealTeamMembers(this.dealTeamList,isNameChanged,initiatorEmail);
        }
      });

        });


    }
  }

  private _updateDealTeamMembers(responseDealTeamTable:any, sendNotification:boolean=false,notifierEmail:string="") {

    const myDealDataObject =  { DealTeamMembersDisplayNames : "",DealTeamMembers:""};
    let dealTeamMembers:any = [];
    let dealTeamMemberEmails:any = [];
     if (responseDealTeamTable && responseDealTeamTable.length > 0) {
         responseDealTeamTable.forEach( (item:any) => {
           dealTeamMembers = dealTeamMembers.concat(item.usersSelected[0].displayName);
           dealTeamMemberEmails = dealTeamMemberEmails.concat(item.usersSelected[0].mail);
         });
         const concatenatedString = dealTeamMembers.join(';#');
         myDealDataObject.DealTeamMembersDisplayNames = concatenatedString;
         const emailString = dealTeamMemberEmails.join(';#');
         myDealDataObject.DealTeamMembers =emailString;
     }
      this.saveInProgress = true;
      forkJoin([ this.mainService.updateForm_DealWbs(AppSettings.myDealFormList, this.myDealFormId, myDealDataObject, this.initialMyDealList.Created),
                    this.mainService.updateForm_DealWbs(AppSettings.dealWbsSetupFormList, this.formId, myDealDataObject,this.initialDealWbsList.Created,false,sendNotification,notifierEmail,this.engagementID)])
      .subscribe( () => {
        this.saveInProgress = false;
      })
      ,catchError((error:any) => {
        this.saveInProgress = false;
        throw error;
      });
  }

  private _createDealTeamObject(dealTeamRow: PeoplepickerDynamicTableItem) {
    const dealteamObject = new DealTeamObject();
    // dealteamObject.NameId = dealTeamRow.idUsersSelected[0];
    // dealteamObject.RoleId = AppSettings.roleNA;
    // dealteamObject.PursuitRoleId = AppSettings.pursuitRoleNA;
    // dealteamObject.SwiftRoleId = Number(dealTeamRow.optionsSelectedThirdColumn.key);
    // dealteamObject.FormId = this.myDealFormId;

    dealteamObject.Name = dealTeamRow.usersSelected[0].displayName;
    dealteamObject.Role = String(dealTeamRow.optionsSelectedFirstColumn.value);
    dealteamObject.PursuitRole = String(dealTeamRow.optionsSelectedSecondColumn.value);
    dealteamObject.SwiftRole =  String(dealTeamRow.optionsSelectedThirdColumn.value);
    dealteamObject.FormId = this.myDealFormId.toString();
    dealteamObject.Email = dealTeamRow.usersSelected[0].mail;

    return dealteamObject;
  }

  private _adminApprovalAction() {
    this.executingWorkflowChangeAction = true;
    this._setValidators(this.initialFormValidators);
    this.formSubmitted = true;
    this.spinnerService.showAutoSaveSpinner();
    forkJoin([
      this._validateDCFUsersBeforeUserAction(),
      this.mainService.getFormStatus_DealWBS(AppSettings.dealWbsSetupFormList,this.formId)])
      .subscribe(([areValid, status]) => {
        const canExecuteResponse = this._canExecuteActionToUpdateWorkflow(areValid, status, true);
        const responseCode = canExecuteResponse[0];
        const canExecute = canExecuteResponse[1];
        if (canExecute) {
          this._stopAutosave();
           this.saveForm().subscribe(code => {
            if (code === responseCodes.tryAgain) {
              setTimeout(() => this._subscribeApprove().subscribe(() => this.spinnerService.hideAutoSaveSpinner()), AppSettings.forceItemReleaseInterval);
            } else if (code !== responseCodes.fail) {
              this._approveForm().subscribe(() => this.spinnerService.hideAutoSaveSpinner());
            }
          });
        } else {
          this.executingWorkflowChangeAction = false;
          this._showModalByResponseCode(responseCode);
          this.spinnerService.hideAutoSaveSpinner();
        }
    });
  }

  private _adminReturnAction() {
    this._setValidators([]);
    this.formSubmitted = true;
    if (this.appFormGroup.valid) {
      this.onReturnForRework();
    } else {
      this._openModal(modalTypes.mandatoryFields);
    }
  }

  private _updateAppFormGroup(changes:any): Observable<any> {
      this._updateValuesBeforeSave(changes);
      this.currentSharepointObjectDealWbs = this._setSharepointObjectDealWbs();
      this.currentSharepointObjectMyDeal = this._setSharepointObjectMyDeal();
      const changesDealTeamTable = changes.responseDealTeam;
      const changesAttachments = changes.responseAttachments;     
      if(this.appContext.autoSaveEnabled)
        {
          this._populateSections(this.currentSharepointObjectDealWbs,this.currentSharepointObjectDealWbs, this.currentSharepointObjectMyDeal, changesDealTeamTable,changesAttachments);        
        }
        //update  attachments which are removed from external
      this.externalAttachmentRemovalUpdate(changesAttachments);
      this._initializeFieldValues(this.currentSharepointObjectDealWbs, this.currentSharepointObjectMyDeal);
      return from(this._createPeoplePickerUserArrays()).pipe(
        switchMap(() => {
         return of(true);
    })
  );
  }
  externalAttachmentRemovalUpdate(changesAttachments: any) {
    this.documentsComponent.updateExternalRemovedDocuments(changesAttachments);
  }

  private _updateValuesBeforeSave(changes:any) {
    this.sharePointMapper.cleanInputsEditedExternally(this.appFormGroup);
    const changesDealWbsObject = this._createObjectLastChangesDealWbs(changes.responseDealWbs[0]);
    const changesMyDealObject = this._createObjectLastChangesMyDeal(changes.responseMyDeal[0]);
    const currentSharepointObjectDealWbs = this._setSharepointObjectDealWbs();
    const currentSharepointObjectMyDeal = this._setSharepointObjectMyDeal();   

    if(currentSharepointObjectDealWbs != undefined  && currentSharepointObjectDealWbs.ParticipatingManager != undefined  && currentSharepointObjectDealWbs.ParticipatingManager.results != undefined && currentSharepointObjectDealWbs.ParticipatingManager.results.results != undefined)
    { 
       currentSharepointObjectDealWbs.ParticipatingManager = currentSharepointObjectDealWbs.ParticipatingManager.results;
    }

    if(changesDealWbsObject != undefined  && changesDealWbsObject.ParticipatingManager != undefined  && changesDealWbsObject.ParticipatingManager.results != undefined )
      { 
        if(changesDealWbsObject.ParticipatingManager.results.length > 0)
          {
        changesDealWbsObject.ParticipatingManager = changesDealWbsObject.ParticipatingManager.results;
        }
      }

    if(currentSharepointObjectDealWbs.ParticipatingManager != undefined  && currentSharepointObjectDealWbs.ParticipatingManager.results != undefined )
      { 
        const peoplePickerUserList = new Array<PeoplePickerUser>();
        if(currentSharepointObjectDealWbs.ParticipatingManager?.results?.length > 0){
          currentSharepointObjectDealWbs.ParticipatingManager.results.forEach((x:any)=>{
            let user=new PeoplePickerUser();
            user.displayName=x.displayName;
            user.mail=x.mail;
            user.DisplayText=x.displayName;
              peoplePickerUserList.push(user);
          });
          currentSharepointObjectDealWbs.ParticipatingManager=peoplePickerUserList; 
        }
      }

      if(this.initialDealWbsSetupObject.ParticipatingManager != undefined  && this.initialDealWbsSetupObject.ParticipatingManager.results != undefined )
        { 
          const peoplePickerUserList = new Array<PeoplePickerUser>();
          if(this.initialDealWbsSetupObject.ParticipatingManager?.results?.length > 0){
            this.initialDealWbsSetupObject.ParticipatingManager.results.forEach((x:any)=>{
              let user=new PeoplePickerUser();
              user.displayName=x.displayName;
              user.mail=x.mail;
              user.DisplayText=x.displayName;
                peoplePickerUserList.push(user);
            });
            this.initialDealWbsSetupObject.ParticipatingManager=peoplePickerUserList; 
          }
        } 
        //Added below for Toggle button comparison level highlighting showing for normal save
        if (this.initialDealWbsSetupObject.DerivedAtAddress == null && changesDealWbsObject.DerivedAtAddress=="") {
          changesDealWbsObject.DerivedAtAddress = null;
        }

    const currentDealTeam = this.engagementTeamComponent.getDealTeamRowsComplete();
    const currentAttachments = this.documentsComponent.getAttachmentsRowsComplete();
    const changesAttachments = this._createObjectLastChangesAttachments(changes.responseAttachments);
 
    this._changeValuesIfApply(changesDealWbsObject, this.initialDealWbsSetupObject, currentSharepointObjectDealWbs, 'dealWbsDifferences');
    this._changeValuesIfApply(changesMyDealObject, this.initialMyDealObject, currentSharepointObjectMyDeal, 'myDealDifferences');
    this._changeDealTeamIfApply(this._loadActualDealTeamList(changes.responseDealWbs,changes.responseDealTeam), this.initialDealTeamTable, currentDealTeam);

    this._changeTableValuesIfApply(changesAttachments, this.initialAttachments,
      currentAttachments, AppSettings.dealWbslAttachmentsList);
  }

  private _createObjectLastChangesAttachments(responseAttachments:any): Array<DocumentTypeItem> {
    const changesAttachmentObject = this.dealWbsSetupServiceHelper.createAttachmentsObject(responseAttachments,this.documentTypesFiltered);
    return changesAttachmentObject;
  }

  private _changeDealTeamIfApply(responseDealTeam: Array<PeoplepickerDynamicTableItem>,
                                 initialDealTeamTable: Array<PeoplepickerDynamicTableItem>,
                                 currentDealTeam: Array<PeoplepickerDynamicTableItem>) {
    let updateDealTeamTable = false;
    const changesLength = responseDealTeam.length;
    const initialLength = initialDealTeamTable.length;
    const currentLength = currentDealTeam.length;
    if (changesLength > 0 && initialLength === changesLength && currentLength === changesLength) {
      updateDealTeamTable = this._compareValuesDealTeamTable(responseDealTeam, initialDealTeamTable, currentDealTeam);
    } else {
      updateDealTeamTable = this._compareLengthDealTeamTableArrays(initialLength, currentLength, changesLength);
    }
    if (updateDealTeamTable) {
      this._updateDealTeamMembers(this.dealTeamList);
    }
    this.engagementTeamComponent.setUpdateDealTeam(updateDealTeamTable);
  }

  private _compareLengthDealTeamTableArrays(initialLength: number, currentLength: number, changesLength: number): boolean {
    let updateDealTeamTable = false;
    if (initialLength === currentLength && initialLength !== changesLength) {
      this._setInputEditedExternally(FormListFields.dealTeamTable);
      updateDealTeamTable = true;
    } else {
      if (initialLength !== currentLength && initialLength !== changesLength  && currentLength !== changesLength) {
        this._setInputWithConflict(FormListFields.dealTeamTable);
        updateDealTeamTable = true;
      }
    }
    return updateDealTeamTable;
  }

  private _changeTableValuesIfApply(changesTable: Array<any>,
    initialTableData: Array<any>,
    currentTableData: Array<any>,
    table:any) {
    let updateTable = false;
    let sortingParam = "documentTypeId";

    const changesLength = changesTable.length;
    const initialLength = initialTableData.length;
    const currentLength = currentTableData.length;

    if (changesLength > 0 && initialLength === changesLength && currentLength === changesLength) {
        //sorting by documentTypeId
        if(table === AppSettings.dealWbslAttachmentsList || table === AppSettings.additionalDocumentTypesList ){
            changesTable.sort(this._compareValues(sortingParam));
            initialTableData.sort(this._compareValues(sortingParam));
            currentTableData.sort(this._compareValues(sortingParam));
        }

      updateTable = this._compareValuesTable(changesTable, initialTableData, currentTableData, table);
    } else {
      updateTable = this._compareLengthTableArrays(initialLength, currentLength, changesLength, table);
    }
  }

  //This method is used to sort the fileds by property as key.This will not be supported for nested object array
  private _compareValues(key:any, order = 'asc') {
    return (a:any, b:any) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  private _compareValuesTable(changesTableData: Array<any>,
    initialTableData: Array<any>,
    currentTableData: Array<any>,
    table: string): boolean {
    let updateTable = false;
    for (let i = 0; i < changesTableData.length; i++) {
      const initialValue = initialTableData[i];
      const changesValue = changesTableData[i];
      const currentValue = currentTableData[i];
      let field;

      if (table === AppSettings.dealWbslAttachmentsList || table === AppSettings.additionalDocumentTypesList) {
        field = FormListFields.documentTypesAttachments;
      }
      let initialObjAttchments: AttachmentItem[] = initialValue.documentTypeAttachments.sort();
      let currentObjAttchments: AttachmentItem[] = currentValue.documentTypeAttachments.sort();
      let externalObjAttchments: AttachmentItem[] = changesValue.documentTypeAttachments.sort();

      if (initialObjAttchments.length === externalObjAttchments.length
        && currentObjAttchments.length === externalObjAttchments.length) {
        for (let i = 0; i < externalObjAttchments.length; i++) {
          const initialObjFileName = initialObjAttchments[i].attachmentName;
          const currentObjFileName = currentObjAttchments[i].attachmentName;
          const changesObjFileName  = externalObjAttchments[i].attachmentName;
          if (initialObjFileName === currentObjFileName && initialObjFileName !== changesObjFileName) {
            this._setInputEditedExternally(field);
            updateTable = true;
            break;
          } else if (initialObjFileName !== currentObjFileName
            && currentObjFileName !== changesObjFileName
            && initialObjFileName !== changesObjFileName) {
            this._setInputWithConflict(field);
            updateTable = true;
            break;
          }
        }
        if(updateTable)
          break;
      }else{
        this._setInputEditedExternally(field);
        updateTable = true;
        break;
      }
   }
    return updateTable;
  }

  private _compareLengthTableArrays(initialLength: number, currentLength: number, changesLength: number, table: string): boolean {
    let updateTable = false;
    let field;

    if (table === AppSettings.dealWbslAttachmentsList || table === AppSettings.additionalDocumentTypesList) {
      field = FormListFields.documentTypesAttachments;
    }
    if (initialLength === currentLength && initialLength !== changesLength) {
      this._setInputEditedExternally(field);
      updateTable = true;
    } else {
      if (initialLength !== currentLength && initialLength !== changesLength  && currentLength !== changesLength) {
        this._setInputWithConflict(field);
        updateTable = true;
      }
    }
    return updateTable;
  }

  private _compareValuesDealTeamTable(responseDealTeam: Array<PeoplepickerDynamicTableItem>,
                                      initialDealTeamTable: Array<PeoplepickerDynamicTableItem>,
                                      currentDealTeam: Array<PeoplepickerDynamicTableItem>): boolean {
    let updateDealTeamTable = false;
    for (let i = 0; i < responseDealTeam.length; i++) {
      const initialValue = initialDealTeamTable[i];
      const changesValue = responseDealTeam[i];
      const currentValue = currentDealTeam[i];
      if (Object.entries(initialValue).toString() === Object.entries(currentValue).toString() &&
      Object.entries(initialValue).toString() !== Object.entries(changesValue).toString()) {
        this._setInputEditedExternally(FormListFields.dealTeamTable);
        updateDealTeamTable = true;
        break;
      } else {
        if (Object.entries(changesValue).toString() !== Object.entries(initialValue).toString() &&
            Object.entries(changesValue).toString() !== Object.entries(currentValue).toString() &&
            Object.entries(currentValue).toString() !== Object.entries(initialValue).toString()) {
            this._setInputWithConflict(FormListFields.dealTeamTable);
            updateDealTeamTable = true;
            break;
        }
      }
    }
    return updateDealTeamTable;
  }

  private _changeValuesIfApply(changesObject:any, initialObject:any, currentObject:any, formObject:any) {
    const changesKeys = Object.keys(changesObject);
    if (changesKeys.length) {
      changesKeys.forEach(input => {
        const initialObjectValue = JSON.stringify(initialObject[input]);        
        const changesObjectValue = JSON.stringify(changesObject[input]);        
        const currentObjectValue = JSON.stringify(currentObject[input]);

        if (initialObjectValue === currentObjectValue && initialObjectValue !== changesObjectValue) {
          this._overwriteInputWithIncomingValue(input, changesObject[input]);
          if (input === 'Series24') {
            this._updateMyDealDataInForm(changesObjectValue);
          }
        } else {
          if (changesObjectValue !== initialObjectValue && changesObjectValue !== currentObjectValue
            && currentObjectValue !== initialObjectValue) {
              this._setInputWithConflict(input, changesObject[input], formObject);
          }
        }
      });
    }
  }

  private _setInputWithConflict(input:any, changesObjectValue?:any, formObject?:any) {
    this.conflictInputsOnSave = true;
    const formControl = this._getFormControl(input);
    if (changesObjectValue) {
      formControl.setValue(changesObjectValue);
      this.differences[formObject][input] = changesObjectValue;
    }
    formControl.setConflictOnMerge(true);
  }

  private _overwriteInputWithIncomingValue(input:any, changesObjectValue:any) {
    const formControl = this._getFormControl(input);
    formControl.setValue(changesObjectValue);
    this._setInputEditedExternally(input);
  }

  private _setInputEditedExternally(input:any) {
    this.overwriteInputs = true;
    const formControl = this._getFormControl(input);
    formControl.setEditedExternally(true);
  }

  private _getFormControl(input:any): CustomFormControl {
    const formGroup = this.sharePointMapper.getFormGroupByName(input, this.formGroups, this.appFormGroup);
    const formControl = formGroup.get(this.sharePointMapper.getFormControlColumnName(input)) as CustomFormControl;
    return formControl;
  }

  private _createObjectLastChangesDealWbs(responseDealWbsChanges:any): SharepointObjectDealWbsSetup {
    const changesDealWbsObject = this.dealWbsSetupServiceHelper.createDealWbsSetupObject(responseDealWbsChanges);
    changesDealWbsObject.ParticipatingManager=this._getLCSPPeoplepickerUserDataForDealWbs(responseDealWbsChanges.ParticipatingManager,responseDealWbsChanges.ParticipatingManagerDisplayNames);
    changesDealWbsObject.MemberFirm = changesDealWbsObject.MemberFirm == "" ? null:changesDealWbsObject.MemberFirm;
    changesDealWbsObject.MemberFirmClientID = changesDealWbsObject.MemberFirmClientID == "" ? null:changesDealWbsObject.MemberFirmClientID;
    changesDealWbsObject.MemberFirmContract = changesDealWbsObject.MemberFirmContract == "" ? null:changesDealWbsObject.MemberFirmContract;
    changesDealWbsObject.MemberFirmReferenceID = changesDealWbsObject.MemberFirmReferenceID == "" ? null:changesDealWbsObject.MemberFirmReferenceID;

    const dealWbsObjectInitiliaze = this.defaultValues.initializeSharepointObject(changesDealWbsObject);
    // this._setObjectFieldDate(dealWbsObjectInitiliaze, dealWbsObjectInitiliaze.BillingIfMilestone, FormListFields.billingIfMilestone);
    // this._setObjectFieldDate(dealWbsObjectInitiliaze, dealWbsObjectInitiliaze.BillingFrom, FormListFields.billingFrom);
    // this._setObjectFieldDate(dealWbsObjectInitiliaze, dealWbsObjectInitiliaze.BillingTo, FormListFields.billingTo);

    // tslint:disable-next-line: max-line-length
    this._setObjectFieldPeoplePicker(dealWbsObjectInitiliaze, dealWbsObjectInitiliaze.ParticipatingManager, FormListFields.participatingManager);
    return dealWbsObjectInitiliaze;
  }

  private _createObjectLastChangesMyDeal(responseMyDealChanges:any): SharepointObjectMyDeal {
    const changesMyDealObject = this.dealWbsSetupServiceHelper.createMyDealObject(responseMyDealChanges);
    changesMyDealObject.AreResourcesWorkOutside = changesMyDealObject.AreResourcesWorkOutside == "" ? null:changesMyDealObject.AreResourcesWorkOutside;
    changesMyDealObject.MemberFirmTaxablePresence = changesMyDealObject.MemberFirmTaxablePresence == "" ? null:changesMyDealObject.MemberFirmTaxablePresence;
    changesMyDealObject.OtherMemberFirmsParticipating = changesMyDealObject.OtherMemberFirmsParticipating == "" ? null:changesMyDealObject.OtherMemberFirmsParticipating;
    changesMyDealObject.WhereWorkMemberFirms = changesMyDealObject.WhereWorkMemberFirms == "" ? null:changesMyDealObject.WhereWorkMemberFirms;
    changesMyDealObject.BillingAddress = changesMyDealObject.BillingAddress == "" ? null:changesMyDealObject.BillingAddress;
    changesMyDealObject.PreviousAuditor = changesMyDealObject.PreviousAuditor == null ? null:changesMyDealObject.PreviousAuditor;
    changesMyDealObject.CurrentAuditor = changesMyDealObject.CurrentAuditor == null ? null:changesMyDealObject.CurrentAuditor;
    changesMyDealObject.PayerAddressSameClientAddress = changesMyDealObject.PayerAddressSameClientAddress == ""? null:changesMyDealObject.PayerAddressSameClientAddress;
    changesMyDealObject.PayerAddress = changesMyDealObject.PayerAddress == ""? null:changesMyDealObject.PayerAddress;
    changesMyDealObject.StartDate = changesMyDealObject.StartDate == ""? null:changesMyDealObject.StartDate;
    changesMyDealObject.EndDate = changesMyDealObject.EndDate == ""? null:changesMyDealObject.EndDate;
    const myDealObjectInitialize = this.defaultValues.initializeSharepointObject(changesMyDealObject);
   
    this._setObjectFieldDate(myDealObjectInitialize, myDealObjectInitialize.StartDate, FormListFields.startDate);
    this._setObjectFieldDate(myDealObjectInitialize, myDealObjectInitialize.EndDate, FormListFields.endDate);
    return myDealObjectInitialize;
  }

  private _setObjectFieldDate(object:any, fieldValue:any, fieldName:any) {
    if (!!fieldValue) {
      object[fieldName] = this.dateFormatterService.formatDateToSend(fieldValue);
    }
  }

  private _setObjectFieldPeoplePicker(object:any, fieldValue:any, fieldName:any) {
    if (!!!fieldValue) {
      object[fieldName] = { results: [] };
    } else {
      //object[fieldName] = { results: fieldValue.results };
      object[fieldName] = { results: fieldValue };
    }
  }
  formatDescription(desc:any){
    return desc=desc.slice(0,-2)
   }
  private _findDifferencesBeforeSaveData() {
    let pManager="";let pManagerDisplayNames="";
    const currentSharepointObjectDealWbs:any = this._setSharepointObjectDealWbs();
    const currentSharepointObjectMyDeal:any = this._setSharepointObjectMyDeal();
    const dealWbsDifferences:any = this._getDifferences(this.initialDealWbsSetupObject, currentSharepointObjectDealWbs);
    const myDealDifferences:any = this._getDifferences(this.initialMyDealObject, currentSharepointObjectMyDeal);
    if(currentSharepointObjectDealWbs.ParticipatingManager?.results?.length>0&&dealWbsDifferences.ParticipatingManager){
      currentSharepointObjectDealWbs.ParticipatingManager.results.forEach((x:any)=>{
        pManager=pManager+x.mail+";#";
        pManagerDisplayNames=pManagerDisplayNames+x.displayName+";#"; 
      });
      dealWbsDifferences.ParticipatingManager=this.formatDescription(pManager);
      dealWbsDifferences.ParticipatingManagerDisplayNames=this.formatDescription(pManagerDisplayNames);
    }else if(currentSharepointObjectDealWbs.ParticipatingManager?.results?.length == 0 && dealWbsDifferences.ParticipatingManager)
    {    
        dealWbsDifferences.ParticipatingManager="";
        dealWbsDifferences.ParticipatingManagerDisplayNames="";      
    }
    if(currentSharepointObjectMyDeal.Series24!=""&&myDealDifferences.Series24){
      this.s24GroupUsers.forEach((x:any)=>{
        if(x.Email==currentSharepointObjectMyDeal.Series24){
          dealWbsDifferences.ParticipatingPartnerDisplayNames=x.LastName+', '+x.FirstName;
          myDealDifferences.Series24DisplayNames=x.LastName+', '+x.FirstName;
        }
      })
      dealWbsDifferences.ParticipatingPartner=currentSharepointObjectMyDeal.Series24;
    }
    return {dealWbsDifferences, myDealDifferences};
  }

  private _getDifferences(initialData:any, currentData:any) {
    const differences:any = {};
    for (const input in initialData) {
      if (initialData[input] !== currentData[input]) {
        differences[input] = currentData[input];
      }
    }
    return differences;
  }

  private _storeSharepointObjects(responseDealTeamTable:any,responseDealWbs:any=[]) {
   this.initialDealWbsSetupObject = this._setSharepointObjectDealWbs();
    this.initialMyDealObject = this._setSharepointObjectMyDeal();     
    this.initialDealTeamTable = this._loadActualDealTeamList(responseDealWbs,responseDealTeamTable);
    this.initialAttachments = this.documentsComponent.getAttachmentsRowsComplete();
  }

  private _updateMyDealDataInForm(responseSeries24:any):Observable<any> {
    const myDealObject =  {Series24: null,Series24DisplayNames: null};
    if (responseSeries24) {
      myDealObject.Series24 = responseSeries24;
      myDealObject.Series24DisplayNames = this.series24DisplayName;
    }
    this.saveInProgress = true;
    return from(this.mainService.updateDealWbsForm(AppSettings.dealWbsSetupFormList, this.formId, myDealObject,this.dealWBSRangeKey)).pipe(
      map((results: any) => {
        this.saveInProgress = false;
      }),
      catchError((err: any) => {
        this.saveInProgress = false;
        this._stopAutosave();
        console.log('Error Updating form data:', err);
        throw err; // Re-throw the error to propagate it through the Observable stream
      })
    );

  }

  private _updateFormItem(differences:any,initiateWorkflow:boolean = false): Observable<any> {
    const objectDealWbs = differences.dealWbsDifferences;
    const objectMyDeal = differences.myDealDifferences;
    
    if(this.appContext.autoSaveEnabled)
     { 
      if(objectDealWbs != undefined  && objectDealWbs.ParticipatingManager != undefined  && objectDealWbs.ParticipatingManager.results != undefined && objectDealWbs.ParticipatingManager.results.results == undefined)
    {
      let pManager="";let pManagerDisplayNames="";
      if(objectDealWbs.ParticipatingManager?.results?.length > 0){
        objectDealWbs.ParticipatingManager.results.forEach((x:any)=>{
          pManager=pManager+x.mail+";#";
          pManagerDisplayNames=pManagerDisplayNames+x.displayName+";#"; 
        });
        objectDealWbs.ParticipatingManager=this.formatDescription(pManager);
        objectDealWbs.ParticipatingManagerDisplayNames=this.formatDescription(pManagerDisplayNames);
      }
    }

     }
    if(isNaN(objectMyDeal.Series24Id)||objectMyDeal.Series24Id==""){delete objectMyDeal.Series24Id; }
    //if(objectMyDeal.SalesOfficeId){objectMyDeal.SalesOffice=objectMyDeal.SalesOfficeId; delete objectMyDeal.SalesOfficeId; }
    const dealWbsLength = Object.keys(objectDealWbs).length;
    const objectMyDealLength = Object.keys(objectMyDeal).length;
     return forkJoin([
        (dealWbsLength) ? this.mainService.updateDealWbsForm(AppSettings.dealWbsSetupFormList, this.formId, objectDealWbs,this.dealWBSRangeKey,initiateWorkflow) : of(true),
        (objectMyDealLength) ? this.mainService.updateDealWbsForm(AppSettings.myDealFormList, this.myDealFormId, objectMyDeal,this.myDealRangeKey) : of(true)])
      .pipe(
        switchMap(([responseDealWbsFormItem, responseMyDealFormItem]) => {
          if (objectMyDeal.Series24) {
            this._updateMyDealDataInForm(objectMyDeal.Series24).subscribe(()=>{
              return of(true);
            });;
          }
          if(responseDealWbsFormItem && responseMyDealFormItem) {
            return of(true);
          } else {
            return of(false);
          }
      })
    );
  }

  private _retrieveLastChangesBeforeSave(): Observable<any> {
    if (this.formId && this.myDealFormId) {
      const folderUrl = this._getFolderUrl();
      return forkJoin({
        responseDealWbs: this.mainService.getListItem_DealWBS(AppSettings.dealWbsSetupFormList, this.formId),
        responseMyDeal: this.mainService.getListItem_DealWBS(AppSettings.myDealFormList, this.myDealFormId),
        responseDealTeam: this.dealWbsSetupService.getDealTeamUsers_DealWbs(this.myDealFormId),
        responseAttachments:  this.fileService.getFiles(this.formId,'DealWbsSetupForm')
      });
    }
      else{
        return of({});
      }

  }
  private _openSessionExpiredModal() {
    this.notifypopupComponent.openPopup(AppSettings.tokenExpiredText);
  }


  private _populateSections(responseFormItem:any, responseDealWbs:any, responseMyDeal:any, dealTeamList:any,responseAttachments?:any) {
  this._populateClientInformationSection(responseDealWbs);
    this._populateArchiveCMRSection(responseDealWbs);
    this._populateMyDealSection(responseMyDeal);
    this._populateEngagementInformationSection(responseDealWbs,responseMyDeal);
    this._populateInternationalWorkIndicatorsSection(responseMyDeal.IsInternationalEngagement);
    this._populateElementClassificationSection(responseDealWbs);
    this._populateInvoiceDetailsAndAddressSection(responseDealWbs, responseMyDeal);
    //this._populateMasterEngagementDetailsSection(responseDealWbs);
    //this._populateBillingScheduleSection(responseDealWbs);
    this._populatePricingDetailsSection(responseDealWbs);
    this._populateParticipationSection(responseDealWbs);
    this._populateEngagementTeamSection(responseFormItem,dealTeamList);
    this._populateDocumentSection(responseAttachments);
    this._isSeries24Selected(responseMyDeal.Series24,this.s24GroupUsers);
  }

  private _populateDocumentSection(responseAttachments:any) {
    // this.documentsComponent.populateDocumentSection(responseAttachments);
    if(responseAttachments==undefined){responseAttachments=[];}
    this.documentsComponent.populateDocumentSection(responseAttachments,true);
  }

  private _handlerFormStatus() {
    if (this.hasPermission('ReadOnly') || !this.hasPermission('Edit')) {
      this._setFormAsReadonly();
    }
  }

  private _handlerAdminReviewSection(adminApprovalDate: string, adminApprovalName: string) {
    this.changeDetector.detectChanges();
    if (this.checkVisibilityAdminReviewSetion()) {
      const adminReviewEditable = this._isAdminReviewEditable();
      this.isAdminReviewEditable=adminReviewEditable;
      this.sidebarComponent.showAdminReview();
      this.adminReviewComponent.setAdminApprovalStatus(adminReviewEditable, adminApprovalDate, adminApprovalName);
      if (adminReviewEditable) {
        this._enableAdminReviewFormGroup();
      }
    } else{
      this.archiveCmrInformationComponent.disableWbsCode();
      this.archiveCmrInformationComponent.disablePrdCode();
    }
    this.isValidateFormInAdminReview();
  }

  private _isAdminReviewEditable() {
    return this.hasPermission('EditAdminSection');
  }

  private _isAdminReviewVisible() {
    return this.hasPermission('ShowAdminSection');
  }

  private _isActiveDealWBSVisible(){
    return this.hasPermission('ShowActiveWBSSection');
  }

  private _isActiveDealWBSEditable(){
    return this.hasPermission('EditActiveWBSSection');
  }
  private _enableAdminReviewFormGroup() {
    this.archiveCmrInformationComponent.enableWbsCode();
    this.archiveCmrInformationComponent.enablePrdCode();
  }

  private _isFormDiscontinuedByDealTeam(discontinuedFormActionGroup: string) {
    return discontinuedFormActionGroup === FormActionGroupValue.dealTeam && this.formStatus === FormStatusValue.discontinued;
  }

  private _handlerAutoSave() { 
    if (this.appContext.autoSaveEnabled) {
      this.isAutoSaveEnabled=this.appContext.autoSaveEnabled;
      this._executeAutoSave();
    } else {
      this._handlerSaveButton();
      this.isAutoSaveEnabled=false;
      if(this.formStatus ==  FormStatusValue.completed)
      this.saveButtonVisible = false;
    }
    //Added newly to hide save button if the form is Discontinued
    if(this.formStatus==FormStatusValue.discontinued){
      this.saveButtonVisible=false;
    }
  }
  isValidFormToTrigger():any{
    let isAutoSaveTrigger=true;  
    let differences = this._findDifferencesBeforeSaveData();
     // Remove Series24Id from myDealDifferences if it exists
     if(isNaN(differences.myDealDifferences.Series24Id) || differences.myDealDifferences.Series24Id == "") {
      delete differences.myDealDifferences.Series24Id;
  }
    if(Object.entries(differences.dealWbsDifferences).toString()=="" &&
    Object.entries(differences.myDealDifferences).toString()==""){isAutoSaveTrigger=false;}
    isAutoSaveTrigger=!isAutoSaveTrigger?this.findDifferenceOfTables():isAutoSaveTrigger;
    return isAutoSaveTrigger;
  }
  findDifferenceOfTables(){
    let isTablesModified=false;
       const currentDealTeam = this.engagementTeamComponent.getDealTeamRowsComplete();;
       if(this.initialDealTeamTable.length!=currentDealTeam.length){
          isTablesModified=true;
        }
        return isTablesModified;
  }
  checkFormCompleted():any{
    let isAutoSaveTrigger=false;
    if(this.formStatus=="Completed"){
      isAutoSaveTrigger=true;
    }
    return isAutoSaveTrigger;
  }

  private _executeAutoSave() {
    this.subscription = interval(this.appContext.autoSaveInterval).subscribe(value => {
      let IsFormValidToTriggerAutoSave=this.isValidFormToTrigger();
      let IsFormCompleted=this.checkFormCompleted();
      if(IsFormValidToTriggerAutoSave&& !IsFormCompleted){
      this.spinnerService.showAutoSaveSpinner();
      }
      this.executingSave = true;
      if (!this.executingWorkflowChangeAction&&IsFormValidToTriggerAutoSave&&!IsFormCompleted) {
        this.saveForm().subscribe((responseCode:any) => {
          if (responseCode !== responseCodes.fail) {
            this.executingSave = false;
            this._showAutosaveAlertMessage(responseCode);
          } else {
            this._stopAutosave();
          }
        });
      }
      timer(this.appContext.autoSaveSpinningWheelInterval).subscribe(x=>{this.spinnerService.hideAutoSaveSpinner()});
    });
  } 
  private _showAutosaveAlertMessage(code:any) {
    let autoSaveMessage;
    const currentTimestamp = this.dateService.FormatDate(new Date().toString());
    switch (code) {
      case responseCodes.ok:
        autoSaveMessage = AppSettings.autosaveOk.replace('{timestamp}', currentTimestamp);
        this.alertMessageType = alertTypes.success; 
        this.alertService.success(autoSaveMessage,alertOptions);        
        break;
      case responseCodes.incomingUpdates:
        autoSaveMessage = AppSettings.autosaveWithUpdates.replace('{timestamp}', currentTimestamp);
        this.alertMessageType = alertTypes.success; 
        this.alertService.success(autoSaveMessage,alertOptions);
        break;
      case responseCodes.conflicts:
        autoSaveMessage = AppSettings.autosaveWithConflicts.replace('{timestamp}', currentTimestamp);
        this.alertMessageType = alertTypes.warning; 
       this.alertService.warn(autoSaveMessage,alertOptions);
        break;
      case responseCodes.tryAgain:
        setTimeout(() => this._subscribeAutosave(), AppSettings.forceItemReleaseInterval);
        break;
      case responseCodes.formUpdated:
        this.executingWorkflowChangeAction = true;
        this._openModal(modalTypes.formUpdated);
        this._stopAutosave();
        break;
    }
  }
  private _subscribeSave() {
    setTimeout(() => this.onSaveForm(), AppSettings.forceItemReleaseInterval);
  }

  private _subscribeAutosave():Observable<any> {
    return from(this.saveForm()).pipe(
      tap(responseCode => {
        if (responseCode !== responseCodes.fail) {
          this._showAutosaveAlertMessage(responseCode);
        }
      })
    );
  }

  private _subscribeSubmit():Observable<any> {
    return from(this.saveForm()).pipe(
      tap(code => {
        if (code !== responseCodes.fail) {
          this._submitForm();
        }
      })
    );
  }

  private _subscribeReturn():Observable<any> {
    return from(this.saveForm()).pipe(
      tap(code => {
        if (code !== responseCodes.fail) {
          this._returnFormForRework().subscribe(()=>{});
        }
      })
    );
  }

  private _subscribeApprove():Observable<any>{
    return from(this.saveForm()).pipe(
      tap(code => {
        if (code !== responseCodes.fail) {
          this._approveForm().subscribe(()=>{});
        }
      })
    );
  }

  private _stopAutosave() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.alertService.info("AutoSave Stopped!",alertOptions);
    }
  }

  private _handlerReactivateFormSection(discontinuedOn: string, discontinuedBy: string) {
    if (this.hasPermission('Reactivate')) {
      // this.reactivateFormComponent.setReactivateSection(discontinuedOn, discontinuedBy);
      this.reactivateFormComponent.setReactivateSection();
      this.sidebarComponent.showReactivateFormSection();
    }
  }

  private _initializeFieldValues(responseDealWbs:any, responseMyDeal:any) {
    this.defaultValues.initializeAppFormGroup(this.appFormGroup, [responseDealWbs, responseMyDeal]);
  }

  private _populateTooltips() {
    // this.tooltipHelperService.getTooltips(AppSettings.myDealTooltipList);
    // this.tooltipHelperService.getTooltips(AppSettings.dealWbsSetupTooltipsList);
    this.tooltipHelperService.getTooltipsDealWbs();
  }

  private _setSharepointObjectMyDeal(): SharepointObjectMyDeal {
    let sharepointObject:any = new SharepointObjectMyDeal();
    const myDealFormGroup = this.appFormGroup.get(formGroups.myDealFormGroup);

    sharepointObject = {
      ...sharepointObject,

      // My Deal
      ...this.sharePointMapper.fillSharepointObject(myDealFormGroup as FormGroup),

      StartDate:
        this.dateFormatterService.formatDateToSend(myDealFormGroup
       .get(this.sharePointMapper.getFormControlColumnName(FormListFields.startDate)).value),
      EndDate:
        this.dateFormatterService.formatDateToSend(myDealFormGroup
        .get(this.sharePointMapper.getFormControlColumnName(FormListFields.endDate)).value),
      Series24Id:
        // tslint:disable-next-line: radix
        parseInt(myDealFormGroup.get(this.sharePointMapper.getFormControlColumnName(FormListFields.participatingPartner)).value),
    };

    return sharepointObject;
  }

  private _setSharepointObjectDealWbs(): SharepointObjectDealWbsSetup {
    let sharepointObject = new SharepointObjectDealWbsSetup();
    //const billingScheduleFormGroup = this.appFormGroup.get(formGroups.billingScheduleFormGroup);

    sharepointObject = {
      ...sharepointObject,

      // Client Information
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.clientInformationFormGroup) as FormGroup),

      // Engagement Information
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.engagementInformationFormGroup) as FormGroup),

      // International Work Indicators
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.internationalWorkIndicatorsFormGroup) as FormGroup),

      // WBS Element Classification
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.wbsElementClassificationFormGroup) as FormGroup),

      // Invoice Details & Addresses
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.invoiceDetailsAndAddressesFormGroup) as FormGroup),

      // Master Engagement Details
      //...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.masterEngagementDetailsFormGroup) as FormGroup),

      // Billing Schedule
      //...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.billingScheduleFormGroup) as FormGroup),

      // BillingIfMilestone:
      // this.dateFormatterService.formatDateToSend(billingScheduleFormGroup
      // .get(this.sharePointMapper.getFormControlColumnName(FormListFields.billingIfMilestone)).value),

      // BillingFrom:
      // this.dateFormatterService.formatDateToSend(billingScheduleFormGroup
      // .get(this.sharePointMapper.getFormControlColumnName(FormListFields.billingFrom)).value),

      // BillingTo:
      // this.dateFormatterService.formatDateToSend(billingScheduleFormGroup
      // .get(this.sharePointMapper.getFormControlColumnName(FormListFields.billingTo)).value),

      // ArchiveCMR
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.archiveCmrFormGroup) as FormGroup),

      // Pricing Details
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.pricingDetailsFormGroup) as FormGroup),

      // Participation
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.participationFormGroup) as FormGroup),

      // Admin Review

      //Active Deal WBS Notification
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.activeDealWBSFormGroup)as FormGroup),
    };

    return sharepointObject;
  }

  private _setValidators(validators: Array<SharepointValidator>) {
    Object.keys(this.appFormGroup.controls).forEach(formControlGroupName => {
      const formGroup: FormGroup = this.appFormGroup.get(formControlGroupName) as FormGroup;
      this.sharePointMapper.setFormControlGroupValidators(formGroup, validators);
    });
  }

  private _initialSetValidators() {
   // this.dealWbsService.getAsteriskList().subscribe((response:any) => {

      if(this.resAsterisk!= undefined && this.resAsterisk.length > 0)
        {
          this.resAsterisk.forEach((element:any) => {
            if(element.FieldName == 'SalesOfficeId')
            {
              element.FieldName = 'SalesOffice'
            }
            if(element.FieldName == 'ParticipatingManagerId')
            {
              element.FieldName = 'ParticipatingManager'
            }
            if(element.FieldName == 'Series24Id')
            {
              element.FieldName = 'Series24'
            }
          });
        }


      this._setValidators(this.resAsterisk);
      this.initialFormValidators = this.resAsterisk;
   // });
  }

  private _openModal(modalType: string) {
    this.modalType = modalType;
    this.notifypopupComponent.openPopup("DealWbs",modalType);
  }

  private _isInGroup(email: string, group:any): boolean {
    return group.find((user:any) => {
      if(user && email && user.Email)
       return user.Email.toLowerCase() === email.toLowerCase();
       return false;
    });
    
  }

  private _isInAdGroup(email: string, group:Array<any>): boolean {
    if(group.length!=0)
      {
        if( email != undefined)
          {
            return group.find(user => user.userPrincipalName.toLowerCase() === email.toLowerCase());
          }
          else
          {
            return false;
          }        
      }     
      else
      {
        return false;
      }     
      //return group.find(user => user.Email === email);
  }

  private _isDCFMember(groups: Array<string>): boolean {
    const complianceGroup = AppSettings.dcfComplianceGroup;
    return !!groups.find(group => group === complianceGroup);
  }

  private _populateMyDealSection(response:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.myDealFormGroup) as FormGroup, response);
    this._setDateInput(formGroups.myDealFormGroup, FormListFields.startDate, response.StartDate);
    this._setDateInput(formGroups.myDealFormGroup, FormListFields.endDate, response.EndDate);
    this.wbsElementClassificationComponent.evaluateIsServiceSameAsClientAddressDependency(response.IsServiceSameAsClientAddress);
  }

  private _populateClientInformationSection(response:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.clientInformationFormGroup) as FormGroup, response);
    this.clientInformationComponent.evaluateIsThisCrossBorderDependency(response.IsThisCrossBorder);
  }

  private _populateArchiveCMRSection(response:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.archiveCmrFormGroup) as FormGroup, response);
    this.archiveCmrInformationComponent.evaluateEngagementAuditDependency(response.IsEngagementAudit);
  }

  private _populateEngagementInformationSection(response:any,resMyDeal:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.engagementInformationFormGroup) as FormGroup, response);
    this.engagementInformationComponent.evaluateModelAfterPriorEngagementDependency(response.ModelAfterPriorEngagement);
    const salesoffice:any={key:resMyDeal.SalesOffice,Value:resMyDeal.SalesOffice}
    this.engagementInformationComponent.onSalesOfficeSelection(salesoffice);
  }

  private _populateInternationalWorkIndicatorsSection(isInternationalEngagement:any) {
    // for now all the inputs of this section are on my deal form group
    this.internationalWorkIndicatorsComponent.evaluateIsThisAInternationalEngagementDependency(isInternationalEngagement);
  }

  private _populateEngagementTeamSection(responseFormItem:any,dealTeamList: Array<PeoplepickerDynamicTableItem>) {
    this.dealTeamList = dealTeamList;
    let actualDealTeamList= this._loadActualDealTeamList(responseFormItem,dealTeamList);
    this.dealTeamList=actualDealTeamList;
    this.appFormGroup.get(formGroups.engagementTeamFormGroup)
    .get(this.sharePointMapper.getFormControlColumnName(FormListFields.dealTeamTable)).setValue(this.dealTeamList);
    this._setDealTeamValues(this.dealTeamList);
    this.spinnerService.hideAutoSaveSpinner();
    if(this.initialDealWbsList.DealTeamMembers==null || this.initialDealWbsList.DealTeamMembers==""){
      if (this.dealTeamList && this.dealTeamList.length > 0) {
        const myDealDataObject =  { DealTeamMembersDisplayNames : "",DealTeamMembers:""};
        let dealTeamMembers:any = [];
        let dealTeamMemberEmails:any = [];
          this.dealTeamList.forEach( (item:any) => {
            dealTeamMembers = dealTeamMembers.concat(item.usersSelected[0].displayName);
            dealTeamMemberEmails = dealTeamMemberEmails.concat(item.usersSelected[0].mail);
          });
          const concatenatedString = dealTeamMembers.join(';#');
          myDealDataObject.DealTeamMembersDisplayNames = concatenatedString;
          const emailString = dealTeamMemberEmails.join(';#');
          myDealDataObject.DealTeamMembers =emailString;

          this.mainService.updateForm_DealWbs(AppSettings.dealWbsSetupFormList, this.formId, myDealDataObject,this.initialDealWbsList.Created)
          .subscribe((response:any) => {
            
          });
      }
    }
  }

  _loadActualDealTeamList(responseFormItem:any,dealTeamList:any){
    let dealTeamValues=new Array<PeoplepickerDynamicTableItem>();
    let indexValue=0;
    dealTeamList.forEach((x:any)=>{
    let dataObject=new PeoplepickerDynamicTableItem();
    dataObject.optionsSelectedFirstColumn=this.loadDropDownValues(x,"First");
    dataObject.optionsSelectedSecondColumn=this.loadDropDownValues(x,"Second");
    dataObject.optionsSelectedThirdColumn=this.loadDropDownValues(x,"Third");
    dataObject.itemId=x.ID;
    dataObject.usersSelected.push(this.loadDealTeamMember(responseFormItem,x,indexValue));
    dealTeamValues.push(dataObject);
    indexValue++;
    })
    return dealTeamValues;
}

loadDropDownValues(data:any,columnIndex:any){
  const dropdownItem = new DropdownItem();
  if (columnIndex == "First") {
    dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
    dropdownItem.value = data.Role;
  }
  else if (columnIndex == "Second") {
    dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
    dropdownItem.value = data.PursuitRole;
  }
  else {
    dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
    dropdownItem.value = data.SwiftRole;
  }
  return dropdownItem;
}

loadDealTeamMember(responseFormItem:any,data: any,index:any) {
  let obj = {} as PeoplePickerUser;
  obj.DisplayText = data.Name;
  obj.Key = data.ID;
  obj.displayName = data.Name;
  obj.mail=data.Email;//this.loadEmailIdFromObject(responseFormItem,index);
  obj.LeftDCF = this._isInAdGroup(obj.mail, JSON.parse((localStorage as any).getItem("adGroupMember"))!=null?JSON.parse((localStorage as any).getItem("adGroupMember")):this.adGroupMember.value)===undefined?true:false
  return obj;
}

fillingDropdownKeyValues(row:any,columnIndex:any){
  let key: any="";
  if (columnIndex == "First") {
    this.firstColumnItemList.forEach((x: any) => {
      if (x.value == row.Role) {
        key = x.key;
      }
    })
  }
  else if (columnIndex == "Second") {
    this.secondColumnItemList.forEach((x: any) => {
      if (x.value == row.PursuitRole) {
        key = x.key;
      }
    })
  }
  else {
    this.thirdColumnItemList.forEach((x: any) => {
      if (x.value == row.SwiftRole) {
        key = x.key;
      }
    })
  }
  return key;
}
LoadDropdownLists(){
  this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamRole,'Role').then(
    (response:any) => {
      // Success block
     this.firstColumnItemList=response;
    }).catch((error:any) =>{console.error('Error fetching data:', error);})



      this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamPursuitRole,'Role').then(
    (response:any) => {
      // Success block
      this.secondColumnItemList=response;
    }).catch((error:any) =>{console.error('Error fetching data:', error);})


    this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamSwiftRole,'Role').then(
      (response:any) => {
        // Success block
        this.thirdColumnItemList=response;
      }).catch((error:any) =>{console.error('Error fetching data:', error);})
}

  private _populateElementClassificationSection(response:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.wbsElementClassificationFormGroup) as FormGroup, response);
  }

  private _populateInvoiceDetailsAndAddressSection(responseFormItem:any, responseMyDeal:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.invoiceDetailsAndAddressesFormGroup) as FormGroup,
    responseFormItem);
    this.invoiceDetailsAndAddressesComponent.evaluateBillingAddressDependency(responseMyDeal.BillingAddressSameClientAddress);
    this.invoiceDetailsAndAddressesComponent.evaluatePayerAddressDependency(responseMyDeal.PayerAddressSameClientAddress);
  }

  // private _populateMasterEngagementDetailsSection(response) {
  //   this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.masterEngagementDetailsFormGroup) as FormGroup, response);
  // }

  // private _populateBillingScheduleSection(response) {
  //   this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.billingScheduleFormGroup) as FormGroup, response);
  //   this._setDateInput(formGroups.billingScheduleFormGroup, FormListFields.billingIfMilestone, response.BillingIfMilestone);
  //   this._setDateInput(formGroups.billingScheduleFormGroup, FormListFields.billingFrom, response.BillingFrom);
  //   this._setDateInput(formGroups.billingScheduleFormGroup, FormListFields.billingTo, response.BillingTo);
  // }

  private _populatePricingDetailsSection(response:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.pricingDetailsFormGroup) as FormGroup, response);
  }

  private _populateParticipationSection(response:any) {
    if(response != undefined  && response.ParticipatingManager != undefined  && response.ParticipatingManager.results != undefined )
    {  response.ParticipatingManager = response.ParticipatingManager.results;
    }
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.participationFormGroup) as FormGroup, response);
    this._setPeoplePickers(formGroups.participationFormGroup, this.sharePointMapper.getFormControlColumnName(
      FormListFields.participatingManager), response.ParticipatingManager);
    this.participationComponent.evaluateDerivedAtAddressDependency(response.DerivedAtAddressSameClientAddres);
  }

  private _setDateInput(formGroup:any, inputName:any, input:any) {
    if (input) {
      this.appFormGroup.get(formGroup)
      .get(this.sharePointMapper.getFormControlColumnName(inputName)).setValue(
        this.dateFormatterService.formatDateOnRetrieve(input));
    }
  }

  private _setSharepointMapper() {
    this.clientInformationComponent.setSharepointMapper(this.sharePointMapper);
    this.engagementInformationComponent.setSharepointMapper(this.sharePointMapper);
    this.internationalWorkIndicatorsComponent.setSharepointMapper(this.sharePointMapper);
    this.engagementTeamComponent.setSharepointMapper(this.sharePointMapper);
    this.wbsElementClassificationComponent.setSharepointMapper(this.sharePointMapper);
    //this.masterEngagementDetailsComponent.setSharepointMapper(this.sharePointMapper);
    this.invoiceDetailsAndAddressesComponent.setSharepointMapper(this.sharePointMapper);
    //this.billingScheduleComponent.setSharepointMapper(this.sharePointMapper);
    this.archiveCmrInformationComponent.setSharepointMapper(this.sharePointMapper);
    this.pricingDetailsComponent.setSharepointMapper(this.sharePointMapper);
    this.participationComponent.setSharepointMapper(this.sharePointMapper);
  }

  private _createSharepointMapper() {
    this.sharePointMapper = new DealWbsSetupSharepointMapper();

    // My Deal
    this._fillMyDeal();

    // Client Information
    this._fillClientInformation();

    // Engagement Information
    this._fillEngagementInformation();

    // International Work Indicators
    this._fillInternationalWorkIndicators();

    // International Work Indicators
    // this._fillElementClassification();

    // Invoice Details & Addresses
    this._fillInvoiceDetailsAndAddresses();

    // Master Engagement Details
    //this._fillMasterEngagementDetails();

    // Engagement Team
    this._fillEngagementTeam();

    // Billing Schedule
    //this._fillBillingSchedule();

    // Archive CMR
    this._fillarchiveCMR();

    // Pricing Details
    this._fillPricingDetails();

    // Participation
    this._fillParticipation();

     // Documents
     this._fillDocuments();

     //Active Deal WBS
     this._fillActiveWBS();
  }

  private _fillMyDeal() {
    this.sharePointMapper.addMapping('ClientName', 'clientName');
    //this.sharePointMapper.addMapping('ClientID', 'clientID');
    this.sharePointMapper.addMapping('EngagementName', 'engagementName');

    this.sharePointMapper.addMapping('StartDate', 'startDate');
    this.sharePointMapper.addMapping('EndDate', 'endDate');
    this.sharePointMapper.addMapping('IsRecurringWBS', 'isRecurringWBS');
    this.sharePointMapper.addMapping('DeloitteUSEntityId', 'deloitteUSEntity');
    this.sharePointMapper.addMapping('IntegratedMarketOffering', 'integratedMarketOffering');    
    this.sharePointMapper.addMapping('SalesOffice', 'salesOffice');
    this.sharePointMapper.addMapping('EngagementIndustrySICCode', 'engagementIndustrySICCode');
    this.sharePointMapper.addMapping('IsInternationalEngagement', 'isInternationalEngagement');
    this.sharePointMapper.addMapping('AreResourcesWorkOutside', 'areResourcesWorkOutside');
    this.sharePointMapper.addMapping('MemberFirmTaxablePresence', 'memberFirmTaxablePresence');
    this.sharePointMapper.addMapping('OtherMemberFirmsParticipating', 'otherMemberFirmsParticipating');
    this.sharePointMapper.addMapping('WhereWorkMemberFirms', 'whereWorkMemberFirms');
    this.sharePointMapper.addMapping('ServiceAtAddress', 'serviceAtAddress');
    this.sharePointMapper.addMapping('MarketOffering', 'marketOffering');
    this.sharePointMapper.addMapping('IsServiceSameAsClientAddress', 'isServiceSameAsClientAddress');
    this.sharePointMapper.addMapping('Material', 'material');
    this.sharePointMapper.addMapping('BillingAddressSameClientAddress', 'billingAddressSameClientAddress');
    this.sharePointMapper.addMapping('BillingAddress', 'billingAddress');
    this.sharePointMapper.addMapping('PayerAddressSameClientAddress', 'payerAddressSameClientAddress');
    this.sharePointMapper.addMapping('PayerAddress', 'payerAddress');
    this.sharePointMapper.addMapping('Series24', 'participatingPartner');
    this.sharePointMapper.addMapping('PreviousAuditor', 'previousAuditor');
    this.sharePointMapper.addMapping('CurrentAuditor', 'currentAuditor');
    this.sharePointMapper.addMapping('WbsCode', 'wbsCode');
    this.sharePointMapper.addMapping('PrdCode','prdCode');
    this.sharePointMapper.addMapping('OpportunityID', 'opportunityID');
  }

  private _fillClientInformation() {
    this.sharePointMapper.addMapping('MemberFirm', 'memberFirm');
    this.sharePointMapper.addMapping('MemberFirmClientID', 'memberFirmClientID');
    this.sharePointMapper.addMapping('MemberFirmContract', 'memberFirmContract');
    this.sharePointMapper.addMapping('MemberFirmReferenceID', 'memberFirmReferenceID');
    this.sharePointMapper.addMapping('IsThisCrossBorder', 'isThisCrossBorder');
  }

  private _fillEngagementInformation() {
    this.sharePointMapper.addMapping('ModelAfterPriorEngagement', 'modelAfterPriorEngagement');
    this.sharePointMapper.addMapping('PriorEngagement', 'priorEngagement');
    this.sharePointMapper.addMapping('EngagementTypeName', 'engagementTypeName');
  }

  private _fillInternationalWorkIndicators() {
    // for now all the inputs of this section are on my deal form group
  }

  private _fillEngagementTeam() {
    this.sharePointMapper.addMapping('DealTeamTable', 'dealteamTable');
  }

  // private _fillElementClassification() {
    // This was commented because all of its fields are currently on MyDeal
  // }

  private _fillInvoiceDetailsAndAddresses() {
    //this.sharePointMapper.addMapping('MemberFirmIdBillingAddress', 'memberFirmIdBillingAddress');
    this.sharePointMapper.addMapping('BillingContact', 'billingContact');
    this.sharePointMapper.addMapping('EmailAddress', 'emailAddress');
    this.sharePointMapper.addMapping('IsSubcontractingArrangement', 'isSubcontractingArrangement');
    this.sharePointMapper.addMapping('PrintSecondBillingAddress', 'printSecondBillingAddress');
    this.sharePointMapper.addMapping('InvoiceMedium', 'invoiceMedium');
    this.sharePointMapper.addMapping('PrintClientReferenceNumber', 'printClientReferenceNumber');
    this.sharePointMapper.addMapping('AllowInvoiceConsolidation', 'allowInvoiceConsolidation');
    this.sharePointMapper.addMapping('BillingCurrency', 'billingCurrency');
    this.sharePointMapper.addMapping('InvoiceLayout', 'invoiceLayout');
    this.sharePointMapper.addMapping('PrintEngagementNumber', 'printEngagementNumber');
    this.sharePointMapper.addMapping('PaymentTerms', 'paymentTerms');
    this.sharePointMapper.addMapping('FXRate', 'fxRate');
    this.sharePointMapper.addMapping('FXRiskSharing', 'fxRiskSharing');
    this.sharePointMapper.addMapping('BaselineFXRate', 'baselineFXRate');
  }

  // private _fillMasterEngagementDetails() {
  //   this.sharePointMapper.addMapping('MasterEngagementID', 'masterEngagementID');
  //   this.sharePointMapper.addMapping('FinancialPlanID', 'financialPlanID');
  // }

  // private _fillBillingSchedule() {
  //   this.sharePointMapper.addMapping('BillingPlanType', 'billingPlanType'),
  //   this.sharePointMapper.addMapping('BillingIntervals', 'billingIntervals'),
  //   this.sharePointMapper.addMapping('BillingIfMilestone', 'billingIfMilestone'),
  //   this.sharePointMapper.addMapping('BillingFrom', 'billingFrom'),
  //   this.sharePointMapper.addMapping('BillingTo', 'billingTo');
  // }

  private _fillarchiveCMR() {
    this.sharePointMapper.addMapping('IsEngagementAudit', 'isEngagementAudit');
    this.sharePointMapper.addMapping('IsArchiveRequired', 'isArchiveRequired');
    this.sharePointMapper.addMapping('WasCMRPerformed', 'wasCMRPerformed');
  }

  private _fillPricingDetails() {
    this.sharePointMapper.addMapping('ContractType', 'contractType');
    this.sharePointMapper.addMapping('ResourceRateType', 'resourceRateType');
    this.sharePointMapper.addMapping('USIResourceRateType', 'usiResourceRateType');
    this.sharePointMapper.addMapping('EventIndicator', 'eventIndicator');
    this.sharePointMapper.addMapping('BudgetGroup', 'budgetGroup');
    this.sharePointMapper.addMapping('PlannedNetRevenue', 'plannedNetRevenue');
    this.sharePointMapper.addMapping('Discount', 'discount');
    this.sharePointMapper.addMapping('PlannedMargin', 'plannedMargin');
    this.sharePointMapper.addMapping('PlannedCost', 'plannedCost');
    this.sharePointMapper.addMapping('TotalPlannedHours', 'totalPlannedHours');
    this.sharePointMapper.addMapping('TimeRevenueCap', 'timeRevenueCap');
  }

  private _fillParticipation() {
    this.sharePointMapper.addMapping('ParticipatingManager', 'participatingManager');
    this.sharePointMapper.addMapping('DerivedAtAddressSameClientAddres', 'derivedAtAddressSameClientAddres');
    this.sharePointMapper.addMapping('DerivedAtAddress', 'derivedAtAddress');
    // this.sharePointMapper.addMapping('ConsultingMarket', 'consultingMarket');
  }

  private _fillDocuments() {
    this.sharePointMapper.addMapping('DocumentTypesAttachments', 'documentTypesAttachments');
  }

  private _fillActiveWBS(){
    this.sharePointMapper.addMapping('IsActiveWBSNotificationSent','isActiveWBSNotificationSent');
  }
  private _isDCFMemberInAdGroup(email:string,groups:Array<any>): boolean {
    return !!groups.find(group => group.userPrincipalName.toLowerCase() === email.toLowerCase());
  }

  private _setFormId() {
    this.formId = Number(this.route.snapshot.queryParams.id);
  }

  private _createForm() {
    this.appFormGroup = new FormGroup({
      myDealFormGroup : new FormGroup({
        clientName : new CustomFormControl('Client Name', ''),
        //clientID : new CustomFormControl('Client #', null),
        engagementName : new CustomFormControl('Engagement Name', null),

        startDate : new CustomFormControl('Start Date', null),
        endDate : new CustomFormControl('End Date', null),
        isRecurringWBS : new CustomFormControl('Is this a Recurring WBS', {value: null, disabled: true}),
        deloitteUSEntity : new CustomFormControl('Deloitte US Entity', {value: null, disabled: true}),
        integratedMarketOffering : new CustomFormControl('Integrated Market Offering', {value: null, disabled: true}),
        salesOffice : new CustomFormControl('Sales Office', null),
        engagementIndustrySICCode : new CustomFormControl('Is this an International Engagement', null),
        isInternationalEngagement : new CustomFormControl('Are the local resources performing work outside the home country', null),
        areResourcesWorkOutside : new CustomFormControl('Does the member firm have a taxable presence in the involved countries',
        {value: null, disabled: true}),
        memberFirmTaxablePresence : new CustomFormControl('Engagement Industry (SIC Code)', {value: null, disabled: true}),
        otherMemberFirmsParticipating : new CustomFormControl('Are any other member firms participating on this engagement',
        {value: null, disabled: true}),
        whereWorkMemberFirms : new CustomFormControl('Where are these member firm employees working from', {value: null, disabled: true}),
        serviceAtAddress  : new CustomFormControl('Service-At Address',null),
        marketOffering  : new CustomFormControl('Market offering', null),
        isServiceSameAsClientAddress  : new CustomFormControl('Is Service-At Adress same as Client Address', null),
        material : new CustomFormControl('Material', null),
        billingAddressSameClientAddress : new CustomFormControl('Is Billing Address same as Client Address', {value: null, disabled: false}),
        billingAddress : new CustomFormControl('Billing Address', null),
        payerAddressSameClientAddress : new CustomFormControl('Is Payer Address same as Client Address', {value: null, disabled: false}),
        payerAddress : new CustomFormControl('Payer Address', null),
        participatingPartner : new CustomFormControl('Resource Rate Type', null),
        previousAuditor : new CustomFormControl('Previous Auditor', {value: '', disabled: true}),
        currentAuditor : new CustomFormControl('Current Auditor', {value: '', disabled: true}),
        wbsCode : new CustomFormControl('WbsCode', null),
        prdCode : new CustomFormControl('PrdCode',null),
        opportunityID : new CustomFormControl('OpportunityID', null)
      }),
      clientInformationFormGroup : new FormGroup({
        memberFirm : new CustomFormControl('Member Firm', {value: null, disabled: true}),
        memberFirmClientID : new CustomFormControl('Member Firm ClientID #', {value: null, disabled: true}),
        memberFirmContract : new CustomFormControl('Member Firm Contract', {value: null, disabled: true}),
        memberFirmReferenceID : new CustomFormControl('Member Firm Reference #', {value: null, disabled: true}),
        isThisCrossBorder : new CustomFormControl('is this cross border',{value: null , disabled: false})
      }),
      engagementInformationFormGroup : new FormGroup({
        modelAfterPriorEngagement : new CustomFormControl('Model after prior engagement', {value: null, disabled: true}),
        priorEngagement : new CustomFormControl('Prior Engagement', {value: null, disabled: true}),
        engagementTypeName : new CustomFormControl('Engagement Type', null)
      }),
      internationalWorkIndicatorsFormGroup : new FormGroup({
        // for now all the inputs of this section are on my deal form group
      }),
      engagementTeamFormGroup : new FormGroup({
        dealteamTable : new CustomFormControl('Deal Team', [])
      }),
      wbsElementClassificationFormGroup : new FormGroup({
        // for now all the inputs of this section are on my deal form group
      }),
      invoiceDetailsAndAddressesFormGroup : new FormGroup({
        //memberFirmIdBillingAddress : new CustomFormControl('Member Firm ID for Billing Address', {value: null, disabled: true}),
        billingContact : new CustomFormControl('Billing Contact', null),
        emailAddress : new CustomFormControl('Email Address', null),
        isSubcontractingArrangement : new CustomFormControl('Is this a Subcontracting Arrangement', {value: null, disabled: false}),
        printSecondBillingAddress : new CustomFormControl('Do you want to print second Billing address on invoice', null),
        invoiceMedium : new CustomFormControl('Invoice Medium', {value: null, disabled: true}),
        printClientReferenceNumber : new CustomFormControl('Print Client Reference Number', null),
        allowInvoiceConsolidation : new CustomFormControl('Allow Invoice Consolidation', {value: null, disabled: true}),
        billingCurrency : new CustomFormControl('Billing Currency', {value: '', disabled: false}),
        invoiceLayout : new CustomFormControl('Invoice Layout', null),
        printEngagementNumber : new CustomFormControl('Print Engagement Number', null),
        paymentTerms : new CustomFormControl('Payment Terms', {value: null, disabled: true}),
        fxRate : new CustomFormControl('FX Rate', null),
        fxRiskSharing : new CustomFormControl('FX Risk Sharing', null),
        baselineFXRate : new CustomFormControl('Baseline FX rate', null)
      }),
      //masterEngagementDetailsFormGroup : new FormGroup({
        //masterEngagementID: new CustomFormControl('Master Engagement ID', null),
        //financialPlanID: new CustomFormControl('Financial Plan ID', null)
      //}),
      // billingScheduleFormGroup : new FormGroup({
      //   billingPlanType: new CustomFormControl('Billing Plan Type', null),
      //   billingIntervals: new CustomFormControl('Billing Intervals', null),
      //   billingIfMilestone: new CustomFormControl('Billing If Milestone', null),
      //   billingFrom: new CustomFormControl('Billing From', null),
      //   billingTo: new CustomFormControl('Billing To', null)
      // }),
      archiveCmrFormGroup : new FormGroup({
        isEngagementAudit : new CustomFormControl('Is the engagememnt an Audit or Other Attest Services', null),
        isArchiveRequired : new CustomFormControl('Is an archive required for the official records of the engagement?', {value: null, disabled: true}),
        wasCMRPerformed : new CustomFormControl('Was CMR performed? (if applicable)', null),
      }),
      pricingDetailsFormGroup : new FormGroup({
        contractType : new CustomFormControl('Contract Type', {value: null, disabled: true}),
        resourceRateType : new CustomFormControl('Resource Rate Type', {value: null, disabled: true}),
        usiResourceRateType : new CustomFormControl('USI Resource Rate Type', {value: null, disabled: true}),
        eventIndicator : new CustomFormControl('Event Indicator', {value: null, disabled: false}),
        budgetGroup : new CustomFormControl('Budget Group 01', {value: null, disabled: true}),
        plannedNetRevenue : new CustomFormControl('Planned Net Revenue', {value: null, disabled: false}),
        discount : new CustomFormControl('Discount %', {value: null, disabled: true}),
        plannedMargin : new CustomFormControl('Planned Margin %', {value: null, disabled: true}),
        plannedCost : new CustomFormControl('Planned Cost', {value: null, disabled: false}),
        totalPlannedHours : new CustomFormControl('Total Planned Hours', {value: null, disabled: false}),
        timeRevenueCap : new CustomFormControl('Time Revenue Cap', {value: null, disabled: true})
      }),
      participationFormGroup : new FormGroup({
        participatingManager : new CustomFormControl('Resource Rate Type', { results: [] , disabled: true}),
        derivedAtAddressSameClientAddres : new CustomFormControl('Resource Rate Type', {value: null, disabled: false}),
        derivedAtAddress : new CustomFormControl('Resource Rate Type', null),
        // consultingMarket : new CustomFormControl('Resource Rate Type', null)
      }),
      documentsFormGroup : new FormGroup({
        documentTypesAttachments : new CustomFormControl('DocumentTypes Attachments', [])
     }),
      // adminReviewFormGroup : new FormGroup({
      //   // for now all the inputs of this section are on my deal form group
      // }),
      activeDealWBSFormGroup : new FormGroup({
        isActiveWBSNotificationSent : new CustomFormControl('Is ActiveW BS Notification Sent',{value: null , disabled: false})
      })
    });
  }

  uploadedAttachmentsHandler(uploadedAttachments:any) {
    this.appFormGroup.get(formGroups.documentsFormGroup)
    .get(this.sharePointMapper.getFormControlColumnName(FormListFields.documentTypesAttachments)).setValue(uploadedAttachments);
  }

  selectedFileHandler(fileSelected: boolean) {
    if (this.formId) {
      this._saveFileToLibrary();
    } else {
      this.saveForm().subscribe((response:any) => {
        this._saveFileToLibrary();
      });
    }
  }

  additionalDocumentTypItemHandler(additionalDocumentType: any) {
    if (this.formId) {
      this._storeAdditionalDocumentTypeItem(additionalDocumentType);
    } else {
      this.saveForm().subscribe((response:any) => {
        this._storeAdditionalDocumentTypeItem(additionalDocumentType);
      });
    }
  }

  private _storeAdditionalDocumentTypeItem(additionalDocumentTypeItem: any) {
    const objectToSend = this._createAdditionalDocumentTypeItemObject(additionalDocumentTypeItem);
    this.mainService.createListItem(AppSettings.additionalDocumentTypesList, objectToSend)
    .then(itemId => {})
    .catch(error => {});
  }

  private _createAdditionalDocumentTypeItemObject(additionalDocumentTypeItem: any) {
    const additionalDocumentTypeItemObject = new AdditionalDocumentTypeItemObject();
    additionalDocumentTypeItemObject.DocumentTypeId = additionalDocumentTypeItem.documentTyeIdToStore;
    additionalDocumentTypeItemObject.FormId = this.formId;

    return additionalDocumentTypeItemObject;
  }

  private _saveFileToLibrary() {
    this.documentsComponent.saveFileToLibrary(this.formId);
  }

  private _setDealTeamValues(dealTeamList: Array<PeoplepickerDynamicTableItem>) {
    if (this.engagementTeamComponent.getUpdateDealTeam()) {
      this.engagementTeamComponent.setDealTeamValues(dealTeamList);
      this.engagementTeamComponent.updatedExcludedUsers();
    }
  }

    private _createPeoplePickerUserArrays(): Observable<any> {
      const participationFormGroup = this.appFormGroup.get(formGroups.participationFormGroup);
      return forkJoin([
        from(this._getPeoplepickerUserData(participationFormGroup.get(
          this.sharePointMapper.getFormControlColumnName(FormListFields.participatingManager)).value.results))
      ]).pipe(
        tap(([participationManagerResponse]) => {
          this.participationComponent.setParticipatingManager(participationManagerResponse);
        })
      );
    }
  private _getPeoplepickerUserData(peoplepickerUserIds:any): any {
      const peoplePickerUserList:any = new Array<PeoplePickerUser>();
      let requests = peoplepickerUserIds.length;
      if (requests) {
        for (const userId of peoplepickerUserIds) {
          let response=this.peoplepickerService.BindParticipationUserDetails(userId);
            peoplePickerUserList.push(response);
            requests--;
            if (requests === 0) {
              const arraySorted = this._sortPeoplepickerUserArray(peoplepickerUserIds, peoplePickerUserList);
              return of(arraySorted);
            }
          }
      } else {
       return of(peoplePickerUserList);
      }
  }

  private _sortPeoplepickerUserArray(peoplepickerUserIds: any,
                                     peoplePickerUserList: Array<PeoplePickerUser>): Array<PeoplePickerUser> {
    let arrayOrder = new Array<PeoplePickerUser>();
    arrayOrder = peoplepickerUserIds.map((userId:any) => {
      return peoplePickerUserList.filter(user => user.mail === userId.mail)[0];
    });
    return arrayOrder;
  }

  private _setPeoplePickers(appFormGroup: string, inputName: string, userIdList:any) {
    const input = this.appFormGroup.get(appFormGroup).get(inputName);
    if (!userIdList) {
      input.setValue({ results: [] });
    } else {
      input.setValue({ results: userIdList });
    }
  }

  appendLCSPValues(response:any){
    this.initialDealWbsList.ParticipatingManager= this._getLCSPPeoplepickerUserDataForDealWbs(response.ParticipatingManager,response.ParticipatingManagerDisplayNames);
    return this.initialDealWbsList;
  }

  private _getLCSPPeoplepickerUserDataForDealWbs(responseemail:any,responseName:any): any{
    const peoplePickerUserList = new Array<PeoplePickerUser>();
    if(responseemail!=null&&responseemail!=undefined&&responseemail!=""){
    if(responseemail.includes(";#")){
      let lcspemail=responseemail.split(";#");
      let lcspname=responseName.split(";#");
    if (lcspemail.length==lcspname.length) {
      for (let i=0;i<=lcspemail.length-1;i++) {
        let user=new PeoplePickerUser();
        user.displayName=lcspname[i];
        user.mail=lcspemail[i];
        user.DisplayText=lcspname[i];
        peoplePickerUserList.push(user);
      }
    } else {
      this._openModal(modalTypes.lcspmismatch);
    }
  }
  else{
    let user=new PeoplePickerUser();
    user.displayName=responseName;
    user.mail=responseemail;
    user.DisplayText=responseName;
    peoplePickerUserList.push(user);
  }
}
    return peoplePickerUserList;
}

  private _validateDCFUsersBeforeUserAction():Observable<any> {
        const series24Selected =
          this.appFormGroup.get(formGroups.myDealFormGroup)
          .get(this.sharePointMapper.getFormControlColumnName(FormListFields.participatingPartner)).value;
        return this._areUsersBelongToDCFGroup(series24Selected, this.dealTeamList, this.s24GroupUsers);
  }

  private _areUsersBelongToDCFGroup(
    series24IdSelected: number,
    dealTeamList: Array<PeoplepickerDynamicTableItem>,
    //dcfUsers:any,
    series24users:any): Observable<any> {
        let series24Valid;
        let dealTeamValid;
        return from(this._isSeries24Selected(series24IdSelected, series24users)).pipe(
          map((isSelected: any) => {
            series24Valid = isSelected;
          dealTeamValid = this._areDealTeamMembersBelongToDCFGroup(dealTeamList);
          return (series24Valid && dealTeamValid);
          }),
          catchError((err: any) => {
            console.log('Error Validating data:', err);
            throw err; // Re-throw the error to propagate it through the Observable stream
          })
        );
  }

  private _isSeries24Selected(series24Email: any, series24users:any): Observable<any> {
      if (series24Email) {
       let data:any= this._isSeries24ValidUser(series24Email, series24users);
        return of(data);
      } else {
          return of(true);
      }
  }

  private _isSeries24ValidUser(email: string, series24users:any): boolean {
    let valid = true;
    let adGroup=localStorage.getItem("adGroupMember")?.toString();
    if (email && this._isInGroup(email, series24users)) {
     if (!this._isInAdGroup(email, adGroup!=null?JSON.parse(adGroup):this.adGroupMember.value)) {
       this.participationComponent.setSeries24WarningMessage(true);
        valid = false;
      } else {
       this.participationComponent.setSeries24WarningMessage(false);
      }
    } else {
      this.appFormGroup.get(formGroups.myDealFormGroup)
      .get(this.sharePointMapper.getFormControlColumnName(FormListFields.participatingPartner)).setValue(null);
      this.participationComponent.setSeries24WarningMessage(false);
    }
    return valid;
  }

  private _areDealTeamMembersBelongToDCFGroup(dealTeamList: Array<PeoplepickerDynamicTableItem>): boolean {
    let valid = true;
    let adGroup=localStorage.getItem("adGroupMember")?.toString();
    if (dealTeamList) {
      dealTeamList.forEach(user => {
        const email = user.usersSelected[0].mail;
        if (!this._isInAdGroup(email, adGroup!=null?JSON.parse(adGroup):this.adGroupMember.value)) {
          user.usersSelected[0].LeftDCF = true;
          valid = false;
        }
      });
      this._setDealTeamValues(dealTeamList);
    }
    return valid;
  }
  private _setFormAsReadonly() {
    Object.keys(this.appFormGroup.controls).forEach(formGroupName => {
      const formGroup = this.appFormGroup.get(formGroupName) as FormGroup;
      Object.keys(formGroup.controls).forEach(formControlName => {
        const elem = formGroup.get(formControlName) as CustomFormControl;
        elem.setReadOnly(true);
      });
    });
  }
  ConfirmDetails(event:any) //Handle the popup click
  {
    if(event.isOkayClicked) // Okay is clicked on the popup
    {
        //based on the type propgate the function call
        switch(event.modalType)
        {
          case modalTypes.submit: // redirect to engagement dashboard
          case modalTypes.discontinueConfirmed: //redirect to engagement dashboard          
          case modalTypes.returnForReworConfirmed: // redirect to engagement dashboard
          case modalTypes.approval: // redirect to engagement dashboard
          case modalTypes.reactivateConfirmed: // redirect to engagement dashboard
          case modalTypes.retryWorkflow: // redirect to engagement dashboard
          case modalTypes.formUpdated:  // redirect to engagement dashboard
          case modalTypes.activeWBSNotification: // redirect to engagement dashboard
          case modalTypes.noDCFMember: // stay on same page
          case modalTypes.noValidForm:
          case modalTypes.access: this.redirectOkModal(event.modalType);break; // stay on the same page with freeze
          case modalTypes.userLeftDcf:
          case modalTypes.mandatoryFields:this.scrollToTop();break;
          case modalTypes.retrieve:this.scrollToSection();break;
          case modalTypes.discontinue:this.discontinueReason=event.discontinueReason;this.confirmDiscontinueReason();break;
          case modalTypes.returnForRework:this.returnForReworkReason=event.returnForReworkReason;this.checkReasonBeforeReturn();break;
          case modalTypes.returnForReworkReason:this.onReturnForRework();break;
          case modalTypes.reactivateForm:this.confirmReactivateForm();break;
          case modalTypes.discontinueReason:this.onDiscontinue();break;
          default:break;
        }
    }
    else //cancel clicked
    {
      switch(event.modalType)
      {
        case modalTypes.discontinue:this.cancelDiscontinue();break;
        case modalTypes.returnForRework:this.cancelReturnForRework();break;
        default:break;
      }
    }
  }
  redirectOkModal(modalType: string) {
   if(modalType==modalTypes.noValidForm)
   {
    this.buttonsVisible=false;
   }
   else
   {
      window.location.href =AppSettings.Engagementdashboard;
   }
  }

}
