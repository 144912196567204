<link rel=”stylesheet” href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<div [ngClass]="isAddingUser?'fullscreen-New': 'fullscreen-Old'">
<div class="header">
    <div class="headerManagement">User Management</div> 
  </div>
  <div class="mainBody" >
    <div style="display: inline-flex;">
    <div class="divFormType">
      <label>Form Type</label>
        <select id="ddlFormType" (change)="onFormTypeChange()"
        [(ngModel)]="selectedFormType"
         class="form-select form-control formtypewidth">
            <option value="" selected disabled>Form Type</option>
            <option *ngFor="let title of formTypes" [value]="title.ID">
  {{title.Name}}
</option>
          </select>
    </div>
    <div class="divFormType userGroupmargin">
      <label>User Group</label>     
        <select  id="ddlFormGroup" (change)="onFormGroupChange()"
        [(ngModel)]="selectedFormGroup"
         class="form-select form-control" [disabled]="isDisabled">
            <option value= ""selected disabled>Select Group</option>
            <option *ngFor="let title of formGroups" [value]="title.ID">
  {{title.Name}}
</option>
          </select>
  </div>
  <div>
    <button class="btn btn-primary btncheckpermission" type="button"  (click)="openCheckPermissionPopup($event)" [disabled]="checkPermissionDisabled" >Check Permissions</button>
</div>
<div *ngIf="isAdminUser" >
  <button class="btn btn-primary btnGenerateUserReport" type="button"  (click)="downloadUserReport()" [disabled]="checkPermissionDisabled">Generate Report</button>
</div>
<div class="divFormType" *ngIf="showTable&&isAdminUser">
    <button class="btn btn-primary btnAddUser" type="button"  (click)="openAddUserPopup($event)" [disabled]="duplicateUserRestrictFlag">Add New User </button>
</div>
</div>
    
    <div class="divUserPermission" style="display: none;">
                
         <label>Check User permission</label> <div class="input-group">
            <input type="text" class="form-control" placeholder="Search People">
            
          </div>
        
    </div>
    
    <div class="divTable" *ngIf="showTable" >
      
<!-- <table class="tblUserMgmt table table-bordered">
    <thead>
        <tr class="tdCenter">
          <th scope="col">Name <img class="imgRoleFilter" src="../../../assets/img/filter.png" alt="filter"><img class="imgRoleFilter" src="../../../assets/img/sortIcon.png" alt="filter"></th>
          <th scope="col">Title</th>
          <th scope="col">Action</th> 
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of reportDashboards" class="tdCenter">
<td>
  {{user.LastName}},{{user.FirstName}} 
</td>
<td>
    {{user.Role}}
  </td>
  <td >
    <button class="btn btn-primary btnDeleteUser" (click)="DeleteUser(user.ID)" type="button">Delete</button>
  </td>
        </tr>
      </tbody>
</table> -->
<table id="engagement-table" class="table-striped">
  <thead style="height: 60px;">
    <tr>
     
      <th scope="col">
        <span
          class="th-label"
          sortable="fullName"
          (sort)="sortTable($event)">
           Name
        </span>
       <app-popover  #popoverName
          [active]="isFilterApply('fullName')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('fullName')">
        
       </app-popover>
     
      </th>
      <th scope="col">
        <span
          class="th-label"
          sortable="Title"
          (sort)="sortTable($event)">
          Title
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Title')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Title')">
        </app-popover>
       
      </th>
      <th scope="col">
        <span
          class="th-label">
         
          Action
        </span>
    </th>
    
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let report of userManagementPerPage; let i = index">
      
     
       <td>
        <span>{{report.fullName}}</span>
      </td>
      <td>
        <span>
          {{report.Title}}
        </span>
      </td> 
      <td>
        <button class="btn btn-primary btnDeleteUser" (click)="DeleteUser(report.ID)" type="button" [disabled]="isAdminDeleteUser">Delete</button>
    </td>
      </tr>
  </tbody>
</table>

<span *ngIf="userManagements && userManagements.length === 0" style="margin-left: 380px;" class="no-matches">No records matched your search.</span>
<br/>
<div class="row">
  <div class="col-md-5">
<app-pagination #pagination
*ngIf="userManagements" 
[page]="commonHelperService.state.page"
[pageSize]="commonHelperService.state.pageSize"
[paginationListValues]="pageSizeListValues"
[listElementsSize]="userManagements.length"
(paginationValues)="onChangePaginationValues($event)">
</app-pagination>
</div>
<div class="col-md-5">
<div class="divPageSizeType">
    <select id="ddlPageSize" (change)="onPageSizeValueChange($event)"
    [(ngModel)]="selectedPageSize"
     class="form-select form-control"
     [disabled]="pageDropdownDisable">
        <option value="" selected disabled>Select your option</option>
        <option *ngFor="let pages of pageSizeListValues" [value]="pages.key">
{{pages.value}}
</option>
      </select>
    </div>
</div>
</div>
<ng-template #popContent>
  <div class="filter-container">
    <div class="filter-body">
      <div
        class="row sort-row"
        (click)="sortTableOnFilterModal(targetField, 'asc')">
          <i class="fas fa-sort-alpha-down sort-icon"></i>
          <span>A on Top</span>
      </div>
      <div
        class="row sort-row"
        (click)="sortTableOnFilterModal(targetField, 'desc')">
          <i class="fas fa-sort-alpha-down-alt sort-icon"></i>
          <span>Z on Top</span>
      </div>
      <hr>
      <div class="row">
        <div
          [ngClass]="{'clear-filter-container-enable': isFilterApply(targetField)}"
          (click)="clearFilters()">
            <i class="fas fa-filter filter-icon"></i>
            <span
              class="filter-icon-span"
              [ngClass]="{'filter-icon-span-enable': !isFilterApply(targetField)}">
                Clear Filters from Value
            </span>
        </div>
      </div>
      <div *ngFor="let value of fieldOptionValues"
        class="row">
        <label class="checkbox-label">
          <input
            type="checkbox"
            class="checkbox-option-field"
            id="{{value}}"
            [checked]="isChecked(value)"
            (click)="onClickFilterCheckbox($event)">
          {{value}}
        </label>
      </div>
    </div>
  </div>
</ng-template>
</div>
  </div>
</div>
  <!-- <notifier-container style="background-color: lightgreen;"></notifier-container> -->
  <app-add-user-popup  (addUserDetails)="addUserDetails($event)" (CloseUserDetails)="CloseUserDetails($event)" [initialUserManagementList]="initialUserManagementList" [formTypeValue]="formTypeValue" [formTypeID]="selectedFormType"></app-add-user-popup>
  <app-notify-popup (addNotifyDetails)="addNotifyDetails($event)" (deleteConfirmDetails)="deleteConfirmDetails($event)"></app-notify-popup>
